import { defineComponent } from "vue";
import userHandler from "@/data/userHandler";
import LayoutsIntro from "@/components/layouts/Intro.vue";
import Button from "@/components/core/Button.vue";
import router from '@/router';
import pageHandler from "@/data/pageHandler";
export default defineComponent({
    name: 'ViewIntro',
    components: { LayoutsIntro, Button },
    methods: {
        start() {
            if (userHandler.getUser().getName()) {
                router.push('/main/tourCollectionSelection');
            }
            else {
                router.push('/main/login');
            }
        }
    },
    ionViewWillEnter() {
        pageHandler.emit('beforePageEnter');
    },
    ionViewDidEnter() {
        pageHandler.emit('pageEnter');
    },
});
