import { BaseChallenge, BaseChallengeItem, BaseChallengeIterable } from "@/data/challenges/challenge";
import PlaceOnAxis from "@/components/challenges/PlaceOnAxis.vue";
import BoneTenYears from "@/components/challengeDescriptions/boneTenYears.vue";
import userHandler from "@/data/userHandler";
import MediaImage from "@/data/mediaImage";
export class ChallengePlaceOnAxis extends BaseChallenge {
    baseDraggableItems = [];
    puzzleItems;
    dragItems = new BaseChallengeIterable();
    itemPositions = {};
    itemSubPositions = {};
    grantedPlacementDelta = 0;
    leftInfinity = false;
    rightInfinity = true;
    rotateAxisText = false;
    numberOfTargetBones = 0;
    numberOfBones = 0;
    draggableItemsAreImages = false;
    isPuzzle = false;
    isPuzzleImageToText = false;
    showPuzzlePiecesForImages = false;
    grantOneItemPerTarget = true;
    pullInItemOnDrop = false;
    useDots = false;
    constructor(items = [], grantedPlacementDelta = 0, leftInfinity = false, rightInfinity = true, rotateAxisText = false, numberOfTargetBones = 0, numberOfBones = 0, isPuzzle = false, isPuzzleImageToText = false, showPuzzlePiecesForImages = false, grantOneItemPerTarget = true, pullInItemOnDrop = false, useDots = false) {
        super('placeOnAxis', PlaceOnAxis, numberOfTargetBones > 0 ? BoneTenYears : null);
        this.items = items;
        this.grantedPlacementDelta = grantedPlacementDelta;
        this.leftInfinity = leftInfinity;
        this.rightInfinity = rightInfinity;
        this.rotateAxisText = rotateAxisText;
        this.numberOfTargetBones = numberOfTargetBones;
        this.numberOfBones = numberOfBones;
        this.isPuzzle = isPuzzle;
        this.isPuzzleImageToText = isPuzzleImageToText;
        this.showPuzzlePiecesForImages = showPuzzlePiecesForImages;
        this.grantOneItemPerTarget = grantOneItemPerTarget;
        this.pullInItemOnDrop = pullInItemOnDrop;
        this.useDots = useDots;
        // prepare items used as draggables
        this.baseDraggableItems = [];
        const puzzleItems = [];
        for (const item of items) {
            if (item.image) {
                this.draggableItemsAreImages = true;
            }
            if (item.isDraggable) {
                if (!item.autoPlace) {
                    const clonedItem = item.clone();
                    this.baseDraggableItems.push(clonedItem);
                }
            }
            if (this.isPuzzle
                &&
                    ((!this.isPuzzleImageToText
                        &&
                            item.isDraggable)
                        ||
                            (this.isPuzzleImageToText
                                &&
                                    !!item.text))) {
                puzzleItems.push(item.clone());
            }
        }
        this.puzzleItems = new BaseChallengeIterable(puzzleItems);
        this.puzzleItems.postInit();
        this.initDraggableItems();
    }
    init() {
        this.state = '';
        this.itemPositions = {};
        this.itemSubPositions = {};
        this.initDraggableItems();
        this.postInit();
    }
    initDraggableItems() {
        const baseDragItems = [];
        for (const item of this.baseDraggableItems) {
            baseDragItems.push(item.clone());
        }
        this.dragItems = new BaseChallengeIterable(baseDragItems);
        this.dragItems.shuffle();
        for (const item of this.items) {
            if (item.isDraggable && item.autoPlace) {
                this.dragItems.addItem(item.clone(), true);
            }
        }
        this.dragItems.postInit();
    }
    draggableItems() {
        return this.dragItems;
    }
    setItemPosition(itemKey, position, updateChosenState = false) {
        // set item position
        this.itemPositions[itemKey] = position;
        // set next free item sub-position
        // needed in placeImageToText mode
        const subPositions = [];
        for (const iks in this.itemPositions) {
            const ik = Number(iks);
            const p = this.itemPositions[iks];
            if (p === position && ik !== itemKey) {
                subPositions.push(this.itemSubPositions[ik]);
            }
        }
        subPositions.sort();
        let newSubPosition = -1;
        for (const is in subPositions) {
            const i = Number(is);
            if (i === 0 && subPositions[i] > 1) {
                newSubPosition = 1;
            }
            else if (i > 0 && subPositions[i] > (subPositions[i - 1] + 1)) {
                newSubPosition = subPositions[i - 1] + 1;
            }
        }
        if (newSubPosition < 0) {
            if (subPositions.length > 0) {
                newSubPosition = subPositions.pop() + 1;
            }
            else {
                newSubPosition = 1;
            }
        }
        this.itemSubPositions[itemKey] = newSubPosition;
        if (updateChosenState) {
            this.updateChosenState();
        }
        return this.itemSubPositions[itemKey];
    }
    getItemsPosition(itemKey) {
        return this.itemPositions[itemKey] || -1;
    }
    getItemsSubPosition(itemKey) {
        return this.itemSubPositions[itemKey] || -1;
    }
    getItemPosition(position) {
        for (const itemKey in this.itemPositions) {
            if (this.itemPositions[itemKey] === position) {
                return itemKey;
            }
        }
        return 0;
    }
    positionOccupied(position) {
        return !!this.getItemPosition(position);
    }
    getNumberOfPositions() {
        let nrOfPositions = 0;
        for (const itemKey in this.itemPositions) {
            if (this.itemPositions[itemKey] > 0) {
                nrOfPositions++;
            }
        }
        return nrOfPositions;
    }
    updateChosenState() {
        if (this.state != 'solved') {
            if (this.numberOfTargetBones > 0) {
                if (this.getNumberOfPositions() > 0) {
                    this.state = 'chosen';
                }
                else {
                    this.state = '';
                }
            }
            else if (this.dragItems) {
                let nrOfItems = 0;
                let nrOfChosenItems = 0;
                for (const baseItem of this.dragItems) {
                    const item = baseItem;
                    if (item && !item.autoPlace && item.isDraggable && item.position !== undefined) {
                        nrOfItems++;
                        if (this.itemPositions[item.key] !== undefined
                            &&
                                (!this.isPuzzle
                                    ||
                                        this.itemPositions[item.key] > 0)) {
                            nrOfChosenItems++;
                        }
                    }
                }
                if (nrOfChosenItems === nrOfItems) {
                    this.state = 'chosen';
                }
                else {
                    this.state = '';
                }
            }
        }
    }
    isCorrect() {
        return (this.state === 'solved' && this.score === 1);
    }
    evaluate() {
        if (this.state !== 'solved') {
            this.state = 'solved';
            this.score = 0;
            if (this.numberOfTargetBones > 0) {
                if (this.numberOfTargetBones == this.getNumberOfPositions()) {
                    this.score = 1;
                }
            }
            else if (this.dragItems) {
                let nrOfItems = 0;
                let nrOfCorrectItems = 0;
                for (const baseItem of this.dragItems) {
                    const item = baseItem;
                    if (item && !item.autoPlace && item.position !== undefined) {
                        nrOfItems++;
                        const itemPosition = this.itemPositions[item.key];
                        let grantedPlacementDelta = this.grantedPlacementDelta;
                        if (item.grantedPlacementDelta !== undefined) {
                            grantedPlacementDelta = item.grantedPlacementDelta;
                        }
                        if (itemPosition !== undefined && Math.abs(item.position - itemPosition) <= grantedPlacementDelta) {
                            nrOfCorrectItems++;
                        }
                    }
                }
                this.score = nrOfCorrectItems / nrOfItems;
            }
            userHandler.getUser().setScore(this.getTourCollectionKey(), this.getTourKey(), this.getTourPartIndex(), this.score);
        }
    }
}
export class ChallengeItemPlaceOnAxis extends BaseChallengeItem {
    axisText = '';
    position = 0;
    grantedPlacementDelta = 0;
    printAxisPoint = false;
    autoPlace = false;
    isDraggable = false;
    text = '';
    placementAreaWidth = 0;
    imageName = '';
    imageDescription = '';
    image = null;
    puzzlePartElements = {};
    rotateAxisText = false;
    constructor(axisText, position, grantedPlacementDelta = undefined, placementAreaWidth = undefined, printAxisPoint = true, autoPlace = false, isDraggable = false, text = '', imageName = '', imageDescription = '', puzzlePartElements = {}, rotateAxisText = false) {
        super('challenge');
        this.axisText = axisText;
        this.position = Math.round(position * 10000) / 10000;
        this.grantedPlacementDelta = grantedPlacementDelta;
        this.placementAreaWidth = placementAreaWidth;
        this.printAxisPoint = printAxisPoint;
        this.autoPlace = autoPlace;
        this.isDraggable = isDraggable;
        this.text = text;
        this.imageName = imageName;
        this.imageDescription = imageDescription;
        if (imageName) {
            this.image = new MediaImage(imageName, undefined, imageDescription);
        }
        this.puzzlePartElements = puzzlePartElements;
        this.rotateAxisText = rotateAxisText;
    }
    clone() {
        return new ChallengeItemPlaceOnAxis(this.axisText, this.position, this.grantedPlacementDelta, this.placementAreaWidth, this.printAxisPoint, this.autoPlace, this.isDraggable, this.text, this.imageName, this.imageDescription, this.puzzlePartElements);
    }
    static getPuzzlePathPart(puzzlePart, includeMoveTo = false, useAsClipPath = false) {
        let path = '';
        if (includeMoveTo) {
            path += 'M';
        }
        if (useAsClipPath) {
            switch (puzzlePart) {
                case 'tlp':
                    path += '0.152,0.99c0,0,0.089-0.46,0.046-0.628C0.191,0.335,0.176,0.276,0.142,0.263C0.107,0.249,0.08,0.295,0.062,0.278C0.039,0.256,0.05,0.147,0.085,0.131C0.127,0.11,0.19,0.237,0.205,0.222C0.222,0.205,0.169,0.01,0.169,0.01';
                    break;
                case 'trp':
                    path += '0.831,0.01c0,0-0.053,0.195-0.036,0.212C0.81,0.237,0.873,0.11,0.915,0.131C0.95,0.147,0.961,0.256,0.938,0.278C0.92,0.295,0.893,0.249,0.858,0.263C0.824,0.276,0.809,0.335,0.802,0.362C0.759,0.53,0.848,0.99,0.848,0.99';
                    break;
                case 'brp':
                    path += '0.848,0.01c0,0-0.089,0.46-0.046,0.628c0.007,0.027,0.022,0.086,0.056,0.099c0.035,0.014,0.063-0.032,0.08-0.015C0.961,0.744,0.95,0.853,0.915,0.869C0.873,0.89,0.81,0.763,0.795,0.778C0.778,0.795,0.831,0.99,0.831,0.99';
                    break;
                case 'blp':
                    path += '0.169,0.99c0,0,0.053-0.195,0.036-0.212C0.19,0.763,0.127,0.89,0.085,0.869C0.05,0.853,0.039,0.744,0.062,0.722c0.018-0.017,0.045,0.029,0.08,0.015c0.034-0.013,0.049-0.072,0.056-0.099C0.241,0.47,0.152,0.01,0.152,0.01';
                    break;
                case 'tln':
                    path += '0.108,0.99c0,0-0.089-0.46-0.046-0.628c0.007-0.027,0.022-0.086,0.056-0.099c0.035-0.014,0.063,0.032,0.08,0.015C0.221,0.256,0.21,0.147,0.175,0.131C0.133,0.11,0.07,0.237,0.055,0.222C0.038,0.205,0.091,0.01,0.091,0.01';
                    break;
                case 'trn':
                    path += '0.909,0.01c0,0,0.053,0.195,0.036,0.212C0.93,0.237,0.867,0.11,0.825,0.131C0.79,0.147,0.779,0.256,0.802,0.278c0.018,0.017,0.045-0.029,0.08-0.015c0.034,0.013,0.049,0.072,0.056,0.099C0.981,0.53,0.892,0.99,0.892,0.99';
                    break;
                case 'brn':
                    path += '0.892,0.01c0,0,0.089,0.46,0.046,0.628C0.931,0.665,0.916,0.724,0.882,0.737C0.847,0.751,0.82,0.705,0.802,0.722C0.779,0.744,0.79,0.853,0.825,0.869C0.867,0.89,0.93,0.763,0.945,0.778C0.962,0.795,0.909,0.99,0.909,0.99';
                    break;
                case 'bln':
                    path += '0.091,0.99c0,0-0.053-0.195-0.036-0.212C0.07,0.763,0.133,0.89,0.175,0.869C0.21,0.853,0.221,0.744,0.198,0.722C0.18,0.705,0.153,0.751,0.118,0.737C0.084,0.724,0.069,0.665,0.062,0.638C0.019,0.47,0.108,0.01,0.108,0.01';
                    break;
                default:
                    path = '';
                    break;
            }
        }
        else {
            switch (puzzlePart) {
                case 'tlp':
                    path += '15.2,74c0,0,8.9-34.3,4.6-46.8c-0.7-2-2.2-6.4-5.6-7.4c-3.5-1-6.3,2.4-8,1.1C3.9,19.3,5,11.2,8.5,10c4.2-1.5,10.5,7.9,12,6.8C22.2,15.5,16.9,1,16.9,1';
                    break;
                case 'trp':
                    path += '77.1,1c0,0-5.3,14.5-3.6,15.8c1.5,1.1,7.8-8.3,12-6.8c3.4,1.2,4.6,9.3,2.3,11c-1.8,1.3-4.5-2.1-8-1.1c-3.4,1-4.9,5.4-5.6,7.4C69.9,39.7,78.8,74,78.8,74';
                    break;
                case 'brp':
                    path += '78.8,1c0,0-8.9,34.3-4.6,46.8c0.7,2,2.2,6.4,5.6,7.4c3.5,1,6.3-2.4,8-1.1c2.3,1.6,1.1,9.7-2.3,11c-4.2,1.5-10.5-7.9-12-6.8C71.8,59.5,77.1,74,77.1,74';
                    break;
                case 'blp':
                    path += '16.9,74c0,0,5.3-14.5,3.6-15.8c-1.5-1.1-7.8,8.3-12,6.8C5,63.8,3.9,55.7,6.2,54c1.8-1.3,4.5,2.1,8,1.1c3.4-1,4.9-5.4,5.6-7.4C24.1,35.3,15.2,1,15.2,1';
                    break;
                case 'tln':
                    path += '10.8,74c0,0-8.9-34.3-4.6-46.8c0.7-2,2.2-6.4,5.6-7.4c3.5-1,6.3,2.4,8,1.1c2.3-1.6,1.1-9.7-2.3-11c-4.2-1.5-10.5,7.9-12,6.8C3.8,15.5,9.1,1,9.1,1';
                    break;
                case 'trn':
                    path += '84.9,1c0,0,5.3,14.5,3.6,15.8c-1.5,1.1-7.8-8.3-12-6.8c-3.4,1.2-4.6,9.3-2.3,11c1.8,1.3,4.5-2.1,8-1.1c3.4,1,4.9,5.4,5.6,7.4C92.1,39.7,83.2,74,83.2,74';
                    break;
                case 'brn':
                    path += '83.2,1c0,0,8.9,34.3,4.6,46.8c-0.7,2-2.2,6.4-5.6,7.4c-3.5,1-6.3-2.4-8-1.1c-2.3,1.6-1.1,9.7,2.3,11c4.2,1.5,10.5-7.9,12-6.8c1.7,1.3-3.6,15.8-3.6,15.8';
                    break;
                case 'bln':
                    path += '9.1,74c0,0-5.3-14.5-3.6-15.8c1.5-1.1,7.8,8.3,12,6.8c3.4-1.2,4.6-9.3,2.3-11c-1.8-1.3-4.5,2.1-8,1.1c-3.4-1-4.9-5.4-5.6-7.4C1.9,35.3,10.8,1,10.8,1';
                    break;
                default:
                    path = '';
                    break;
            }
        }
        return path;
    }
    static getPuzzlePieceOffsetXFactor(leftPart = '', rightPart = '') {
        const defaultPart = 'T  ';
        const isLeftPositive = String(leftPart || defaultPart).substr(2, 1).toUpperCase() === 'P';
        const isLeftNegative = String(leftPart || defaultPart).substr(2, 1).toUpperCase() === 'N';
        const isLeftNeutral = String(leftPart || defaultPart).substr(2, 1).toUpperCase() === ' ';
        const isRightPositive = String(rightPart || defaultPart).substr(2, 1).toUpperCase() === 'P';
        const isRightNegative = String(rightPart || defaultPart).substr(2, 1).toUpperCase() === 'N';
        const isRightNeutral = String(rightPart || defaultPart).substr(2, 1).toUpperCase() === ' ';
        if (isLeftPositive && isRightNegative) {
            return -.5;
        }
        if (isLeftNegative && isRightPositive) {
            return .5;
        }
        if (isLeftNeutral && isRightPositive) {
            return 1;
        }
        if (isLeftPositive && isRightNeutral) {
            return -1;
        }
        return 0;
    }
    static getPuzzlePath(leftPart = '', rightPart = '', useAsClipPath = false) {
        // center top
        let path = `M 47 1 L`;
        if (useAsClipPath) {
            path = `M .5 .01 L`;
        }
        // right path (downwards)
        if (rightPart) {
            path += ChallengeItemPlaceOnAxis.getPuzzlePathPart(rightPart, false, useAsClipPath);
        }
        else if (useAsClipPath) {
            path += ' .99 .01 L .99 .99 ';
        }
        else {
            path += ' 93 1 L 93 74 ';
        }
        // center bottom
        if (useAsClipPath) {
            path += ' L .5 .99 L ';
        }
        else {
            path += ' L 47 74 L ';
        }
        // left path (upwards)
        if (leftPart) {
            path += ChallengeItemPlaceOnAxis.getPuzzlePathPart(leftPart, false, useAsClipPath);
        }
        else if (useAsClipPath) {
            path += ' .01 .99 L .01 .01 ';
        }
        else {
            path += ' 1 74 L 1 1 ';
        }
        // center top
        path += ' Z';
        return path;
    }
    // eslint-disable-next-line
    static getPuzzlePieceClipPath(puzzleElements) {
        return this.getPuzzlePath(String(puzzleElements?.left || ''), String(puzzleElements?.right || ''), true);
    }
}
