import { markRaw } from "vue";
import { Tour, TourPart } from "@/data/tourHandlerCore";
import { YEAR } from "@/lib/constants";
import { ChallengeItemMultiSelectText, ChallengeMultiSelectImageText } from "@/data/challenges/challengeMultiSelectImageText";
import { ChallengeItemPairImageText, ChallengePairImageText } from "@/data/challenges/challengePairImageText";
import { ChallengeItemTimelineText, ChallengeTimeline } from "@/data/challenges/challengeTimeline";
import { ChallengeItemPlaceOnAxis, ChallengePlaceOnAxis } from "@/data/challenges/challengePlaceOnAxis";
import { ChallengeItemPickDropZoneImage, ChallengeItemPickDropZoneText, ChallengePickDropZone } from "@/data/challenges/challengePickDropZone";
export default function (tourCollectionKey) {
    const tour = new Tour(tourCollectionKey, 'C', 'Schnüffeltour 3', false, '', true);
    tour.addPart(new TourPart('', 'Hör dir an, was Lorenz über seinen Besuch im Museum Bürmoos zu berichten hat!', 'Letztes Jahr besuchte ich mit meiner Klasse das Museum in Bürmoos. Das hat mir gut gefallen.<br><br>Was wir da alles selber entdecken konnten! Wir haben einige Dinge aus der Vergangenheit auch angreifen dürfen.<br><br>Zum Beispiel die große Pfeife zum Glasblasen. Diese Pfeife war echt schwer.<br><br>Damit konnte man Dinge aus Glas herstellen.<br><br>Und wir haben mit einer Glasfeder und Tinte unsere Namen in einer alten Schrift geschrieben.<br><br>In einer alten Küche, die im Museum aufgebaut ist, konnten wir uns anschauen, wie die Menschen früher zu Hause lebten. Wir konnten auch vieles ausprobieren.<br><br>Lustig waren auch die Museumsmaskottchen: Die Gänse Olga und Gustl, die uns auf unserer Entdeckungstour durch das Museum begleitet haben.<br><br>Nach dem Besuch hab ich noch viel über die Vergangenheit nachgedacht. Früher war im Vergleich zu heute vieles ganz anders.', true, null, false, false, 'bm_lorenz_museumsbesuch.m4a', undefined, undefined, undefined, true));
    tour.addPart(new TourPart('', 'Lorenz hat heute seinen siebten Geburtstag. Genau vor einem Jahr war er im Museum in Bürmoos.<br><br>In welchem Jahr fanden die anderen Ereignisse statt? Jetzt musst du auch ein bisschen rechnen.', '', true, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 8), (3.5 + 0 * 15 / 8) / 22, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 7), (3.5 + 1 * 15 / 8) / 22, undefined, undefined, true, false, true, 'Geburt von Lorenz'),
        new ChallengeItemPlaceOnAxis(String(YEAR - 6), (3.5 + 2 * 15 / 8) / 22, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 5), (3.5 + 3 * 15 / 8) / 22, undefined, undefined, true, false, true, '2. Geburtstag von Lorenz'),
        new ChallengeItemPlaceOnAxis(String(YEAR - 4), (3.5 + 4 * 15 / 8) / 22, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 3), (3.5 + 5 * 15 / 8) / 22, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 2), (3.5 + 6 * 15 / 8) / 22, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 1), (3.5 + 7 * 15 / 8) / 22, undefined, undefined, true, false, true, 'Museums-Besuch von Lorenz'),
        new ChallengeItemPlaceOnAxis(String(YEAR), (3.5 + 8 * 15 / 8) / 22, undefined, undefined, true, true, true, '7. Geburtstag von Lorenz'),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false, false),
    ], 1 / 22, true, true, true, 0, 0, false, false, false, false, false, false)));
    tour.addPart(new TourPart('', '', 'Lorenz feiert heute seinen siebten Geburtstag.<br><br>Welches der drei Ereignisse liegt für Lorenz nicht in der Vergangenheit?', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Museumsbesuch', false),
        new ChallengeItemMultiSelectText('Geburt', false),
        new ChallengeItemMultiSelectText('7. Geburtstag', true)
    ], undefined, true, true)));
    tour.addPart(new TourPart('', 'Das Torf-Glas-Ziegel-Museum erzählt auch Geschichten. Dort wird ebenso etwas über die Vergangenheit erzählt.', 'Vor 150 Jahren brauchte man in Bürmoos viele Arbeiter für den Torfabbau und für die Herstellung von Glas und von Ziegelsteinen.<br><br>So kamen Arbeiter mit ihren Familien aus anderen Ländern und brachten auch ihre eigene Lebensweise mit.<br><br>Da war was los im Ort und in der Schule! Man hörte ganz verschiedene Sprachen und Dialekte.<br><br>Mittags roch es aus jeder Wohnung anders. Die Menschen kochten nämlich die Speisen aus ihrer Heimat.<br><br>Im Museum gibt es zum Beispiel eine Liwanzenpfanne. Darin wurden böhmische Liwanzen, das sind spezielle, kleine Palatschinken gemacht.<br><br>Auch heute erinnern noch einige Speisen an die Länder aus denen die Arbeiter gekommen sind.', true, undefined, false, false, 'bm_torfabbau.m4a', undefined, undefined, undefined, true));
    tour.addPart(new TourPart('', 'Vor 150 Jahren kamen viele Arbeiter nach Bürmoos.<br><br>Lorenz ist heute 7 Jahre alt geworden.<br><br>Wo am Zeitstrahl müssen seine sieben Lebensjahre eingeordnet werden? Ziehe!', '', true, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis('vor 150 Jahren', 2 / 21, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('', 10.5 / 21, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('', 18 / 21, .5 / 21, 2 / 21, false, false, true, 'Lebens-abschnitt von Lorenz'),
        new ChallengeItemPlaceOnAxis('heute', 19 / 21, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false, false),
    ], 1 / 21, true, true, true)));
    tour.addPart(new TourPart('', 'Wo liegen die Zeitabschnitte? Ziehe!', '', true, new ChallengeTimeline([
        new ChallengeItemTimelineText('Vergangenheit', 'gestern'),
        new ChallengeItemTimelineText('Jetztzeit /<br>Gegenwart', 'heute'),
        new ChallengeItemTimelineText('Zukunft', 'morgen'),
    ])));
    tour.addPart(new TourPart('', '', 'Erzählt man in einem Museum über die Vergangenheit, nennt man das Geschichte.<br><br>Was braucht ein Museum dazu, um das gut zu machen?', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Wissen über die Zukunft', false),
        new ChallengeItemMultiSelectText('Dinge von früher', true),
        new ChallengeItemMultiSelectText('Fantasie', false)
    ], undefined, true, true)));
    tour.addPart(new TourPart('', '', 'Erzählt man in einem Museum über die Vergangenheit, nennt man das Geschichte.<br><br>Was braucht ein Museum dazu, um das gut zu machen?', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Dinge aus dem Supermarkt', false),
        new ChallengeItemMultiSelectText('alte Aufzeichnungen', true),
        new ChallengeItemMultiSelectText('Fotos von heute', false)
    ], undefined, true, true)));
    tour.addPart(new TourPart('', '', 'Es gibt unterschiedliche Museen. Manche Museen beschäftigen sich mit <b>Kunst</b>, andere mit <b>Technik</b> oder <b>Natur</b>, wieder andere mit <b>Geschichte</b>.<br><br>In Museen finden sich unterschiedliche Dinge.', true));
    tour.addPart(new TourPart('Finde die richtigen Paare!', 'Ziehe Bild und Erklärung zusammen.', '', false, new ChallengePairImageText([
        new ChallengeItemPairImageText('Kunst-<br>museum', 'bm_kunstmuseum_personen.jpg'),
        new ChallengeItemPairImageText('Naturkunde-<br>museum', 'bm_naturkundemuseum_muscheln.jpg'),
        new ChallengeItemPairImageText('Geschichts-<br>museum', 'bm_geschichtsmuseum_ruestungen.jpg'),
    ])));
    tour.addPart(new TourPart('', 'Das Museum in Bürmoos, ein besonderes Museum ...', 'Das Museum in Bürmoos ist ein <b>Geschichtsmuseum</b>. Es beschäftigt sich damit, wie früher im Moor <b>Torferde abgebaut</b> wurde.<br><br>Es zeigt auch, wie ein Ziegelstein entsteht und wie <b>Glas</b> vor über 100 Jahren für ganz verschiedene Zwecke <b>erzeugt</b> wurde.<br><br>Im Museum wird auch über das <b>Leben der Bevölkerung von damals</b> erzählt.<br><br>So erfährt man etwas über eine vergangene Zeit.', true, undefined, false, false, '', { name: 'bm_museum_intro.mp4', portrait: true }));
    tour.addPart(new TourPart('', '', 'Was wird also vom Museum in Bürmoos gezeigt?', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Kunst', false),
        new ChallengeItemMultiSelectText('Geschichte', true),
        new ChallengeItemMultiSelectText('Natur', false)
    ], undefined, false, true)));
    tour.addPart(new TourPart('', '', 'Die Dinge aus dem nächsten Spiel stammen aus dem Museum in Bürmoos.<br><br>Sie helfen uns zu erkennen, dass es früher anders war.<br><br>Manche Dinge gibt es so gar nicht mehr, andere haben sich verändert.', true));
    tour.addPart(new TourPart('', 'Ist das Ding aus unserem Alltag verschwunden? Oder wird das Ding heute noch in einer veränderten Form genützt?<br><br>Tippe auf das Bild, um es zu vergrößern.<br><br>Ziehe!', '', true, new ChallengePickDropZone(new ChallengeItemPickDropZoneImage('bm_ziegelmodel.png', 'Holzmodl zur Herstellung von Ziegel, Bürmoos, frühes 20. Jh.'), [
        new ChallengeItemPickDropZoneText('verschwunden', true),
        new ChallengeItemPickDropZoneText('verändert', false)
    ])));
    tour.addPart(new TourPart('', 'Ist das Ding aus unserem Alltag verschwunden? Oder wird das Ding heute noch in einer veränderten Form genützt?<br><br>Tippe auf das Bild, um es zu vergrößern.<br><br>Ziehe!', '', true, new ChallengePickDropZone(new ChallengeItemPickDropZoneImage('bm_kuechenwaage_alt.png', 'Haushaltswaage, Bürmoos, 2. Hälfte des 20. Jh.'), [
        new ChallengeItemPickDropZoneText('verschwunden', false),
        new ChallengeItemPickDropZoneText('verändert', true)
    ])));
    tour.addPart(new TourPart('', 'Ist das Ding aus unserem Alltag verschwunden? Oder wird das Ding heute noch in einer veränderten Form genützt?<br><br>Tippe auf das Bild, um es zu vergrößern.<br><br>Ziehe!', '', true, new ChallengePickDropZone(new ChallengeItemPickDropZoneImage('bm_pferdeschuh.png', 'Schuh für Pferde, um im Moor nicht einzusinken, Bürmoos, 19. Jh.'), [
        new ChallengeItemPickDropZoneText('verschwunden', true),
        new ChallengeItemPickDropZoneText('verändert', false)
    ])));
    tour.addPart(new TourPart('', 'Ist das Ding aus unserem Alltag verschwunden? Oder wird das Ding heute noch in einer veränderten Form genützt?<br><br>Tippe auf das Bild, um es zu vergrößern.<br><br>Ziehe!', '', true, new ChallengePickDropZone(new ChallengeItemPickDropZoneImage('bm_sparherd.png', 'Sparherd zum Kochen und Heizen, Bürmoos, Mitte 20. Jh.'), [
        new ChallengeItemPickDropZoneText('verschwunden', false),
        new ChallengeItemPickDropZoneText('verändert', true)
    ])));
    tour.addPart(new TourPart('', 'Ist das Ding aus unserem Alltag verschwunden? Oder wird das Ding heute noch in einer veränderten Form genützt?<br><br>Tippe auf das Bild, um es zu vergrößern.<br><br>Ziehe!', '', false, new ChallengePickDropZone(new ChallengeItemPickDropZoneImage('bm_torfstecher.png', 'Torfstechmesser; Schaufel zum Abbau von Torf, Bürmoos, um 1900'), [
        new ChallengeItemPickDropZoneText('verschwunden', true),
        new ChallengeItemPickDropZoneText('verändert', false)
    ])));
    return markRaw(tour);
}
