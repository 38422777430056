import { defineComponent } from 'vue';
import { IonHeader, IonToolbar, IonMenuButton, IonButtons } from '@ionic/vue';
import userHandler from '@/data/userHandler';
import router from "@/router";
import SymbolBone from "@/components/symbols/bone.vue";
import pageHookMixin from "@/data/pageHookMixin";
import { round, vh, vw } from "@/lib/utils";
import pageHandler from "@/data/pageHandler";
export default defineComponent({
    name: 'Header',
    mixins: [pageHookMixin],
    components: {
        SymbolBone,
        IonHeader,
        IonToolbar,
        IonMenuButton,
        IonButtons
    },
    props: {
        progress: {
            type: Number,
            default: 0
        },
        maxProgress: {
            type: Number,
            default: 0
        },
        preventTopRightContent: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        user() {
            return userHandler.getUser();
        },
        screen() {
            return pageHandler.getScreen();
        },
        topRightStyle() {
            let width = '0px';
            let dotWidth = '5%';
            const maxProgress = Math.max(0, this.maxProgress);
            const progress = Math.max(0, Math.min(maxProgress, this.progress));
            const screen = this.screen;
            if (maxProgress > 0) {
                dotWidth = round(Math.min(vh(2.5, screen), vw((1 / maxProgress) * 75, screen)), 0);
                width = `calc(${dotWidth}px * ${maxProgress - progress})`;
            }
            return {
                '--anti-progress-bar-width': width,
                '--dot-width': `${dotWidth}px`,
            };
        }
    },
    methods: {
        login() {
            router.push('/main/login');
        },
    }
});
