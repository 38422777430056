export default class Emitter {
    events = {};
    constructor() {
        this.events = {};
    }
    on(groupID, elementID, handler) {
        if (!this.events[groupID]) {
            this.events[groupID] = {};
        }
        this.events[groupID][elementID] = handler;
    }
    off(groupID, elementID) {
        if (this.events[groupID]) {
            delete this.events[groupID][elementID];
        }
    }
    emit(groupID, event = {}) {
        for (const elementID in this.events[groupID] || {}) {
            this.events[groupID][elementID](event);
        }
    }
}
