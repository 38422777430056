import { markRaw } from "vue";
import { Tour, TourPart } from "@/data/tourHandlerCore";
import { ChallengeItemMultiSelectImage, ChallengeItemMultiSelectText, ChallengeMultiSelectImageText } from "@/data/challenges/challengeMultiSelectImageText";
import { ChallengeItemSingleSelectImage, ChallengeSingleSelectImage } from "@/data/challenges/challengeSingleSelectImage";
import { ChallengeItemPlaceOnAxis, ChallengePlaceOnAxis } from "@/data/challenges/challengePlaceOnAxis";
import { ChallengeItemPickTextBySwipe, ChallengePickTextBySwipe } from "@/data/challenges/challengePickTextBySwipe";
import { ChallengeItemOrderText, ChallengeOrderText } from "@/data/challenges/challengeOrderText";
export default function (tourCollectionKey) {
    const tour = new Tour(tourCollectionKey, 'D', 'Knochenjagd', true, '', true);
    tour.addPart(new TourPart('', 'Hör genau hin, was in der Sprachaufzeichnung erzählt wird.<br><br>Damit kannst du die nächste Aufgabe lösen.', '„Es war einmal eine Königin. Sie lebte auf einem schönen Schloss weit oben in den Bergen ...“<br><br>Solche Geschichten nennt man <b>Märchen</b>. Sie erzählen uns wundersame Geschichten von seltsamen Wesen und erfundenen Menschen. Wann und wo die Geschichten spielen, ist nicht bekannt.<br><br><b>Geschichten im Museum</b> sind anders. Sie erzählen uns von echten Menschen an bestimmten Orten in der Vergangenheit.', true, undefined, false, false, 'xx_knochenjagd_maerchen.m4a'));
    tour.addPart(new TourPart('Ist das ein Merkmal von Märchen?', '', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Ort und Zeit sind bekannt', true), 'nein', 'ja')));
    tour.addPart(new TourPart('Ist das ein Merkmal von Märchen?', '', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('frei erfundene Geschichten', false), 'nein', 'ja')));
    tour.addPart(new TourPart('Ist das ein Merkmal von Märchen?', '', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Fantastische Wesen treten auf', false), 'nein', 'ja')));
    tour.addPart(new TourPart('Ist das ein Merkmal von Märchen?', '', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Geschichten über Ereignisse, die wirklich stattgefunden haben', true), 'nein', 'ja')));
    tour.addPart(new TourPart('', 'Was sind die Merkmale von Geschichten im Museum?<br>Kreuze <b>alle</b> richtigen Antworten an!', '', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('frei erfundene Geschichten', false),
        new ChallengeItemMultiSelectText('Ort und Zeit sind bekannt', true),
        new ChallengeItemMultiSelectText('Fantastische Wesen treten auf', false),
        new ChallengeItemMultiSelectText('Geschichten über Ereignisse, die wirklich stattgefunden haben', true),
    ])));
    tour.addPart(new TourPart('', '', 'Mit Dingen von früher kann man herausfinden, wie Menschen in der Vergangenheit gelebt haben. Erzählt man davon, so wird Geschichte erzählt.<br><br>Willst du dir eine Geschichte aus dem Museum in Bürmoos anhören?', false, undefined, false, false, 'bm_fensterglas.mp3'));
    tour.addPart(new TourPart('', '', 'Willst du etwas über die Vergangenheit herausfinden, musst du Dinge von früher untersuchen. Diese Dinge nennt man dann Quellen.<br><br>Aus ihnen kann man Informationen erhalten. Geschichte in einem Museum wird mit diesen Informationen erzählt.<br><br>Quellen gibt es in verschiedenen Formen.', true));
    tour.addPart(new TourPart('', '', 'Markiere die <b>schriftliche</b> Quelle.', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(false, 'bm_dachziegelmacher.jpg', 'Foto von jugendlichen Dachziegelmachern, Bürmoos, aufgenommen vor 90 Jahren'),
        new ChallengeItemSingleSelectImage(true, 'bm_arbeitsvertrag.png', 'Arbeitsvertrag zwischen einem Arbeiter und dem Fabriksbesitzer Ignaz Glaser, abgeschlossen vor 120 Jahren'),
        new ChallengeItemSingleSelectImage(false, 'bm_ziegel_buermoos.png', 'Ziegelstein mit Aufschrift, Bürmoos, in etwa 140 Jahre alt'),
    ])));
    tour.addPart(new TourPart('', '', 'Markiere die <b>bildliche</b> Quelle.', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(true, 'bm_dachziegelmacher.jpg', 'Foto von jugendlichen Dachziegelmachern, Bürmoos, aufgenommen vor 90 Jahren'),
        new ChallengeItemSingleSelectImage(false, 'bm_arbeitsvertrag.png', 'Arbeitsvertrag zwischen einem Arbeiter und dem Fabriksbesitzer Ignaz Glaser, abgeschlossen vor 120 Jahren'),
        new ChallengeItemSingleSelectImage(false, 'bm_ziegel_buermoos.png', 'Ziegelstein mit Aufschrift, Bürmoos, in etwa 140 Jahre alt'),
    ])));
    tour.addPart(new TourPart('', '', 'Markiere die <b>gegenständliche</b> Quelle.', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(false, 'bm_dachziegelmacher.jpg', 'Foto von jugendlichen Dachziegelmachern, Bürmoos, aufgenommen vor 90 Jahren'),
        new ChallengeItemSingleSelectImage(false, 'bm_arbeitsvertrag.png', 'Arbeitsvertrag zwischen einem Arbeiter und dem Fabriksbesitzer Ignaz Glaser, abgeschlossen vor 120 Jahren'),
        new ChallengeItemSingleSelectImage(true, 'bm_ziegel_buermoos.png', 'Ziegelstein mit Aufschrift, Bürmoos, in etwa 140 Jahre alt'),
    ])));
    tour.addPart(new TourPart('', 'Mit welcher Form von Quelle haben wir es hier zu tun?', 'Sieh dir das Video an und markiere die Form der Quelle!', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Schriftliche Quelle', false),
        new ChallengeItemMultiSelectText('Mündliche Quelle', true),
        new ChallengeItemMultiSelectText('Gegenständliche Quelle', false),
    ]), false, false, '', { name: 'bm_torf_kasteln.mp4', portrait: false }));
    tour.addPart(new TourPart('', 'Eine Geschichte aus dem Museum: „Vor 100 Jahren wurde in Bürmoos Torferde von Hand aus dem Moor gestochen. Das war eine sehr anstrengende Arbeit. Die getrockneten Torfziegel dienten als Heizmaterial für die Fabrik. Heute darf aus Umweltschutzgründen Torf nicht mehr abgebaut werden.“<br><br>Schau dir das Foto genau an.', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Kann dieses Foto aus der Vergangenheit von Bürmoos als Quelle diese Geschichte beweisen?', true), 'falsch', 'richtig'), false, true, '', '', 'bm_alleestrasse.jpg', 'Foto von Alleestraße, Bürmoos, 1935'));
    tour.addPart(new TourPart('', 'Eine Geschichte aus dem Museum: „Vor 100 Jahren wurde in Bürmoos Torferde von Hand aus dem Moor gestochen. Das war eine sehr anstrengende Arbeit. Die getrockneten Torfziegel dienten als Heizmaterial für die Fabrik. Heute darf aus Umweltschutzgründen Torf nicht mehr abgebaut werden.“<br><br>Schau dir das Foto genau an.', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Kann dieses Foto aus der Vergangenheit von Bürmoos als Quelle diese Geschichte beweisen?', true), 'falsch', 'richtig'), false, true, '', '', 'bm_kirche.jpg', 'Foto, Bürmooser Kirche, 1956'));
    tour.addPart(new TourPart('', 'Eine Geschichte aus dem Museum: „Vor 100 Jahren wurde in Bürmoos Torferde von Hand aus dem Moor gestochen. Das war eine sehr anstrengende Arbeit. Die getrockneten Torfziegel dienten als Heizmaterial für die Fabrik. Heute darf aus Umweltschutzgründen Torf nicht mehr abgebaut werden.“<br><br>Schau dir das Foto genau an.', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Kann dieses Foto aus der Vergangenheit von Bürmoos als Quelle diese Geschichte beweisen?', false), 'falsch', 'richtig'), false, true, '', '', 'bm_torfstecher_arbeit.jpg', 'Foto von Torfstechern, Bürmoos, 1920'));
    tour.addPart(new TourPart('', 'Eine Geschichte aus dem Museum: „Vor 100 Jahren wurde in Bürmoos Torferde von Hand aus dem Moor gestochen. Das war eine sehr anstrengende Arbeit. Die getrockneten Torfziegel dienten als Heizmaterial für die Fabrik. Heute darf aus Umweltschutzgründen Torf nicht mehr abgebaut werden.“<br><br>Schau dir das Foto genau an.', '', false, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Kann dieses Foto aus der Vergangenheit von Bürmoos als Quelle diese Geschichte beweisen?', true), 'falsch', 'richtig'), false, true, '', '', 'bm_dampflock.jpg', 'Foto von Dampflok, Bürmoos, 1900'));
    tour.addPart(new TourPart('', 'Hier haben wir eine historische Quelle. Um welche Art von Quelle handelt es sich?', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Schriftliche Quelle', false),
        new ChallengeItemMultiSelectText('Bildliche Quelle', true),
        new ChallengeItemMultiSelectText('Gegenständliche Quelle', false),
        new ChallengeItemMultiSelectText('Mündliche Quelle', false)
    ], [
        new ChallengeItemMultiSelectImage('bm_torfstecher_arbeit.jpg', undefined, 'Foto von Torfstechern, Bürmoos, 1920, vor 100 Jahren'),
    ])));
    tour.addPart(new TourPart('', 'Was kannst du auf dem Ausschnitt des Fotos erkennen?', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Männlicher Torfstecher', true),
        new ChallengeItemMultiSelectText('Werkzeug', false),
        new ChallengeItemMultiSelectText('Ausgestochener Torf', false)
    ], [
        new ChallengeItemMultiSelectImage('bm_torfstecher_arbeit.jpg', {
            x: .66,
            y: 0,
            w: .19,
            h: .65
        }, 'Foto von Torfstechern, Bürmoos, 1920, vor 100 Jahren'),
    ], true)));
    tour.addPart(new TourPart('', 'Was kannst du auf dem Ausschnitt des Fotos erkennen?', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Männlicher Torfstecher', false),
        new ChallengeItemMultiSelectText('Werkzeug', true),
        new ChallengeItemMultiSelectText('Ausgestochener Torf', false)
    ], [
        new ChallengeItemMultiSelectImage('bm_torfstecher_arbeit.jpg', {
            x: .25,
            y: .3,
            w: .09,
            h: .35
        }, 'Foto von Torfstechern, Bürmoos, 1920, vor 100 Jahren'),
    ], true)));
    tour.addPart(new TourPart('', 'Was kannst du auf dem Ausschnitt des Fotos erkennen?', '', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Männlicher Torfstecher', false),
        new ChallengeItemMultiSelectText('Werkzeug', false),
        new ChallengeItemMultiSelectText('Ausgestochener Torf', true)
    ], [
        new ChallengeItemMultiSelectImage('bm_torfstecher_arbeit.jpg', {
            x: .08,
            y: .12,
            w: .14,
            h: .18
        }, 'Foto von Torfstechern, Bürmoos, 1920, vor 100 Jahren'),
    ], true)));
    tour.addPart(new TourPart('', 'Das Foto stammt aus Bürmoos und ist 100 Jahre alt.<br><br>Welche Fragen kann man anhand dieser bildlichen Quelle beantworten?<br><br>Wähle <b>alle</b> richtigen Fragen aus!', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Was machten die Arbeiter vor 100 Jahren in ihrer Freizeit?', false),
        new ChallengeItemMultiSelectText('Wie viel verdienten Arbeiter in der Vergangenheit?', false),
        new ChallengeItemMultiSelectText('Wie sah die Arbeitskleidung von Torfstechern aus?', true),
        new ChallengeItemMultiSelectText('Mit welchen Werkzeugen arbeiteten Torfarbeiter vor 100 Jahren?', true)
    ], [
        new ChallengeItemMultiSelectImage('bm_torfstecher_arbeit.jpg', undefined, 'Foto von Torfstechern, Bürmoos, 1920, vor 100 Jahren'),
    ], false)));
    tour.addPart(new TourPart('', 'Mit Quellen können wir Informationen über die Vergangenheit herausfinden.<br>Du fragst dich: Wie war das Leben der Torfarbeiter vor 100 Jahren?', 'Diese Quelle erlaubt verschiedene Antworten.<br>Markiere <b>alle</b> möglichen Antworten.', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Die Arbeiter hatten viel Freizeit.', false),
        new ChallengeItemMultiSelectText('Torfarbeiter arbeiteten gemeinsam im Moor.', true),
        new ChallengeItemMultiSelectText('Es wurden spezielle Werkzeuge eingesetzt.', true),
        new ChallengeItemMultiSelectText('Das Wetter im Sommer war früher immer warm.', false)
    ], [
        new ChallengeItemMultiSelectImage('bm_torfstecher_arbeit.jpg', undefined, 'Foto von Torfstechern, Bürmoos, 1920, vor 100 Jahren'),
    ], false)));
    tour.addPart(new TourPart('', 'Oje, jetzt ist uns die Geschichte durcheinander gekommen. Bringe die Teile in die richtige Reihenfolge. Ziehe!', '', false, new ChallengeOrderText([
        new ChallengeItemOrderText('Vor tausenden Jahren wurde in Ägypten schon mit Ziegelsteinen gebaut.'),
        new ChallengeItemOrderText('Dabei wurde der Lehm (Ton) für den Ziegel per Hand mit einer Holzform geformt.'),
        new ChallengeItemOrderText('Anschließend wurden die getrockneten Ziegelsteine in einem speziellen Ofen steinhart „gebacken“.'),
        new ChallengeItemOrderText('Heute übernehmen große Maschinen die Arbeit und es gibt viele verschiedene Ziegelsteine.')
    ])));
    tour.addPart(new TourPart('', '', '', false, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis('20', 1 / 5, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('40', 2 / 5, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('60', 3 / 5, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('80', 4 / 5, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false)
    ], .1, false, false, false, 10, 12)));
    tour.addPart(new TourPart('', 'Über welche Ereignisse aus den letzten 170 Jahren erzählt das Torf-Glas-Ziegel-Museum?<br><br>Tippe auf ein Bild, um es zu vergrößern. Dort findest du wichtige Hinweise.<br><br>Ziehe die Bilder an die richtige Stelle auf dem Zeitstrahl.', '', false, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis('', 2 / 21, undefined, undefined, true, false, true, '', 'bm_torfziegel.png', 'Beginn des Torfabbaus vor 170 Jahren, Torfziegel aus 1852'),
        new ChallengeItemPlaceOnAxis('', 8 / 21, undefined, undefined, true, false, true, '', 'bm_rechnung-alt.png', 'Höhepunkt der Torf-Glas-Ziegel-Industrie vor 110 Jahren. Rechnung aus 1913'),
        new ChallengeItemPlaceOnAxis('vor 100 Jahren', 9 / 21, undefined, undefined, true, false, false),
        new ChallengeItemPlaceOnAxis('', 14 / 21, undefined, undefined, true, false, true, '', 'bm_buergermeister.png', 'Gemeindegründung von Bürmoos 1967, vor 50 Jahren. Portrait des 1. Bürgermeisters'),
        new ChallengeItemPlaceOnAxis('heute', 19 / 21, undefined, undefined, true, false, false),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false, false),
    ], 1 / 21, true, true, true, 0, 0, false, false, false, true, false, true)));
    return markRaw(tour);
}
