import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { IonicVue } from '@ionic/vue';
import { SplashScreen } from '@capacitor/splash-screen';
import { TextZoom } from '@capacitor/text-zoom';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
/* Theme variables */
import './theme/variables.css';
import './theme/fonts.css';
import userHandler from "@/data/userHandler";
import pageHandler from "@/data/pageHandler";
import { PLATFORM } from "@/lib/constants";
(async () => {
    // load actual user
    await userHandler.loadUserList();
    // initialize app
    const rootProps = {};
    const app = createApp(App, rootProps)
        .use(IonicVue, {
        animated: true,
    })
        .use(router);
    // set pageHandler emitter
    app.config.globalProperties.emitter = pageHandler.emitter;
    // wait for router
    await router.isReady();
    // prevent app from zooming
    if (PLATFORM !== 'web') {
        await TextZoom.set({ value: 1 });
    }
    // mount app
    app.mount('#app');
    // hide splash screen
    await SplashScreen.hide();
})();
