import { defineComponent } from "vue";
import SymbolSolution from "@/components/symbols/solution.vue";
import pageHookMixin from "@/data/pageHookMixin";
export default defineComponent({
    name: 'DropZone',
    components: { SymbolSolution },
    mixins: [pageHookMixin],
    props: {
        horizontalElements: {
            type: String,
            default: 'triple' // single, double, triple
        },
        reverse: {
            type: Boolean,
            default: false
        },
        showConnector: {
            type: Boolean,
            default: false
        },
        showDropZoneSymbol: {
            type: Boolean,
            default: true
        },
        dropZoneKey: {
            type: Number,
            default: -1
        },
        challengeState: {
            type: String,
            default: ''
        },
        itemKey: {
            type: Number,
            default: -1
        },
        itemIsCorrect: {
            type: Boolean,
            default: false
        },
    },
    data() {
        const cssClasses = ['dropZoneContainer'];
        if (this.reverse) {
            cssClasses.push('reverse');
        }
        if (this.horizontalElements === 'triple' || this.horizontalElements === 'double') {
            cssClasses.push(this.horizontalElements);
        }
        else {
            cssClasses.push('single');
        }
        return {
            cssClasses: cssClasses.join(' '),
            showOuterBottomDescription: !!this.$slots.outerBottomDescription,
        };
    },
    watch: {
        state(newState, oldState) {
            if (this.$el && newState.isSelected && oldState.isSelected != newState.isSelected) {
                const newRotation = `${Math.floor(Math.random() * 60) - 30}deg`;
                this.$el.style.setProperty('--selected-offset-rotation', newRotation);
            }
        }
    },
    computed: {
        state() {
            let isSelected = false;
            let isCorrect = false;
            let isWrong = false;
            let isInvalid = false;
            if (this.challengeState === 'invalid') {
                isInvalid = true;
            }
            else if (this.challengeState === 'solved') {
                if (this.itemKey > -1) {
                    if (this.itemIsCorrect) {
                        isCorrect = true;
                    }
                    else {
                        isWrong = true;
                    }
                }
            }
            else if (this.itemKey > -1) {
                isSelected = true;
            }
            return {
                isSelected,
                isCorrect,
                isWrong,
                isInvalid,
            };
        },
    },
});
