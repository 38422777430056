import { reactive } from 'vue';
import { Storage } from '@capacitor/storage';
import { MAX_BONES_PER_TOUR, TECH_DEMO_USER } from "@/lib/constants";
import audioHandler from "@/data/audioHandler";
export class User {
    index = -1;
    name = '';
    scores = {};
    completedTours = {};
    unlockedTours = {};
    constructor(data = { name: '', index: -1, scores: {}, completedTours: {}, unlockedTours: {} }) {
        this.set(data);
    }
    set(data = { name: '', index: -1, scores: {}, completedTours: {}, unlockedTours: {} }) {
        data = data || {};
        this.index = data.index;
        this.name = data.name;
        this.scores = data.scores;
        this.completedTours = data.completedTours;
        this.unlockedTours = data.unlockedTours;
    }
    getName() {
        return this.name;
    }
    setName(name) {
        this.name = name;
    }
    getIndex() {
        return this.index;
    }
    setIndex(index) {
        this.index = index;
    }
    getScores() {
        return this.scores;
    }
    setScores(scores = {}) {
        this.scores = scores;
    }
    completeTour(tourCollection, tourKey) {
        if (tourCollection && tourKey) {
            if (!this.completedTours[tourCollection.key])
                this.completedTours[tourCollection.key] = {};
            this.completedTours[tourCollection.key][tourKey] = true;
            let toursCompleted = true;
            for (const tour of tourCollection.getTours()) {
                if (tour.ancestorsHasToBeCompleted && toursCompleted && (!tour.lockedByUserName || this.amI(tour.lockedByUserName))) {
                    this.unlockTour(tourCollection.key, tour.getKey());
                }
                toursCompleted = toursCompleted && this.completedTours[tourCollection.key][tour.getKey()];
            }
        }
    }
    amI(userName) {
        return String(this.name || '').toLowerCase() === String(userName || '').toLowerCase();
    }
    getCompletedTours() {
        return this.completedTours;
    }
    setCompletedTours(completedTours = {}) {
        this.completedTours = completedTours;
    }
    unlockTour(tourCollectionKey, tourKey) {
        if (tourCollectionKey && tourKey) {
            if (!this.unlockedTours[tourCollectionKey])
                this.unlockedTours[tourCollectionKey] = {};
            this.unlockedTours[tourCollectionKey][tourKey] = true;
        }
    }
    isTourUnlocked(tourCollectionKey, tourKey, ancestorsHasToBeCompleted, lockedByUserName) {
        if (tourCollectionKey && tourKey) {
            if (!this.unlockedTours[tourCollectionKey])
                this.unlockedTours[tourCollectionKey] = {};
            return !!this.unlockedTours[tourCollectionKey][tourKey] || (!ancestorsHasToBeCompleted && this.amI(lockedByUserName)) || !ancestorsHasToBeCompleted || this.amI(TECH_DEMO_USER);
        }
        return false;
    }
    getUnlockedTours() {
        return this.unlockedTours;
    }
    setUnlockedTours(unlockedTours = {}) {
        this.unlockedTours = unlockedTours;
    }
    setScore(tourCollectionKey, tourKey, tourPartIndex, score = 0, preventSoundEffect = false) {
        if (!this.scores)
            this.scores = {};
        if (!this.scores[tourCollectionKey])
            this.scores[tourCollectionKey] = {};
        if (!this.scores[tourCollectionKey][tourKey])
            this.scores[tourCollectionKey][tourKey] = {};
        if (!this.scores[tourCollectionKey][tourKey].parts)
            this.scores[tourCollectionKey][tourKey].parts = {};
        this.scores[tourCollectionKey][tourKey].scorePartSet = true;
        this.scores[tourCollectionKey][tourKey].parts[tourPartIndex] = score;
        (async () => await userHandler.saveUserList())();
        if (!preventSoundEffect) {
            if (score === 1) {
                audioHandler.play('positive');
            }
            else {
                audioHandler.play('negative');
            }
        }
    }
    updateScore(tourCollection, tour) {
        if (tourCollection && tour) {
            const nrOfParts = tour.getNumberOfParts();
            const score = this.scores[tourCollection.key][tour.getKey()] || {};
            if (score.scorePartSet) {
                score.scorePartSet = false;
                score.score = 0;
                score.normalizedScore = 0;
                if (score && nrOfParts > 0) {
                    const scoreParts = score.parts;
                    let tourScore = 0;
                    for (const tourPartIndex in scoreParts) {
                        tourScore += scoreParts[tourPartIndex];
                    }
                    tourScore = tourScore / nrOfParts;
                    score.score = tourScore;
                    score.normalizedScore = Math.round(tourScore * MAX_BONES_PER_TOUR);
                    score.parts = {};
                }
            }
        }
    }
    resetScoreParts(tourCollectionKey, tourKey) {
        if (tourCollectionKey && tourKey && this.scores?.[tourCollectionKey]?.[tourKey]?.parts) {
            this.scores[tourCollectionKey][tourKey].parts = {};
        }
    }
    getNextUnsolvedTourPartIndex(tourCollection, tourKey, tourPartIndex) {
        if (tourCollection && tourKey && (tourPartIndex || tourPartIndex === 0)) {
            const tour = tourCollection.getTourByKey(tourKey);
            if (tour) {
                const nrOfParts = tour.getNumberOfParts();
                const scoreParts = this.scores?.[tourCollection.key]?.[tourKey]?.parts || {};
                for (let tpi = 0; tpi < nrOfParts; tpi++) {
                    if (scoreParts[tpi] === undefined) {
                        return tpi;
                    }
                }
            }
        }
        return;
    }
}
export class UserHandler {
    static userHandlerVersion = 'v1.0.1';
    userIndex;
    user;
    userList;
    userListLoaded;
    constructor() {
        this.userIndex = -1;
        this.user = new User();
        this.userList = [];
        this.userListLoaded = false;
    }
    async loadUserList() {
        try {
            if (!this.userListLoaded) {
                const rawUserListState = await Storage.get({ key: 'userHandler' });
                if (rawUserListState.value) {
                    const userListState = JSON.parse(rawUserListState.value) || {};
                    if (userListState.userHandlerVersion && userListState.userHandlerVersion === UserHandler.userHandlerVersion) {
                        this.userList.splice(0, this.userList.length);
                        for (const userIndex in userListState.userList) {
                            this.userList.push(userListState.userList[userIndex]);
                        }
                        this.userListLoaded = true;
                        await this.setUser(userListState.userIndex, false);
                    }
                }
            }
        }
        catch (error) {
            console.error(error);
        }
        return this.userListLoaded;
    }
    async saveUserList() {
        try {
            await Storage.set({
                key: 'userHandler',
                value: JSON.stringify({
                    userHandlerVersion: UserHandler.userHandlerVersion,
                    userIndex: this.userIndex,
                    userList: this.userList
                })
            });
            return true;
        }
        catch (error) {
            console.error(error);
        }
        return false;
    }
    getUserList() {
        return this.userList;
    }
    async addUser(userName, setUser = false) {
        for (const u of this.userList) {
            if (u.name === userName) {
                return {
                    index: u.index,
                    status: 'userAlreadyExists'
                };
            }
        }
        const newUserIndex = this.userList.length;
        this.userList.push({
            name: userName,
            index: newUserIndex,
            scores: {},
            completedTours: {},
            unlockedTours: {}
        });
        if (setUser) {
            await this.setUser(newUserIndex, false);
        }
        await this.saveUserList();
        return {
            index: newUserIndex,
            status: 'userSuccessfullyAdded'
        };
    }
    async removeUser(userIndex = -1) {
        if (userIndex >= 0 && this.userList[userIndex]) {
            this.userList.splice(userIndex, 1);
            if (this.userIndex === userIndex) {
                this.userIndex = -1;
                this.user = new User();
            }
            await this.saveUserList();
            return true;
        }
        return false;
    }
    getUser() {
        return this.user;
    }
    async setUser(userIndex, saveList = true) {
        if (userIndex >= 0 && this.userList[userIndex]) {
            if (this.userIndex !== userIndex) {
                this.userIndex = userIndex;
                this.user.set(this.userList[userIndex]);
                if (saveList)
                    await this.saveUserList();
            }
        }
        else {
            console.error('setUser() USER INDEX MISSING');
        }
    }
    initTour(tour, resetScoreParts = true) {
        if (tour) {
            if (resetScoreParts) {
                this.resetScoreParts(tour);
            }
            tour.initParts();
        }
    }
    completeTour(tourCollection, tourKey) {
        if (tourCollection && this.user) {
            this.user.completeTour(tourCollection, tourKey);
        }
    }
    updateScore(tourCollection, tour) {
        if (tourCollection && tour && this.user) {
            this.user.updateScore(tourCollection, tour);
        }
    }
    resetScoreParts(tour) {
        if (tour) {
            const tourCollectionKey = tour.getTourCollectionKey();
            if (tourCollectionKey) {
                this.user.resetScoreParts(tourCollectionKey, tour.getKey());
                (async () => await this.saveUserList())();
            }
        }
    }
    getNextUnsolvedTourPartIndex(tourCollection, tourKey, tourPartIndex) {
        return this.user.getNextUnsolvedTourPartIndex(tourCollection, tourKey, tourPartIndex);
    }
}
const userHandler = reactive(new UserHandler());
export default userHandler;
