import { defineComponent } from 'vue';
import Media from '@/data/media';
import { CapacitorVideoPlayerWeb } from "capacitor-video-player/dist/esm/web";
import pageHookMixin from "@/data/pageHookMixin";
import pageHandler from "@/data/pageHandler";
export default defineComponent({
    name: 'Video',
    mixins: [pageHookMixin],
    props: {
        file: {
            type: String,
            default: ''
        },
        video: {
            type: Object
        },
        small: {
            type: Boolean,
            default: false
        },
        float: {
            type: Boolean,
            default: false
        },
    },
    unmounted() {
        this.media = null;
        this.url = '';
        delete this.player;
    },
    data() {
        let file = this.file;
        if (!file && this.video?.name) {
            file = this.video?.name;
        }
        const portrait = this.video?.portrait || false;
        const player = null;
        const media = new Media('video', file)
            .getData()
            .then(url => {
            this.loaded(url);
        });
        return {
            media,
            url: '',
            videoFile: file,
            portrait,
            loading: true,
            loadingError: false,
            stopLoadingGranted: false,
            player,
            playerInitialized: false,
            playerInitStarted: false,
            playerInitError: false,
            playing: false,
            refreshProgress: 0,
            progressAngle: 0,
            progressArc: 'M 162.918 194.851 A 100 100, 0, 1, 1, 162.918 194.851',
            loadingPosition: 0,
            loadingArc: 'M 172.9853 204.4871 A 25 25, 0, 0, 0, 197.4389 199.2893',
            symbolColor: '#b8d9ff'
        };
    },
    computed: {
        cssClasses() {
            const list = ['videoContainer'];
            if (this.small) {
                list.push('small');
            }
            if (this.float) {
                list.push('float');
            }
            if (this.portrait) {
                list.push('portrait');
            }
            return list.join(' ');
        }
    },
    mounted() {
        this.initRefreshProgress();
    },
    methods: {
        onScreenUpdate() {
            this.updatePlayerDimensions();
        },
        loaded(url) {
            this.url = url;
            this.stopLoadingGranted = true;
            if (!this.url) {
                this.loadingError = true;
            }
        },
        onLoadingEndCycle() {
            if (this.loading && this.stopLoadingGranted) {
                this.loading = false;
                this.symbolColor = '#004965';
            }
        },
        async close() {
            if (this.playerInitialized) {
                const isPlaying = await this.isPlaying();
                if (isPlaying) {
                    await this.pausePlayer();
                }
            }
            const playerContainer = this.$refs.playerContainer;
            if (playerContainer) {
                playerContainer.style.display = 'none';
            }
        },
        async playToggle() {
            if (!this.loading && this.url) {
                const playerContainer = this.$refs.playerContainer;
                if (playerContainer) {
                    const isPlaying = await this.isPlaying();
                    if (!this.playerInitialized && !this.playerInitStarted) {
                        playerContainer.style.display = 'flex';
                        await this.initPlayer(true);
                    }
                    else if (isPlaying) {
                        playerContainer.style.display = 'none';
                        await this.pausePlayer();
                    }
                    else {
                        playerContainer.style.display = 'flex';
                        await this.playPlayer();
                    }
                }
            }
        },
        async initPlayer(playAfterInit = false) {
            if (!this.playerInitStarted && !this.playerInitialized) {
                this.playerInitStarted = true;
                try {
                    const dimensions = await this.updatePlayerDimensions();
                    // https://github.com/jepiqueau/capacitor-video-player/blob/master/docs/API.md#capvideoplayeroptions
                    this.player = new CapacitorVideoPlayerWeb();
                    this.player.addListener('jeepCapVideoPlayerReady', async () => {
                        this.playerInitialized = true;
                        // play after init on ready if browser supports this event
                        if (playAfterInit && !(await this.isPlaying())) {
                            this.playPlayer();
                        }
                    });
                    this.player.addListener('jeepCapVideoPlayerEnded', () => {
                        this.close();
                    });
                    try {
                        await this.player.initPlayer({
                            mode: 'embedded',
                            url: this.url,
                            // url: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                            playerId: this._componentID,
                            componentTag: 'div',
                            width: dimensions.width,
                            height: dimensions.height
                        });
                        // play after init backup
                        if (playAfterInit && !(await this.isPlaying())) {
                            this.playPlayer();
                        }
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                catch (error) {
                    console.log('init error', error);
                    this.playerInitStarted = false;
                    this.playerInitialized = false;
                    this.playerInitError = true;
                }
            }
            return this.playerInitialized;
        },
        async playPlayer() {
            if (!this.player)
                return false;
            const response = await this.player.play({ playerId: this._componentID });
            this.playing = true;
            this.initRefreshProgress();
            return response.result && response.value;
        },
        async pausePlayer() {
            if (!this.player)
                return false;
            const response = await this.player.pause({ playerId: this._componentID });
            this.playing = false;
            return response.result && response.value;
        },
        async isPlaying() {
            if (!this.player)
                return (this.playing = false);
            const response = await this.player.isPlaying({ playerId: this._componentID });
            return (this.playing = (response.result && response.value));
        },
        async getCurrentTime() {
            if (!this.player)
                return 0;
            const response = await this.player.getCurrentTime({ playerId: this._componentID });
            if (response.result) {
                return response.value;
            }
            return 0;
        },
        async getDuration() {
            if (!this.player)
                return 0;
            const response = await this.player.getDuration({ playerId: this._componentID });
            if (response.result) {
                return response.value;
            }
            return 0;
        },
        async setCurrentTime(currentTime) {
            if (!this.player)
                return 0;
            const response = await this.player.setCurrentTime({ playerId: this._componentID, seektime: currentTime });
            if (response.result) {
                return response.value;
            }
            return 0;
        },
        async updatePlayerDimensions() {
            let width = 4000;
            let height = 3000;
            let left = 0;
            let top = 0;
            if (this.portrait) {
                width = 3000;
                height = 4000;
            }
            const screen = pageHandler.getScreen();
            if (screen?.width > 0 && screen?.height > 0) {
                if (this.portrait) {
                    height = screen?.height * .75;
                    width = Math.min(Math.round(height / 4 * 3), screen?.width);
                    top = Math.max(0, ((screen?.height || 0) - height) / 2);
                    left = Math.max(0, ((screen?.width || 0) - width) / 2);
                }
                else {
                    width = screen?.width;
                    height = Math.min(Math.round(width / 4 * 3), screen?.height);
                    top = Math.max(0, ((screen?.height || 0) - height) / 2);
                }
            }
            const playerContainer = this.$el.querySelector('.media');
            if (playerContainer) {
                playerContainer.style.width = `${width}px`;
                playerContainer.style.height = `${height}px`;
                playerContainer.style.left = `${left}px`;
                playerContainer.style.top = `${top}px`;
            }
            // reinitialize player according to new dimensions
            if (this.player) {
                await this.close();
                const currentTime = await this.getCurrentTime();
                playerContainer.innerHTML = '';
                await this.player.initPlayer({
                    mode: 'embedded',
                    url: this.url,
                    // url: 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
                    playerId: this._componentID,
                    componentTag: 'div',
                    width,
                    height
                });
                await this.playToggle();
                await this.setCurrentTime(currentTime);
            }
            return {
                width,
                height,
                left,
                top
            };
        },
        initRefreshProgress() {
            if (this.refreshProgress)
                clearTimeout(this.refreshProgress);
            this.refreshProgress = setTimeout(this.updateProgress, 50);
        },
        async updateProgress() {
            if (this.loading) {
                this.loadingPosition = (this.loadingPosition + 1) % 20;
                if (this.loadingPosition === 19) {
                    this.onLoadingEndCycle();
                }
                const progress = (this.loadingPosition / 20);
                const progressAngle = progress * 360;
                const alpha = -progressAngle;
                const posStart = this.polarToCartesian(180.7107, 180.7107, 25, alpha);
                const posEnd = this.polarToCartesian(180.7107, 180.7107, 25, (alpha + 60) % 360);
                this.loadingArc = `M ${posStart.x} ${posStart.y} A 25 25, 0, 0, 0, ${posEnd.x} ${posEnd.y}`;
            }
            else if (this.playerInitialized) {
                const currentTime = await this.getCurrentTime();
                const duration = await this.getDuration();
                const progress = ((currentTime || 0) / (duration || 1));
                const progressAngle = progress * 333.9;
                if (progressAngle) {
                    const alpha = 31.59 - progressAngle;
                    const pos = this.polarToCartesian(110, 110, 100, alpha);
                    let largeArcFlag = '0';
                    if (progressAngle > 180) {
                        largeArcFlag = '1';
                    }
                    this.progressArc = `M 162.918 194.851 A 100 100, 0, ${largeArcFlag}, 1, ${pos.x} ${pos.y}`;
                }
            }
            if (this.loading || this.playing) {
                this.initRefreshProgress();
            }
        },
        polarToCartesian(centerX, centerY, radius, angleInDegrees) {
            var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
            return {
                x: centerX + (radius * Math.cos(angleInRadians)),
                y: centerY - (radius * Math.sin(angleInRadians))
            };
        }
    }
});
