import ModalSpeechBubble from "@/components/modals/SpeechBubble.vue";
import Headline from '@/components/core/Headline.vue';
import Description from '@/components/core/Description.vue';
import Button from '@/components/core/Button.vue';
import modalHandler from '@/data/modalHandler';
import routerHandler from "@/data/routerHandler";
import { defineComponent } from "vue";
import router from "@/router";
export default defineComponent({
    name: 'ModalEarlyLeaveTour',
    components: {
        ModalSpeechBubble,
        Headline,
        Description,
        Button
    },
    methods: {
        close(forward = false) {
            const meta = modalHandler.getMeta();
            modalHandler.hide();
            if (forward) {
                if (meta.forwardUrl) {
                    routerHandler.grantNextRoute();
                    router.resetIncomingRouteParams();
                    router.resetNavigationInfo();
                    router.push({
                        path: meta.forwardUrl,
                        replace: true,
                    });
                }
            }
            else {
                router.resetIncomingRouteParams();
                router.resetNavigationInfo();
            }
        }
    }
});
