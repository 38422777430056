import { BaseChallenge, BaseChallengeItem } from "@/data/challenges/challenge";
import OrderText from "@/components/challenges/OrderText.vue";
import userHandler from "@/data/userHandler";
export class ChallengeOrderText extends BaseChallenge {
    constructor(items = []) {
        super('orderText', OrderText);
        this.items = items;
    }
    init() {
        this.state = '';
        this.shuffle(true);
        this.postInit();
    }
    isCorrect() {
        return (this.state === 'solved' && this.score === 1);
    }
    chosen() {
        return this.state === 'chosen';
    }
    setChosen() {
        if (this.state !== 'solved') {
            this.state = 'chosen';
        }
    }
    evaluate() {
        if (this.state !== 'solved') {
            this.state = 'solved';
            if (this.itemsIndexes.length > 0) {
                let nrOfCorrectItems = 0;
                for (const index in this.itemsIndexes) {
                    if (Number(index) === this.itemsIndexes[index]) {
                        nrOfCorrectItems++;
                    }
                }
                this.score = nrOfCorrectItems / this.items.length;
            }
            userHandler.getUser().setScore(this.getTourCollectionKey(), this.getTourKey(), this.getTourPartIndex(), this.score);
        }
    }
}
export class ChallengeItemOrderText extends BaseChallengeItem {
    text = '';
    constructor(text) {
        super('challenge');
        this.text = text;
    }
}
