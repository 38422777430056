import { defineComponent } from "vue";
import { Challenge } from "@/data/challenges/challenge";
import Button from '@/components/core/Button.vue';
import router from "@/router";
import modalHandler from "@/data/modalHandler";
export default defineComponent({
    name: 'ChallengeFallback',
    components: {
        Button
    },
    props: {
        challenge: {
            type: Challenge
        },
        forwardUrl: {
            type: String
        },
        showMotivationModal: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onForwardButtonClick() {
            this.challenge?.setScore(1);
            if (this.forwardUrl) {
                if (this.showMotivationModal) {
                    modalHandler.show('motivation', {
                        forwardUrl: this.forwardUrl
                    });
                }
                else {
                    router.push({
                        path: this.forwardUrl,
                        replace: true,
                    });
                }
            }
        }
    }
});
