import ModalSpeechBubble from '@/components/modals/SpeechBubble.vue';
import Headline from '@/components/core/Headline.vue';
import Description from '@/components/core/Description.vue';
import Button from '@/components/core/Button.vue';
import modalHandler from '@/data/modalHandler';
export default {
    name: 'EarlyLeaveTour',
    components: {
        ModalSpeechBubble,
        Headline,
        Description,
        Button
    },
    methods: {
        close() {
            modalHandler.hide();
        }
    }
};
