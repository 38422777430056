import { defineComponent } from "vue";
import { SPEECH_BUBBLE_AREA_HEIGHT, SPEECH_BUBBLE_AREA_OFFSET_Y } from "@/lib/constants";
export default defineComponent({
    name: 'SymbolSpeechBubblePointer',
    props: {
        backgroundColor: {
            type: String,
            default: '#e1b153'
        },
        strokeColor: {
            type: String,
            default: '#F7F9FB'
        },
        pointerAreaWith: {
            type: Number,
            default: 0
        }
    },
    computed: {
        offsetY() {
            if (this.pointerAreaWith !== 0) {
                return SPEECH_BUBBLE_AREA_HEIGHT + SPEECH_BUBBLE_AREA_OFFSET_Y;
            }
            return 0;
        }
    }
});
