import { defineComponent } from "vue";
import pageHandler from "@/data/pageHandler";
import LayoutsMain from "@/components/layouts/Main.vue";
import Headline from "@/components/core/Headline.vue";
import Description from "@/components/core/Description.vue";
import TourSummary from "@/components/core/TourSummary.vue";
import audioHandler from "@/data/audioHandler";
import router from "@/router";
import { tourHandler } from "@/data/tourHandler";
import userHandler from "@/data/userHandler";
import { MAX_BONES_PER_TOUR } from "@/lib/constants";
import pageHookMixin from "@/data/pageHookMixin";
export default defineComponent({
    name: 'ViewTourPartSummary',
    mixins: [pageHookMixin],
    components: { LayoutsMain, Headline, Description, TourSummary },
    mounted() {
        audioHandler.play('success');
    },
    data() {
        const route = router.currentRoute?.value;
        const { tourCollectionKey, tourKey } = route?.params || {};
        const tourCollection = tourHandler.getTourCollectionByKey(String(tourCollectionKey));
        const tour = tourCollection?.getTourByKey(String(tourKey));
        const username = userHandler.getUser().getName();
        return {
            tourCollectionKey,
            tourKey,
            tourCollection,
            tour,
            preparationDone: false,
            normalizedScore: 1,
            perfectScore: false,
            username
        };
    },
    computed: {
        tourSummary() {
            return {
                headline: this.headline(this.username, this.normalizedScore, this.perfectScore, this.preparationDone),
                description: this.description(this.username, this.normalizedScore, this.perfectScore, this.preparationDone, this.tour?.getIsLast())
            };
        }
    },
    methods: {
        beforePageEnter() {
            this.prepare();
        },
        async prepare() {
            userHandler.updateScore(this.tourCollection, this.tour);
            this.normalizedScore = this.tour?.getNormalizedScore() || 1;
            this.perfectScore = this.tour?.isPerfectScore() || false;
            if (this.normalizedScore === MAX_BONES_PER_TOUR) {
                userHandler.completeTour(this.tourCollection, this.tour?.getKey());
            }
            await userHandler.saveUserList();
            this.preparationDone = true;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        headline: (username = '', nrOfAchievedBones = 0, perfectScore = false, preparationDone = false) => {
            if (!preparationDone)
                return 'Speichern';
            if (perfectScore)
                return 'Beeindruckend!';
            if (nrOfAchievedBones > 2)
                return 'Super!';
            return 'Geschafft!';
        },
        description: (username, nrOfAchievedBones = 0, perfectScore = false, preparationDone = false, tourIsLast = false) => {
            if (!preparationDone) {
                return `${username}, dein Spielstand wird gespeichert.`;
            }
            if (nrOfAchievedBones === 3) {
                if (perfectScore) {
                    return `${username}, das war perfekt! Du hast alles richtig gemacht.`;
                }
                if (tourIsLast) {
                    return `${username}, das hast du sehr gut gemacht!`;
                }
                return `${username}, das hast du sehr gut gemacht! Versuche die nächste Tour.`;
            }
            if (nrOfAchievedBones === 2) {
                return `${username}, nicht schlecht. Aber das geht noch besser.`;
            }
            return `${username}, das kannst du sicher besser. Probiere es doch noch einmal.`;
        },
    },
    ionViewWillEnter() {
        pageHandler.emit('beforePageEnter');
    },
    ionViewDidEnter() {
        pageHandler.emit('pageEnter');
    },
});
