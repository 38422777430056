import { defineComponent } from "vue";
import LayoutsMain from "@/components/layouts/Main.vue";
import Headline from "@/components/core/Headline.vue";
import Description from "@/components/core/Description.vue";
import pageHandler from "@/data/pageHandler";
import pageHookMixin from "@/data/pageHookMixin";
export default defineComponent({
    name: 'ViewImprint',
    mixins: [pageHookMixin],
    components: { LayoutsMain, Headline, Description },
    ionViewWillEnter() {
        pageHandler.emit('beforePageEnter');
    },
    ionViewDidEnter() {
        pageHandler.emit('pageEnter');
    },
});
