import { markRaw } from 'vue';
import Fallback from '@/components/challenges/Fallback.vue';
import userHandler from "@/data/userHandler";
let itemCounter = 0;
export class BaseChallengeIterable {
    items = [];
    itemsIndexes = [];
    constructor(items = [], indexes = []) {
        this.items = items;
        this.itemsIndexes = indexes;
    }
    postInit() {
        if (this.items && this.items.length > 0) {
            // reset last values
            for (const item of this.items) {
                item.isFirst = false;
                item.isLast = false;
                item.isShuffeledFirst = false;
                item.isShuffeledLast = false;
            }
            this.items[0].isFirst = true;
            this.items[this.items.length - 1].isLast = true;
            if (this.itemsIndexes.length === this.items.length) {
                this.items[this.itemsIndexes[0]].isShuffeledFirst = true;
                this.items[this.itemsIndexes[this.itemsIndexes.length - 1]].isShuffeledLast = true;
            }
        }
    }
    length() {
        return this.items.length;
    }
    shuffle(ensureWrongOrder = false) {
        this.itemsIndexes = [];
        const itemsLength = this.items.length;
        for (let i = 0; i < itemsLength; i++) {
            let j = 0;
            do {
                j = Math.floor(Math.random() * itemsLength);
            } while (ensureWrongOrder && i == 0 && j == 0);
            while (this.itemsIndexes.indexOf(j) > -1) {
                j = (j + 1) % itemsLength;
            }
            this.itemsIndexes.push(j);
        }
    }
    getItem(index) {
        if (index > -1 && index < this.items.length && index < this.itemsIndexes.length) {
            return this.items[this.itemsIndexes[index]];
        }
        return null;
    }
    getUnshuffledItem(index) {
        if (index > -1 && index < this.items.length) {
            return this.items[index];
        }
        return null;
    }
    addItem(item, atBeginning = false) {
        if (item) {
            if (atBeginning) {
                this.itemsIndexes.unshift(0);
                for (const i in this.itemsIndexes) {
                    if (Number(i) > 0) {
                        this.itemsIndexes[i]++;
                    }
                }
                this.items.unshift(item);
            }
            else {
                this.itemsIndexes.push(this.items.length);
                this.items.push(item);
            }
        }
    }
    getItemByKey(key) {
        for (const index in this.items) {
            if (this.items[index].key === key) {
                return this.items[index];
            }
        }
        return null;
    }
    getNumberOfItems() {
        return this.items.length;
    }
    getItemsIndexes() {
        return this.itemsIndexes;
    }
    setItemsIndexes(itemsIndexes) {
        this.itemsIndexes = itemsIndexes;
    }
    [Symbol.iterator]() {
        let i = 0;
        const items = this.items;
        const itemsIndexes = this.itemsIndexes;
        return {
            next() {
                if (itemsIndexes.length > 0) {
                    if (i < itemsIndexes.length) {
                        return {
                            done: false,
                            value: items[itemsIndexes[i++]]
                        };
                    }
                    return {
                        done: true,
                        value: null
                    };
                }
                else if (items.length > 0) {
                    if (i < items.length) {
                        return {
                            done: false,
                            value: items[i++]
                        };
                    }
                    return {
                        done: true,
                        value: null
                    };
                }
                return {
                    done: true,
                    value: null
                };
            }
        };
    }
}
export class BaseChallenge extends BaseChallengeIterable {
    tourCollectionKey = '';
    tourKey = '';
    tourPartIndex = -1;
    score = 0;
    type;
    component;
    topDescriptionComponent;
    bottomDescriptionComponent;
    animateViewTopOnInit = false;
    state = '';
    constructor(type, component = null, topDescriptionComponent = null, bottomDescriptionComponent = null, tourCollectionKey = '', tourKey = '', tourPartIndex = -1) {
        super();
        this.tourCollectionKey = tourCollectionKey;
        this.tourKey = tourKey;
        this.tourPartIndex = tourPartIndex;
        this.type = type;
        if (component === null) {
            this.component = markRaw(Fallback);
        }
        else {
            this.component = markRaw(component);
        }
        if (topDescriptionComponent !== null) {
            this.topDescriptionComponent = markRaw(topDescriptionComponent);
        }
        if (bottomDescriptionComponent !== null) {
            this.bottomDescriptionComponent = markRaw(bottomDescriptionComponent);
        }
    }
    getTourCollectionKey() {
        return this.tourCollectionKey;
    }
    setTourCollectionKey(tourCollectionKey = '') {
        this.tourCollectionKey = tourCollectionKey;
    }
    getTourKey() {
        return this.tourKey;
    }
    setTourKey(tourKey = '') {
        this.tourKey = tourKey;
    }
    getTourPartIndex() {
        return this.tourPartIndex;
    }
    setTourPartIndex(tourPartIndex = -1) {
        this.tourPartIndex = tourPartIndex;
    }
    getState() {
        return this.state;
    }
    getItemSymbol(itemKey, itemValid, itemIsCorrect) {
        if (!itemValid) {
            return 'invalid';
        }
        if (this.state === 'solved') {
            if (itemKey > -1) {
                if (itemIsCorrect) {
                    return 'correct';
                }
                return 'incorrect';
            }
        }
        if (itemKey > -1) {
            return 'selected';
        }
        return '';
    }
}
export class BaseChallengeItem {
    type;
    key;
    isFirst = false;
    isLast = false;
    isShuffeledFirst = false;
    isShuffeledLast = false;
    constructor(type = 'challenge') {
        this.type = type;
        this.key = ++itemCounter;
    }
}
export class Challenge extends BaseChallenge {
    constructor() {
        super('FALLBACK');
    }
    init() {
        this.postInit();
    }
    setScore(score = 1) {
        userHandler.getUser().setScore(this.getTourCollectionKey(), this.getTourKey(), this.getTourPartIndex(), score, true);
    }
}
