import { BaseChallenge, BaseChallengeItem, BaseChallengeIterable } from "@/data/challenges/challenge";
import PlaceImageOnText from "@/components/challenges/PlaceImageOnText.vue";
import MediaImage from "@/data/mediaImage";
import userHandler from "@/data/userHandler";
import audioHandler from "@/data/audioHandler";
export class ChallengePlaceImageOnText extends BaseChallenge {
    itemKeyOrder = [];
    dropZones = {};
    draggableItems;
    constructor(items) {
        super('placeImageOnText', PlaceImageOnText);
        this.items = items;
        this.itemKeyOrder = items.map(item => item.key);
        // prepare items used as draggables
        this.draggableItems = new BaseChallengeIterable(items);
        this.draggableItems.shuffle();
        this.draggableItems.postInit();
    }
    init() {
        this.state = '';
        this.dropZones = {};
        this.postInit();
    }
    registerDropZone(dropZoneKey, bbox) {
        let centerY = 0;
        if (bbox?.y > 0 && bbox?.height > 0) {
            centerY = Math.floor(bbox.y + (bbox.height / 2));
            if (!this.dropZones[centerY]) {
                this.dropZones[centerY] = {
                    key: dropZoneKey,
                    index: -1,
                    itemKey: -1,
                    itemIsCorrect: false,
                    itemKeys: {},
                    valid: true,
                    bbox
                };
            }
        }
        return centerY;
    }
    sortDropZones() {
        const listOfY = [];
        for (const cy in this.dropZones) {
            listOfY.push(Number(cy));
        }
        listOfY.sort((a, b) => a < b ? -1 : (a === b ? 0 : 1));
        for (const i in listOfY) {
            const y = Number(listOfY[i]);
            this.dropZones[y].index = Number(i);
        }
    }
    selectItemDropZone(itemKey, cursorX, cursorY) {
        let chosen = true;
        let playSoundEffect = false;
        for (const centerY in this.dropZones) {
            const dropZone = this.dropZones[centerY];
            if (dropZone.bbox.y <= cursorY && cursorY <= (dropZone.bbox.y + dropZone.bbox.height)) {
                dropZone.itemKeys[itemKey] = true;
                if (dropZone.itemKey < 0) {
                    playSoundEffect = true;
                    dropZone.itemKey = itemKey;
                    dropZone.itemIsCorrect = this.itemKeyOrder[dropZone.index] === dropZone.itemKey;
                }
            }
            else {
                delete dropZone.itemKeys[itemKey];
                if (dropZone.itemKey === itemKey) {
                    const itemKeyIndexes = Object.keys(dropZone.itemKeys);
                    if (itemKeyIndexes.length > 0) {
                        dropZone.itemKey = Number(itemKeyIndexes[0]);
                        dropZone.itemIsCorrect = this.itemKeyOrder[dropZone.index] === dropZone.itemKey;
                    }
                    else {
                        dropZone.itemKey = -1;
                        dropZone.itemIsCorrect = false;
                    }
                }
            }
            dropZone.valid = Object.keys(dropZone.itemKeys).length < 2;
            if (dropZone.itemKey < 0 || !dropZone.valid) {
                chosen = false;
            }
        }
        if (playSoundEffect) {
            audioHandler.play('select');
        }
        if (this.state !== 'solved') {
            if (chosen) {
                this.state = 'chosen';
            }
            else {
                this.state = '';
            }
        }
    }
    getDropZoneStates() {
        const dropZoneStates = {};
        for (const centerY in this.dropZones) {
            const dropZone = this.dropZones[centerY];
            if (dropZone.key > -1) {
                dropZoneStates[dropZone.key] = {
                    itemKey: dropZone.itemKey,
                    itemIsCorrect: dropZone.itemIsCorrect,
                    valid: dropZone.valid,
                    bbox: dropZone.bbox
                };
            }
        }
        return dropZoneStates;
    }
    getNumberOfDropZones() {
        return Object.keys(this.dropZones).length;
    }
    evaluate() {
        if (this.state !== 'solved') {
            this.state = 'solved';
            if (this.items.length > 0) {
                let nrOfCorrectItems = 0;
                for (const centerY in this.dropZones) {
                    const dropZone = this.dropZones[centerY];
                    if (dropZone.itemIsCorrect) {
                        nrOfCorrectItems++;
                    }
                }
                this.score = nrOfCorrectItems / this.items.length;
            }
            userHandler.getUser().setScore(this.getTourCollectionKey(), this.getTourKey(), this.getTourPartIndex(), this.score);
        }
    }
}
export class ChallengeItemPlaceImageOnText extends BaseChallengeItem {
    text;
    media;
    constructor(text, imageName, imageDescription = '', imageMeta = {}) {
        super('challenge');
        this.text = text;
        this.media = new MediaImage(imageName, undefined, imageDescription, imageMeta);
    }
}
