import { markRaw } from "vue";
import { Tour, TourPart } from "@/data/tourHandlerCore";
import { ChallengeItemMultiSelectImage, ChallengeItemMultiSelectText, ChallengeMultiSelectImageText } from "@/data/challenges/challengeMultiSelectImageText";
import { ChallengeItemSingleSelectImage, ChallengeSingleSelectImage } from "@/data/challenges/challengeSingleSelectImage";
import { ChallengeItemPlaceOnAxis, ChallengePlaceOnAxis } from "@/data/challenges/challengePlaceOnAxis";
import { ChallengeItemPickTextBySwipe, ChallengePickTextBySwipe } from "@/data/challenges/challengePickTextBySwipe";
import { ChallengeItemOrderText, ChallengeOrderText } from "@/data/challenges/challengeOrderText";
export default function (tourCollectionKey) {
    const tour = new Tour(tourCollectionKey, 'D', 'Knochenjagd', true, '', true);
    tour.addPart(new TourPart('', 'Hör genau hin, was in der Sprachaufzeichnung erzählt wird.<br><br>Damit kannst du die nächste Aufgabe lösen.', '„Es war einmal eine Königin. Sie lebte auf einem schönen Schloss weit oben in den Bergen ...“<br><br>Solche Geschichten nennt man <b>Märchen</b>. Sie erzählen uns wundersame Geschichten von seltsamen Wesen und erfundenen Menschen. Wann und wo die Geschichten spielen, ist nicht bekannt.<br><br><b>Geschichten im Museum</b> sind anders. Sie erzählen uns von echten Menschen an bestimmten Orten in der Vergangenheit.', true, undefined, false, false, 'xx_knochenjagd_maerchen.m4a'));
    tour.addPart(new TourPart('Ist das ein Merkmal von Märchen?', '', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Ort und Zeit sind bekannt', true), 'nein', 'ja')));
    tour.addPart(new TourPart('Ist das ein Merkmal von Märchen?', '', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('frei erfundene Geschichten', false), 'nein', 'ja')));
    tour.addPart(new TourPart('Ist das ein Merkmal von Märchen?', '', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Fantastische Wesen treten auf', false), 'nein', 'ja')));
    tour.addPart(new TourPart('Ist das ein Merkmal von Märchen?', '', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Geschichten über Ereignisse, die wirklich stattgefunden haben', true), 'nein', 'ja')));
    tour.addPart(new TourPart('', 'Was sind die Merkmale von Geschichten im Museum?<br>Kreuze <b>alle</b> richtigen Antworten an!', '', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('frei erfundene Geschichten', false),
        new ChallengeItemMultiSelectText('Ort und Zeit sind bekannt', true),
        new ChallengeItemMultiSelectText('Fantastische Wesen treten auf', false),
        new ChallengeItemMultiSelectText('Geschichten über Ereignisse, die wirklich stattgefunden haben', true),
    ])));
    tour.addPart(new TourPart('', '', 'Mit Dingen von früher kann man herausfinden, wie Menschen in der Vergangenheit gelebt haben. Erzählt man davon, so wird Geschichte erzählt.<br><br>Willst du dir eine Geschichte aus dem Museum in Werfenweng anhören?', false, undefined, false, false, 'bb_schisport_anfaenge.mp3'));
    tour.addPart(new TourPart('', '', 'Willst du etwas über die Vergangenheit herausfinden, musst du Dinge von früher untersuchen. Diese Dinge nennt man dann Quellen.<br><br>Aus ihnen kann man Informationen erhalten. Geschichte in einem Museum wird mit diesen Informationen erzählt.<br><br>Quellen gibt es in verschiedenen Formen.', true));
    tour.addPart(new TourPart('', '', 'Markiere die <b>schriftliche</b> Quelle.', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(false, 'ww_heeresfabrik.png', 'Foto, Skitraining in der Heeresskifabrik, Salzburg ca. 1910, 110 Jahre alt'),
        new ChallengeItemSingleSelectImage(true, 'ww_buch_nansen.png', 'Aufzeichnungen eines Forschers, Buch von Fridtjof Nansen, „Auf Schneeschuhen durch Grönland“, Norwegen, 1888-1889, 130 Jahre alt'),
        new ChallengeItemSingleSelectImage(false, 'ww_skihelm_brille.png', 'Skihelm und Skibrille, Gastein, 1958, 60 Jahre alt'),
    ])));
    tour.addPart(new TourPart('', '', 'Markiere die <b>bildliche</b> Quelle.', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(true, 'ww_heeresfabrik.png', 'Foto, Skitraining in der Heeresskifabrik, Salzburg ca. 1910, 110 Jahre alt'),
        new ChallengeItemSingleSelectImage(false, 'ww_buch_nansen.png', 'Aufzeichnungen eines Forschers, Buch von Fridtjof Nansen, „Auf Schneeschuhen durch Grönland“, Norwegen, 1888-1889, 130 Jahre alt'),
        new ChallengeItemSingleSelectImage(false, 'ww_skihelm_brille.png', 'Skihelm und Skibrille, Gastein, 1958, 60 Jahre alt'),
    ])));
    tour.addPart(new TourPart('', '', 'Markiere die <b>gegenständliche</b> Quelle.', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(false, 'ww_heeresfabrik.png', 'Foto, Skitraining in der Heeresskifabrik, Salzburg ca. 1910, 110 Jahre alt'),
        new ChallengeItemSingleSelectImage(false, 'ww_buch_nansen.png', 'Aufzeichnungen eines Forschers, Buch von Fridtjof Nansen, „Auf Schneeschuhen durch Grönland“, Norwegen, 1888-1889, 130 Jahre alt'),
        new ChallengeItemSingleSelectImage(true, 'ww_skihelm_brille.png', 'Skihelm und Skibrille, Gastein, 1958, 60 Jahre alt'),
    ])));
    tour.addPart(new TourPart('', 'Mit welcher Form von Quelle haben wir es hier zu tun?', 'Sieh dir das Video an und markiere die Form der Quelle!', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Schriftliche Quelle', false),
        new ChallengeItemMultiSelectText('Mündliche Quelle', true),
        new ChallengeItemMultiSelectText('Gegenständliche Quelle', false),
    ]), false, false, '', { name: 'ww_zeitzeuge.mp4', portrait: false }));
    tour.addPart(new TourPart('', 'Eine Geschichte aus dem Museum: „Schon vor tausend Jahren gab es Ski. Die Leute fuhren aber nicht zum Spaß auf Skiern, sondern um im tiefen Schnee vorwärtszukommen. Zum Beispiel wurden schon vor etwa 500 Jahren in Skandinavien im Norden Europas Skier verwendet. Diese wurden in der gleichen Form noch bis vor 100 Jahren eingesetzt. Sie hatten eine hochgebogene Form und wurden mit einem einzelnen Skistock gefahren.“<br><br>Schau dir das Foto genau an.', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Kann dieses Foto aus der Vergangenheit als Quelle diese Geschichte beweisen?', true), 'nein', 'ja', 'slim'), false, true, '', '', 'ww_hinterseer.png', 'Foto des bekannten Skirennläufers Hansi Hinterseer, 1972, vor 50 Jahren'));
    tour.addPart(new TourPart('', 'Eine Geschichte aus dem Museum: „Schon vor tausend Jahren gab es Ski. Die Leute fuhren aber nicht zum Spaß auf Skiern, sondern um im tiefen Schnee vorwärtszukommen. Zum Beispiel wurden schon vor etwa 500 Jahren in Skandinavien im Norden Europas Skier verwendet. Diese wurden in der gleichen Form noch bis vor 100 Jahren eingesetzt. Sie hatten eine hochgebogene Form und wurden mit einem einzelnen Skistock gefahren.“<br><br>Schau dir das Bild aus der Vergangenheit genau an.', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Kann das Bild aus der Vergangenheit als Quelle diese Geschichte beweisen?', false), 'nein', 'ja', 'slim'), false, true, '', '', 'ww_olaus_magnus.jpg', 'Zeichnung aus einem Buch von Bischof Olaus Magnus, er schreibt über die Völker des Nordens, 1555, vor etwa 530 Jahren'));
    tour.addPart(new TourPart('', 'Eine Geschichte aus dem Museum: „Schon vor tausend Jahren gab es Ski. Die Leute fuhren aber nicht zum Spaß auf Skiern, sondern um im tiefen Schnee vorwärtszukommen. Zum Beispiel wurden schon vor etwa 500 Jahren in Skandinavien im Norden Europas Skier verwendet. Diese wurden in der gleichen Form noch bis vor 100 Jahren eingesetzt. Sie hatten eine hochgebogene Form und wurden mit einem einzelnen Skistock gefahren.“<br><br>Schau dir den Gegenstand aus der Vergangenheit genau an.', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Kann der Gegenstand aus der Vergangenheit als Quelle diese Geschichte beweisen?', true), 'nein', 'ja', 'slim'), false, true, '', '', 'ww_rucksack_alt.png', 'Rucksack, Salzburg, 1950, 70 Jahre alt'));
    tour.addPart(new TourPart('', 'Eine Geschichte aus dem Museum: „Schon vor tausend Jahren gab es Ski. Die Leute fuhren aber nicht zum Spaß auf Skiern, sondern um im tiefen Schnee vorwärtszukommen. Zum Beispiel wurden schon vor etwa 500 Jahren in Skandinavien im Norden Europas Skier verwendet. Diese wurden in der gleichen Form noch bis vor 100 Jahren eingesetzt. Sie hatten eine hochgebogene Form und wurden mit einem einzelnen Skistock gefahren.“<br><br>Schau dir den Gegenstand aus der Vergangenheit genau an.', '', false, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Kann der Gegenstand aus der Vergangenheit als Quelle diese Geschichte beweisen?', true), 'nein', 'ja', 'slim'), false, true, '', '', 'ww_skihandschuhe_neu.png', 'Skihandschuhe von Marcel Hirscher, Skiweltmeisterschaft in Schladming, 2013, vor ca. 10 Jahren'));
    tour.addPart(new TourPart('', 'Hier haben wir eine historische Quelle. Um welche Art von Quelle handelt es sich?', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Schriftliche Quelle', false),
        new ChallengeItemMultiSelectText('Bildliche Quelle', true),
        new ChallengeItemMultiSelectText('Gegenständliche Quelle', false),
        new ChallengeItemMultiSelectText('Mündliche Quelle', false)
    ], [
        new ChallengeItemMultiSelectImage('ww_heutransport.png', undefined, 'Foto eines Knechts mit Heubrett (Heubrett war unterm Heu)'),
    ])));
    tour.addPart(new TourPart('', 'Was kannst du auf dem Ausschnitt des Fotos erkennen?', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Knecht, der Heu transportiert', true),
        new ChallengeItemMultiSelectText('Heuhaufen mit Heubrett unter dem Heu', false),
        new ChallengeItemMultiSelectText('Schnur des Heubretts und Stecken in der Hand', false)
    ], [
        new ChallengeItemMultiSelectImage('ww_heubrett_arrangement.png', {
            x: 1424 / 2000,
            y: 884 / 1930,
            w: 327 / 2000,
            h: 643 / 1930
        }, 'Foto eines Knechts mit Heubrett (Heubrett war unterm Heu)'),
    ], true)));
    tour.addPart(new TourPart('', 'Was kannst du auf dem Ausschnitt des Fotos erkennen?', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Knecht, der Heu transportiert', false),
        new ChallengeItemMultiSelectText('Heuhaufen mit Heubrett unter dem Heu', true),
        new ChallengeItemMultiSelectText('Schnur des Heubretts und Stecken in der Hand', false)
    ], [
        new ChallengeItemMultiSelectImage('ww_heubrett_arrangement.png', {
            x: 712 / 2000,
            y: 632 / 1930,
            w: 813 / 2000,
            h: 813 / 1930
        }, 'Foto eines Knechts mit Heubrett (Heubrett war unterm Heu)'),
    ], true)));
    tour.addPart(new TourPart('', 'Was kannst du auf dem Ausschnitt des Fotos erkennen?', '', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Knecht, der Heu transportiert', false),
        new ChallengeItemMultiSelectText('Heuhaufen mit Heubrett unter dem Heu', false),
        new ChallengeItemMultiSelectText('Schnur des Heubretts und Stecken in der Hand', true)
    ], [
        new ChallengeItemMultiSelectImage('ww_heubrett_arrangement.png', {
            x: 1583 / 2000,
            y: 1119 / 1930,
            w: 168 / 2000,
            h: 111 / 1930
        }, 'Foto eines Knechts mit Heubrett (Heubrett war unterm Heu)'),
    ], true)));
    tour.addPart(new TourPart('', 'Das Foto stammt aus Werfenweng und ist 100 Jahre alt.<br><br>Welche Fragen kann man anhand dieser bildlichen Quelle beantworten?<br><br>Wähle <b>alle</b> richtigen Fragen aus!', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Was machten Knechte vor 100 Jahren in ihrer Freizeit?', false),
        new ChallengeItemMultiSelectText('Wie viel verdienten Knechte früher?', false),
        new ChallengeItemMultiSelectText('Wie sah die Arbeitskleidung von Knechten aus?', true),
        new ChallengeItemMultiSelectText('Wie transportierten Knechte vor 100 Jahren das Heu vom Berg?', true)
    ], [
        new ChallengeItemMultiSelectImage('ww_heubrett_arrangement.png', undefined, 'Foto eines Knechts mit Heubrett (Heubrett war unterm Heu)'),
    ], false)));
    tour.addPart(new TourPart('', 'Mit Quellen können wir Informationen über die Vergangenheit herausfinden.<br>Du fragst dich: Wie war das Leben von Knechten vor 100 Jahren?', 'Diese Quelle erlaubt verschiedene Antworten.<br>Markiere <b>alle</b> möglichen Antworten.', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Die Knechte hatten viel Freizeit.', false),
        new ChallengeItemMultiSelectText('Knechte benutzten für den Transport von Heu vom Berg ins Tal Heubretter.', true),
        new ChallengeItemMultiSelectText('Gelenkt und gebremst wurde ein beladenes Heubrett mit dem Stecken.', true),
        new ChallengeItemMultiSelectText('Das Wetter im Sommer war früher immer warm.', false)
    ], [
        new ChallengeItemMultiSelectImage('ww_heubrett_arrangement.png', undefined, 'Foto eines Knechts mit Heubrett (Heubrett war unterm Heu)'),
    ], false)));
    tour.addPart(new TourPart('', 'Oje, jetzt ist uns die Geschichte durcheinander gekommen. Bringe die Teile in die richtige Reihenfolge. Ziehe!', '', false, new ChallengeOrderText([
        new ChallengeItemOrderText('Vor etwa 400 Jahren wurden Vorläufer des heutigen Snowboards von Menschen im Bergbau und auf Bauernhöfen erfunden.'),
        new ChallengeItemOrderText('Auf langen Holzbrettern stehend rutschten sie damals schon den Berg hinab.'),
        new ChallengeItemOrderText('Erst vor etwa 60 Jahren kamen die Vorgänger des heutigen Snowboards in Amerika auf.'),
        new ChallengeItemOrderText('In den letzten 40 Jahren wurden die Boards mit moderneren Materialien hergestellt und damit zu richtigen Sportgeräten.')
    ])));
    tour.addPart(new TourPart('', '', '', false, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis('20', 1 / 5, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('40', 2 / 5, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('60', 3 / 5, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('80', 4 / 5, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false)
    ], .1, false, false, false, 10, 12)));
    tour.addPart(new TourPart('', 'Über welche Ereignisse aus den letzten 100 Jahren erzählt das Salzburger FIS Landesskimuseum?<br><br>Tippe auf ein Bild, um es zu vergrößern. Dort findest du wichtige Hinweise.<br><br>Ziehe die Bilder an die richtige Stelle auf dem Zeitstrahl.', '', false, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis('vor 100 Jahren', 2 / 14, undefined, undefined, true, false, true, '', 'ww_uhrfeder.png', 'Erfindung der Stahlkante durch Rudolf Lettner 1917, vor ca. 100 Jahren, man benutzte eine Uhrfeder für die Kante'),
        new ChallengeItemPlaceOnAxis('', 9 / 14, undefined, undefined, true, false, true, '', 'ww_weltcupkugel_kronberger.png', 'Petra Kronberger gewinnt den Ski-Gesamtweltcup, 1991/92, vor ca. 30 Jahren, Foto der Sieger-Kristallkugel'),
        new ChallengeItemPlaceOnAxis('', 11 / 14, undefined, undefined, true, false, true, '', 'ww_hirscher_ski.png', 'Marcel Hirscher siegt in der Weltmeisterschaft, Schladming, 2013, vor ca. 10 Jahren, Foto der Slalomskier Marcel Hirschers'),
        new ChallengeItemPlaceOnAxis('heute', 12 / 14, undefined, undefined, true, false, false),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false, false),
    ], 1 / 14, true, true, true, 0, 0, false, false, false, true, false, true)));
    return markRaw(tour);
}
