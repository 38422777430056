import { markRaw } from "vue";
import MediaImage from "@/data/mediaImage";
export const imprintEntries = markRaw([
    { key: 'PH', name: 'PH Salzburg', logo: 'logo_ph.jpg' },
    { key: 'UN', name: 'UNI Salzburg', logo: 'logo_un.jpg' },
    { key: 'CU', name: 'CodeUp', logo: 'logo_cu.png' },
    { key: 'LS', name: 'Land Salzburg', logo: 'logo_ls.png' },
    { key: 'LVSMS', name: 'Land Salzburg', logo: 'logo_lvsms.jpg' },
]);
export function prepareMedia() {
    for (const entry of imprintEntries) {
        new MediaImage(entry.logo, undefined, undefined, undefined, true);
    }
}
export default {
    imprintEntries,
    prepareMedia
};
