export default {
    name: 'SymbolMascotSideways',
    props: {
        showSpeechBubblePointer: {
            type: Boolean,
            default: false
        },
        x: {
            type: Number,
            default: 0
        },
        y: {
            type: Number,
            default: 0
        },
        inverted: {
            type: Boolean,
            default: false
        }
    }
};
