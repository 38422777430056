import { defineComponent } from "vue";
export default defineComponent({
    name: 'Checkbox',
    props: {
        text: {
            type: String
        },
        useButtonStyle: {
            type: Boolean,
            default: false
        },
        useStyle: {
            type: String,
            default: ''
        }
    },
    computed: {
        additionalClasses() {
            const additionalClasses = [];
            if (this.useButtonStyle) {
                additionalClasses.push('useButtonStyle');
            }
            if (this.useStyle) {
                additionalClasses.push(this.useStyle);
            }
            return additionalClasses.join(' ');
        }
    }
});
