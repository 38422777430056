import { defineComponent } from "vue";
import { round } from "@/lib/utils";
import { SPEECH_BUBBLE_AREA_HEIGHT, SPEECH_BUBBLE_AREA_OFFSET_Y } from "@/lib/constants";
export default defineComponent({
    name: 'SymbolSpeechBubblePointerArea',
    props: {
        strokeColor: {
            type: String,
            default: '#F7F9FB'
        },
        width: {
            type: Number,
            default: 0
        },
    },
    setup() {
        return {
            height: SPEECH_BUBBLE_AREA_HEIGHT,
            offsetY: SPEECH_BUBBLE_AREA_OFFSET_Y
        };
    },
    computed: {
        path() {
            const w = this.width;
            const h = this.height;
            const br = round(h / 2 - 1, 2);
            const path = `
          M 1 1
           A ${br} ${br}, 0, 0, 0, ${br + 1}, ${br + 1}
           A ${round(w / 2 - h / 2, 2)} ${br}, 1, 0, 1, ${round(w / 2, 2)} ${h - 1}
           A ${round(w / 2 - h / 2, 2)} ${br}, 1, 0, 1, ${round(w - h / 2, 2)} ${br + 1}
           A ${br} ${br}, 0, 0, 0, ${w - 1}, 1
        `;
            return path;
        }
    }
});
