import { defineComponent } from "vue";
export default defineComponent({
    name: 'SymbolInvalid',
    props: {
        preventAnimation: {
            type: Boolean,
            default: false
        },
        backgroundColor: {
            type: String,
            default: 'transparent'
        },
        strokeColor: {
            type: String,
            default: '#990D0D'
        },
    },
    computed: {
        cssClasses() {
            const classes = [];
            if (this.preventAnimation) {
                classes.push('preventAnimation');
            }
            return classes.join(' ');
        }
    }
});
