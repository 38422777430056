import { defineComponent } from "vue";
import { tourHandler } from "@/data/tourHandler";
import LayoutsMain from "@/components/layouts/Main.vue";
import Headline from "@/components/core/Headline.vue";
import Description from "@/components/core/Description.vue";
import Image from "@/components/core/Image.vue";
import SymbolMascotSideways from "@/components/symbols/mascot/sideways.vue";
import router from "@/router";
import pageHandler from "@/data/pageHandler";
import pageHookMixin from "@/data/pageHookMixin";
export default defineComponent({
    name: 'ViewTourCollectionWelcome',
    mixins: [pageHookMixin],
    components: { LayoutsMain, Headline, Description, Image, SymbolMascotSideways },
    data() {
        const { tourCollectionKey } = router.currentRoute?.value?.params || {};
        return {
            tourCollectionKey: String(tourCollectionKey),
        };
    },
    updated() {
        const { tourCollectionKey } = router.currentRoute?.value?.params || {};
        if (tourCollectionKey) {
            this.tourCollectionKey = String(tourCollectionKey);
        }
    },
    computed: {
        tourCollection() {
            return tourHandler.getTourCollectionByKey(this.tourCollectionKey);
        },
        topImage() {
            if (this.tourCollection?.welcomeTopImage) {
                return this.tourCollection?.welcomeTopImage;
            }
            return {};
        },
        bottomImage() {
            if (this.tourCollection?.welcomeBottomImage) {
                return this.tourCollection?.welcomeBottomImage;
            }
            return {};
        },
        forwardUrl() {
            if (this.tourCollection) {
                return this.tourCollection.getPath();
            }
            return '';
        }
    },
    ionViewWillEnter() {
        pageHandler.emit('beforePageEnter');
    },
    ionViewDidEnter() {
        pageHandler.emit('pageEnter');
    },
});
