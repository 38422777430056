import { markRaw } from "vue";
import { Tour, TourPart } from "@/data/tourHandlerCore";
import { PUZZLE_PIECE_ELEMENTS } from "@/lib/constants";
import { ChallengeItemMultiSelectImage, ChallengeItemMultiSelectText, ChallengeMultiSelectImageText } from "@/data/challenges/challengeMultiSelectImageText";
import { ChallengeItemPlaceOnAxis, ChallengePlaceOnAxis } from "@/data/challenges/challengePlaceOnAxis";
import { ChallengeItemPickTextBySwipe, ChallengePickTextBySwipe } from "@/data/challenges/challengePickTextBySwipe";
import { ChallengeItemPlaceImageOnText, ChallengePlaceImageOnText } from "@/data/challenges/challengePlaceImageOnText";
import { ChallengeItemPlaceImageOnImage, ChallengePlaceImageOnImage } from "@/data/challenges/challengePlaceImageOnImage";
import { ChallengeItemPickImage, ChallengePickImage } from "@/data/challenges/challengePickImage";
export default function (tourCollectionKey) {
    const tour = new Tour(tourCollectionKey, 'E', 'Jetzt geht\'s um die Wurst', true, '', true);
    tour.addPart(new TourPart('', '', 'Das Salzburger FIS Landesskimuseum bewahrt unterschiedliche <b>Dinge aus der Vergangenheit</b> auf. Wir nennen diese Dinge auch <b>Quellen</b>.<br><br>Heute können wir sie ansehen und mit ihnen herausfinden, wie es früher gewesen sein könnte.'));
    tour.addPart(new TourPart('', 'Ziehe das Bild der Quelle zur richtigen Beschreibung!<br><br>Tippe auf ein Bild, um es zu vergrößern. Dort findest du wichtige Hinweise.', '', false, new ChallengePlaceImageOnText([
        new ChallengeItemPlaceImageOnText('Ein Lehrer mit Buben im Schnee. Sie haben Ski und Stecken dabei.', 'ww_leher_kinder.jpg', 'Foto, Lehrer mit Schülern, 1908, Mühlbach am Hochkönig, vor ca. 120 Jahren'),
        new ChallengeItemPlaceImageOnText('Männer mit Skiern nach dem Aufstieg auf den Berg (Hochkeil).', 'ww_skifahrer.jpg', 'Foto, kein Skilift zum Hochkeil, um 1939, Mühlbach am Hochkönig'),
        new ChallengeItemPlaceImageOnText('Eines der ersten Pistengeräte auf der Skipiste.', 'ww_pistengerät.jpg', 'Foto, erstes Pistengerät (Kahlbacher K2000 „Schneewiesel“), Hochkeil, Mühlbach am Hochkönig, Anfang 1970er Jahre'),
        new ChallengeItemPlaceImageOnText('Schilift und Parkplatz für Skifahrerinnen und Skifahrer.', 'ww_saukarlift.jpg', 'Foto, Saukarlift, 1985, Hochkeil, Mühlbach am Hochkönig'),
    ])));
    tour.addPart(new TourPart('', 'Was können wir aus dieser Quelle über die Vergangenheit erfahren?<br><br>Tippe auf ein Bild, um es zu vergrößern. Dort findest du wichtige Hinweise.', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Man kann ableiten, wie schnell die Ski vor über 100 Jahren waren.', false),
        new ChallengeItemMultiSelectText('Man kann ableiten, dass Skifahrer vor über 100 Jahren nur einen Stock hatten.', true),
        new ChallengeItemMultiSelectText('Man kann ableiten, ob Skifahren vor über 100 Jahren gleich lustig war, wie heute.', false),
    ], [
        new ChallengeItemMultiSelectImage('ww_leher_kinder.jpg', undefined, 'Foto, Lehrer mit Schülern, 1908, Mühlbach am Hochkönig, vor ca. 120 Jahren'),
    ], true, true, 'slim')));
    tour.addPart(new TourPart('', 'Was können wir aus dieser Quelle über die Vergangenheit erfahren?', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Man nutzte vor 80 Jahren schon spezielle Sonnenbrillen zum Skifahren.', true),
        new ChallengeItemMultiSelectText('Man trug vor 80 Jahren schon warme Skiunterwäsche.', false),
        new ChallengeItemMultiSelectText('Man nutzte vor 80 Jahren schon eine Creme zum Schutz vor der Sonne.', false),
    ], [
        new ChallengeItemMultiSelectImage('ww_skifahrer.jpg', undefined, 'Foto, kein Skilift zum Hochkeil, um 1939, Mühlbach am Hochkönig'),
    ], true, true, 'slim')));
    tour.addPart(new TourPart('', 'Was können wir aus dieser Quelle über die Vergangenheit erfahren?', '', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Man hatte vor 50 Jahren schon Sessellifte und Gondeln.', false),
        new ChallengeItemMultiSelectText('Vor 50 Jahren trugen schon alle einen Skihelm auf der Piste.', false),
        new ChallengeItemMultiSelectText('Manche hatten vor 50 Jahren kurze Hosen und dicke Socken zum Skifahren an.', true),
    ], [
        new ChallengeItemMultiSelectImage('ww_pistengerät.jpg', undefined, 'Foto, erstes Pistengerät (Kahlbacher K2000 „Schneewiesel“), Hochkeil, Mühlbach am Hochkönig, Anfang 1970er Jahre'),
    ], true, true, 'slim')));
    tour.addPart(new TourPart('', 'Passt die Geschichte zu den Quellen? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Vor 60 Jahren wurden immer mehr Skilifte in Salzburg gebaut. Es kamen viele Touristen. Kinderzimmer wurden damals in einigen Orten als Gästezimmer vermietet. Später wurden Pensionen und Hotels gebaut.', true, [
        { name: 'ww_leher_kinder.jpg', description: 'Foto, Lehrer mit Schülern, 1908, Mühlbach am Hochkönig, vor ca. 120 Jahren' },
        { name: 'ww_skifahrer.jpg', description: 'Foto, kein Skilift zum Hochkeil, um 1939, Mühlbach am Hochkönig' },
        { name: 'ww_pistengerät.jpg', description: 'Foto, erstes Pistengerät (Kahlbacher K2000 „Schneewiesel“), Hochkeil, Mühlbach am Hochkönig, Anfang 1970er Jahre' },
        { name: 'ww_saukarlift.jpg', description: 'Foto, Saukarlift, 1985, Hochkeil, Mühlbach am Hochkönig' },
    ]), 'passt nicht', 'passt', 'slim'), false));
    tour.addPart(new TourPart('', 'Passt die Geschichte zu den Quellen? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Vor 110 Jahren gab es noch keine Skilifte in Salzburg. Kinder und Erwachsene stiegen zuerst auf und fuhren dann ab. Pisten und Lifte wurden vor 70 Jahren errichtet. Heute ist Skifahren ein Sport für viele.', false, [
        { name: 'ww_leher_kinder.jpg', description: 'Foto, Lehrer mit Schülern, 1908, Mühlbach am Hochkönig, vor ca. 120 Jahren' },
        { name: 'ww_skifahrer.jpg', description: 'Foto, kein Skilift zum Hochkeil, um 1939, Mühlbach am Hochkönig' },
        { name: 'ww_pistengerät.jpg', description: 'Foto, erstes Pistengerät (Kahlbacher K2000 „Schneewiesel“), Hochkeil, Mühlbach am Hochkönig, Anfang 1970er Jahre' },
        { name: 'ww_saukarlift.jpg', description: 'Foto, Saukarlift, 1985, Hochkeil, Mühlbach am Hochkönig' },
    ]), 'passt nicht', 'passt', 'slim'), false));
    tour.addPart(new TourPart('', 'Passt die Geschichte zu den Quellen? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Als man in Salzburg vor ca. 110 Jahren mit dem Skifahren begann, sind die Skifahrer noch nicht mit zwei Stöcken gefahren. Sie hatten nur einen Holzstock beim Fahren in den Händen. Später verwendete man zwei Stöcke.', false, [
        { name: 'ww_leher_kinder.jpg', description: 'Foto, Lehrer mit Schülern, 1908, Mühlbach am Hochkönig, vor ca. 120 Jahren' },
        { name: 'ww_skifahrer.jpg', description: 'Foto, kein Skilift zum Hochkeil, um 1939, Mühlbach am Hochkönig' },
        { name: 'ww_pistengerät.jpg', description: 'Foto, erstes Pistengerät (Kahlbacher K2000 „Schneewiesel“), Hochkeil, Mühlbach am Hochkönig, Anfang 1970er Jahre' },
        { name: 'ww_saukarlift.jpg', description: 'Foto, Saukarlift, 1985, Hochkeil, Mühlbach am Hochkönig' },
    ]), 'passt nicht', 'passt', 'slim'), false));
    tour.addPart(new TourPart('', 'Passt die Geschichte zu den Quellen? Wische!', '', false, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Das Skifahren und die Wintergäste veränderten die Dörfer in den letzten 100 Jahren. Viele Menschen wollten das nicht. Sie wollten so weiterleben wie vorher.', true, [
        { name: 'ww_leher_kinder.jpg', description: 'Foto, Lehrer mit Schülern, 1908, Mühlbach am Hochkönig, vor ca. 120 Jahren' },
        { name: 'ww_skifahrer.jpg', description: 'Foto, kein Skilift zum Hochkeil, um 1939, Mühlbach am Hochkönig' },
        { name: 'ww_pistengerät.jpg', description: 'Foto, erstes Pistengerät (Kahlbacher K2000 „Schneewiesel“), Hochkeil, Mühlbach am Hochkönig, Anfang 1970er Jahre' },
        { name: 'ww_saukarlift.jpg', description: 'Foto, Saukarlift, 1985, Hochkeil, Mühlbach am Hochkönig' },
    ]), 'passt nicht', 'passt', 'slim')));
    tour.addPart(new TourPart('', 'In welcher Quelle kannst du die Antwort auf die Frage finden? Zieh die richtige Quelle auf die Frage.', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('ww_leher_kinder.jpg', 'Foto, Lehrer mit Schülern, 1908, Mühlbach am Hochkönig, vor ca. 120 Jahren', true),
        new ChallengeItemPickImage('ww_skifahrer.jpg', 'Foto, kein Skilift zum Hochkeil, um 1939, Mühlbach am Hochkönig', false),
        new ChallengeItemPickImage('ww_pistengerät.jpg', 'Foto, erstes Pistengerät (Kahlbacher K2000 „Schneewiesel“), Hochkeil, Mühlbach am Hochkönig, Anfang 1970er Jahre', false),
    ], 'Hatten Buben vor 110 Jahren die gleiche Skiausrüstung wie Erwachsene?', 'slim')));
    tour.addPart(new TourPart('', 'In welcher Quelle kannst du die Antwort auf die Frage finden? Zieh die richtige Quelle auf die Frage.', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('ww_leher_kinder.jpg', 'Foto, Lehrer mit Schülern, 1908, Mühlbach am Hochkönig, vor ca. 120 Jahren', false),
        new ChallengeItemPickImage('ww_skifahrer.jpg', 'Foto, kein Skilift zum Hochkeil, um 1939, Mühlbach am Hochkönig', false),
        new ChallengeItemPickImage('ww_pistengerät.jpg', 'Foto, erstes Pistengerät (Kahlbacher K2000 „Schneewiesel“), Hochkeil, Mühlbach am Hochkönig, Anfang 1970er Jahre', true),
    ], 'Wie schauten die ersten Pistengeräte vor ca. 60 Jahren aus?', 'slim')));
    tour.addPart(new TourPart('', 'In welcher Quelle kannst du die Antwort auf die Frage finden? Zieh die richtige Quelle auf die Frage.', '', false, new ChallengePickImage([
        new ChallengeItemPickImage('ww_leher_kinder.jpg', 'Foto, Lehrer mit Schülern, 1908, Mühlbach am Hochkönig, vor ca. 120 Jahren', false),
        new ChallengeItemPickImage('ww_skifahrer.jpg', 'Foto, kein Skilift zum Hochkeil, um 1939, Mühlbach am Hochkönig', true),
        new ChallengeItemPickImage('ww_pistengerät.jpg', 'Foto, erstes Pistengerät (Kahlbacher K2000 „Schneewiesel“), Hochkeil, Mühlbach am Hochkönig, Anfang 1970er Jahre', false),
    ], 'Wie waren erwachsene Männer vor 80 Jahren beim Skifahren angezogen?', 'slim')));
    tour.addPart(new TourPart('', '', 'Eine Künstlerin hat versucht, einen römischen Soldaten möglichst richtig zu malen.', true, new ChallengePlaceImageOnImage('bm_roemer-rekonstruktion.png', 1000, 2100, [], true)));
    tour.addPart(new TourPart('', 'Welche Teile des Bildes konnte die Zeichnerin mit Quellen als Vorlage gut zeichnen?<br>Ziehe die Quellen an die richtigen Stellen!', '', false, new ChallengePlaceImageOnImage('bm_roemer-rekonstruktion.png', 1000, 2100, [
        new ChallengeItemPlaceImageOnImage({ x: 370, y: 276 }, 'grave'),
        new ChallengeItemPlaceImageOnImage({ x: 930, y: 359 }, 'spear', 'bm_roemer-rekonstruktion-speer-spitze.png', 'Römische Speerspitzen, 1.-4. Jh. n. Chr.', {
            isDark: true,
            copyright: {
                name: 'N. Gail | Landessammlungen NÖ, Archäologischer Park Carnuntum',
                license: '',
                symbol: 'c',
                url: 'https://www.online.landessammlungen-noe.at/objects/1303802/lanzenspeerspitze'
            }
        }),
        new ChallengeItemPlaceImageOnImage({ x: 412, y: 831 }, 'grave', 'bm_roemer-rekonstruktion-grabstele-titus-calidius.png', 'Römisches Grabmal, 1. Jh. n. Chr., Fundort: Österreich', {
            copyright: {
                name: 'Mathias Kabel',
                license: 'CC BY 2.5',
                symbol: 'cc',
                url: 'https://commons.wikimedia.org/wiki/File:Grabstein_Titus_Calidius_Carnuntum.jpg'
            }
        }),
        new ChallengeItemPlaceImageOnImage({ x: 244, y: 1262 }, 'sword', 'bm_roemer-rekonstruktion-klinge.png', 'Römische Klinge, 1.-2. Jh. n. Chr.', {
            isDark: true,
            copyright: {
                name: 'N. Gail | Landessammlungen NÖ, Archäologischer Park Carnuntum',
                license: '',
                symbol: 'c',
                url: 'https://www.online.landessammlungen-noe.at/objects/1303394/klinge'
            }
        }),
        new ChallengeItemPlaceImageOnImage({ x: 276, y: 1786 }, 'grave'), // shinpad
    ]), false));
    tour.addPart(new TourPart('', 'Hör dir das an ...', 'Ist das ein Märchen oder geht es um Geschichte?', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Das ist ein Märchen.', false),
        new ChallengeItemMultiSelectText('Das ist Geschichte.', true),
    ], [], true, true), false, false, 'ww_e_1_geschichte.m4a'));
    tour.addPart(new TourPart('', 'Hör dir das zweite Beispiel an ...', 'Ist das ein Märchen oder geht es um Geschichte?', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Das ist ein Märchen.', true),
        new ChallengeItemMultiSelectText('Das ist Geschichte.', false),
    ], [], true, true), false, false, 'ww_e_2_maerchen.m4a'));
    tour.addPart(new TourPart('', 'Hör dir das dritte Beispiel an ...', 'Ist das ein Märchen oder geht es um Geschichte?', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Das ist ein Märchen.', false),
        new ChallengeItemMultiSelectText('Das ist Geschichte.', true),
    ], [], true, true), false, false, 'ww_e_3_geschichte.m4a'));
    tour.addPart(new TourPart('', 'Das ist ein Foto von der Gründung des Skiklubs Mühlbach vor über 100 Jahren (1908).<br><br>Welche Fragen kann man mit dem Foto beantworten?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Waren Frauen bei der Gründung Teil des Skiklubs?', false, [
        { name: 'ww_skiklub_gruendung.jpg', description: 'Foto, Gründung des Skiclubs Mühlbach am Hochkönig, 1908' },
    ]), 'unmöglich', 'möglich')));
    tour.addPart(new TourPart('', 'Das ist ein Foto von der Gründung des Skiklubs Mühlbach vor über 100 Jahren (1908).<br><br>Welche Fragen kann man mit dem Foto beantworten?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Durften Frauen damals auch schon Ski fahren?', true, [
        { name: 'ww_skiklub_gruendung.jpg', description: 'Foto, Gründung des Skiclubs Mühlbach am Hochkönig, 1908' },
    ]), 'unmöglich', 'möglich')));
    tour.addPart(new TourPart('', 'Das ist ein Foto von der Gründung des Skiklubs Mühlbach vor über 100 Jahren (1908).<br><br>Welche Fragen kann man mit dem Foto beantworten?', '', false, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Waren Buben damals gute Skifahrer?', true, [
        { name: 'ww_skiklub_gruendung.jpg', description: 'Foto, Gründung des Skiclubs Mühlbach am Hochkönig, 1908' },
    ]), 'unmöglich', 'möglich')));
    tour.addPart(new TourPart('', 'Die lange Geschichte der Menschen wird in <b>Abschnitte</b> eingeteilt.<br><br>Hilf mit, die Abschnitte in die richtige Reihenfolge zu bringen.', '', true, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis('', .5 * (1.3 / 4), undefined, undefined, false, false, true, 'Steinzeit', '', '', PUZZLE_PIECE_ELEMENTS[0]),
        new ChallengeItemPlaceOnAxis('Jahr 0', (1.5 * (1.3 / 4)) - .1, undefined, undefined, true, false, true, 'Altertum', '', '', PUZZLE_PIECE_ELEMENTS[1]),
        new ChallengeItemPlaceOnAxis('', (2.5 * (1.3 / 4)) - .2, undefined, undefined, false, false, true, 'Mittelalter', '', '', PUZZLE_PIECE_ELEMENTS[2]),
        new ChallengeItemPlaceOnAxis('heute', (3.5 * (1.3 / 4)) - .3, undefined, undefined, true, false, true, 'Neuzeit', '', '', PUZZLE_PIECE_ELEMENTS[3]),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false, false),
    ], 1 / 20, true, true, true, 0, 0, true), false, true, 'xx_geschichte_der_menschen.m4a'));
    tour.addPart(new TourPart('', 'Ordne die Quellen ihren richtigen Abschnitten zu.<br><br>Aus welchem Zeitabschnitt sind die Quellen aus dem Museum?', '', false, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis('', .5 * (1.3 / 4), undefined, undefined, false, false, false, 'Steinzeit', '', '', PUZZLE_PIECE_ELEMENTS[0]),
        new ChallengeItemPlaceOnAxis('Jahr 0', (1.5 * (1.3 / 4)) - .1, undefined, undefined, true, false, false, 'Altertum', '', '', PUZZLE_PIECE_ELEMENTS[1]),
        new ChallengeItemPlaceOnAxis('', (2.5 * (1.3 / 4)) - .2, undefined, undefined, false, false, false, 'Mittelalter', '', '', PUZZLE_PIECE_ELEMENTS[2]),
        new ChallengeItemPlaceOnAxis('', (3.5 * (1.3 / 4)) - .3, undefined, undefined, true, false, true, 'Neuzeit', 'ww_olaus_magnus.jpg', 'Zeichnung aus einem Buch von Bischof Olaus Magnus, er schreibt über die Völker des Nordens, 1555, vor etwa 530 Jahren', PUZZLE_PIECE_ELEMENTS[3]),
        new ChallengeItemPlaceOnAxis('', (3.5 * (1.3 / 4)) - .3, undefined, undefined, false, false, true, '', 'ww_hirscher.png', 'Outfit von Marcel Hirscher, Österreich, 21. Jh.', PUZZLE_PIECE_ELEMENTS[3]),
        new ChallengeItemPlaceOnAxis('heute', (3.5 * (1.3 / 4)) - .3, undefined, undefined, false, false, true, '', 'ww_skijacke.png', 'Kinderskijacke, Österreich, ca. Ende 1970er', PUZZLE_PIECE_ELEMENTS[3]),
        new ChallengeItemPlaceOnAxis('', (3.5 * (1.3 / 4)) - .3, undefined, undefined, false, false, true, '', 'ww_gletscherbrille.png', 'Gletscherbrille, Österreich, 1900', PUZZLE_PIECE_ELEMENTS[3]),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false, false),
    ], 1 / 20, true, true, true, 0, 0, true, true, false, false, true, false)));
    tour.addPart(new TourPart('', 'Ein Blick ins Salzburger FIS Landesskimuseum ...', 'Das Landesskimuseum Werfenweng erzählt die Geschichte des Skilaufens von seinen Anfängen in der Steinzeit über das Mittelalter bis zur Gegenwart.<br><br>Die Ausstellungsstücke, die man sich in Werfenweng anschauen kann, sind alle aus der Neuzeit. Dazu zählen neben den ältesten hölzernen Ski aus Salzburg auch topmoderne Modelle, wie sie im Winter auf der Skipiste gefahren werden.', true, undefined, false, false, '', { name: 'ww_einblick.mp4', portrait: false }));
    tour.addPart(new TourPart('', '', 'Welche Zeitabschnitte der Geschichte werden vom Salzburger FIS Landesskimuseum gezeigt?<br><br>Wenn du möchtest, kannst du dir hier das Video gern nochmal anschauen.<br><br>Markiere <b>alle</b> richtigen Antworten!', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Steinzeit', true),
        new ChallengeItemMultiSelectText('Altertum', true),
        new ChallengeItemMultiSelectText('Mittelalter', true),
        new ChallengeItemMultiSelectText('Neuzeit', true),
    ], [], false, true, undefined, false), false, false, '', { name: 'ww_einblick.mp4', portrait: false }));
    tour.addPart(new TourPart('', 'Warum erzählt das Museum in Werfenweng die Geschichte des Skifahrens? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Weil es dazu Dinge aus der Vergangenheit gesammelt hat.', false), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Warum erzählt das Museum in Werfenweng die Geschichte des Skifahrens? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Weil es keine andere Geschichte erzählen kann.', true), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Warum erzählt das Museum in Werfenweng die Geschichte des Skifahrens? Wische!', '', false, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Weil es nur einen Teil aus der Vergangenheit erzählen kann.', false), 'falsch', 'richtig')));
    return markRaw(tour);
}
