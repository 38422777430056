import { defineComponent } from "vue";
import { IonItem, IonReorder, IonReorderGroup } from '@ionic/vue';
import { ChallengeOrderText } from "@/data/challenges/challengeOrderText";
import Button from '@/components/core/Button.vue';
import SymbolSolution from "@/components/symbols/solution.vue";
import router from '@/router';
import modalHandler from "@/data/modalHandler";
import audioHandler from "@/data/audioHandler";
import pageHookMixin from "@/data/pageHookMixin";
export default defineComponent({
    name: 'ChallengeOrderText',
    mixins: [pageHookMixin],
    components: {
        SymbolSolution,
        IonItem,
        IonReorder,
        IonReorderGroup,
        Button
    },
    props: {
        challenge: {
            type: ChallengeOrderText
        },
        forwardUrl: {
            type: String
        },
        showMotivationModal: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        buttonColor(newState, oldState) {
            if (newState === 'orange' && oldState !== newState) {
                audioHandler.play('select');
            }
        }
    },
    computed: {
        solved() {
            if (this.challenge) {
                return this.challenge.state === 'solved';
            }
            return false;
        },
        buttonColor() {
            if (this.challenge) {
                if (this.solved) {
                    return 'green';
                }
                if (this.challenge.chosen()) {
                    return 'orange';
                }
            }
            return 'gray';
        },
    },
    methods: {
        pageEnter() {
            this.$el.classList.add('animate');
        },
        doReorder(event) {
            if (this.challenge) {
                this.challenge.setChosen();
                this.challenge.setItemsIndexes(event.detail.complete(this.challenge.getItemsIndexes()));
            }
        },
        onForwardButtonClick() {
            if (this.challenge) {
                if (this.solved) {
                    if (this.forwardUrl) {
                        if (this.showMotivationModal) {
                            modalHandler.show('motivation', {
                                forwardUrl: this.forwardUrl
                            });
                        }
                        else {
                            router.replace(this.forwardUrl);
                        }
                    }
                }
                if (this.challenge.chosen()) {
                    this.challenge.evaluate();
                }
            }
        }
    }
});
