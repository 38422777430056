import { IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import Header from '@/components/core/Header.vue';
import Content from '@/components/core/Content.vue';
export default defineComponent({
    name: 'LayoutsMain',
    components: {
        IonPage,
        Header,
        Content
    },
    props: {
        preventForwardButton: {
            type: Boolean,
            default: false
        },
        progress: {
            type: Number,
            default: 0
        },
        maxProgress: {
            type: Number,
            default: 0
        },
        preventTopRightContent: {
            type: Boolean,
            default: false
        },
        waveType: {
            type: Number,
            default: 2
        },
        waveColor: {
            type: String,
            default: '#2f728f'
        },
        forwardUrl: {
            type: String,
            default: ""
        },
        collapsable: {
            type: Boolean,
            default: false
        },
        animateViewTopOnInit: {
            type: Boolean,
            default: false
        },
        scrollable: {
            type: Boolean,
            default: false
        },
    },
});
