import { ChallengeItemOrderTextCircle, ChallengeOrderTextCircle } from "@/data/challenges/challengeOrderTextCircle";
import { ChallengeItemMultiSelectText, ChallengeMultiSelectImageText } from "@/data/challenges/challengeMultiSelectImageText";
import { ChallengeItemPlaceOnAxis, ChallengePlaceOnAxis } from "@/data/challenges/challengePlaceOnAxis";
import { ChallengeItemPickTextBySwipe, ChallengePickTextBySwipe } from "@/data/challenges/challengePickTextBySwipe";
import { ChallengeItemPairImageText, ChallengePairImageText } from "@/data/challenges/challengePairImageText";
import { ChallengeItemPickImage, ChallengePickImage } from "@/data/challenges/challengePickImage";
import { Tour, TourPart } from "@/data/tourHandlerCore";
import { markRaw } from "vue";
export default function (tourCollectionKey) {
    const tour = new Tour(tourCollectionKey, 'A', 'Schnüffeltour 1', false, '', true);
    tour.addPart(new TourPart('Was ist Zeit?', 'Jetzt geht\'s los: Drück den oberen Knopf und hör dir den Text an. Weiter kommst du mit dem Knopf unten.', 'Wenn wir über Zeit sprechen, denken die meisten Menschen an Minuten und Stunden. Wir denken auch an die Zeit, die uns fehlt, um etwas fertig zu machen.<br><br>Sehr früh begannen die Menschen, Zeit einzuteilen. Sie beobachteten dazu die Sonne und den Mond. Tage und Monate konnten so festgelegt werden. Auch Jahreszeiten wurden eingeteilt: Frühling, Sommer, Herbst und Winter.<br><br>Aber auch ich werde immer älter. Jeden Geburtstag werde ich daran erinnert, ein Jahr älter zu sein.', false, null, false, false, 'xx_was-ist-zeit.m4a'));
    tour.addPart(new TourPart('Die Jahreszeiten', 'Ziehe die Jahreszeiten an die richtige Stelle.', '', false, new ChallengeOrderTextCircle([
        new ChallengeItemOrderTextCircle('Frühling', 'spring', 0),
        new ChallengeItemOrderTextCircle('Sommer', 'summer'),
        new ChallengeItemOrderTextCircle('Herbst', 'autumn'),
        new ChallengeItemOrderTextCircle('Winter', 'winter')
    ])));
    tour.addPart(new TourPart('', 'Wer ist am ältesten? Tippe auf die richtige Antwort.', '', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Ich', false),
        new ChallengeItemMultiSelectText('Mama', false),
        new ChallengeItemMultiSelectText('Oma', true)
    ], undefined, true, true)));
    tour.addPart(new TourPart('Mein Leben auf einer Linie', 'Zu deiner Geburt warst du null Jahre alt.<br>Wie alt warst du bei den anderen Ereignissen in deinem Leben.<br>Ziehe die Ereignisse an die richtige Stelle.', '', false, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('*', 0, undefined, undefined, false, true, true, 'Meine Geburt'),
        new ChallengeItemPlaceOnAxis('1', 1 / 8, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('2', 2 / 8, .5 / 8, undefined, true, false, true, 'zweiter Geburtstag'),
        new ChallengeItemPlaceOnAxis('3', 3 / 8, undefined, undefined, true),
        new ChallengeItemPlaceOnAxis('', 3.5 / 8, 1.5 / 8, undefined, false, false, true, 'erster Tag im Kindergarten'),
        new ChallengeItemPlaceOnAxis('4', 4 / 8, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('5', 5 / 8, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('6', 6 / 8, undefined, undefined, true, false, false),
        new ChallengeItemPlaceOnAxis('', 6.25 / 8, .75 / 8, undefined, false, false, true, 'erster Schultag'),
        new ChallengeItemPlaceOnAxis('7', 7 / 8, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false)
    ], .5 / 8, false, true)));
    tour.addPart(new TourPart('Was ist Vergangenheit?', '', 'Das, was jetzt gerade ist, nennen wir <b>Gegenwart</b>.<br><br>Das, was morgen sein wird, ist die <b>Zukunft</b>.<br><br><b>Vergangenheit</b> nennen wir alles, was bereits gewesen ist. Das ist alles, was du erlebt hast und vieles mehr.', false, null, false, false, 'xx_was-ist-vergangenheit.m4a'));
    tour.addPart(new TourPart('', 'Wie kannst du herausfinden, womit du in der Vergangenheit als Baby gespielt hast?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Ich frage einen Erwachsenen, der mich als Baby kannte.', false), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kannst du herausfinden, womit du in der Vergangenheit als Baby gespielt hast?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Ich suche im Keller nach Spielsachen von mir.', false), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kannst du herausfinden, womit du in der Vergangenheit als Baby gespielt hast?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Das lese ich in einem Sachbuch nach.', true), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kannst du herausfinden, womit du in der Vergangenheit als Baby gespielt hast?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Ich schaue im Internet nach.', true), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kannst du herausfinden, womit du in der Vergangenheit als Baby gespielt hast?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Ich frage meine Schulfreundin.', true), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kannst du herausfinden, womit du in der Vergangenheit als Baby gespielt hast?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Ich suche Fotos von mir von früher.', false), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kannst du herausfinden, womit du in der Vergangenheit als Baby gespielt hast?', '', false, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Ich suche nach Videos von mir als Baby.', false), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', '', 'Es gibt verschiedene Dinge aus der Vergangenheit.<br><br>Durch diese Dinge können wir etwas über früher herausfinden.<br><br>Die Dinge können unterschiedliche Formen haben.', true));
    tour.addPart(new TourPart('Finde die passenden Paare!', 'Ziehe Bild und Erklärung zusammen.', '', false, new ChallengePairImageText([
        new ChallengeItemPairImageText('Film von früher', 'bm_filmrolle.png', ''),
        new ChallengeItemPairImageText('Gegenstand von früher', 'bm_kinderschuhe.png', ''),
        new ChallengeItemPairImageText('Bild von früher', 'bm_kinderfoto.png', ''),
        new ChallengeItemPairImageText('Erzählungen von früher', 'bm_mann-sprechblase-baby.jpg', ''),
    ])));
    tour.addPart(new TourPart('', 'Im Museum in Bramberg werden Dinge von früher aufbewahrt.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welches Ding ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('bb_krampusmaske_alt.png', 'Krampusmaske aus Holz', true),
        new ChallengeItemPickImage('bb_krampus_neu.png', 'Krampusmaske aus Gummi', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', 'Im Museum in Bramberg werden Dinge von früher aufbewahrt.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welches Ding ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('bb_wiege_alt.png', 'Wiege', true),
        new ChallengeItemPickImage('bb_maxi_cosi.png', 'Kindersitz', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', 'Im Museum in Bramberg werden Dinge von früher aufbewahrt.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welches Ding ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('bb_waermflasche_alt.png', 'Wärmepfanne aus Metall', true),
        new ChallengeItemPickImage('bb_waermflasche_neu.png', 'Wärmflasche aus Plastik', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', 'Im Museum in Bramberg werden Dinge von früher aufbewahrt.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welches Ding ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', false, new ChallengePickImage([
        new ChallengeItemPickImage('bb_bienenstock_alt.png', 'Geflochtener Bienenstock', true),
        new ChallengeItemPickImage('bb_bienenstock_neu.png', 'Bienenstock aus Kunststoff (Styropor)', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', '', 'Mit Dingen aus der Vergangenheit kann man herausfinden, wie Menschen früher gelebt haben. Erzählt man davon, wird Geschichte erzählt.<br><br>Das Museum Bramberg erzählt über die Vergangenheit des Ortes. Dazu hat es viele Dinge gesammelt.', true));
    tour.addPart(new TourPart('', 'Hör dir an, was es aus dem Museum Bramberg zu berichten gibt!', 'Im Museum Bramberg zeigen wir viele Mineralien und Kristalle, die in unseren Bergen gefunden wurden. Außerdem erzählen wir, wie die Menschen bei uns früher gelebt haben. Dazu sammeln wir verschiedene Gegenstände aus der Vergangenheit und stellen sie im Museum aus. Auch Gebäude von früher zeigen wir euch.<br><br>In Bramberg gab es schon früher viele verschiedene Berufe: Bauern, Handwerker, aber auch Bergleute, die Metalle und Edelsteine aus dem Erdinneren holten. Sie alle haben Spuren hinterlassen. Im Museum kann man diese anschauen.', true, null, false, false, 'bb_intro-museum.mp3'));
    tour.addPart(new TourPart('', 'Hör genau hin, was im Video erzählt wird.<br><br>Damit kannst du die nächste Aufgabe lösen.', 'Im Museum Bramberg gibt es aber auch noch weitere Dinge zu entdecken.', true, null, false, false, '', { name: 'bb_schusterkugel.mp4', portrait: false }));
    tour.addPart(new TourPart('', 'Wie kann uns dieses Ding aus dem Museum helfen, etwas über die Vergangenheit herauszufinden?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Mit diesem Ding kann man herausfinden, wie Menschen früher Bäume gefällt haben.', true, [{ name: 'bb_schusterkugel.png', description: 'Schusterkugel' }]), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kann uns dieses Ding aus dem Museums helfen, etwas über die Vergangenheit herauszufinden?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Mit diesem Ding kann man herausfinden, wie die Menschen früher Fleisch gekocht haben.', true, [{ name: 'bb_schusterkugel.png', description: 'Schusterkugel' }]), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kann uns dieses Ding aus dem Museums helfen, etwas über die Vergangenheit herauszufinden?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Mit diesem Ding kann man herausfinden, wie Menschen früher Licht nutzten.', false, [{ name: 'bb_schusterkugel.png', description: 'Schusterkugel' }]), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', '', 'Hör dir nun eine Geschichte über die Vergangenheit rund um die Schusterkugel an.', false, null, false, false, '', { name: 'bb_schuster.mp4', portrait: false }));
    return markRaw(tour);
}
