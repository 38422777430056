import { defineComponent } from 'vue';
import SymbolMascotSideways from "@/components/symbols/mascot/sideways.vue";
import pageHookMixin from "@/data/pageHookMixin";
export default defineComponent({
    name: 'Wave',
    components: { SymbolMascotSideways },
    mixins: [pageHookMixin],
    props: {
        type: {
            type: String,
            default: 'wave'
        },
        subType: {
            type: Number,
            default: 0
        },
        showCollapsableButton: {
            type: Boolean,
            default: false
        },
        collapsableState: {
            type: String,
            default: 'atBottom'
        },
        color: {
            type: String,
            default: '#2f728f'
        }
    },
    data() {
        return {
            intro: this.type === 'intro',
            waveA: this.subType === 0,
            waveB: this.subType === 1,
            waveC: this.subType === 2
        };
    },
    methods: {
        onWaveCollapsableButtonClick(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.$emit('onWaveCollapsableButtonClick');
        }
    }
});
