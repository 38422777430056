import { markRaw } from "vue";
import { Tour, TourPart } from "@/data/tourHandlerCore";
import { ChallengeItemMultiSelectImage, ChallengeItemMultiSelectText, ChallengeMultiSelectImageText } from "@/data/challenges/challengeMultiSelectImageText";
import { ChallengeItemSingleSelectImage, ChallengeSingleSelectImage } from "@/data/challenges/challengeSingleSelectImage";
import { ChallengeItemPlaceOnAxis, ChallengePlaceOnAxis } from "@/data/challenges/challengePlaceOnAxis";
import { ChallengeItemPickTextBySwipe, ChallengePickTextBySwipe } from "@/data/challenges/challengePickTextBySwipe";
import { ChallengeItemOrderText, ChallengeOrderText } from "@/data/challenges/challengeOrderText";
export default function (tourCollectionKey) {
    const tour = new Tour(tourCollectionKey, 'D', 'Knochenjagd', true, '', true);
    tour.addPart(new TourPart('', 'Hör genau hin, was in der Sprachaufzeichnung erzählt wird.<br><br>Damit kannst du die nächste Aufgabe lösen.', '„Es war einmal eine Königin. Sie lebte auf einem schönen Schloss weit oben in den Bergen ...“<br><br>Solche Geschichten nennt man <b>Märchen</b>. Sie erzählen uns wundersame Geschichten von seltsamen Wesen und erfundenen Menschen. Wann und wo die Geschichten spielen, ist nicht bekannt.<br><br><b>Geschichten im Museum</b> sind anders. Sie erzählen uns von echten Menschen an bestimmten Orten in der Vergangenheit.', true, undefined, false, false, 'xx_knochenjagd_maerchen.m4a'));
    tour.addPart(new TourPart('Ist das ein Merkmal von Märchen?', '', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Ort und Zeit sind bekannt', true), 'nein', 'ja')));
    tour.addPart(new TourPart('Ist das ein Merkmal von Märchen?', '', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('frei erfundene Geschichten', false), 'nein', 'ja')));
    tour.addPart(new TourPart('Ist das ein Merkmal von Märchen?', '', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Fantastische Wesen treten auf', false), 'nein', 'ja')));
    tour.addPart(new TourPart('Ist das ein Merkmal von Märchen?', '', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Geschichten über Ereignisse, die wirklich stattgefunden haben', true), 'nein', 'ja')));
    tour.addPart(new TourPart('', 'Was sind die Merkmale von Geschichten im Museum?<br>Kreuze <b>alle</b> richtigen Antworten an!', '', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('frei erfundene Geschichten', false),
        new ChallengeItemMultiSelectText('Ort und Zeit sind bekannt', true),
        new ChallengeItemMultiSelectText('Fantastische Wesen treten auf', false),
        new ChallengeItemMultiSelectText('Geschichten über Ereignisse, die wirklich stattgefunden haben', true),
    ])));
    tour.addPart(new TourPart('', '', 'Mit Dingen von früher kann man herausfinden, wie Menschen in der Vergangenheit gelebt haben. Erzählt man davon, so wird Geschichte erzählt.<br><br>Willst du dir eine Geschichte aus dem Museum in Bramberg anhören?', false, undefined, false, false, 'bb_sommerfrische.mp3'));
    tour.addPart(new TourPart('', '', 'Willst du etwas über die Vergangenheit herausfinden, musst du Dinge von früher untersuchen. Diese Dinge nennt man dann Quellen.<br><br>Aus ihnen kann man Informationen erhalten. Geschichte in einem Museum wird mit diesen Informationen erzählt.<br><br>Quellen gibt es in verschiedenen Formen.', true));
    tour.addPart(new TourPart('', '', 'Markiere die <b>schriftliche</b> Quelle.', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(false, 'bb_tischlerei_millinger.jpg', 'Foto, Tischlerei Millinger, Bramberg, aufgenommen vor ca. 100 Jahren'),
        new ChallengeItemSingleSelectImage(true, 'bb_urkunde.png', 'Urkunde, Bramberg, 1654, in etwa 370 Jahre alt'),
        new ChallengeItemSingleSelectImage(false, 'bb_figur.png', 'Christusfigur aus Wachs, Andachtsfigur, ca. 170 Jahre alt'),
    ])));
    tour.addPart(new TourPart('', '', 'Markiere die <b>bildliche</b> Quelle.', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(true, 'bb_tischlerei_millinger.jpg', 'Foto, Tischlerei Millinger, Bramberg, aufgenommen vor ca. 100 Jahren'),
        new ChallengeItemSingleSelectImage(false, 'bb_urkunde.png', 'Urkunde, Bramberg, 1654, in etwa 370 Jahre alt'),
        new ChallengeItemSingleSelectImage(false, 'bb_figur.png', 'Christusfigur aus Wachs, Andachtsfigur, ca. 170 Jahre alt'),
    ])));
    tour.addPart(new TourPart('', '', 'Markiere die <b>gegenständliche</b> Quelle.', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(false, 'bb_tischlerei_millinger.jpg', 'Foto, Tischlerei Millinger, Bramberg, aufgenommen vor ca. 100 Jahren'),
        new ChallengeItemSingleSelectImage(false, 'bb_urkunde.png', 'Urkunde, Bramberg, 1654, in etwa 370 Jahre alt'),
        new ChallengeItemSingleSelectImage(true, 'bb_figur.png', 'Christusfigur aus Wachs, Andachtsfigur, ca. 170 Jahre alt'),
    ])));
    tour.addPart(new TourPart('', 'Mit welcher Form von Quelle haben wir es hier zu tun?', 'Sieh dir das Video an und markiere die Form der Quelle!', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Schriftliche Quelle', false),
        new ChallengeItemMultiSelectText('Mündliche Quelle', true),
        new ChallengeItemMultiSelectText('Gegenständliche Quelle', false),
    ]), false, false, '', { name: 'bb_zeitzeuge.mp4', portrait: false }));
    tour.addPart(new TourPart('', 'Eine Geschichte aus dem Museum: „Vor 500 Jahren war Mühlbach bei Bramberg ein Bergwerksort. Hier wurde Kupfer produziert. Das ist ein Metall, das sich in Steinen aus dem Bergwerk findet. Diese Steine wurden nach vielen Arbeitsschritten in Öfen sehr stark erhitzt. So konnte das Kupfer aus dem Stein geschmolzen werden. Die meisten dafür notwendigen Gebäude gibt es heute in Mühlbach nicht mehr.“<br><br>Schau dir das Foto genau an.', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Kann dieses Foto aus der Vergangenheit von Bramberg als Quelle diese Geschichte beweisen?', true), 'falsch', 'richtig', 'slim'), false, true, '', '', 'bb_fassergut.jpg', 'Foto, Bauernfamilie vor dem Fassergut, aufgenommen vor ca. 100 Jahren'));
    tour.addPart(new TourPart('', 'Eine Geschichte aus dem Museum: „Vor 500 Jahren war Mühlbach bei Bramberg ein Bergwerksort. Hier wurde Kupfer produziert. Das ist ein Metall, das sich in Steinen aus dem Bergwerk findet. Diese Steine wurden nach vielen Arbeitsschritten in Öfen sehr stark erhitzt. So konnte das Kupfer aus dem Stein geschmolzen werden. Die meisten dafür notwendigen Gebäude gibt es heute in Mühlbach nicht mehr.“<br><br>Schau dir das Foto genau an.', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Kann dieses Foto aus der Vergangenheit von Bramberg als Quelle diese Geschichte beweisen?', false), 'falsch', 'richtig', 'slim'), false, true, '', '', 'bb_lucia_stollen_eingang.jpg', 'Foto, Eingang zum Luciastollen, in Betrieb bis vor 150 Jahren'));
    tour.addPart(new TourPart('', 'Eine Geschichte aus dem Museum: „Vor 500 Jahren war Mühlbach bei Bramberg ein Bergwerksort. Hier wurde Kupfer produziert. Das ist ein Metall, das sich in Steinen aus dem Bergwerk findet. Diese Steine wurden nach vielen Arbeitsschritten in Öfen sehr stark erhitzt. So konnte das Kupfer aus dem Stein geschmolzen werden. Die meisten dafür notwendigen Gebäude gibt es heute in Mühlbach nicht mehr.“<br><br>Schau dir das Foto genau an.', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Kann dieses Foto aus der Vergangenheit von Bramberg als Quelle diese Geschichte beweisen?', true), 'falsch', 'richtig', 'slim'), false, true, '', '', 'bb_bramberg_ortschaft.jpg', 'Foto, Ortsansicht von Bramberg, 1920, aufgenommen vor ca. 100 Jahren'));
    tour.addPart(new TourPart('', 'Eine Geschichte aus dem Museum: „Vor 500 Jahren war Mühlbach bei Bramberg ein Bergwerksort. Hier wurde Kupfer produziert. Das ist ein Metall, das sich in Steinen aus dem Bergwerk findet. Diese Steine wurden nach vielen Arbeitsschritten in Öfen sehr stark erhitzt. So konnte das Kupfer aus dem Stein geschmolzen werden. Die meisten dafür notwendigen Gebäude gibt es heute in Mühlbach nicht mehr.“<br><br>Schau dir das Foto genau an.', '', false, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Kann dieses Foto aus der Vergangenheit von Bramberg als Quelle diese Geschichte beweisen?', true), 'falsch', 'richtig', 'slim'), false, true, '', '', 'bb_bauernstube.jpg', 'Bauernstube, Bramberg, ca. 220 Jahre alt'));
    tour.addPart(new TourPart('', 'Hier haben wir eine historische Quelle. Um welche Art von Quelle handelt es sich?', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Schriftliche Quelle', false),
        new ChallengeItemMultiSelectText('Bildliche Quelle', true),
        new ChallengeItemMultiSelectText('Gegenständliche Quelle', false),
        new ChallengeItemMultiSelectText('Mündliche Quelle', false)
    ], [
        new ChallengeItemMultiSelectImage('bb_anna_mundloch.jpg', undefined, 'Foto eines Bergarbeiters, Bramberg, um 1925, aufgenommen vor ca. 100 Jahren'),
    ])));
    tour.addPart(new TourPart('', 'Was kannst du auf dem Ausschnitt des Fotos erkennen?', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Hammer mit gebogenem Griff (Bergschlägel)', true),
        new ChallengeItemMultiSelectText('Lampe für den Bergbau (Karbid-Lampe)', false),
        new ChallengeItemMultiSelectText('Stolleneingang', false)
    ], [
        new ChallengeItemMultiSelectImage('bb_anna_mundloch.jpg', {
            x: 400 / 854,
            y: 394 / 611,
            w: 80 / 854,
            h: 132 / 611
        }, 'Foto eines Bergarbeiters, Bramberg, um 1925, aufgenommen vor ca. 100 Jahren'),
    ], true)));
    tour.addPart(new TourPart('', 'Was kannst du auf dem Ausschnitt des Fotos erkennen?', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Hammer mit gebogenem Griff (Bergschlägel)', false),
        new ChallengeItemMultiSelectText('Lampe für den Bergbau (Karbid-Lampe)', true),
        new ChallengeItemMultiSelectText('Stolleneingang', false)
    ], [
        new ChallengeItemMultiSelectImage('bb_anna_mundloch.jpg', {
            x: 380 / 854,
            y: 236 / 611,
            w: 62 / 854,
            h: 101 / 611
        }, 'Foto eines Bergarbeiters, Bramberg, um 1925, aufgenommen vor ca. 100 Jahren'),
    ], true)));
    tour.addPart(new TourPart('', 'Was kannst du auf dem Ausschnitt des Fotos erkennen?', '', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Hammer mit gebogenem Griff (Bergschlägel)', false),
        new ChallengeItemMultiSelectText('Lampe für den Bergbau (Karbid-Lampe)', false),
        new ChallengeItemMultiSelectText('Stolleneingang', true)
    ], [
        new ChallengeItemMultiSelectImage('bb_anna_mundloch.jpg', {
            x: 242 / 854,
            y: 131 / 611,
            w: 209 / 854,
            h: 427 / 611
        }, 'Foto eines Bergarbeiters, Bramberg, um 1925, aufgenommen vor ca. 100 Jahren'),
    ], true)));
    tour.addPart(new TourPart('', 'Das Foto stammt aus Bramberg und ist 100 Jahre alt.<br><br>Welche Fragen kann man anhand dieser bildlichen Quelle beantworten?<br><br>Wähle <b>alle</b> richtigen Fragen aus!', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Was machten Bergarbeiter vor 100 Jahren in ihrer Freizeit?', false),
        new ChallengeItemMultiSelectText('Wie viel verdienten Bergarbeiter früher?', false),
        new ChallengeItemMultiSelectText('Wie sah die Arbeitskleidung von Bergarbeitern aus?', true),
        new ChallengeItemMultiSelectText('Mit welchen Werkzeugen arbeiteten Bergarbeiter vor 100 Jahren?', true)
    ], [
        new ChallengeItemMultiSelectImage('bb_anna_mundloch.jpg', undefined, 'Foto eines Bergarbeiters, Bramberg, um 1925, aufgenommen vor ca. 100 Jahren'),
    ], false)));
    tour.addPart(new TourPart('', 'Mit Quellen können wir Informationen über die Vergangenheit herausfinden.<br>Du fragst dich: Wie war das Leben von Bergarbeitern vor 100 Jahren?', 'Diese Quelle erlaubt verschiedene Antworten.<br>Markiere <b>alle</b> möglichen Antworten.', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Die Arbeiter hatten viel Freizeit.', false),
        new ChallengeItemMultiSelectText('Bergarbeiter benutzten spezielle Lampen für die Arbeit im Stollen.', true),
        new ChallengeItemMultiSelectText('Nicht in allen Stollen gab es Schienen. Das Gestein musste also auf dem Rücken hinaus getragen werden.', true),
        new ChallengeItemMultiSelectText('Das Wetter im Sommer war früher immer warm.', false)
    ], [
        new ChallengeItemMultiSelectImage('bb_anna_mundloch.jpg', undefined, 'Foto eines Bergarbeiters, Bramberg, um 1925, aufgenommen vor ca. 100 Jahren'),
    ], false, false, 'slim')));
    tour.addPart(new TourPart('', 'Oje, jetzt ist uns die Geschichte durcheinander gekommen. Bringe die Teile in die richtige Reihenfolge. Ziehe!', '', false, new ChallengeOrderText([
        new ChallengeItemOrderText('Vor ungefähr 700 Jahren wurde das Haus mit dem Namen Wilhelmgut gebaut.'),
        new ChallengeItemOrderText('Danach wurde es mehrere 100 Jahre als Bauernhaus genutzt.'),
        new ChallengeItemOrderText('Erst vor rund 100 Jahren war im Haus ein Altersheim untergebracht und später auch noch eine Art Krankenhaus, wo Frauen ihre Kinder zur Welt brachten.'),
        new ChallengeItemOrderText('Heute ist dieses Gebäude das Museum Bramberg.')
    ])));
    tour.addPart(new TourPart('', '', '', false, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis('20', 1 / 5, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('40', 2 / 5, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('60', 3 / 5, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('80', 4 / 5, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false)
    ], .1, false, false, false, 10, 12)));
    tour.addPart(new TourPart('', 'Über welche Ereignisse aus den letzten 100 Jahren erzählt das Museum Bramberg?<br><br>Tippe auf ein Bild, um es zu vergrößern. Dort findest du wichtige Hinweise.<br><br>Ziehe die Bilder an die richtige Stelle auf dem Zeitstrahl.', '', false, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis('vor 100 Jahren', 2 / 14, undefined, undefined, true, false, false),
        new ChallengeItemPlaceOnAxis('', 3 / 14, undefined, undefined, true, false, true, '', 'bb_brechln.jpg', 'Leinenherstellung aus Flachs, Foto vom Flachsbrecheln, 1934, aufgenommen vor 90 Jahren'),
        new ChallengeItemPlaceOnAxis('', 5 / 14, undefined, undefined, true, false, true, '', 'bb_portrait_kuenstler.png', 'Das Leben Josef Stoitzners, ein bekannter Maler, Tod vor 70 Jahren, Selbstportrait'),
        new ChallengeItemPlaceOnAxis('heute', 12 / 14, undefined, undefined, true, false, false),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false, false),
    ], 1 / 14, true, true, true, 0, 0, false, false, false, true, false, true)));
    return markRaw(tour);
}
