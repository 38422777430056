import { defineComponent } from "vue";
import userHandler from "@/data/userHandler";
import router from "@/router";
import Button from "@/components/core/Button.vue";
import modalHandler from "@/data/modalHandler";
export default defineComponent({
    name: 'LoginForm',
    components: {
        Button
    },
    data() {
        return {
            name: ''
        };
    },
    computed: {
        buttonColor() {
            let color = 'gray';
            if (this.name != '') {
                color = 'green';
            }
            return color;
        }
    },
    methods: {
        async useName(event) {
            event.preventDefault();
            const addUserStatus = await userHandler.addUser(this.name, true);
            if (addUserStatus.status === 'userAlreadyExists') {
                modalHandler.show('userAlreadyExists', {
                    preExistingUserIndex: addUserStatus.index
                });
            }
            else {
                this.name = '';
                router.push('/main/tourCollectionSelection');
            }
        }
    }
});
