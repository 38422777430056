import { defineComponent } from "vue";
import { MAX_BONES_PER_TOUR } from '@/lib/constants';
import userHandler from "@/data/userHandler";
import LayoutsMain from "@/components/layouts/Main.vue";
import Headline from "@/components/core/Headline.vue";
import Description from "@/components/core/Description.vue";
import router from "@/router";
import Button from "@/components/core/Button.vue";
import SymbolBone from "@/components/symbols/bone.vue";
import modalHandler from "@/data/modalHandler";
import pageHandler from "@/data/pageHandler";
import pageHookMixin from "@/data/pageHookMixin";
import { tourHandler } from "@/data/tourHandler";
export default defineComponent({
    name: 'ViewTourSelection',
    mixins: [pageHookMixin],
    components: { SymbolBone, LayoutsMain, Headline, Description, Button },
    data() {
        const { tourCollectionKey } = router.currentRoute?.value?.params || {};
        return {
            fullScore: MAX_BONES_PER_TOUR,
            tourCollectionKey: String(tourCollectionKey),
        };
    },
    updated() {
        const { tourCollectionKey } = router.currentRoute?.value?.params || {};
        if (tourCollectionKey) {
            this.tourCollectionKey = String(tourCollectionKey);
        }
    },
    computed: {
        tourCollection() {
            return tourHandler.getTourCollectionByKey(this.tourCollectionKey);
        },
        tours() {
            return this.tourCollection?.getTours() || [];
        },
        getTourCollectionScore() {
            return this.tourCollection?.getNormalizedScore() || 0;
        },
    },
    methods: {
        startTour(tour) {
            if (this.tourCollection) {
                if (this.isTourFreeToPlay(tour)) {
                    userHandler.initTour(tour);
                    router.push(this.tourCollection.getPath(tour, 0));
                }
                else {
                    modalHandler.show('tourLocked');
                }
            }
        },
        isTourFreeToPlay(tour) {
            return (!tour.lockedByUserName && !tour.ancestorsHasToBeCompleted) || userHandler.getUser().isTourUnlocked(this.tourCollection?.key, tour.getKey(), tour.ancestorsHasToBeCompleted, tour.lockedByUserName);
        },
        buttonColor(tour) {
            if (this.isTourFreeToPlay(tour)) {
                return 'green';
            }
            return 'gray';
        }
    },
    ionViewWillEnter() {
        pageHandler.emit('beforePageEnter');
    },
    ionViewDidEnter() {
        pageHandler.emit('pageEnter');
    },
});
