import { AH } from "capacitor-plugin-asset-handler";
import { PLATFORM } from "@/lib/constants";
export default class Media {
    type;
    name;
    extension;
    constructor(type, name) {
        this.type = type;
        this.name = name;
        const nameParts = String(name || '').split('.');
        let extension = nameParts.pop();
        if (!extension || extension.length > 5) {
            extension = '';
        }
        else {
            this.name = nameParts.join('.');
        }
        if (extension) {
            this.extension = extension;
        }
        else {
            this.extension = 'jpg';
            if (this.type === 'video')
                this.extension = 'mp4';
            else if (this.type === 'audio')
                this.extension = 'mp3';
        }
    }
    async getData() {
        try {
            if (PLATFORM === 'android') {
                // this url may be a base64 data url (e.g., via android asset pack) or a simple url to public/assets/...
                const filePath = this.getFilePath();
                const asset = await AH.get_asset_url({ path: filePath });
                return asset?.url || '';
            }
            return this.getFileUrl();
        }
        catch (error) {
            console.error(error);
        }
        return '';
    }
    getFilePath() {
        return `${this.type}/${this.name}.${this.extension}`;
    }
    getFileUrl() {
        return `/assets/api/${this.getFilePath()}`;
    }
}
