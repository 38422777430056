import { defineComponent } from 'vue';
import MediaImage from '@/data/mediaImage';
import DraggableGesture from "@/components/core/DraggableGesture.vue";
import modalHandler from "@/data/modalHandler";
import SymbolSpeechBubblePointer from "@/components/symbols/speechBubblePointer.vue";
import SymbolSpeechBubblePointerArea from "@/components/symbols/speechBubblePointerArea.vue";
import SymbolImageZoomArrows from "@/components/symbols/imageZoomArrows.vue";
import pageHookMixin from "@/data/pageHookMixin";
import { IonSpinner } from "@ionic/vue";
export default defineComponent({
    name: 'Image',
    components: {
        SymbolImageZoomArrows,
        SymbolSpeechBubblePointer,
        SymbolSpeechBubblePointerArea,
        DraggableGesture,
        IonSpinner
    },
    mixins: [pageHookMixin],
    props: {
        mediaImage: {
            type: MediaImage
        },
        imageFile: {
            type: String
        },
        clipPath: {
            type: String
        },
        imageDescription: {
            type: String
        },
        rotate: {
            type: Boolean,
            default: false
        },
        shadow: {
            type: Boolean,
            default: false
        },
        float: {
            type: Boolean,
            default: false
        },
        showAsSpeechBubble: {
            type: Boolean,
            default: false
        },
        showAsSpeechBubblePlacementAreaWidth: {
            type: Number,
            default: 0
        },
        draggable: {
            type: Boolean,
            default: false
        },
        zIndexDelta: {
            type: Number,
            default: 0
        },
        orientation: {
            type: String,
            default: 'landscape' // landscape, portrait, circle
        },
        horizontalElements: {
            type: String,
            default: 'double'
        },
        itemKey: {
            type: Number,
            default: -1
        },
        dndAdditionalClasses: {
            type: Array,
            default: () => []
        },
    },
    unmounted() {
        this.media = null;
        this.thumb = '';
    },
    mounted() {
        this.prepare();
        const style = this.$el?.style;
        style?.setProperty('--z-index-delta', `${this.zIndexDelta || 0}`);
    },
    data() {
        let rotation = 0;
        let offsetY = 0;
        if (this.rotate) {
            rotation = Math.floor(Math.random() * 12) - 6;
            offsetY = Math.floor(Math.random() * 30) / 10;
        }
        return {
            thumb: '',
            media: null,
            highlightBBox: {
                x: 0,
                y: 0,
                w: 0,
                h: 0
            },
            showSymbol: !!this.$slots.symbol,
            showImageSlot: !!this.$slots.image,
            offsetY,
            rotation
        };
    },
    computed: {
        cssClasses() {
            const additionalContainerClasses = [];
            const additionalClasses = ['image'].concat(this.dndAdditionalClasses);
            if (this.orientation !== '') {
                additionalClasses.push(this.orientation);
            }
            if (this.horizontalElements) {
                additionalContainerClasses.push(this.horizontalElements);
            }
            if (this.shadow) {
                additionalClasses.push('shadow');
            }
            if (this.float) {
                additionalContainerClasses.push('float');
            }
            if (this.showAsSpeechBubble) {
                additionalContainerClasses.push('speechBubble');
            }
            return {
                element: additionalClasses.join(' '),
                container: additionalContainerClasses.join(' '),
            };
        }
    },
    methods: {
        async prepare() {
            if (this.mediaImage) {
                this.media = this.mediaImage;
            }
            else if (this.imageFile) {
                this.media = new MediaImage(this.imageFile, undefined, this.imageDescription);
            }
            if (this.media) {
                this.thumb = await this.media.getThumb() || '';
                const bbox = this.media.getHighlightBBox() || {};
                if (bbox.w > 0) {
                    this.highlightBBox = {
                        x: Math.round(bbox.x * 100),
                        y: Math.round(bbox.y * 100),
                        w: Math.round(bbox.w * 100),
                        h: Math.round(bbox.h * 100),
                    };
                }
                this.$el.classList.add('animate');
            }
            else {
                this.$el.classList.add('animate');
            }
        },
        imageZoomOpen() {
            if (this.media) {
                modalHandler.show('imageZoom', {
                    mediaImage: this.media
                });
            }
        },
        draggableOnStart(itemKey, cursorX, cursorY, bbox = null, element = null) {
            this.$emit('draggableOnStart', itemKey, cursorX, cursorY, bbox, element);
        },
        draggableOnMove(itemKey, cursorX, cursorY, bbox = null, element = null) {
            this.$emit('draggableOnMove', itemKey, cursorX, cursorY, bbox, element);
        },
        draggableOnEnd(itemKey, cursorX, cursorY, bbox = null, element = null) {
            this.$emit('draggableOnEnd', itemKey, cursorX, cursorY, bbox, element);
        }
    }
});
