import { markRaw } from "vue";
import { TourHandler, TourCollection } from '@/data/tourHandlerCore';
import bmTourA from '@/data/tours/bm/tourA';
import bmTourB from '@/data/tours/bm/tourB';
import bmTourC from '@/data/tours/bm/tourC';
import bmTourD from '@/data/tours/bm/tourD';
import bmTourE from '@/data/tours/bm/tourE';
import bbTourA from '@/data/tours/bb/tourA';
import bbTourB from '@/data/tours/bb/tourB';
import bbTourC from '@/data/tours/bb/tourC';
import bbTourD from '@/data/tours/bb/tourD';
import bbTourE from '@/data/tours/bb/tourE';
import wwTourA from '@/data/tours/ww/tourA';
import wwTourB from '@/data/tours/ww/tourB';
import wwTourC from '@/data/tours/ww/tourC';
import wwTourD from '@/data/tours/ww/tourD';
import wwTourE from '@/data/tours/ww/tourE';
// import inTourT from '@/data/tours/_in/tourT'
const tourHandlerCore = new TourHandler();
const tourCollectionA = new TourCollection('BM', 'Bürmoos', 'logo_buermoos.jpg', { imageFile: 'bm_museum_eingang.jpg', imageDescription: 'Eingang des Torf-Glas-Ziegel-Museums' }, { imageFile: 'welcome_bottom_bm.jpg', imageDescription: 'Jutta Ramböck' });
const tourCollectionB = new TourCollection('BB', 'Bramberg', 'logo_bramberg.svg', { imageFile: 'bb_museum_eingang.jpg', imageDescription: 'Eingang des Museums Bramberg' }, { imageFile: 'welcome_bottom_bb.jpg', imageDescription: 'Waltraud Moser-Schmidl' });
const tourCollectionC = new TourCollection('WW', 'Werfenweng', 'logo_werfenweng.jpg', { imageFile: 'ww_museum_eingang.jpg', imageDescription: 'Eingang des Salzburger FIS Landesskimuseums' });
tourCollectionA.addTour(bmTourA(tourCollectionA.key));
tourCollectionA.addTour(bmTourB(tourCollectionA.key));
tourCollectionA.addTour(bmTourC(tourCollectionA.key));
tourCollectionA.addTour(bmTourD(tourCollectionA.key));
tourCollectionA.addTour(bmTourE(tourCollectionA.key));
// tourCollectionA.addTour(inTourT(tourCollectionA.key))
tourCollectionB.addTour(bbTourA(tourCollectionB.key));
tourCollectionB.addTour(bbTourB(tourCollectionB.key));
tourCollectionB.addTour(bbTourC(tourCollectionB.key));
tourCollectionB.addTour(bbTourD(tourCollectionB.key));
tourCollectionB.addTour(bbTourE(tourCollectionB.key));
tourCollectionC.addTour(wwTourA(tourCollectionC.key));
tourCollectionC.addTour(wwTourB(tourCollectionC.key));
tourCollectionC.addTour(wwTourC(tourCollectionC.key));
tourCollectionC.addTour(wwTourD(tourCollectionC.key));
tourCollectionC.addTour(wwTourE(tourCollectionC.key));
tourHandlerCore.addTourCollection(tourCollectionA);
tourHandlerCore.addTourCollection(tourCollectionB);
tourHandlerCore.addTourCollection(tourCollectionC);
tourHandlerCore.initToursCollections();
export const tourHandler = markRaw(tourHandlerCore);
export default tourHandler;
