import { defineComponent } from "vue";
import { ChallengePlaceImageOnText } from "@/data/challenges/challengePlaceImageOnText";
import Image from '@/components/core/Image.vue';
import Button from '@/components/core/Button.vue';
import router from '@/router';
import modalHandler from "@/data/modalHandler";
import pageHookMixin from "@/data/pageHookMixin";
import DropZone from "@/components/core/DropZone.vue";
import { onBBoxReady } from "@/lib/utils";
export default defineComponent({
    name: 'ChallengePickTextBySwipe',
    mixins: [pageHookMixin],
    components: {
        DropZone,
        Image,
        Button
    },
    props: {
        challenge: {
            type: ChallengePlaceImageOnText
        },
        forwardUrl: {
            type: String
        },
        showMotivationModal: {
            type: Boolean,
            default: false
        },
        bottomReadyToMeasure: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        if (this.challenge && this.bottomReadyToMeasure) {
            this.startInit();
        }
    },
    updated() {
        if (this.bottomReadyToMeasure) {
            this.startInit();
        }
    },
    data() {
        return {
            initialized: false,
            dropZoneStates: this.challenge?.getDropZoneStates() || {}
        };
    },
    computed: {
        solved() {
            if (this.challenge) {
                return this.challenge.state === 'solved';
            }
            return false;
        },
        buttonColor() {
            if (this.challenge) {
                if (this.solved) {
                    return 'green';
                }
                if (this.challenge.state === 'chosen') {
                    return 'orange';
                }
            }
            return 'gray';
        },
    },
    methods: {
        onScreenUpdate() {
            router.go(0);
        },
        startInit() {
            if (!this.initialized) {
                this.initialized = true;
                onBBoxReady(this.$el, 75, () => this.init());
            }
        },
        init() {
            this.updateDropZones();
        },
        updateDropZones() {
            if (this.challenge && this.challenge.getNumberOfDropZones() < 1) {
                const items = this.$el.querySelectorAll('.target > .item');
                for (const i in items) {
                    if (!isNaN(Number(i))) {
                        const dropZone = items[i].querySelector('.dropZone');
                        const dropZoneKey = Number(dropZone.dataset.dropZoneKey);
                        this.challenge?.registerDropZone(dropZoneKey, items[i].getBoundingClientRect());
                    }
                }
                // eslint-disable-next-line vue/no-mutating-props
                this.challenge?.sortDropZones();
            }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        draggableOnStart(itemKey, cursorX, cursorY, bbox = null, element = null) {
            if (element) {
                element.style.setProperty('transition', `none`);
            }
        },
        draggableOnMove(itemKey, cursorX, cursorY) {
            this.challenge?.selectItemDropZone(itemKey, cursorX, cursorY);
            this.dropZoneStates = this.challenge?.getDropZoneStates() || [];
        },
        draggableOnEnd(itemKey, cursorX, cursorY, bbox = null, element = null) {
            this.dropZoneStates = this.challenge?.getDropZoneStates() || [];
            if (element && bbox?.x) {
                for (const dropZoneKey in this.dropZoneStates) {
                    const dropZoneState = this.dropZoneStates[dropZoneKey];
                    if (dropZoneState.itemKey === itemKey) {
                        if (dropZoneState.valid) {
                            const offsetX = Number(String(element.style.getPropertyValue('--outer-offset-x') || '').replace(/px$/, ''));
                            const offsetY = Number(String(element.style.getPropertyValue('--outer-offset-y') || '').replace(/px$/, ''));
                            const x = Math.round(offsetX + dropZoneState.bbox.x - bbox.x);
                            const y = Math.round(offsetY + dropZoneState.bbox.y + (dropZoneState.bbox.height / 2) - (bbox.y + (bbox.height / 2)));
                            element.style.setProperty('transition', `transform .2s ease-in-out`);
                            element.style.setProperty('--outer-offset-x', `${x}px`);
                            element.style.setProperty('--outer-offset-y', `${y}px`);
                        }
                        break;
                    }
                }
            }
        },
        onForwardButtonClick() {
            if (this.challenge?.state === 'solved' && this.forwardUrl) {
                if (this.showMotivationModal) {
                    modalHandler.show('motivation', {
                        forwardUrl: this.forwardUrl
                    });
                }
                else {
                    router.replace(this.forwardUrl);
                }
            }
            if (this.challenge?.state === 'chosen') {
                this.dropZoneStates = this.challenge?.getDropZoneStates() || [];
                this.challenge.evaluate();
            }
        }
    }
});
