import { reactive, markRaw } from 'vue';
import ModalMotivation from '@/components/modals/Motivation.vue';
import ModalUserAlreadyExists from '@/components/modals/UserAlreadyExists.vue';
import MissingDevModal from '@/components/modals/MissingDev.vue';
import RemoveUserModal from '@/components/modals/RemoveUser.vue';
import EarlyLeaveTourModal from '@/components/modals/EarlyLeaveTour.vue';
import ImageZoomModal from '@/components/modals/ImageZoom.vue';
import TourLocked from '@/components/modals/TourLocked.vue';
import audioHandler from "@/data/audioHandler";
export class ModalHandler {
    component = null;
    showModal = false;
    meta = {};
    isNextRouteGranted = false;
    constructor() {
        this.component = markRaw(EarlyLeaveTourModal);
    }
    show(componentKey, meta = {}, preventSoundEffect = false) {
        this.showModal = true;
        this.meta = meta;
        switch (componentKey) {
            case 'motivation':
                this.component = markRaw(ModalMotivation);
                break;
            case 'userAlreadyExists':
                this.component = markRaw(ModalUserAlreadyExists);
                break;
            case 'missingDev':
                this.component = markRaw(MissingDevModal);
                break;
            case 'removeUser':
                this.component = markRaw(RemoveUserModal);
                break;
            case 'earlyLeaveTour':
                this.component = markRaw(EarlyLeaveTourModal);
                break;
            case 'imageZoom':
                this.component = markRaw(ImageZoomModal);
                break;
            case 'tourLocked':
                this.component = markRaw(TourLocked);
                break;
            default:
                this.component = null;
                break;
        }
        if (this.component !== null && componentKey !== 'imageZoom' && !preventSoundEffect) {
            audioHandler.play('speechBubble');
        }
    }
    hide() {
        this.showModal = false;
        this.meta = {};
    }
    get() {
        return this.component;
    }
    getMeta() {
        return this.meta;
    }
    grantNextRoute() {
        this.isNextRouteGranted = true;
    }
    nextRouteGranted() {
        const nextRouteGranted = this.isNextRouteGranted;
        this.isNextRouteGranted = false;
        return nextRouteGranted;
    }
}
const modalHandler = reactive(new ModalHandler());
export default modalHandler;
