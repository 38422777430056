import { BaseChallenge, BaseChallengeItem } from "@/data/challenges/challenge";
import MultiSelectImageText from "@/components/challenges/MultiSelectImageText.vue";
import MediaImage from "@/data/mediaImage";
import { defaultBBox } from "@/data/mediaImage";
import userHandler from "@/data/userHandler";
import audioHandler from "@/data/audioHandler";
export class ChallengeMultiSelectImageText extends BaseChallenge {
    selectedKeys = {};
    useAsSingleSelect = false;
    useButtonStyle = false;
    useStyle = '';
    presentationalItems = [];
    shuffleItems = true;
    constructor(items = [], presentationalItems = [], useAsSingleSelect = false, useButtonStyle = false, useStyle = '', shuffleItems = true) {
        super('multiSelectImageText', MultiSelectImageText);
        this.items = items;
        this.useAsSingleSelect = useAsSingleSelect;
        this.useButtonStyle = useButtonStyle;
        this.useStyle = useStyle;
        this.presentationalItems = presentationalItems || [];
        this.shuffleItems = shuffleItems;
    }
    init() {
        this.selectedKeys = {};
        this.state = '';
        if (this.shuffleItems) {
            this.shuffle();
        }
        this.postInit();
    }
    toggleSelection(key = -1) {
        if (key > -1 && this.state !== 'solved') {
            if (this.useAsSingleSelect) {
                this.selectedKeys = { [key]: !this.selectedKeys[key] };
            }
            else {
                this.selectedKeys[key] = !this.selectedKeys[key];
            }
            if (this.selectedKeys[key]) {
                audioHandler.play('select');
            }
        }
    }
    isCorrect() {
        if (this.state === 'solved') {
            for (const item of this.items) {
                if ((item.isCorrect && !this.selectedKeys[item.key])
                    ||
                        (!item.isCorrect && this.selectedKeys[item.key])) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }
    chosen() {
        for (const key in this.selectedKeys) {
            if (this.selectedKeys[key]) {
                return true;
            }
        }
        return false;
    }
    isSelected(key = -1) {
        return this.selectedKeys[key];
    }
    evaluate() {
        if (this.state !== 'solved') {
            this.state = 'solved';
            if (this.items.length > 0) {
                let nrOfCorrectItems = 0;
                for (const item of this.items) {
                    if ((item.isCorrect && this.selectedKeys[item.key])
                        ||
                            (!item.isCorrect && !this.selectedKeys[item.key])) {
                        nrOfCorrectItems++;
                    }
                }
                this.score = nrOfCorrectItems / this.items.length;
            }
            userHandler.getUser().setScore(this.getTourCollectionKey(), this.getTourKey(), this.getTourPartIndex(), this.score);
        }
    }
}
export class ChallengeItemMultiSelectImage extends BaseChallengeItem {
    media;
    constructor(imageName, highlightBBox = defaultBBox, description = '') {
        super('presentational');
        this.media = new MediaImage(imageName, highlightBBox, description);
    }
}
export class ChallengeItemMultiSelectText extends BaseChallengeItem {
    isCorrect = false;
    answer = '';
    constructor(answer, isCorrect = false) {
        super('challenge');
        this.isCorrect = isCorrect;
        this.answer = answer;
    }
}
