import Media from '@/data/media';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Audio',
    props: {
        file: {
            type: String,
            required: true,
            default: ''
        },
        small: {
            type: Boolean,
            default: false
        },
        float: {
            type: Boolean,
            default: false
        },
    },
    unmounted() {
        this.media = null;
        this.url = '';
    },
    data() {
        const media = new Media('audio', this.file)
            .getData()
            .then((url) => {
            this.loaded(url);
        });
        return {
            media,
            url: '',
            loading: true,
            loadingError: false,
            stopLoadingGranted: false,
            playing: false,
            refreshProgress: 0,
            progressAngle: 0,
            progressArc: 'M 162.918 194.851 A 100 100, 0, 1, 1, 162.918 194.851',
            loadingPosition: 0,
            loadingArc: 'M 172.9853 204.4871 A 25 25, 0, 0, 0, 197.4389 199.2893',
            symbolColor: '#b8d9ff'
        };
    },
    computed: {
        cssClasses() {
            const list = ['audioContainer'];
            if (this.small) {
                list.push('small');
            }
            if (this.float) {
                list.push('float');
            }
            return list.join(' ');
        }
    },
    mounted() {
        this.initRefreshProgress();
    },
    methods: {
        loaded(url) {
            this.url = url;
            this.stopLoadingGranted = true;
            if (!this.url) {
                this.loadingError = true;
            }
        },
        onLoadingEndCycle() {
            if (this.loading && this.stopLoadingGranted) {
                this.loading = false;
                this.symbolColor = '#004965';
            }
        },
        playToggle() {
            if (!this.loading) {
                const mediaPlayer = this.$refs.mediaPlayer;
                if (this.playing) {
                    this.playing = false;
                    mediaPlayer.pause();
                    if (this.refreshProgress)
                        clearTimeout(this.refreshProgress);
                }
                else {
                    this.playing = true;
                    mediaPlayer.play();
                    this.initRefreshProgress();
                }
            }
        },
        initRefreshProgress() {
            if (this.refreshProgress)
                clearTimeout(this.refreshProgress);
            this.refreshProgress = setTimeout(this.updateProgress, 50);
        },
        updateProgress() {
            if (this.loading) {
                this.loadingPosition = (this.loadingPosition + 1) % 20;
                if (this.loadingPosition === 19) {
                    this.onLoadingEndCycle();
                }
                const progress = (this.loadingPosition / 20);
                const progressAngle = progress * 360;
                const alpha = -progressAngle;
                const posStart = this.polarToCartesian(180.7107, 180.7107, 25, alpha);
                const posEnd = this.polarToCartesian(180.7107, 180.7107, 25, (alpha + 60) % 360);
                this.loadingArc = `M ${posStart.x} ${posStart.y} A 25 25, 0, 0, 0, ${posEnd.x} ${posEnd.y}`;
            }
            else {
                const mediaPlayer = this.$refs.mediaPlayer;
                if (mediaPlayer !== null) {
                    if (mediaPlayer.ended) {
                        this.playing = false;
                    }
                    else {
                        const progress = ((mediaPlayer.currentTime || 0) / (mediaPlayer.duration || 1));
                        const progressAngle = progress * 333.9;
                        if (progressAngle) {
                            const alpha = 31.59 - progressAngle;
                            const pos = this.polarToCartesian(110, 110, 100, alpha);
                            let largeArcFlag = '0';
                            if (progressAngle > 180) {
                                largeArcFlag = '1';
                            }
                            this.progressArc = `M 162.918 194.851 A 100 100, 0, ${largeArcFlag}, 1, ${pos.x} ${pos.y}`;
                        }
                    }
                }
            }
            if (this.loading || this.playing) {
                this.initRefreshProgress();
            }
        },
        polarToCartesian(centerX, centerY, radius, angleInDegrees) {
            var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
            return {
                x: centerX + (radius * Math.cos(angleInRadians)),
                y: centerY - (radius * Math.sin(angleInRadians))
            };
        }
    }
});
