import pageHookMixin from "@/data/pageHookMixin";
export default {
    name: 'SymbolBoneText',
    mixins: [pageHookMixin],
    props: {
        text: {
            type: String
        },
        backgroundColor: {
            type: String,
            default: '#DFDFDF'
        },
        strokeColor: {
            type: String,
            default: '#333333'
        }
    }
};
