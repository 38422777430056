import { defineComponent } from "vue";
import { ChallengeTimeline } from "@/data/challenges/challengeTimeline";
import Image from "@/components/core/Image.vue";
import Text from "@/components/core/Text.vue";
import Button from '@/components/core/Button.vue';
import router from '@/router';
import DropZone from "@/components/core/DropZone.vue";
import modalHandler from "@/data/modalHandler";
import pageHookMixin from "@/data/pageHookMixin";
export default defineComponent({
    name: 'ChallengeTimeline',
    mixins: [pageHookMixin],
    components: {
        DropZone,
        Image,
        Text,
        Button
    },
    props: {
        challenge: {
            type: ChallengeTimeline
        },
        forwardUrl: {
            type: String
        },
        showMotivationModal: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            dropZoneStates: this.challenge?.getDropZoneStates() || {}
        };
    },
    computed: {
        solved() {
            if (this.challenge) {
                return this.challenge.state === 'solved';
            }
            return false;
        },
        buttonColor() {
            if (this.challenge) {
                if (this.solved) {
                    return 'green';
                }
                if (this.challenge?.state === 'chosen') {
                    return 'orange';
                }
            }
            return 'gray';
        },
        nrOfDropZonesTop() {
            return Math.ceil((this.challenge?.getNumberOfItems?.() || 0) / 2);
        },
        nrOfDropZonesBottom() {
            return Math.floor((this.challenge?.getNumberOfItems?.() || 0) / 2);
        }
    },
    methods: {
        onScreenUpdate() {
            router.go(0);
        },
        updateDropZone() {
            if (this.challenge && this.challenge.getNumberOfDropZones() < 1) {
                const dropZones = this.$el.querySelectorAll('.dropZone');
                for (const dz in dropZones) {
                    if (!isNaN(Number(dz))) {
                        const dropZone = dropZones[dz];
                        const dropZoneKey = Number(dropZone.dataset.dropZoneKey);
                        this.challenge?.registerDropZone(dropZoneKey, dropZone.getBoundingClientRect());
                    }
                }
                // eslint-disable-next-line vue/no-mutating-props
                this.challenge?.sortDropZones();
            }
        },
        draggableOnMove(itemKey, cursorX, cursorY) {
            this.updateDropZone();
            this.challenge?.selectItemDropZone(itemKey, cursorX, cursorY);
            this.dropZoneStates = this.challenge?.getDropZoneStates() || [];
        },
        onForwardButtonClick() {
            if (this.challenge?.state === 'solved' && this.forwardUrl) {
                if (this.showMotivationModal) {
                    modalHandler.show('motivation', {
                        forwardUrl: this.forwardUrl
                    });
                }
                else {
                    router.replace(this.forwardUrl);
                }
            }
            if (this.challenge?.state === 'chosen') {
                this.dropZoneStates = this.challenge?.getDropZoneStates() || [];
                this.challenge.evaluate();
            }
        }
    }
});
