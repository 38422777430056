import { defineComponent } from "vue";
import LayoutsMain from "@/components/layouts/Main.vue";
import Headline from "@/components/core/Headline.vue";
import pageHandler from "@/data/pageHandler";
import pageHookMixin from "@/data/pageHookMixin";
import { PLATFORM } from "@/lib/constants";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import router from "@/router";
export default defineComponent({
    name: 'ViewImprint',
    mixins: [pageHookMixin],
    components: { LayoutsMain, PrivacyPolicy, Headline },
    setup() {
        const meta = router.currentRoute?.value?.meta || {};
        let isApp = PLATFORM !== 'web';
        if (meta.isApp !== undefined) {
            isApp = Boolean(meta.isApp);
        }
        return {
            isApp
        };
    },
    ionViewWillEnter() {
        pageHandler.emit('beforePageEnter');
    },
    ionViewDidEnter() {
        pageHandler.emit('pageEnter');
    },
});
