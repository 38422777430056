import { BaseChallenge, BaseChallengeItem } from "@/data/challenges/challenge";
import SingleSelectImage from "@/components/challenges/SingleSelectImage.vue";
import MediaImage from "@/data/mediaImage";
import userHandler from "@/data/userHandler";
export class ChallengeSingleSelectImage extends BaseChallenge {
    selectedKey = -1;
    constructor(items = []) {
        super('singleSelectImage', SingleSelectImage);
        this.items = items;
    }
    init() {
        this.selectedKey = -1;
        this.state = '';
        this.shuffle();
        this.postInit();
    }
    select(key = -1) {
        if (this.state !== 'solved') {
            this.selectedKey = key;
            if (key >= 0) {
                this.state = 'chosen';
            }
            else {
                this.state = '';
            }
        }
    }
    deselect() {
        if (this.state !== 'solved') {
            this.selectedKey = -1;
            this.state = '';
        }
    }
    getSelectedKey() {
        return this.selectedKey;
    }
    evaluate() {
        if (this.state !== 'solved') {
            this.state = 'solved';
            if (this.items.length > 0) {
                this.score = 0;
                for (const item of this.items) {
                    if (item.isCorrect && this.selectedKey === item.key) {
                        this.score = 1;
                        break;
                    }
                }
            }
            userHandler.getUser().setScore(this.getTourCollectionKey(), this.getTourKey(), this.getTourPartIndex(), this.score);
        }
    }
}
export class ChallengeItemSingleSelectImage extends BaseChallengeItem {
    isCorrect = false;
    media;
    constructor(isCorrect = false, imageName, imageDescription = '', imageMeta = {}) {
        super('challenge');
        this.isCorrect = isCorrect;
        this.media = new MediaImage(imageName, undefined, imageDescription, imageMeta);
    }
}
