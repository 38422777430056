import { BaseChallenge, BaseChallengeItem, BaseChallengeIterable } from "@/data/challenges/challenge";
import PlaceImageOnImage from "@/components/challenges/PlaceImageOnImage.vue";
import MediaImage from "@/data/mediaImage";
import userHandler from "@/data/userHandler";
import audioHandler from "@/data/audioHandler";
import { round } from "@/lib/utils";
const getDefaultReferenceFrame = () => ({
    target: {
        width: 0,
        height: 0
    },
    baseImage: {
        x: 0,
        y: 0,
        width: 0,
        height: 0
    },
    stroke: {
        width: 0,
        startCircleRadiusFactor: 3
    }
});
export class ChallengePlaceImageOnImage extends BaseChallenge {
    targetImage = null;
    targetImageWidth = 0;
    targetImageHeight = 0;
    useRepresentationalMode = false;
    referenceFrame = getDefaultReferenceFrame();
    dropZones = {};
    draggableItems;
    constructor(targetImageName, targetImageWidth, targetImageHeight, items, useRepresentationalMode = false) {
        super('placeImageOnImage', PlaceImageOnImage);
        this.items = items;
        this.targetImage = new MediaImage(targetImageName, undefined, '', {}, true);
        this.targetImageWidth = targetImageWidth;
        this.targetImageHeight = targetImageHeight;
        this.useRepresentationalMode = useRepresentationalMode;
        // prepare items used as draggables
        const draggableItems = [];
        for (const item of items) {
            if (item.image) {
                draggableItems.push(item);
            }
        }
        this.draggableItems = new BaseChallengeIterable(draggableItems);
        this.draggableItems.shuffle();
        this.draggableItems.postInit();
    }
    init() {
        if (this.useRepresentationalMode) {
            this.state = 'solved';
        }
        else {
            this.state = '';
        }
        this.dropZones = {};
        this.postInit();
    }
    registerDropZone(dropZoneKey, bbox) {
        if (dropZoneKey > 0 && bbox?.y > 0 && bbox?.height > 0) {
            if (this.dropZones[dropZoneKey]) {
                this.dropZones[dropZoneKey].bbox = bbox;
            }
        }
    }
    selectItemDropZone(itemKey, cursorX, cursorY) {
        let nrOfChosenValidItems = 0;
        let playSoundEffect = false;
        // update dropZone state
        for (const dropZoneKey in this.dropZones) {
            const dropZone = this.dropZones[dropZoneKey];
            const item = (this.getItemByKey(itemKey) || {});
            if (dropZone.bbox.x <= cursorX && cursorX <= (dropZone.bbox.x + dropZone.bbox.width)
                &&
                    dropZone.bbox.y <= cursorY && cursorY <= (dropZone.bbox.y + dropZone.bbox.height)) {
                dropZone.itemKeys[itemKey] = true;
                if (dropZone.itemKey < 0) {
                    playSoundEffect = true;
                    dropZone.itemKey = itemKey;
                    dropZone.itemIsCorrect = dropZone.solutionKey === item.solutionKey;
                }
            }
            else {
                delete dropZone.itemKeys[itemKey];
                if (dropZone.itemKey === itemKey) {
                    const itemKeyIndexes = Object.keys(dropZone.itemKeys);
                    if (itemKeyIndexes.length > 0) {
                        dropZone.itemKey = Number(itemKeyIndexes[0]);
                        dropZone.itemIsCorrect = dropZone.solutionKey === item.solutionKey;
                    }
                    else {
                        dropZone.itemKey = -1;
                        dropZone.itemIsCorrect = false;
                    }
                }
            }
            dropZone.valid = Object.keys(dropZone.itemKeys).length < 2;
            if (dropZone.itemKey > 0 && dropZone.valid) {
                nrOfChosenValidItems++;
            }
        }
        // update challenge state
        if (this.state !== 'solved') {
            if (nrOfChosenValidItems === this.draggableItems.length()) {
                this.state = 'chosen';
            }
            else {
                this.state = '';
            }
        }
        // update item symbols (has to happen after challenge state update)
        this.updateItemSymbols();
        // play sound effect if necessary
        if (playSoundEffect) {
            audioHandler.play('select');
        }
    }
    updateItemSymbols() {
        for (const dropZoneKey in this.dropZones) {
            const dropZone = this.dropZones[dropZoneKey];
            dropZone.itemSymbol = this.getItemSymbol(dropZone.itemKey, dropZone.valid, dropZone.itemIsCorrect);
        }
    }
    getDropZones() {
        return this.dropZones;
    }
    getNumberOfDropZones() {
        return Object.keys(this.dropZones).length;
    }
    evaluate() {
        if (this.state !== 'solved') {
            this.state = 'solved';
            this.updateItemSymbols();
            this.score = 0;
            if (this.items.length > 0) {
                let nrOfCorrectItems = 0;
                for (const dropZoneKey in this.dropZones) {
                    const dropZone = this.dropZones[dropZoneKey];
                    if (dropZone.itemIsCorrect) {
                        nrOfCorrectItems++;
                    }
                }
                this.score = nrOfCorrectItems / this.draggableItems.length();
            }
            userHandler.getUser().setScore(this.getTourCollectionKey(), this.getTourKey(), this.getTourPartIndex(), this.score);
        }
    }
    initializeReferenceFrame(targetBBox) {
        if (this.targetImageWidth > 0 && this.targetImageHeight > 0 && targetBBox.width > 0 && targetBBox.height > 0) {
            this.referenceFrame = getDefaultReferenceFrame();
            this.referenceFrame.target.width = round(targetBBox.width, 0);
            this.referenceFrame.target.height = round(targetBBox.height, 0);
            this.referenceFrame.baseImage = {
                x: 0,
                y: 0,
                width: this.referenceFrame.target.width * .65,
                height: 0
            };
            const target = this.referenceFrame.target;
            const baseImage = this.referenceFrame.baseImage;
            baseImage.height = baseImage.width / this.targetImageWidth * this.targetImageHeight;
            // prevent image height from exceeding target height
            if (baseImage.height > target.height) {
                baseImage.height = target.height;
                baseImage.width = baseImage.height / this.targetImageHeight * this.targetImageWidth;
            }
            // center image in representational mode
            if (this.useRepresentationalMode) {
                baseImage.x = round((target.width - baseImage.width) / 2, 0);
            }
            // calculate stroke
            this.referenceFrame.stroke.width = round(baseImage.width * .01, 0);
            // define dropZones
            const numberOfDropZones = this.items.length;
            if (numberOfDropZones > 0) {
                const deltaDropZoneY = (target.height * .81) / ((numberOfDropZones * numberOfDropZones) - 1);
                const dropZoneWidth = target.width * .25;
                const dropZoneHeight = Math.min(dropZoneWidth * .75, (numberOfDropZones - 1) * deltaDropZoneY);
                this.dropZones = {};
                for (const is in this.items) {
                    const i = Number(is);
                    const item = this.items[is];
                    const centerY = ((i + 1) * numberOfDropZones - 1 - (numberOfDropZones - 1) / 2) * deltaDropZoneY + this.referenceFrame.stroke.width * 3;
                    this.dropZones[item.key] = {
                        dropZoneKey: item.key,
                        solutionKey: item.solutionKey,
                        index: i,
                        itemKey: -1,
                        itemKeys: {},
                        itemSymbol: '',
                        itemIsCorrect: false,
                        valid: true,
                        hasDraggable: !!item.image,
                        bbox: {},
                        area: {
                            cy: round(centerY, 0),
                            x: round(target.width - dropZoneWidth - 2 * this.referenceFrame.stroke.width, 0),
                            y: round(centerY - (dropZoneHeight / 2), 0),
                            width: round(dropZoneWidth, 0),
                            height: round(dropZoneHeight, 0)
                        },
                        start: {
                            cx: round(item.dropPosition.x / this.targetImageWidth * baseImage.width, 3),
                            cy: round(item.dropPosition.y / this.targetImageHeight * baseImage.height, 3),
                        }
                    };
                }
            }
        }
        return this.referenceFrame;
    }
    getReferenceFrame() {
        return this.referenceFrame;
    }
}
export class ChallengeItemPlaceImageOnImage extends BaseChallengeItem {
    dropPosition = {};
    solutionKey = '';
    image = null;
    constructor(dropPosition, solutionKey = '', imageName = '', imageDescription = '', imageMeta = {}) {
        super('challenge');
        this.dropPosition = dropPosition || {
            x: 0,
            y: 0,
            shape: null
        };
        this.solutionKey = solutionKey;
        if (imageName) {
            this.image = new MediaImage(imageName, undefined, imageDescription, imageMeta);
        }
    }
}
