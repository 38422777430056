import { defineComponent } from "vue";
import pageHandler from "@/data/pageHandler";
const eventTypes = [
    'beforePageEnter',
    'pageEnter',
    'onScreenInit',
    'onScreenUpdate',
];
export default defineComponent({
    setup() {
        return {
            _pageID: '',
            _componentIndex: 0,
            _componentID: ''
        };
    },
    created() {
        this._pageID = this.$parent?._pageID || pageHandler.getPageID();
        this._componentIndex = pageHandler.getComponentIndex(this._pageID);
        this._componentID = `com_${this._pageID}-${this._componentIndex}`;
        for (const eventType of eventTypes) {
            if (this[eventType] !== undefined) {
                const event = `${eventType}-${this._pageID}`;
                const trigger = `${eventType}-${this._componentID}`;
                pageHandler.emitter.on(event, trigger, this[eventType]);
            }
        }
    },
    beforeUnmount() {
        if (this._pageID && this._componentID) {
            for (const eventType of eventTypes) {
                if (this[eventType] !== undefined) {
                    const event = `${eventType}-${this._pageID}`;
                    const trigger = `${eventType}-${this._componentID}`;
                    pageHandler.emitter.off(event, trigger);
                }
            }
        }
    },
});
