import { defineComponent } from "vue";
import SymbolCheck from "@/components/symbols/check.vue";
import SymbolX from "@/components/symbols/x.vue";
import SymbolPaw from "@/components/symbols/paw.vue";
import SymbolInvalid from "@/components/symbols/invalid.vue";
export default defineComponent({
    name: 'SymbolSolution',
    components: { SymbolX, SymbolCheck, SymbolPaw, SymbolInvalid },
    props: {
        isSelected: {
            type: Boolean,
            default: false
        },
        isCorrect: {
            type: Boolean,
            default: false
        },
        isIncorrect: {
            type: Boolean,
            default: false
        },
        isInvalid: {
            type: Boolean,
            default: false
        },
        symbol: {
            type: String,
            default: ''
        },
    },
});
