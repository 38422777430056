import { defineComponent } from "vue";
export default defineComponent({
    name: 'ImageCopyright',
    props: {
        // {
        //   name: 'Max Mustermann',
        //   license: 'CC BY 2.5',
        //   symbol: 'cc',
        //   url: 'https://origin.tld/image123.jpg'
        // }
        copyright: {
            type: Object,
        },
    },
    data() {
        const parts = [];
        if (this.copyright?.name) {
            parts.push(this.copyright.name);
        }
        if (this.copyright?.license) {
            parts.push(this.copyright.license);
        }
        return {
            text: parts.join(' | ')
        };
    },
    methods: {
        openCopyrightPage(event) {
            const copyrightLink = event?.target?.closest('a.link');
            if (copyrightLink) {
                event.preventDefault();
                event.stopImmediatePropagation();
                copyrightLink.click();
            }
        }
    }
});
