import { markRaw } from "vue";
import { Tour, TourPart } from "@/data/tourHandlerCore";
import { ChallengeItemMultiSelectImage, ChallengeItemMultiSelectText, ChallengeMultiSelectImageText } from "@/data/challenges/challengeMultiSelectImageText";
import { ChallengeItemPairImageText, ChallengePairImageText } from "@/data/challenges/challengePairImageText";
import { ChallengeItemPickImage, ChallengePickImage } from "@/data/challenges/challengePickImage";
import { ChallengeItemSingleSelectImage, ChallengeSingleSelectImage } from "@/data/challenges/challengeSingleSelectImage";
import { ChallengeItemTimelineImage, ChallengeTimeline } from "@/data/challenges/challengeTimeline";
export default function (tourCollectionKey) {
    const tour = new Tour(tourCollectionKey, 'B', 'Schnüffeltour 2', false, '', true);
    tour.addPart(new TourPart('Was ist ein Museum?', 'Jetzt geht\'s los: Drück den oberen Knopf und hör dir den Text an. Weiter kommst du mit dem Knopf unten.', 'In einem Museum werden verschiedene Dinge gesammelt und aufbewahrt. Ein Museum befindet sich zumeist in einem Haus. In diesem Haus gibt es Ausstellungen. Besucherinnen und Besucher können sich dort die gesammelten Dinge ansehen.<br><br>Geschichtsmuseen versuchen zu zeigen, was in der Vergangenheit passiert ist und wie Menschen früher gelebt haben. Die Leute vom Museum erforschen auch die gesammelten Gegenstände. Sie untersuchen, was man damit über die Vergangenheit herausfinden kann.', true, null, false, false, 'xx_was-ist-ein-museum.m4a'));
    tour.addPart(new TourPart('', 'Das Sammeln von besonderen Gegenständen ist die Aufgabe von Museen.<br><br>Geschichtsmuseen bewahren für uns Dinge aus der Vergangenheit in einem Lager auf. Dieses Lager nennt man Sammlung.<br><br>Welches Bild zeigt die <b>Sammlung</b> des Museums Bramberg?', '', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(false, 'bb_museum_eingang.jpg'),
        new ChallengeItemSingleSelectImage(false, 'bb_museum_eingang_detail.jpg'),
        new ChallengeItemSingleSelectImage(true, 'bb_sammlung.jpg'),
        new ChallengeItemSingleSelectImage(true, 'bb_ausstellung.jpg'),
    ])));
    tour.addPart(new TourPart('', 'In Ausstellungen können sich Besucherinnen und Besucher die gesammelten Gegenstände ansehen.<br><br>Welches Bild zeigt die <b>Ausstellung</b> des Museums Bramberg?', '', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(false, 'bb_museum_eingang.jpg'),
        new ChallengeItemSingleSelectImage(false, 'bb_museum_eingang_detail.jpg'),
        new ChallengeItemSingleSelectImage(true, 'bb_sammlung.jpg'),
        new ChallengeItemSingleSelectImage(true, 'bb_ausstellung.jpg'),
    ])));
    tour.addPart(new TourPart('', 'Ein Museum befindet sich in den meisten Fällen in einem Haus. Dieses Haus ist zu bestimmten Öffnungszeiten für alle interessierten Menschen zugänglich.<br><br>Welches Bild zeigt das <b>Haus</b> des Museums Bramberg?', '', false, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(true, 'bb_museum_eingang.jpg'),
        new ChallengeItemSingleSelectImage(true, 'bb_museum_eingang_detail.jpg'),
        new ChallengeItemSingleSelectImage(false, 'bb_sammlung.jpg'),
        new ChallengeItemSingleSelectImage(false, 'bb_ausstellung.jpg'),
    ])));
    tour.addPart(new TourPart('', 'Museen bewahren Dinge von früher für heute auf.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welcher Gegenstand ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('bb_buegeleisen_alt.png', 'Bügeleisen', true),
        new ChallengeItemPickImage('bb_buegeleisen_neu.png', 'Bügeleisen', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', 'Museen bewahren Dinge von früher für heute auf.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welcher Gegenstand ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('bb_rad_alt.png', 'Holzrad', true),
        new ChallengeItemPickImage('bb_rad_neu.png', 'Autoreifen', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', 'Museen bewahren Dinge von früher für heute auf.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welcher Gegenstand ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('bb_spritze_alt.png', 'Spritze', true),
        new ChallengeItemPickImage('bb_spritze_neu.png', 'Spritze', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', 'Museen bewahren Dinge von früher für heute auf.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welcher Gegenstand ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', false, new ChallengePickImage([
        new ChallengeItemPickImage('bb_steinzeitbeil.png', 'Beil ohne Stiel', true),
        new ChallengeItemPickImage('bb_beil_neu.png', 'Axt', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', '', 'Manche Dinge von früher sind bis heute aufgehoben worden. Man kann diese Dinge untersuchen, um etwas über früher herauszufinden.<br><br>Dann bezeichnet man sie als <b>Quellen</b>. Mit Quellen kann man etwas über die Vergangenheit herausfinden.<br><br>Man unterscheidet verschiedene Arten von Quellen.', true));
    tour.addPart(new TourPart('Finde die richtigen Paare!', 'Ziehe Bild und Erklärung zusammen.', '', true, new ChallengePairImageText([
        new ChallengeItemPairImageText('Bildliche Quelle', 'bm_photo_album.jpg'),
        new ChallengeItemPairImageText('Gegenständliche Quelle', 'bb_holzschuessel.png'),
        new ChallengeItemPairImageText('Schriftliche Quelle', 'bb_urkunde.png', 'Urkunde, Bramberg, 1654, in etwa 370 Jahre alt'),
        new ChallengeItemPairImageText('Mündliche Quelle', 'bm_zeitzeuge.jpg'),
    ])));
    tour.addPart(new TourPart('', 'Quellen können schriftlich sein.', 'Welche Quelle passt nicht dazu?', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(false, 'bm_inkunabel_memmingen.jpg', ''),
        new ChallengeItemSingleSelectImage(false, 'bm_postcard.jpg', ''),
        new ChallengeItemSingleSelectImage(true, 'bm_lascaux.jpg', 'Malerei aus einer Höhle in Frankreich', {
            copyright: {
                name: 'Lascaux, Public domain, via Wikimedia Commons',
                license: '',
                symbol: 'cc',
                url: 'https://commons.wikimedia.org/wiki/File:Lascaux_painting.jpg'
            }
        }),
    ])));
    tour.addPart(new TourPart('', 'Quellen können bildlich sein.', 'Welche Quelle passt nicht dazu?', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(true, 'bm_photo_apparat.jpg', ''),
        new ChallengeItemSingleSelectImage(false, 'bm_photo_collage.jpg', ''),
        new ChallengeItemSingleSelectImage(false, 'bm_brueghel-tower-of-babel.jpg', 'Gemälde von Pieter Brueghel dem Älteren, gemalt vor etwa 450 Jahren (1563)', {
            copyright: {
                name: 'Pieter Brueghel the Elder, Public domain, via Wikimedia Commons',
                license: '',
                symbol: 'cc',
                url: 'https://commons.wikimedia.org/wiki/File:Brueghel-tower-of-babel.jpg'
            }
        }),
    ])));
    tour.addPart(new TourPart('', 'Quellen können gegenständlich sein.', 'Welche Quelle passt nicht dazu?', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(false, 'bm_naehmaschine_alt.jpg', 'Nähmaschine'),
        new ChallengeItemSingleSelectImage(false, 'bm_vase.jpg', 'Vase'),
        new ChallengeItemSingleSelectImage(true, 'bm_zeitzeuge_2.jpg', 'Zeitzeugin'),
    ])));
    tour.addPart(new TourPart('', 'Quellen können mündlich sein.', 'Welche Quelle passt nicht dazu?', false, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(true, 'bm_burg.jpg', 'Burg'),
        new ChallengeItemSingleSelectImage(false, 'bm_kind.jpg', 'Zeitzeuge'),
        new ChallengeItemSingleSelectImage(false, 'bm_zeitzeuge_3.jpg', 'Zeitzeuge'),
    ])));
    tour.addPart(new TourPart('', 'Forschung und Dokumentation als Aufgaben des Museums', 'Eine wichtige Aufgabe des Museums ist die Forschung.<br><br>Die Vergangenheit wird erforscht, um etwas über das Früher und über das Heute zu lernen – oder einfach nur, um zu staunen und sich zu unterhalten.<br><br>Deshalb werden Quellen, also Dinge aus der Vergangenheit, untersucht. Man fragt sich, was man mit ihnen über die Vergangenheit herausfinden kann.<br><br>Die Ergebnisse der Forschung werden in der Ausstellung den Besucherinnen und Besuchern gezeigt.', true, undefined, false, false, 'xx_forschung_dokumentation.m4a'));
    tour.addPart(new TourPart('', 'Wie und warum wird inventarisiert und geforscht im Museum?', 'Im Museum Bramberg sammeln wir vor allem Objekte, die mit Bramberg in Verbindung stehen. Die Gegenstände werden aber nicht einfach so gezeigt. Sie werden zuerst vermessen, fotografiert und es wird genau notiert, wo sie herkommen und wofür sie verwendet wurden. Zum Abschluss erhält jeder Gegenstand eine eigene Nummer. Mit dieser Inventarnummer kann ich die Gegenstände dann in meinem Computer wiederfinden. Das ist sehr wichtig, wir haben tausende Ausstellungsstücke im Museum und kein Mensch kann sich für jedes einzelne merken, wann es hergestellt wurde oder wem es einmal gehört hat ...', true, undefined, false, false, undefined, { name: 'bb_inventarisierung.mp4', portrait: false }));
    tour.addPart(new TourPart('', 'Was kann man mit diesen drei Quellen erforschen? Markiere <b>alle</b> richtigen Antworten!', '', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Ob Messer auf der ganzen Welt gleich ausgesehen haben.', false),
        new ChallengeItemMultiSelectText('Ob sich Messer verändert haben.', true),
        new ChallengeItemMultiSelectText('Ob wir Messer heute noch so verwenden.', true),
        new ChallengeItemMultiSelectText('Wie Menschen früher zusammengelebt haben.', false)
    ], [
        new ChallengeItemMultiSelectImage('bb_messer_alt.png', undefined, 'Eisenmesser, Bramberg, ca. 170 Jahre alt'),
        new ChallengeItemMultiSelectImage('bb_messer_neu.png', undefined, 'Messer mit Griff aus Kunststoff, Salzburg, ca. 20 Jahre alt'),
        new ChallengeItemMultiSelectImage('bb_steinzeitmesser.png', undefined, 'Steinmesser, Bramberg, ca. 5000 Jahre alt')
    ])));
    tour.addPart(new TourPart('', 'Mit diesen Quellen kann man etwas über die Entwicklung von Messern erfahren. Bringe die drei Objekte in die richtige Reihenfolge.', '', false, new ChallengeTimeline([
        new ChallengeItemTimelineImage('bb_steinzeitmesser.png', 'Steinmesser, Bramberg, ca. 5000 Jahre alt'),
        new ChallengeItemTimelineImage('bb_messer_alt.png', 'Eisenmesser, Bramberg, ca. 170 Jahre alt'),
        new ChallengeItemTimelineImage('bb_messer_neu.png', 'Messer mit Griff aus Kunststoff, Salzburg, ca. 20 Jahre alt', {}, 'heute'),
    ], 'alt', 'neu')));
    tour.addPart(new TourPart('', '', 'In der Steinzeit nutzte man Schneidewerkzeuge aus Stein als Messer. Es wurden besondere Steine dazu verwendet, wie Feuerstein oder Hornstein. Auch Bergkristalle wurden für Geräte genutzt. Obwohl es sich um Steine handelt, waren die Messer und Werkzeuge sehr scharf. Erst sehr viel später fand man heraus, wie man Metalle, wie Kupfer oder Eisen, aus anderen Steinen gewinnt. Man holte die Steine in mühsamer Arbeit aus den Bergen, erhitzte sie stark und konnte damit das Metall herausschmelzen. Aus dem Metall fertigte man auch Messer an. Alte Messer aus Metall haben oft einen Griff aus Holz. Sie wurden vom Schmied im Dorf mit der Hand gemacht. Heute haben die Messer oft Plastikgriffe und werden in großen Fabriken hergestellt.', true, undefined, false, false, 'bb_werkzeuge_messer.mp3'));
    tour.addPart(new TourPart('', 'Welche Frage wird in der Geschichte beantwortet?<br><br>Wenn du möchtest, kannst du dir hier die Erzählung gern noch einmal anhören.', '', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Was haben Menschen in der Steinzeit gegessen?', false),
        new ChallengeItemMultiSelectText('Wie veränderten sich Messer im Laufe der Zeit?', true),
        new ChallengeItemMultiSelectText('Wird es Messer auch in Zukunft geben?', false)
    ], [], true), false, true, 'bb_werkzeuge_messer.mp3'));
    return markRaw(tour);
}
