import { BaseChallenge, BaseChallengeItem, BaseChallengeIterable } from "@/data/challenges/challenge";
import PickDropZone from "@/components/challenges/PickDropZone.vue";
import MediaImage from "@/data/mediaImage";
import userHandler from "@/data/userHandler";
export class ChallengePickDropZone extends BaseChallenge {
    dropZones = {};
    draggableItems;
    constructor(imageItem, textItems = []) {
        super('pickDropZone', PickDropZone);
        this.items = textItems;
        // prepare items used as draggables
        const draggableItems = [imageItem];
        this.draggableItems = new BaseChallengeIterable(draggableItems);
        this.draggableItems.postInit();
    }
    init() {
        this.state = '';
        this.dropZones = {};
        this.shuffle();
        this.postInit();
    }
    registerDropZone(dropZoneKey, bbox) {
        let centerX = 0;
        if (bbox?.x > 0 && bbox?.width > 0) {
            centerX = Math.floor(bbox.x + (bbox.width / 2));
            if (!this.dropZones[centerX]) {
                const item = (this.getItemByKey(dropZoneKey) || {});
                this.dropZones[centerX] = {
                    key: dropZoneKey,
                    index: -1,
                    itemKey: -1,
                    itemIsCorrect: item.isCorrect,
                    itemKeys: {},
                    valid: true,
                    bbox
                };
            }
        }
        return centerX;
    }
    sortDropZones() {
        const listOfX = [];
        for (const cx in this.dropZones) {
            listOfX.push(Number(cx));
        }
        listOfX.sort((a, b) => a < b ? -1 : (a === b ? 0 : 1));
        for (const i in listOfX) {
            const x = Number(listOfX[i]);
            this.dropZones[x].index = Number(i);
        }
    }
    selectItemDropZone(itemKey, cursorX, cursorY) {
        let chosen = false;
        for (const centerX in this.dropZones) {
            const dropZone = this.dropZones[centerX];
            if (dropZone.bbox.x <= cursorX && cursorX <= (dropZone.bbox.x + dropZone.bbox.width)
                &&
                    dropZone.bbox.y <= cursorY && cursorY <= (dropZone.bbox.y + dropZone.bbox.height)) {
                dropZone.itemKeys[itemKey] = true;
                if (dropZone.itemKey < 0) {
                    dropZone.itemKey = itemKey;
                }
            }
            else {
                delete dropZone.itemKeys[itemKey];
                if (dropZone.itemKey === itemKey) {
                    const itemKeyIndexes = Object.keys(dropZone.itemKeys);
                    if (itemKeyIndexes.length > 0) {
                        dropZone.itemKey = Number(itemKeyIndexes[0]);
                    }
                    else {
                        dropZone.itemKey = -1;
                    }
                }
            }
            dropZone.valid = Object.keys(dropZone.itemKeys).length < 2;
            if (dropZone.itemKey > 0 && dropZone.valid) {
                chosen = true;
            }
        }
        if (this.state !== 'solved') {
            if (chosen) {
                this.state = 'chosen';
            }
            else {
                this.state = '';
            }
        }
    }
    getDropZoneStates() {
        const dropZoneStates = {};
        for (const centerX in this.dropZones) {
            const dropZone = this.dropZones[centerX];
            if (dropZone.key > -1) {
                dropZoneStates[dropZone.key] = {
                    itemKey: dropZone.itemKey,
                    itemIsCorrect: dropZone.itemIsCorrect,
                    valid: dropZone.valid
                };
            }
        }
        return dropZoneStates;
    }
    getNumberOfDropZones() {
        return Object.keys(this.dropZones).length;
    }
    evaluate() {
        if (this.state !== 'solved') {
            this.state = 'solved';
            this.score = 0;
            for (const dz in this.dropZones || {}) {
                const dropZone = this.dropZones[dz];
                if (dropZone.itemKey > -1 && dropZone.itemIsCorrect) {
                    this.score = 1;
                    break;
                }
            }
            userHandler.getUser().setScore(this.getTourCollectionKey(), this.getTourKey(), this.getTourPartIndex(), this.score);
        }
    }
}
export class ChallengeItemPickDropZoneImage extends BaseChallengeItem {
    media;
    constructor(imageName, imageDescription = '') {
        super('challengeImage');
        this.media = new MediaImage(imageName, undefined, imageDescription);
    }
}
export class ChallengeItemPickDropZoneText extends BaseChallengeItem {
    text = '';
    isCorrect = false;
    constructor(text, isCorrect) {
        super('challengeText');
        this.text = text;
        this.isCorrect = isCorrect;
    }
}
