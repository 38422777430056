<template>
  <div :class="classes" @click="click">
    <slot />
    <div class="desktopDNDBlocker"/>
  </div>
</template>

<script>
import { createGesture } from '@ionic/core';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DraggableGesture',
  props: {
    draggable: {
      type: Boolean,
      default: false
    },
    offsetX: {
      type: Number,
      default: 0
    },
    offsetY: {
      type: Number,
      default: 0
    },
    rotation: {
      type: Number,
      default: 0
    },
    itemKey: {
      type: Number,
      default: -1
    },
    preventInternalMovement: {
      type: Boolean,
      default: false
    },
  },
  emits: {
    draggableOnStart: true,
    draggableOnMove: true,
    draggableOnEnd: true,
    draggableOnClick: true,
  },
  unmounted(){
    this.dragGesture = null
  },
  data() {
    return {
      dragGesture: undefined,
      x: 0,
      y: 0,
      bbox: {
        x: 0,
        y: 0
      }
    }
  },
  mounted(){
    this.$el.style.transform = `translate(calc(var(--outer-offset-x) + ${this.offsetX}px), calc(var(--outer-offset-y) + ${this.offsetY}px)) rotate(calc(var(--outer-offset-rotation) + ${this.rotation}deg))`

    if(this.draggable){
      this.initDraggable()
    }
  },
  updated(){
    if(this.draggable && !this.dragGesture){
      this.initDraggable()
    }
  },
  computed: {
    classes(){
      const classes = ['dnd']

      if(this.draggable){
        classes.push('isDraggable')
      }

      return classes.join(' ')
    }
  },
  methods: {
    initDraggable(){
      const element = this.$el

      this.dragGesture = createGesture({
        el: element,
        direction: undefined,
        maxAngle: undefined,
        threshold: 0,
        disableScroll: false,
        gestureName: 'defaultDraggableGesture',
        passive: false,
        canStart: () => true,
        onStart: (detail) => {
          if(!this.draggable) return

          if(this.bbox.x === 0 && this.bbox.y === 0){
            this.bbox = element.getBoundingClientRect();
          }

          this.$emit('draggableOnStart', this.itemKey, detail.currentX, detail.currentY, this.bbox, element);
        },
        onMove: (detail) => {
          if(!this.draggable) return

          const x = this.offsetX + this.x + detail.deltaX
          const y = this.offsetY + this.y + detail.deltaY
          if(!this.preventInternalMovement){
            element.style.transform = `translate(calc(var(--outer-offset-x) + ${x}px), calc(var(--outer-offset-y) + ${y}px)) rotate(calc(var(--outer-offset-rotation) + ${this.rotation}deg)) scale(var(--outer-offset-scale))`
          }

          const bbox = element.getBoundingClientRect()
          this.$emit('draggableOnMove', this.itemKey, detail.currentX, detail.currentY, bbox, element);

          element.style.zIndex = 450
        },
        onEnd: (detail) => {
          if(!this.draggable) return

          element.style.zIndex = 'calc(var(--z-index-base) + var(--z-index-delta) + 1)';
          this.x += detail.deltaX;
          this.y += detail.deltaY;

          this.$emit('draggableOnEnd', this.itemKey, detail.currentX, detail.currentY, element.getBoundingClientRect(), element);

          const movement = Math.abs(detail.deltaX) + Math.abs(detail.deltaY)
          if(movement <= 5){
            this.$emit('draggableOnClick', this.itemKey);
          }
        },
      })

      this.dragGesture.enable(true);
    },
    click(){
      // if this element is not draggable clicks have to be triggered in a more traditional manner
      if(!this.draggable){
        this.$emit('draggableOnClick', this.itemKey);
      }
    }
  }
});
</script>

<style lang="less" scoped>
  .dnd {
    position: relative;
    z-index: 400;
    --outer-offset-x: 0px;
    --outer-offset-y: 0px;
    --outer-offset-rotation: 0deg;
    --outer-offset-scale: 1;
  }
  .dnd.isDraggable:hover{
    cursor: move;
  }

  .dnd > .desktopDNDBlocker {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: none;
    z-index: 400;
  }
</style>
<style lang="less">
  .plt-desktop .dnd > .desktopDNDBlocker {
    display: block;
  }
</style>