import { reactive } from 'vue';
import Emitter from '@/lib/emitter';
import { md5 } from "@/lib/utils";
import lastDeploy from "@/data/lastDeploy";
import { PLATFORM } from "@/lib/constants";
export class PageHandler {
    i = 0;
    pageID = '';
    componentIndexes = {};
    collapsable = true;
    collapsableAtTop = false;
    lastDeployAt = lastDeploy;
    emitter = new Emitter();
    eventStates = {};
    screen = { width: 0, height: 0 };
    nrOfPreparedMedia = {
        load: 0,
        isLoaded: 0,
        downloaded: 0,
        debug: {}
    };
    constructor() {
        this.update('~~~INIT~~~', true, false);
    }
    update(path = undefined, collapsable = undefined, collapsableAtTop = undefined) {
        if (path !== undefined) {
            this.pageID = md5(String(path || ''));
        }
        this.i = (this.i + 1) % 1000;
        if (collapsable !== undefined)
            this.collapsable = collapsable;
        if (collapsableAtTop !== undefined)
            this.collapsableAtTop = collapsableAtTop;
    }
    getScreen() {
        return this.screen || {
            width: 0,
            height: 0
        };
    }
    setScreen(screen) {
        this.screen = {
            width: screen?.width || 0,
            height: screen?.height || 0,
        };
    }
    emit(eventType) {
        if (eventType === 'onScreenInit') {
            this.eventStates.onScreenInit = 'running';
        }
        else if (eventType === 'onScreenUpdate') {
            this.eventStates.onScreenUpdate = 'running';
        }
        else if (eventType === 'beforePageEnter') {
            this.eventStates.beforePageEnter = 'running';
        }
        else if (eventType === 'pageEnter') {
            if (this.eventStates.beforePageEnter === 'running') {
                this.eventStates.pageEnter = 'delayed';
            }
            else {
                this.eventStates.pageEnter = 'running';
            }
        }
        if (this.eventStates[eventType] === 'running') {
            this.emitter.emit(`${eventType}-${this.pageID}`);
            delete this.eventStates[eventType];
            if (eventType === 'beforePageEnter' && this.eventStates.pageEnter === 'delayed') {
                this.emit('pageEnter');
            }
            if (eventType === 'onScreenInit' || eventType === 'onScreenUpdate') {
                this.updateScreen();
            }
        }
    }
    updateScreen() {
        const screen = this.getScreen();
        if (screen.height > 0) {
            this.setScreen(screen);
            const html = document.querySelector('html');
            if (html && PLATFORM === 'ios') {
                html.classList.add('isIOSPlatform');
            }
            const bodyStyle = document.querySelector('body')?.style;
            if (bodyStyle) {
                bodyStyle.setProperty('--real-height-vh', `${(screen.height) / 100}px`);
                bodyStyle.setProperty('--real-height-vh-rnd', `${Math.round((screen.height) / 100)}px`);
                if (screen.height * .75 > screen.width) {
                    bodyStyle.setProperty('--real-base-min', `${(screen.width) / 100}px`);
                    bodyStyle.setProperty('--real-base-min-rnd', `${Math.round((screen.width) / 100)}px`);
                    bodyStyle.setProperty('--real-base-max', `${(screen.height) / 100 * .75}px`);
                    bodyStyle.setProperty('--real-base-max-rnd', `${Math.round((screen.height) / 100 * .75)}px`);
                }
                else {
                    bodyStyle.setProperty('--real-base-max', `${(screen.width) / 100}px`);
                    bodyStyle.setProperty('--real-base-max-rnd', `${Math.round((screen.width) / 100)}px`);
                    bodyStyle.setProperty('--real-base-min', `${(screen.height) / 100 * .75}px`);
                    bodyStyle.setProperty('--real-base-min-rnd', `${Math.round((screen.height) / 100 * .75)}px`);
                }
                if (screen.height * .5 > screen.width) {
                    bodyStyle.setProperty('--real-high-min', `${(screen.width) / 100}px`);
                    bodyStyle.setProperty('--real-high-min-rnd', `${Math.round((screen.width) / 100)}px`);
                    bodyStyle.setProperty('--real-high-max', `${(screen.height) / 100 * .5}px`);
                    bodyStyle.setProperty('--real-high-max-rnd', `${Math.round((screen.height) / 100 * .5)}px`);
                }
                else {
                    bodyStyle.setProperty('--real-high-max', `${(screen.width) / 100}px`);
                    bodyStyle.setProperty('--real-high-max-rnd', `${Math.round((screen.width) / 100)}px`);
                    bodyStyle.setProperty('--real-high-min', `${(screen.height) / 100 * .5}px`);
                    bodyStyle.setProperty('--real-high-min-rnd', `${Math.round((screen.height) / 100 * .5)}px`);
                }
            }
        }
    }
    bottomReadyToMeasure() {
        return !this.collapsable || this.collapsableAtTop;
    }
    getPageID() {
        return this.pageID;
    }
    getPageIndex() {
        return this.i;
    }
    getComponentIndex(pageID) {
        if (!this.componentIndexes[pageID])
            this.componentIndexes[pageID] = 0;
        return ++this.componentIndexes[pageID];
    }
    getNumberOfPreparedMedia() {
        return this.nrOfPreparedMedia;
    }
    resetNumberOfPreparedMedia(resetIsLoadedCounter = false) {
        this.nrOfPreparedMedia.load = 0;
        this.nrOfPreparedMedia.downloaded = 0;
        if (resetIsLoadedCounter) {
            this.nrOfPreparedMedia.isLoaded = 0;
        }
    }
    updateNumberOfPreparedMedia(load = 0, downloaded = 0, isLoaded = 0, filePath = '') {
        this.nrOfPreparedMedia.load += Number(load);
        this.nrOfPreparedMedia.downloaded += Number(downloaded);
        this.nrOfPreparedMedia.isLoaded += Number(isLoaded);
        if (filePath) {
            this.nrOfPreparedMedia.debug[filePath] = this.nrOfPreparedMedia.debug[filePath] || 0;
            this.nrOfPreparedMedia.debug[filePath]++;
        }
    }
}
const pageHandler = reactive(new PageHandler());
export default pageHandler;
