import { defineComponent } from "vue";
import LayoutsMain from "@/components/layouts/Main.vue";
import Description from "@/components/core/Description.vue";
import router from "@/router";
import Button from "@/components/core/Button.vue";
import pageHandler from "@/data/pageHandler";
import pageHookMixin from "@/data/pageHookMixin";
import MediaImage from "@/data/mediaImage";
import { PLATFORM } from "@/lib/constants";
export default defineComponent({
    name: 'ViewPlatforms',
    mixins: [pageHookMixin],
    components: { LayoutsMain, Description, Button },
    setup() {
        const links = {
            android: {
                platform: 'android',
                url: 'https://play.google.com/store/apps/details?id=at.codeup.samu',
                file: 'logo_google.svg',
            },
            ios: {
                platform: 'ios',
                url: 'https://apps.apple.com/app/salzburger-museums-app/id1606757331',
                file: 'logo_ios.svg',
            },
            web: {
                platform: 'web',
                url: 'https://museumsapp.at/main/intro',
                text: 'Weiter im Web',
            },
        };
        return {
            links
        };
    },
    data() {
        this.prepare();
        return {
            logos: {}
        };
    },
    methods: {
        async prepare() {
            for (const l in this.links || {}) {
                const link = this.links[l];
                if (link.file) {
                    const logo = new MediaImage(link.file, undefined, undefined, undefined, true);
                    this.logos[link.platform] = await logo.getImage();
                }
            }
        },
        routeTo(platform = 'web', event) {
            if (PLATFORM === platform) {
                event.preventDefault();
                event.stopImmediatePropagation();
                router.push({
                    path: '/main/intro',
                    replace: false
                });
            }
        }
    },
    ionViewWillEnter() {
        pageHandler.emit('beforePageEnter');
    },
    ionViewDidEnter() {
        pageHandler.emit('pageEnter');
    },
});
