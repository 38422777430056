import { defineComponent } from "vue";
import { ChallengeOrderTextCircle } from "@/data/challenges/challengeOrderTextCircle";
import Text from '@/components/core/Text.vue';
import DropZone from "@/components/core/DropZone.vue";
import Button from '@/components/core/Button.vue';
import SymbolSolution from "@/components/symbols/solution.vue";
import router from '@/router';
import modalHandler from "@/data/modalHandler";
import pageHookMixin from "@/data/pageHookMixin";
import { onBBoxReady } from "@/lib/utils";
export default defineComponent({
    name: 'ChallengeOrderTextCircle',
    mixins: [pageHookMixin],
    components: {
        SymbolSolution,
        DropZone,
        Text,
        Button
    },
    props: {
        challenge: {
            type: ChallengeOrderTextCircle
        },
        forwardUrl: {
            type: String
        },
        showMotivationModal: {
            type: Boolean,
            default: false
        },
        bottomReadyToMeasure: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            initialized: false,
            dropZoneStates: this.challenge?.getDropZoneStates() || {},
        };
    },
    mounted() {
        if (this.challenge && this.bottomReadyToMeasure) {
            this.startInit();
        }
    },
    updated() {
        if (this.bottomReadyToMeasure) {
            this.startInit();
        }
    },
    computed: {
        solved() {
            if (this.challenge) {
                return this.challenge.state === 'solved';
            }
            return false;
        },
        buttonColor() {
            if (this.challenge) {
                if (this.solved) {
                    return 'green';
                }
                if (this.challenge.state === 'chosen') {
                    return 'orange';
                }
            }
            return 'gray';
        },
    },
    methods: {
        startInit() {
            if (!this.initialized) {
                this.initialized = true;
                onBBoxReady(this.$el.querySelector('.target .dropZones'), 75, () => this.init());
            }
        },
        init() {
            this.updateDropZones();
        },
        onScreenUpdate() {
            router.go(0);
        },
        updateDropZones() {
            if (this.challenge && this.challenge.getNumberOfDropZones() < 1) {
                const dropZones = this.$el.querySelectorAll('.dropZone');
                for (const dz in dropZones) {
                    if (!isNaN(Number(dz))) {
                        const dropZone = dropZones[dz];
                        const dropZoneKey = Number(dropZone.dataset.dropZoneKey);
                        if (!isNaN(dropZoneKey) && dropZoneKey > -1) {
                            const bbox = dropZone.getBoundingClientRect();
                            this.challenge?.registerDropZone(dropZoneKey, bbox);
                        }
                    }
                }
                // eslint-disable-next-line vue/no-mutating-props
                this.challenge?.sortDropZones();
                this.dropZoneStates = this.challenge?.getDropZoneStates() || {};
                // autoPlace items if necessary
                for (const dz in this.dropZoneStates) {
                    const dropZoneState = this.dropZoneStates[dz];
                    if (dropZoneState.autoPlaced) {
                        this.moveDNDsToDropZones(dropZoneState.itemKey, dropZoneState.index);
                    }
                }
            }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        draggableOnMove(itemKey, cursorX, cursorY, bbox = null, element = null) {
            this.updateDropZones();
            const matchingDropZone = this.challenge?.selectItemDropZone(itemKey, cursorX, cursorY);
            this.dropZoneStates = this.challenge?.getDropZoneStates() || {};
            if (element) {
                if (matchingDropZone?.index > -1) {
                    element.style.setProperty('--quarter-circle-rotation', `${matchingDropZone.index * 90}deg`);
                }
                else {
                    element.style.setProperty('--quarter-circle-rotation', null);
                }
            }
        },
        moveDNDsToDropZones(autoPlaceItemKey = undefined, autoPlaceToDropZoneIndex = undefined) {
            const isAutoPlaceSingleItem = (autoPlaceItemKey !== undefined && autoPlaceToDropZoneIndex !== undefined);
            for (const dzs in this.dropZoneStates || {}) {
                const dropZoneState = this.dropZoneStates[dzs] || {};
                if ((!isAutoPlaceSingleItem
                    &&
                        dropZoneState.itemKey !== undefined
                    &&
                        dropZoneState.bbox
                    &&
                        !dropZoneState.autoPlaced)
                    ||
                        (isAutoPlaceSingleItem
                            &&
                                dropZoneState.bbox
                            &&
                                dropZoneState.index === autoPlaceToDropZoneIndex
                            &&
                                dropZoneState.autoPlaced)) {
                    let textContainerElement;
                    if (isAutoPlaceSingleItem) {
                        textContainerElement = this.$el.querySelector(`.textContainer[data-item-key="${autoPlaceItemKey}"]`);
                    }
                    else {
                        textContainerElement = this.$el.querySelector(`.textContainer[data-item-key="${dropZoneState.itemKey}"]`);
                    }
                    if (textContainerElement) {
                        const dndElement = textContainerElement.querySelector(`.dnd`) || null;
                        const quarterElement = dndElement?.querySelector(`.backgroundQuarter`) || null;
                        if (dndElement && quarterElement) {
                            // get element ready for measurement (disable animation and rotate into target position)
                            if (isAutoPlaceSingleItem) {
                                quarterElement.style.setProperty('--quarter-circle-transition', `none`);
                                quarterElement.style.setProperty('--quarter-circle-rotation', `${autoPlaceToDropZoneIndex * 90}deg`);
                            }
                            // calculate position delta
                            const bbox = quarterElement.getBoundingClientRect();
                            const delta = {
                                x: (dropZoneState.bbox.x - bbox.x + (dropZoneState.index < 2 ? 2 : 0)),
                                y: (dropZoneState.bbox.y - bbox.y + (dropZoneState.index > 0 && dropZoneState.index < 3 ? 2 : 0))
                            };
                            // reset rotation value
                            if (isAutoPlaceSingleItem) {
                                quarterElement.style.setProperty('--quarter-circle-rotation', null);
                            }
                            // reset rotation value, turn on animation and move to target position
                            requestAnimationFrame(() => {
                                requestAnimationFrame(() => {
                                    if (isAutoPlaceSingleItem) {
                                        quarterElement.style.setProperty('--quarter-circle-transition', null);
                                        dndElement.style.setProperty('--quarter-circle-rotation', `${autoPlaceToDropZoneIndex * 90}deg`);
                                    }
                                    textContainerElement.style.transform = `translate(${delta.x}px, ${delta.y}px)`;
                                });
                            });
                        }
                    }
                    if (isAutoPlaceSingleItem) {
                        break;
                    }
                }
            }
        },
        onForwardButtonClick() {
            if (this.challenge) {
                if (this.solved) {
                    if (this.forwardUrl) {
                        if (this.showMotivationModal) {
                            modalHandler.show('motivation', {
                                forwardUrl: this.forwardUrl
                            });
                        }
                        else {
                            router.replace(this.forwardUrl);
                        }
                    }
                }
                if (this.challenge.state === 'chosen') {
                    this.challenge.evaluate();
                    this.moveDNDsToDropZones();
                }
            }
        }
    }
});
