import { BaseChallenge, BaseChallengeItem } from "@/data/challenges/challenge";
import PickTextBySwipe from "@/components/challenges/PickTextBySwipe.vue";
import userHandler from "@/data/userHandler";
import MediaImage from "@/data/mediaImage";
export class ChallengePickTextBySwipe extends BaseChallenge {
    leftText = '';
    rightText = '';
    swipeContainerStyle = '';
    position = 0;
    constructor(item, leftText = '', rightText = '', swipeContainerStyle = '') {
        super('pickTextBySwipe', PickTextBySwipe);
        this.items = [item];
        this.leftText = leftText;
        this.rightText = rightText;
        this.swipeContainerStyle = swipeContainerStyle;
        this.animateViewTopOnInit = true;
    }
    init() {
        this.state = '';
        this.position = 0;
        this.postInit();
    }
    toggleSelection(position = 0) {
        this.position = position;
    }
    isCorrect() {
        if (this.state === 'solved') {
            const item = this.items[0];
            return ((item.leftCorrect && this.position === -1)
                ||
                    (!item.leftCorrect && this.position === 1));
        }
        return false;
    }
    chosen() {
        return this.position !== 0;
    }
    evaluate() {
        if (this.state !== 'solved') {
            this.state = 'solved';
            this.score = 0;
            if (this.items.length > 0 && this.isCorrect()) {
                this.score = 1;
            }
            userHandler.getUser().setScore(this.getTourCollectionKey(), this.getTourKey(), this.getTourPartIndex(), this.score);
        }
    }
}
export class ChallengeItemPickTextBySwipe extends BaseChallengeItem {
    text;
    leftCorrect = false;
    images = [];
    constructor(text, leftCorrect = false, images = []) {
        super('challenge');
        this.text = text;
        this.leftCorrect = leftCorrect;
        if (images.length > 0) {
            for (const i in images) {
                const image = images[i];
                if (image?.name) {
                    this.images.push(new MediaImage(image?.name, image?.highlightBBox, image?.description || '', { ...(image?.meta || {}), ...{ index: i } }));
                }
            }
        }
    }
}
