import { Md5 } from 'ts-md5/dist/md5';
export function vw(width = 0, calculateValueByScreen = null) {
    if (width === 0) {
        return '0';
    }
    if (calculateValueByScreen?.height > 0) {
        if (width < 0) {
            return Math.max(width * calculateValueByScreen.width / 100, width * .75 * calculateValueByScreen.height / 100);
        }
        return Math.min(width * calculateValueByScreen.width / 100, width * .75 * calculateValueByScreen.height / 100);
    }
    if (width < 0) {
        return `calc(${width} * var(--real-base-min))`;
    }
    return `calc(${width} * var(--real-base-min))`;
}
export function vh(height = 0, calculateValueByScreen = null) {
    if (height === 0) {
        return '0';
    }
    if (calculateValueByScreen?.height > 0) {
        return height * calculateValueByScreen.height / 100;
    }
    return `calc(var(--real-height-vh, 10px) * ${height})`;
}
export function md5(value = '') {
    return Md5.hashStr(String(value || ''));
}
export function callDelayed(callback, delay = 0) {
    if (delay) {
        setTimeout(() => callback(), delay);
    }
    else {
        callback();
    }
}
export function round(value, nrOfDecimals = 2) {
    const pot = Math.pow(10, nrOfDecimals);
    return Math.round(value * pot) / pot;
}
export function onBBoxReady(element, delay = 50, callback, lastBBox = null, secCounter = 0) {
    delay = Math.max(5, delay);
    if (secCounter > 100)
        return;
    setTimeout(() => {
        const bbox = element?.getBoundingClientRect?.() || {};
        if (lastBBox !== null && bbox.x === lastBBox.x && bbox.width > 0 && bbox.width === lastBBox.width && bbox.y === lastBBox.y && bbox.height > 0 && bbox.height === lastBBox.height) {
            callback(bbox);
        }
        else {
            onBBoxReady(element, delay, callback, bbox, secCounter + 1);
        }
    }, delay);
}
// export function onScreenReady(delay = 50, callback: CallableFunction, lastScreen: any = {}, secCounter = 0){
//     delay = Math.max(5, delay)
//
//     if(secCounter > 50) return
//
//     setTimeout(() => {
//         const screen = {
//             width: window.innerWidth,
//             height: window.innerHeight,
//         }
//         if(screen.width > 0 && screen.width === lastScreen.width && screen.height > 0 && screen.height === lastScreen.height){
//             callback(screen)
//         } else {
//             onScreenReady(delay, callback, screen, secCounter + 1)
//         }
//     }, delay)
// }
export default {
    vw,
    md5,
};
