import pageHookMixin from "@/data/pageHookMixin";
import { defineComponent } from "vue";
import audioHandler from "@/data/audioHandler";
import { callDelayed } from "@/lib/utils";
export default defineComponent({
    name: 'SymbolBone',
    mixins: [pageHookMixin],
    props: {
        showShadow: {
            type: Boolean,
            default: true
        },
        showStroke: {
            type: Boolean,
            default: true
        },
        isPlaceholder: {
            type: Boolean,
            default: false
        },
        golden: {
            type: Boolean,
            default: false
        },
        animationType: {
            type: String,
            default: ''
        },
        animationSound: {
            type: Boolean,
            default: true
        },
        animationDelayIndex: {
            type: Number,
            default: 0
        },
        backgroundColor: {
            type: String,
            default: '#DFDFDF'
        },
        shadowColor: {
            type: String,
            default: '#848484'
        },
        strokeColor: {
            type: String,
            default: '#000000'
        }
    },
    computed: {
        cssClasses() {
            const classes = [];
            if (this.isPlaceholder) {
                classes.push('placeholder');
            }
            if (this.animationType) {
                classes.push('animation');
                classes.push(this.animationType);
            }
            return classes.join(' ');
        },
        internalBackgroundColor() {
            if (this.golden) {
                return '#ffdc47';
            }
            return this.backgroundColor;
        },
        internalShadowColor() {
            if (this.golden) {
                return '#ccb44d';
            }
            return this.shadowColor;
        }
    },
    methods: {
        pageEnter() {
            if (this.animationType) {
                callDelayed(() => {
                    if (this.animationSound) {
                        audioHandler.play('snapIn');
                    }
                    this.$el.classList.add('animate');
                }, this.animationDelayIndex * 200);
            }
        }
    },
});
