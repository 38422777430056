import { defineComponent } from "vue";
import { tourHandler } from "@/data/tourHandler";
import { imprintEntries } from "@/data/imprintHandler";
import LayoutsMain from "@/components/layouts/Main.vue";
import Headline from "@/components/core/Headline.vue";
import Description from "@/components/core/Description.vue";
import pageHandler from "@/data/pageHandler";
import pageHookMixin from "@/data/pageHookMixin";
import MediaImage from "@/data/mediaImage";
export default defineComponent({
    name: 'ViewImprint',
    mixins: [pageHookMixin],
    components: { LayoutsMain, Headline, Description },
    setup() {
        return {
            tourCollections: tourHandler.getTourCollections(),
            imprintEntries,
        };
    },
    data() {
        this.prepare();
        return {
            entries: {}
        };
    },
    methods: {
        async prepare() {
            for (const tourCollection of this.tourCollections || []) {
                this.entries[tourCollection.key] = {
                    name: tourCollection.name,
                    logo: await tourCollection.logo.getData(),
                };
            }
            for (const entry of this.imprintEntries || []) {
                this.entries[entry.key] = {
                    name: entry.name,
                    logo: await new MediaImage(entry.logo, undefined, undefined, undefined, true).getImage(),
                };
            }
        },
        navTo(event) {
            let target = event.target;
            if (target) {
                while (!target.classList.contains('entry') && !!target.parentElement) {
                    target = target.parentElement;
                }
                for (const web of target.getElementsByClassName('web')) {
                    if (web) {
                        web.click();
                        break;
                    }
                }
            }
        }
    },
    ionViewWillEnter() {
        pageHandler.emit('beforePageEnter');
    },
    ionViewDidEnter() {
        pageHandler.emit('pageEnter');
    },
});
