import { IonApp, IonRouterOutlet, IonContent, IonHeader, IonItem, IonList, IonMenu, IonMenuButton, IonTitle, IonToolbar, IonItemDivider, menuController } from '@ionic/vue';
import { defineComponent } from 'vue';
import SymbolMascotSideways from "@/components/symbols/mascot/sideways.vue";
import userHandler from "@/data/userHandler";
import modalHandler from "@/data/modalHandler";
import AudioHandlerContainer from "@/components/core/AudioHandler.vue";
import router, { isInTour } from "@/router";
import { MAIN_VERSION, PLATFORM } from "@/lib/constants";
import resizeHandler from "@/data/resizeHandler";
export default defineComponent({
    name: 'App',
    components: {
        AudioHandlerContainer,
        SymbolMascotSideways,
        IonApp,
        IonRouterOutlet,
        IonContent,
        IonHeader,
        IonItem,
        IonList,
        IonMenu,
        IonMenuButton,
        IonTitle,
        IonToolbar,
        IonItemDivider,
    },
    mounted() {
        this.openCustom();
        resizeHandler.init();
    },
    setup() {
        return {
            modalHandler,
        };
    },
    computed: {
        userIndex() {
            return userHandler.getUser().getIndex();
        },
        playSoundsByWebAudio() {
            return PLATFORM === 'web';
        },
        mainVersion: function () {
            return MAIN_VERSION;
        }
    },
    methods: {
        openFirst() {
            menuController.enable(true, 'first');
            menuController.open('first');
        },
        openEnd() {
            menuController.open('end');
        },
        openCustom() {
            menuController.enable(true, 'custom');
            menuController.open('custom');
        },
        removeUser() {
            menuController.close();
            if (userHandler.getUser().getIndex() > -1) {
                modalHandler.show('removeUser');
            }
        },
        showMissingDev() {
            menuController.close();
            modalHandler.show('missingDev');
        },
        routeTo(route, grantedWithoutUser = false) {
            menuController.close();
            if (grantedWithoutUser || this.userIndex >= 0) {
                router.push({
                    path: route,
                    replace: isInTour()
                });
            }
            else {
                router.push({
                    path: '/main/login',
                    replace: isInTour()
                });
            }
        },
    }
});
