import { defineComponent } from "vue";
import { ChallengeItemPlaceOnAxis } from "@/data/challenges/challengePlaceOnAxis";
export default defineComponent({
    name: 'SymbolPuzzlePiece',
    props: {
        parts: {
            type: Object,
            required: true
        },
        useAsClipPathId: {
            type: String,
            default: ''
        },
        innerBackgroundColor: {
            type: String,
            default: 'transparent'
        },
        outerBackgroundColor: {
            type: String,
            default: 'transparent'
        },
        strokeColor: {
            type: String,
            default: '#FFFFFF'
        },
        strokeWidth: {
            type: String,
            default: '2px'
        }
    },
    computed: {
        path() {
            return ChallengeItemPlaceOnAxis.getPuzzlePath(String(this.parts?.left || ''), String(this.parts?.right || ''), false);
        },
        clipPath() {
            return ChallengeItemPlaceOnAxis.getPuzzlePath(String(this.parts?.left || ''), String(this.parts?.right || ''), true);
        }
    }
});
