import LayoutsMain from "@/components/layouts/Main.vue";
import LoginForm from "@/components/core/login/Form.vue";
import LoginList from "@/components/core/login/List.vue";
import Headline from "@/components/core/Headline.vue";
import Description from "@/components/core/Description.vue";
import { defineComponent } from "vue";
import pageHandler from "@/data/pageHandler";
export default defineComponent({
    name: 'ViewLogin',
    components: { LayoutsMain, Headline, Description, LoginForm, LoginList },
    ionViewWillEnter() {
        pageHandler.emit('beforePageEnter');
    },
    ionViewDidEnter() {
        pageHandler.emit('pageEnter');
    },
});
