import ModalSpeechBubble from "@/components/modals/SpeechBubble.vue";
import Headline from '@/components/core/Headline.vue';
import Description from '@/components/core/Description.vue';
import Button from '@/components/core/Button.vue';
import modalHandler from '@/data/modalHandler';
import { defineComponent } from "vue";
import router from '@/router';
export default defineComponent({
    name: 'ModalMotivation',
    components: {
        ModalSpeechBubble,
        Headline,
        Description,
        Button
    },
    methods: {
        close() {
            const meta = modalHandler.getMeta();
            modalHandler.hide();
            if (meta.forwardUrl) {
                router.replace(meta.forwardUrl);
            }
        }
    }
});
