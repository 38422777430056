import pageHandler from "@/data/pageHandler";
const resizeObserver = new ResizeObserver(entries => {
    handleResize(entries?.[0]?.target);
});
function handleResize(target) {
    if (target) {
        const to = parseInt(target.dataset?.sto || '0');
        if (to && !isNaN(to)) {
            clearTimeout(to);
        }
        target.dataset.sto = String(setTimeout(() => {
            target.dataset.sto = '';
            const lastScreen = pageHandler.getScreen();
            const newScreen = {
                width: window.innerWidth,
                height: window.innerHeight,
            };
            if (lastScreen.width !== newScreen.width || lastScreen.height !== newScreen.height) {
                pageHandler.setScreen(newScreen);
                if (!lastScreen.width && !lastScreen.height) {
                    pageHandler.emit('onScreenInit');
                }
                else {
                    pageHandler.emit('onScreenUpdate');
                }
            }
        }, 50));
    }
}
export function init() {
    const element = document.querySelector('body');
    resizeObserver.observe(element);
    handleResize(element);
}
export default {
    init
};
