import { defineComponent } from "vue";
import pageHookMixin from "@/data/pageHookMixin";
import { onBBoxReady } from "@/lib/utils";
export default defineComponent({
    name: 'TopPartHeadline',
    mixins: [pageHookMixin],
    methods: {
        pageEnter() {
            onBBoxReady(this.$el, 75, () => {
                this.$el.classList.add('animate');
            });
        }
    }
});
