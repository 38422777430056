import Media from "@/data/media";
export const defaultBBox = {
    x: 0,
    y: 0,
    w: 0,
    h: 0
};
export default class MediaImage {
    name;
    thumbName;
    image;
    thumb;
    highlightBBox;
    description;
    meta = {};
    constructor(name, highlightBBox = defaultBBox, description = '', meta = {}, preventThumb = false) {
        this.name = name;
        this.highlightBBox = highlightBBox;
        this.meta = meta;
        const nameParts = String(name || '').split('.');
        let extension = nameParts.pop();
        if (!extension || extension.length > 5) {
            extension = '';
        }
        else {
            this.name = nameParts.join('.');
        }
        extension = extension || 'jpg';
        this.thumbName = `${this.name}.thumb.${extension}`;
        this.name = `${this.name}.${extension}`;
        this.image = new Media('image', this.name);
        if (!preventThumb) {
            this.thumb = new Media('image', this.thumbName);
        }
        this.description = description;
    }
    async getImage() {
        return await this.image.getData();
    }
    async getThumb() {
        return await this.thumb?.getData();
    }
    getHighlightBBox() {
        if (this.highlightBBox?.w !== undefined && this.highlightBBox.w > 0) {
            return this.highlightBBox;
        }
        return defaultBBox;
    }
}
