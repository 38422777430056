import { createRouter, createWebHistory } from '@ionic/vue-router';
import ViewPlatforms from '@/views/main/ViewPlatforms.vue';
import ViewIntro from '@/views/main/ViewIntro.vue';
import ViewLogin from '@/views/main/ViewLogin.vue';
import ViewTourPart from '@/views/main/ViewTourPart.vue';
import ViewTourPartSummary from '@/views/main/ViewTourPartSummary.vue';
import ViewTourPartInvitation from '@/views/main/ViewTourPartInvitation.vue';
import ViewTourCollectionSelection from '@/views/main/ViewTourCollectionSelection.vue';
import ViewTourCollectionWelcome from '@/views/main/ViewTourCollectionWelcome.vue';
import ViewTourSelection from '@/views/main/ViewTourSelection.vue';
import ViewImprint from "@/views/main/ViewImprint.vue";
import ViewTeacher from "@/views/main/ViewTeacher.vue";
import ViewPrivacyPolicy from "@/views/main/ViewPrivacyPolicy.vue";
import modalHandler from '@/data/modalHandler';
import routerHandler from '@/data/routerHandler';
import pageHandler from '@/data/pageHandler';
import userHandler from '@/data/userHandler';
import tourHandler from '@/data/tourHandler';
import { markRaw } from "vue";
import { PLATFORM, TECH_DEMO_USER } from "@/lib/constants";
let defaultPage = '/main/intro';
if (PLATFORM === 'web') {
    defaultPage = '/main/platforms';
}
const routes = [
    {
        path: '/',
        redirect: defaultPage
    },
    {
        path: '/main',
        redirect: defaultPage
    },
    {
        path: '/main/platforms',
        component: ViewPlatforms,
    },
    {
        path: '/main/intro',
        component: ViewIntro,
    },
    {
        path: '/main/intro',
        component: ViewIntro,
    },
    {
        path: '/main/login',
        component: ViewLogin,
    },
    {
        path: '/main/privacyPolicy',
        component: ViewPrivacyPolicy,
    },
    {
        path: '/main/privacyPolicy/app',
        component: ViewPrivacyPolicy,
        meta: {
            isApp: true
        },
    },
    {
        path: '/main/privacyPolicy/web',
        component: ViewPrivacyPolicy,
        meta: {
            isApp: false
        },
    },
    {
        path: '/main/imprint',
        component: ViewImprint,
    },
    {
        path: '/main/teacher',
        component: ViewTeacher,
    },
    {
        path: '/main/tourCollectionSelection',
        component: ViewTourCollectionSelection,
    },
    {
        path: '/tourCollectionWelcome/:tourCollectionKey',
        component: ViewTourCollectionWelcome,
    },
    {
        path: '/tourCollections/:tourCollectionKey',
        component: ViewTourSelection,
        meta: {
            isTourSelection: true
        },
    },
    {
        path: '/tourCollections/:tourCollectionKey/tours/:tourKey/:tourPartIndex/0',
        name: 'tourPart_0',
        component: ViewTourPart,
    },
    {
        path: '/tourCollections/:tourCollectionKey/tours/:tourKey/:tourPartIndex/1',
        name: 'tourPart_1',
        component: ViewTourPart,
    },
    {
        path: '/tourCollections/:tourCollectionKey/tours/:tourKey/summary',
        component: ViewTourPartSummary,
        meta: {
            isTourSummary: true
        },
    },
    {
        path: '/tourCollections/:tourCollectionKey/tours/:tourKey/invitation',
        component: ViewTourPartInvitation,
        meta: {
            isTourInvitation: true
        },
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
// Check the user's status, and route rules
router.beforeEach((to, from) => {
    const fromMeta = { ...(from.params || {}), ...(from.meta || {}) };
    const toMeta = { ...(to.params || {}), ...(to.meta || {}) };
    if (fromMeta.tourPartIndex) {
        fromMeta.tourPartIndex = parseInt(String(fromMeta.tourPartIndex));
    }
    if (toMeta.tourPartIndex) {
        toMeta.tourPartIndex = parseInt(String(toMeta.tourPartIndex));
    }
    const reloadWithinTourDetected = from.path === '/' && !!toMeta.tourCollectionKey && !!toMeta.tourKey && toMeta.tourPartIndex > -1;
    const nextRouteIsCollapsable = to?.matched?.[0]?.props?.default?.tourPart?.collapsable || false;
    const nextRouteGranted = routerHandler.nextRouteGranted();
    const nextRouteNavigationNotAllowed = (fromMeta.tourKey &&
        fromMeta.tourPartIndex !== undefined &&
        (!toMeta.tourCollectionKey ||
            fromMeta.tourCollectionKey !== toMeta.tourCollectionKey ||
            !toMeta.tourKey ||
            fromMeta.tourKey !== toMeta.tourKey ||
            ((fromMeta.tourPartIndex > toMeta.tourPartIndex ||
                toMeta.tourPartIndex === undefined) &&
                !toMeta.isTourSummary &&
                !toMeta.isTourSelection)));
    if (!nextRouteGranted && nextRouteNavigationNotAllowed) {
        let forwardUrl = to.path;
        if (toMeta.tourCollectionKey) {
            forwardUrl = `/tourCollections/${toMeta.tourCollectionKey}`;
        }
        // prevent next view from measuring bounding boxes to early
        pageHandler.update(undefined, nextRouteIsCollapsable, false);
        modalHandler.show('earlyLeaveTour', {
            forwardUrl,
        });
        return false;
    }
    // router fix for problems according to previously aborted router.replace actions
    if (nextRouteGranted && nextRouteNavigationNotAllowed) {
        router.resetIncomingRouteParams();
    }
    // prevent user from reloading already solved tourParts if necessary
    if (reloadWithinTourDetected && !userHandler.getUser().amI(TECH_DEMO_USER)) {
        const tourCollection = tourHandler.getTourCollectionByKey(toMeta.tourCollectionKey);
        if (tourCollection) {
            const targetTourPartIndex = userHandler.getNextUnsolvedTourPartIndex(tourCollection, toMeta.tourKey, toMeta.tourPartIndex);
            if (targetTourPartIndex !== undefined && targetTourPartIndex !== toMeta.tourPartIndex) {
                userHandler.initTour(tourCollection.getTourByKey(toMeta.tourKey), false);
                const newPath = `/tourCollections/${toMeta.tourCollectionKey}/tours/${toMeta.tourKey}/${targetTourPartIndex}/${targetTourPartIndex % 2}`;
                return newPath;
            }
        }
    }
    // prevent next view from measuring bounding boxes to early
    pageHandler.update(to.path, nextRouteIsCollapsable, false);
    return;
});
export default markRaw(router);
export function isInTour() {
    const { tourCollectionKey, tourKey } = router.currentRoute?.value?.params || {};
    const { isTourSummary } = router.currentRoute?.value?.meta || {};
    return !!tourCollectionKey && !!tourKey && !isTourSummary;
}
