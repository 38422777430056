import { markRaw, reactive } from "vue";
import { Challenge } from "@/data/challenges/challenge";
import userHandler from "@/data/userHandler";
import Media from "@/data/media";
import { MIN_DISTANCE_NUMBER_OF_TOUR_PARTS_MOTIVATION, MIN_NUMBER_OF_TOUR_PARTS_MOTIVATION } from "@/lib/constants";
import MediaImage from "@/data/mediaImage";
import { prepareMedia as prepareImprintMedia } from "@/data/imprintHandler";
export class TourPart {
    headline;
    description;
    bottomDescription;
    hasFollowUps = false;
    collapsable = false;
    index = -1;
    challenge;
    showAdditionalsOnTop = false;
    audioFile = '';
    video = {};
    imageFile = '';
    imageDescription = '';
    showMotivationModal = false;
    bottomScrollable = false;
    constructor(headline, description, bottomDescription = '', hasFollowUps = false, challenge = null, collapsable = false, showAdditionalsOnTop = false, audioFile = '', video = {}, imageFile = '', imageDescription = '', bottomScrollable = false) {
        this.headline = headline || '';
        this.description = description || '';
        this.bottomDescription = bottomDescription || '';
        this.hasFollowUps = hasFollowUps || false;
        if (challenge === null) {
            this.challenge = reactive(new Challenge());
        }
        else {
            this.challenge = reactive(challenge);
        }
        this.collapsable = collapsable;
        this.showAdditionalsOnTop = showAdditionalsOnTop;
        this.audioFile = audioFile;
        this.video = video;
        this.imageFile = imageFile;
        this.imageDescription = imageDescription;
        this.bottomScrollable = bottomScrollable;
    }
    getIndex() {
        return this.index;
    }
    setIndex(index) {
        this.index = index;
        this.challenge.setTourPartIndex(index);
    }
    setTourKey(key) {
        this.challenge.setTourKey(key);
    }
    setTourCollectionKey(key) {
        this.challenge.setTourCollectionKey(key);
    }
    prepareMedia() {
        if (this.imageFile)
            new MediaImage(this.imageFile);
        if (this.audioFile)
            new Media('audio', this.audioFile);
        if (this.video?.name)
            new Media('video', this.video?.name);
    }
}
export class Tour {
    tourCollectionKey;
    key;
    name;
    path;
    parts;
    lockedByUserName = '';
    ancestorsHasToBeCompleted;
    showMotivationModal;
    isLast = false;
    constructor(tourCollectionKey, key, name, ancestorsHasToBeCompleted = false, lockedByUserName = '', showMotivationModal = false) {
        this.tourCollectionKey = tourCollectionKey;
        this.key = key;
        this.name = name;
        this.ancestorsHasToBeCompleted = ancestorsHasToBeCompleted;
        this.path = `/tours/${key}/`;
        this.parts = [];
        this.lockedByUserName = lockedByUserName;
        this.showMotivationModal = showMotivationModal;
    }
    addPart(part) {
        part.setTourCollectionKey(this.tourCollectionKey);
        part.setTourKey(this.key);
        part.setIndex(this.parts.length);
        this.parts.push(markRaw(part));
    }
    init() {
        // identify tourPart which should show motivation modal
        if (this.showMotivationModal && this.parts.length >= MIN_NUMBER_OF_TOUR_PARTS_MOTIVATION) {
            const halfNumberOfElements = Math.floor(this.parts.length / 2) - MIN_DISTANCE_NUMBER_OF_TOUR_PARTS_MOTIVATION;
            const motivationPartStartIndex = Math.round(this.parts.length / 2) - 1;
            for (let i = 0; i < halfNumberOfElements; i++) {
                for (const direction of [-1, 1]) {
                    const motivationPartIndex = motivationPartStartIndex + (i * direction);
                    if (this.parts[motivationPartIndex]?.hasFollowUps === false) {
                        this.parts[motivationPartIndex].showMotivationModal = true;
                        return;
                    }
                }
            }
        }
    }
    initParts() {
        for (const tour of this.parts) {
            tour.challenge.init();
        }
    }
    getTourCollectionKey() {
        return this.tourCollectionKey;
    }
    setTourCollectionKey(tourCollectionKey) {
        if (tourCollectionKey) {
            this.tourCollectionKey = tourCollectionKey;
        }
    }
    getKey() {
        return this.key;
    }
    getName() {
        return this.name;
    }
    getPath(index = -1, showInvitation = false) {
        showInvitation = showInvitation && this.isLast;
        if (showInvitation) {
            return `${this.path}invitation`;
        }
        if (index >= 0) {
            if (this.parts[index]) {
                return `${this.path}${index}/${index % 2}`;
            }
            if (index >= this.parts.length) {
                return `${this.path}summary`;
            }
        }
        return '';
    }
    getParts() {
        return this.parts;
    }
    getNumberOfParts() {
        return this.parts.length;
    }
    getScore() {
        const scores = userHandler.getUser().getScores();
        const tourScore = ((scores || {})[this.tourCollectionKey] || {})[this.key] || {};
        return tourScore.score || 0;
    }
    isPerfectScore() {
        return this.getScore() === 1;
    }
    getNormalizedScore() {
        const scores = userHandler.getUser().getScores();
        const tourScore = ((scores || {})[this.tourCollectionKey] || {})[this.key] || {};
        return tourScore.normalizedScore || 0;
    }
    getPart(tourPartIndex) {
        if ((tourPartIndex || tourPartIndex === 0) && this.parts.length > tourPartIndex) {
            return this.parts[tourPartIndex];
        }
        return null;
    }
    prepareMedia() {
        for (const tourPart of this.parts) {
            tourPart.prepareMedia();
        }
    }
    setAsLast() {
        this.isLast = true;
    }
    getIsLast() {
        return this.isLast;
    }
}
export class TourCollection {
    key;
    name = '';
    logo;
    welcomeTopImage = {};
    welcomeBottomImage = {};
    path;
    tourPath;
    tours;
    constructor(key, name, logoName, welcomeTopImage = {}, welcomeBottomImage = {}) {
        this.key = key;
        this.name = name;
        this.logo = new Media('image', logoName);
        this.path = `/tourCollections/${key}`;
        this.tourPath = `/tours/${key}`;
        this.tours = [];
        this.welcomeTopImage = welcomeTopImage || {};
        this.welcomeBottomImage = welcomeBottomImage || {};
    }
    addTour(tour) {
        tour.init();
        this.tours.push(markRaw(tour));
    }
    getTours() {
        return this.tours;
    }
    markLastTour() {
        if (this.tours.length > 0) {
            this.tours[this.tours.length - 1].setAsLast();
        }
    }
    getPath(tour = null, tourPartIndex = -1, showInvitation = false) {
        if (tour) {
            return `${this.path}${tour.getPath(tourPartIndex, showInvitation)}`;
        }
        return this.path;
    }
    getWelcomePath() {
        return `/tourCollectionWelcome/${this.key}`;
    }
    getForwardPath(tour, actualTourPart) {
        if (tour && actualTourPart) {
            return this.getPath(tour, actualTourPart.getIndex() + 1);
        }
        return '';
    }
    initTours() {
        for (const tour of this.tours) {
            tour.initParts();
        }
    }
    getNormalizedScore() {
        let score = 0;
        for (const t in this.tours) {
            score += this.tours[t].getNormalizedScore();
        }
        return score;
    }
    getTourByKey(key) {
        if (key) {
            for (const tour of this.tours) {
                if (tour.getKey() === key) {
                    return tour;
                }
            }
        }
        return null;
    }
    prepareMedia() {
        if (this.welcomeTopImage?.imageFile)
            new MediaImage(this.welcomeTopImage?.imageFile);
        if (this.welcomeBottomImage?.imageFile)
            new MediaImage(this.welcomeBottomImage?.imageFile);
        prepareImprintMedia();
        for (const tour of this.tours) {
            tour.prepareMedia();
        }
    }
}
export class TourHandler {
    tourCollections;
    constructor() {
        this.tourCollections = [];
    }
    addTourCollection(tourCollection) {
        tourCollection.markLastTour();
        this.tourCollections.push(markRaw(tourCollection));
    }
    getTourCollections() {
        return this.tourCollections;
    }
    getTourCollectionByKey(key) {
        if (key) {
            for (const tourCollection of this.tourCollections) {
                if (tourCollection.key === key) {
                    return tourCollection;
                }
            }
        }
        return null;
    }
    initToursCollections() {
        for (const tourCollection of this.tourCollections) {
            tourCollection.initTours();
        }
    }
}
