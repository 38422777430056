import { defineComponent } from "vue";
import { ChallengePairImageText } from "@/data/challenges/challengePairImageText";
import Image from "@/components/core/Image.vue";
import Text from "@/components/core/Text.vue";
import Button from '@/components/core/Button.vue';
import router from '@/router';
import SymbolCheck from "@/components/symbols/check.vue";
import SymbolX from "@/components/symbols/x.vue";
import modalHandler from "@/data/modalHandler";
import pageHookMixin from "@/data/pageHookMixin";
export default defineComponent({
    name: 'ChallengePairImage',
    mixins: [pageHookMixin],
    components: {
        SymbolX,
        SymbolCheck,
        Image,
        Text,
        Button
    },
    props: {
        challenge: {
            type: ChallengePairImageText
        },
        forwardUrl: {
            type: String
        },
        showMotivationModal: {
            type: Boolean,
            default: false
        },
        bottomReadyToMeasure: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        elements() {
            if (this.challenge) {
                return this.challenge.getCombinedItems();
            }
            return [];
        },
        solved() {
            return this.challenge?.state === 'solved';
        },
        buttonColor() {
            if (this.challenge?.state === 'chosen') {
                return 'orange';
            }
            if (this.challenge?.state === 'solved') {
                return 'green';
            }
            return 'gray';
        },
    },
    methods: {
        onScreenUpdate() {
            router.go(0);
        },
        updateBBoxes() {
            if (this.challenge && this.challenge.getNumberOfBoundingBoxes() < 1) {
                const pairs = this.$el.querySelectorAll('.pair');
                for (const pair of pairs) {
                    if (pair) {
                        const bbox = pair.getBoundingClientRect();
                        const target = this.challenge.registerTargetBoundingBox(bbox);
                        if (target.key > -1) {
                            pair.dataset.orderKey = target.key;
                        }
                    }
                }
                // eslint-disable-next-line vue/no-mutating-props
                this.challenge.sortTargetBoundingBoxes();
                const elements = this.$el.querySelectorAll('.pair > div');
                for (const element of elements) {
                    if (element) {
                        const key = Number(element.dataset?.key);
                        const type = element.dataset?.type;
                        if (key && type) {
                            const dnd = element.querySelector('.dnd');
                            if (dnd) {
                                const bbox = dnd.getBoundingClientRect();
                                this.challenge.registerBoundingBox(key, type, bbox);
                            }
                        }
                    }
                }
            }
        },
        draggableOnStart(itemKey, cursorX, cursorY, bbox = null, element = null) {
            if (this.challenge) {
                this.updateBBoxes();
                let type = '';
                if (element?.classList.contains('image')) {
                    type = 'image';
                }
                else if (element?.classList.contains('text')) {
                    type = 'text';
                }
                if (bbox !== null) {
                    this.challenge.registerBoundingBox(itemKey, type, bbox);
                }
                if (element) {
                    element.style.clipPath = 'none';
                    const pairedElement = this.challenge.getPaired(itemKey, type);
                    if (pairedElement) {
                        const associatedElement = this.$el.querySelector(`.pair > .${pairedElement.type}Container[data-key="${pairedElement.key}"] > div`);
                        if (associatedElement) {
                            associatedElement.style.clipPath = 'none';
                        }
                    }
                }
            }
        },
        draggableOnMove(itemKey, cursorX, cursorY, bbox = null, element = null) {
            if (this.challenge) {
                let type = '';
                if (element?.classList.contains('image')) {
                    type = 'image';
                }
                else if (element?.classList.contains('text')) {
                    type = 'text';
                }
                if (bbox !== null) {
                    this.challenge.registerBoundingBox(itemKey, type, bbox);
                }
            }
        },
        draggableOnEnd(itemKey, cursorX, cursorY, bbox = null, element = null) {
            if (this.challenge) {
                let type = '';
                if (element?.classList.contains('image')) {
                    type = 'image';
                }
                else if (element?.classList.contains('text')) {
                    type = 'text';
                }
                if (bbox !== null) {
                    this.challenge.registerBoundingBox(itemKey, type, bbox);
                }
                const overlap = this.challenge.calculateBoundingBoxOverlapping(itemKey, type);
                if (overlap) {
                    if (overlap.previouslyPairedElement.key > -1) {
                        const prevElement = this.$el.querySelector(`.pair > .${overlap.previouslyPairedElement.type}Container[data-key="${overlap.previouslyPairedElement.key}"] > div`);
                        if (prevElement) {
                            prevElement.style.clipPath = `none`;
                        }
                    }
                    if (element && overlap.clipA) {
                        element.style.clipPath = `polygon(${overlap.clipA})`;
                        if (overlap.clipB) {
                            const associatedElement = this.$el.querySelector(`.pair > .${overlap.type}Container[data-key="${overlap.key}"] > div`);
                            if (associatedElement) {
                                associatedElement.style.clipPath = `polygon(${overlap.clipB})`;
                            }
                        }
                    }
                }
                // set all zIndexDelta's
                const zIndexOrdered = this.challenge.reorderZIndexList(itemKey, element?.classList.contains('text'));
                for (const zIndexDelta in zIndexOrdered) {
                    const element = this.$el.querySelector(`#${this._componentID}_${zIndexOrdered[zIndexDelta]}`);
                    if (element) {
                        element.style.setProperty('--z-index-delta', String(zIndexDelta));
                    }
                }
            }
        },
        onForwardButtonClick() {
            if (this.challenge?.state === 'solved' && this.forwardUrl) {
                if (this.showMotivationModal) {
                    modalHandler.show('motivation', {
                        forwardUrl: this.forwardUrl
                    });
                }
                else {
                    router.replace(this.forwardUrl);
                }
            }
            if (this.challenge?.state === 'chosen') {
                this.moveElementsToTheirTargetPositions();
                this.challenge.evaluate();
                requestAnimationFrame(() => {
                    const combinedItems = this.challenge?.getFinalItemProperties() || [];
                    let symbolTypeClass = '';
                    for (const item of combinedItems) {
                        if (item.isCorrect) {
                            symbolTypeClass = 'correct';
                        }
                        else {
                            symbolTypeClass = 'incorrect';
                        }
                        item.element = this.$el.querySelector(`.pair[data-order-key="${item.targetOrderKey}"] .symbol svg.${symbolTypeClass}`);
                        if (item.element) {
                            item.element.style.display = 'block';
                        }
                    }
                    requestAnimationFrame(() => {
                        for (const item of combinedItems) {
                            if (item.element) {
                                item.element.style.opacity = 1;
                                item.element.style.transform = 'scale(1.5)';
                            }
                        }
                    });
                });
            }
        },
        moveElementsToTheirTargetPositions() {
            if (this.challenge) {
                const combinedItems = this.challenge.getFinalItemProperties();
                // prepare base properties for automatic move
                requestAnimationFrame(() => {
                    for (const item of combinedItems) {
                        for (const type of ['image', 'text']) {
                            const element = this.$el.querySelector(`#${this._componentID}_${type}_${item[type].key} > div`);
                            if (element) {
                                element.style.position = 'fixed';
                                element.style.left = `${item[type].bbox.x}px`;
                                element.style.top = `${item[type].bbox.y}px`;
                                element.style.transform = `none`;
                                element.style.boxShadow = `none`;
                                element.style.opacity = .5;
                            }
                        }
                    }
                    // perform automatic move to target position
                    requestAnimationFrame(() => {
                        for (const item of combinedItems) {
                            for (const type of ['image', 'text']) {
                                const element = this.$el.querySelector(`#${this._componentID}_${type}_${item[type].key} > div`);
                                if (element) {
                                    element.style.opacity = 1;
                                    element.style.clipPath = `none`;
                                    element.style.left = `${item[type].targetX}px`;
                                    element.style.top = `${item[type].targetY}px`;
                                }
                            }
                        }
                    });
                });
            }
        },
        pageEnter() {
            if (this.bottomReadyToMeasure && !this.$el.classList.contains('animate')) {
                this.$el.classList.add('animate');
            }
        },
    },
    updated() {
        if (this.bottomReadyToMeasure && !this.$el.classList.contains('animate')) {
            setTimeout(() => {
                this.$el.classList.add('animate');
            }, 300);
        }
    },
});
