import { Capacitor } from "@capacitor/core";
export const MAX_BONES_PER_TOUR = 3;
export const TECH_DEMO_USER = 'samurocks';
export const PUZZLE_PIECE_ELEMENTS = [
    { right: 'trp' },
    { left: 'tln', right: 'brp' },
    { left: 'bln', right: 'brn' },
    { left: 'blp' }
];
export const SPEECH_BUBBLE_AREA_HEIGHT = 14;
export const SPEECH_BUBBLE_AREA_OFFSET_Y = -4;
export const MIN_NUMBER_OF_TOUR_PARTS_MOTIVATION = 8;
export const MIN_DISTANCE_NUMBER_OF_TOUR_PARTS_MOTIVATION = 2;
export const YEAR = new Date().getFullYear();
export const PLATFORM = Capacitor.getPlatform();
export const MAIN_VERSION = 'v1.0.9';
