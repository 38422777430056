import { defineComponent } from "vue";
import { ChallengeMultiSelectImageText } from "@/data/challenges/challengeMultiSelectImageText";
import Image from "@/components/core/Image.vue";
import Button from '@/components/core/Button.vue';
import Checkbox from "@/components/core/Checkbox.vue";
import SymbolSolution from "@/components/symbols/solution.vue";
import router from '@/router';
import modalHandler from "@/data/modalHandler";
import pageHookMixin from "@/data/pageHookMixin";
export default defineComponent({
    name: 'ChallengeMultiSelectImageText',
    mixins: [pageHookMixin],
    components: {
        SymbolSolution,
        Checkbox,
        Image,
        Button
    },
    props: {
        challenge: {
            type: ChallengeMultiSelectImageText
        },
        forwardUrl: {
            type: String
        },
        showMotivationModal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        if (this.challenge) {
            let horizontalImages = 'single';
            let nrOfRepresentationalElements = this.challenge.presentationalItems.length;
            if (this.challenge?.useStyle === 'slim') {
                nrOfRepresentationalElements += 1;
            }
            switch (nrOfRepresentationalElements) {
                case 3:
                    horizontalImages = 'triple';
                    break;
                case 2:
                    horizontalImages = 'double';
                    break;
                default:
                    horizontalImages = 'single';
                    break;
            }
            return {
                horizontalImages,
                presentationalItems: this.challenge.presentationalItems
            };
        }
        return {
            horizontalImages: 0,
            presentationalItems: []
        };
    },
    computed: {
        solved() {
            if (this.challenge) {
                return this.challenge.state === 'solved';
            }
            return false;
        },
        buttonColor() {
            if (this.challenge) {
                if (this.solved) {
                    return 'green';
                }
                if (this.challenge.chosen()) {
                    return 'orange';
                }
            }
            return 'gray';
        },
    },
    methods: {
        select(event, item) {
            event.preventDefault();
            event.stopImmediatePropagation();
            const newRotation = `${Math.floor(Math.random() * 60) - 30}deg`;
            const newTranslationX = `${(Math.floor(Math.random() * 6) - 3) / 10}em`;
            const newTranslationY = `${(Math.floor(Math.random() * 6) - 3) / 10}em`;
            event.currentTarget.style.setProperty('--selected-offset-rotation', newRotation);
            event.currentTarget.style.setProperty('--selected-offset-x', newTranslationX);
            event.currentTarget.style.setProperty('--selected-offset-y', newTranslationY);
            if (this.challenge) {
                this.challenge.toggleSelection(item.key);
            }
        },
        onForwardButtonClick() {
            if (this.challenge) {
                if (this.solved) {
                    if (this.forwardUrl) {
                        if (this.showMotivationModal) {
                            modalHandler.show('motivation', {
                                forwardUrl: this.forwardUrl
                            });
                        }
                        else {
                            router.replace(this.forwardUrl);
                        }
                    }
                }
                if (this.challenge.chosen()) {
                    this.challenge.evaluate();
                }
            }
        }
    }
});
