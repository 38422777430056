import { IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import Header from '@/components/core/Header.vue';
import Content from '@/components/core/Content.vue';
export default defineComponent({
    name: 'LayoutsIntro',
    components: {
        IonPage,
        Header,
        Content
    }
});
