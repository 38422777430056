import { defineComponent } from 'vue';
import DraggableGesture from "@/components/core/DraggableGesture.vue";
import SymbolSpeechBubblePointer from "@/components/symbols/speechBubblePointer.vue";
import SymbolSpeechBubblePointerArea from "@/components/symbols/speechBubblePointerArea.vue";
export default defineComponent({
    name: 'Text',
    components: {
        SymbolSpeechBubblePointer,
        SymbolSpeechBubblePointerArea,
        DraggableGesture
    },
    props: {
        text: {
            type: String,
            default: ''
        },
        rotate: {
            type: Boolean,
            default: false
        },
        shadow: {
            type: Boolean,
            default: false
        },
        draggable: {
            type: Boolean,
            default: false
        },
        showAsSpeechBubble: {
            type: Boolean,
            default: false
        },
        showAsSpeechBubblePlacementAreaWidth: {
            type: Number,
            default: 0
        },
        preventInternalMovement: {
            type: Boolean,
            default: false
        },
        quarterCircleRotation: {
            type: Number,
            default: -1
        },
        zIndexDelta: {
            type: Number,
            default: 0
        },
        orientation: {
            type: String,
            default: 'landscape'
        },
        horizontalElements: {
            type: String,
            default: 'double'
        },
        itemKey: {
            type: Number,
            default: -1
        },
    },
    data() {
        const additionalContainerClasses = [];
        const additionalClasses = ['text'];
        if (this.orientation !== '') {
            additionalClasses.push(this.orientation);
        }
        if (this.horizontalElements) {
            additionalContainerClasses.push(this.horizontalElements);
        }
        if (this.shadow) {
            additionalClasses.push('shadow');
        }
        if (this.quarterCircleRotation > -1) {
            additionalClasses.push('quarterCircle');
        }
        let rotation = 0;
        let offsetY = 0;
        if (this.rotate) {
            rotation = Math.floor(Math.random() * 12) - 6;
            offsetY = Math.floor(Math.random() * 30) / 10;
        }
        return {
            showSymbol: !!this.$slots.symbol,
            showInnerSymbol: !!this.$slots.innerSymbol,
            cssClasses: additionalClasses.join(' '),
            cssContainerClasses: additionalContainerClasses.join(' '),
            offsetY,
            rotation
        };
    },
    mounted() {
        const style = this.$el?.style;
        style?.setProperty('--quarter-circle-rotation', `${this.quarterCircleRotation || 0}deg`);
        style?.setProperty('--z-index-delta', `${this.zIndexDelta || 0}`);
    },
    methods: {
        draggableOnStart(itemKey, cursorX, cursorY, bbox = null, element = null) {
            this.$emit('draggableOnStart', itemKey, cursorX, cursorY, bbox, element);
        },
        draggableOnMove(itemKey, cursorX, cursorY, bbox = null, element = null) {
            this.$emit('draggableOnMove', itemKey, cursorX, cursorY, bbox, element);
        },
        draggableOnEnd(itemKey, cursorX, cursorY, bbox = null, element = null) {
            this.$emit('draggableOnEnd', itemKey, cursorX, cursorY, bbox, element);
        }
    }
});
