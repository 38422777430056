import { markRaw } from "vue";
import { Tour, TourPart } from "@/data/tourHandlerCore";
import { PUZZLE_PIECE_ELEMENTS } from "@/lib/constants";
import { ChallengeItemMultiSelectImage, ChallengeItemMultiSelectText, ChallengeMultiSelectImageText } from "@/data/challenges/challengeMultiSelectImageText";
import { ChallengeItemPlaceOnAxis, ChallengePlaceOnAxis } from "@/data/challenges/challengePlaceOnAxis";
import { ChallengeItemPickTextBySwipe, ChallengePickTextBySwipe } from "@/data/challenges/challengePickTextBySwipe";
import { ChallengeItemPlaceImageOnText, ChallengePlaceImageOnText } from "@/data/challenges/challengePlaceImageOnText";
import { ChallengeItemPlaceImageOnImage, ChallengePlaceImageOnImage } from "@/data/challenges/challengePlaceImageOnImage";
export default function (tourCollectionKey) {
    const tour = new Tour(tourCollectionKey, 'E', 'Jetzt geht\'s um die Wurst', true, '', true);
    tour.addPart(new TourPart('', '', 'Das Museum Bramberg bewahrt unterschiedliche <b>Dinge aus der Vergangenheit</b> auf. Wir nennen diese Dinge auch <b>Quellen</b>.<br><br>Heute können wir sie ansehen und mit ihnen herausfinden, wie es früher gewesen sein könnte.'));
    tour.addPart(new TourPart('', 'Ziehe das Bild der Quelle zur richtigen Beschreibung!<br><br>Tippe auf ein Bild, um es zu vergrößern. Dort findest du wichtige Hinweise.', '', false, new ChallengePlaceImageOnText([
        new ChallengeItemPlaceImageOnText('Holzschüssel zum Zubereiten von Essen.', 'bb_holzschuessel.png', 'Holzschüssel, Bramberg, ca. 120 Jahre alt'),
        new ChallengeItemPlaceImageOnText('In diesem Kochgefäß wurde über offenem Feuer Essen zubereitet.', 'bb_pfanne.png', 'Pfanne mit Pfannenhalter, Bramberg, ca. 120 Jahre alt'),
        new ChallengeItemPlaceImageOnText('In einer solchen Vorrichtung wurden Löffel und Gabeln aufgehängt.', 'bb_besteckhalterung.jpg', 'Besteckhalterung, Bramberg, ca. 120 Jahre alt'),
        new ChallengeItemPlaceImageOnText('Damit wurden Zutaten abgewogen.', 'bb_kuechenwaage.png', 'Küchenwaage, Bramberg, ca. 120 Jahre alt'),
    ])));
    tour.addPart(new TourPart('', 'Was können wir aus dieser Quelle über die Vergangenheit erfahren?<br><br>Tippe auf ein Bild, um es zu vergrößern. Dort findest du wichtige Hinweise.', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Holzteller waren in manchen Haushalten um 1900 noch sehr verbreitet.', true),
        new ChallengeItemMultiSelectText('Solche Plastikteller waren um 1900 in vielen Haushalten verfügbar.', false),
        new ChallengeItemMultiSelectText('Mit diesem Holzteller wurde über dem Feuer Suppe zubereitet.', false),
    ], [
        new ChallengeItemMultiSelectImage('bb_holzschuessel.png', undefined, 'Holzschüssel, Bramberg, ca. 120 Jahre alt'),
    ], true, true, 'slim')));
    tour.addPart(new TourPart('', 'Was können wir aus dieser Quelle über die Vergangenheit erfahren?', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Eine Waage war in manchen Haushalten damals schon vorhanden.', true),
        new ChallengeItemMultiSelectText('Diese Waage wurden damals schon mit Batterien betrieben.', false),
        new ChallengeItemMultiSelectText('Eine Digitalanzeige dieser Waage half damals das Gewicht abzulesen.', false),
    ], [
        new ChallengeItemMultiSelectImage('bb_kuechenwaage.png', undefined, 'Küchenwaage, Bramberg, ca. 120 Jahre alt'),
    ], true, true, 'slim')));
    tour.addPart(new TourPart('', 'Was können wir aus dieser Quelle über die Vergangenheit erfahren?', '', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Besteck wurde vor allem aus Holz hergestellt.', false),
        new ChallengeItemMultiSelectText('Besteck wurde nicht in einer Lade aufbewahrt, sondern an der Wand.', true),
        new ChallengeItemMultiSelectText('Es wurde damals kein Besteck verwendet.', false),
    ], [
        new ChallengeItemMultiSelectImage('bb_besteckhalterung.jpg', undefined, 'Besteckhalterung, Bramberg, ca. 120 Jahre alt'),
    ], true, true, 'slim')));
    tour.addPart(new TourPart('', 'Passt die Geschichte zu den Quellen? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Vor hundert Jahren kochten vor allem die Frauen zu Hause. Das ist heute ganz anders geworden und auch Männer kümmern sich um den Haushalt.', true, [
        { name: 'bb_holzschuessel.png', description: 'Holzschüssel, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_pfanne.png', description: 'Pfanne mit Pfannenhalter, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_besteckhalterung.jpg', description: 'Besteckhalterung, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_kuechenwaage.png', description: 'Küchenwaage, Bramberg, ca. 120 Jahre alt' },
    ]), 'passt nicht', 'passt', 'slim'), false));
    tour.addPart(new TourPart('', 'Passt die Geschichte zu den Quellen? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Vor hundert Jahren bereitete man noch mit ganz anderen Gegenständen Speisen zu. Plastik gab es damals noch nicht in der Küche.', false, [
        { name: 'bb_holzschuessel.png', description: 'Holzschüssel, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_pfanne.png', description: 'Pfanne mit Pfannenhalter, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_besteckhalterung.jpg', description: 'Besteckhalterung, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_kuechenwaage.png', description: 'Küchenwaage, Bramberg, ca. 120 Jahre alt' },
    ]), 'passt nicht', 'passt', 'slim'), false));
    tour.addPart(new TourPart('', 'Passt die Geschichte zu den Quellen? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Vor hundert Jahren kochten die Leute in Bramberg groß auf. Da gab es sehr schmackhafte Gerichte. Fertiggerichte aus der Tiefkühltruhe gab es damals noch nicht.', true, [
        { name: 'bb_holzschuessel.png', description: 'Holzschüssel, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_pfanne.png', description: 'Pfanne mit Pfannenhalter, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_besteckhalterung.jpg', description: 'Besteckhalterung, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_kuechenwaage.png', description: 'Küchenwaage, Bramberg, ca. 120 Jahre alt' },
    ]), 'passt nicht', 'passt', 'slim'), false));
    tour.addPart(new TourPart('', 'Passt die Geschichte zu den Quellen? Wische!', '', false, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Kochen war vor 100 Jahren noch viel anstrengender als heute. Man musste Teig mit den eigenen Händen kneten und die Mikrowelle war noch unbekannt.', true, [
        { name: 'bb_holzschuessel.png', description: 'Holzschüssel, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_pfanne.png', description: 'Pfanne mit Pfannenhalter, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_besteckhalterung.jpg', description: 'Besteckhalterung, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_kuechenwaage.png', description: 'Küchenwaage, Bramberg, ca. 120 Jahre alt' },
    ]), 'passt nicht', 'passt', 'slim')));
    tour.addPart(new TourPart('', 'Welche Fragen können mit den Quellen beantwortet werden? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Schmeckten die Speisen vor 100 Jahren gleich wie heute?', true, [
        { name: 'bb_holzschuessel.png', description: 'Holzschüssel, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_pfanne.png', description: 'Pfanne mit Pfannenhalter, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_besteckhalterung.jpg', description: 'Besteckhalterung, Bramberg, ca. 120 Jahre alt' },
    ]), 'unmöglich', 'möglich', 'slim')));
    tour.addPart(new TourPart('', 'Welche Fragen können mit den Quellen beantwortet werden? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Gab es damals schon elektrischen Strom in den Küchen?', true, [
        { name: 'bb_holzschuessel.png', description: 'Holzschüssel, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_pfanne.png', description: 'Pfanne mit Pfannenhalter, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_besteckhalterung.jpg', description: 'Besteckhalterung, Bramberg, ca. 120 Jahre alt' },
    ]), 'unmöglich', 'möglich', 'slim')));
    tour.addPart(new TourPart('', 'Welche Fragen können mit den Quellen beantwortet werden? Wische!', '', false, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Welche Dinge fand man vor ca. 100 Jahren in der Küche?', false, [
        { name: 'bb_holzschuessel.png', description: 'Holzschüssel, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_pfanne.png', description: 'Pfanne mit Pfannenhalter, Bramberg, ca. 120 Jahre alt' },
        { name: 'bb_besteckhalterung.jpg', description: 'Besteckhalterung, Bramberg, ca. 120 Jahre alt' },
    ]), 'unmöglich', 'möglich', 'slim')));
    tour.addPart(new TourPart('', '', 'Eine Künstlerin hat versucht, einen römischen Soldaten möglichst richtig zu malen.', true, new ChallengePlaceImageOnImage('bm_roemer-rekonstruktion.png', 1000, 2100, [], true)));
    tour.addPart(new TourPart('', 'Welche Teile des Bildes konnte die Zeichnerin mit Quellen als Vorlage gut zeichnen?<br>Ziehe die Quellen an die richtigen Stellen!', '', false, new ChallengePlaceImageOnImage('bm_roemer-rekonstruktion.png', 1000, 2100, [
        new ChallengeItemPlaceImageOnImage({ x: 370, y: 276 }, 'grave'),
        new ChallengeItemPlaceImageOnImage({ x: 930, y: 359 }, 'spear', 'bm_roemer-rekonstruktion-speer-spitze.png', 'Römische Speerspitzen, 1.-4. Jh. n. Chr.', {
            isDark: true,
            copyright: {
                name: 'N. Gail | Landessammlungen NÖ, Archäologischer Park Carnuntum',
                license: '',
                symbol: 'c',
                url: 'https://www.online.landessammlungen-noe.at/objects/1303802/lanzenspeerspitze'
            }
        }),
        new ChallengeItemPlaceImageOnImage({ x: 412, y: 831 }, 'grave', 'bm_roemer-rekonstruktion-grabstele-titus-calidius.png', 'Römisches Grabmal, 1. Jh. n. Chr., Fundort: Österreich', {
            copyright: {
                name: 'Mathias Kabel',
                license: 'CC BY 2.5',
                symbol: 'cc',
                url: 'https://commons.wikimedia.org/wiki/File:Grabstein_Titus_Calidius_Carnuntum.jpg'
            }
        }),
        new ChallengeItemPlaceImageOnImage({ x: 244, y: 1262 }, 'sword', 'bm_roemer-rekonstruktion-klinge.png', 'Römische Klinge, 1.-2. Jh. n. Chr.', {
            isDark: true,
            copyright: {
                name: 'N. Gail | Landessammlungen NÖ, Archäologischer Park Carnuntum',
                license: '',
                symbol: 'c',
                url: 'https://www.online.landessammlungen-noe.at/objects/1303394/klinge'
            }
        }),
        new ChallengeItemPlaceImageOnImage({ x: 276, y: 1786 }, 'grave'), // shinpad
    ]), false));
    tour.addPart(new TourPart('', 'Hör dir das an ...', 'Ist das ein Märchen oder geht es um Geschichte?', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Das ist ein Märchen.', false),
        new ChallengeItemMultiSelectText('Das ist Geschichte.', true),
    ], [], true, true), false, false, 'bb_e_1_geschichte.m4a'));
    tour.addPart(new TourPart('', 'Hör dir das zweite Beispiel an ...', 'Ist das ein Märchen oder geht es um Geschichte?', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Das ist ein Märchen.', true),
        new ChallengeItemMultiSelectText('Das ist Geschichte.', false),
    ], [], true, true), false, false, 'bb_e_2_maerchen.m4a'));
    tour.addPart(new TourPart('', 'Hör dir das dritte Beispiel an ...', 'Ist das ein Märchen oder geht es um Geschichte?', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Das ist ein Märchen.', false),
        new ChallengeItemMultiSelectText('Das ist Geschichte.', true),
    ], [], true, true), false, false, 'bb_e_3_geschichte.m4a'));
    tour.addPart(new TourPart('', 'Das ist ein Foto aus Bramberg, das etwa 100 Jahre alt ist.<br><br>Welche Fragen kann man mit dem Foto beantworten?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Welche Kleidung trugen Menschen am Bauernhof vor ca. 100 Jahren in Bramberg?', false, [
        { name: 'bb_mittererlach.jpg', description: 'Foto einer Bauernfamilie' },
    ]), 'unmöglich', 'möglich')));
    tour.addPart(new TourPart('', 'Das ist ein Foto aus Bramberg, das etwa 100 Jahre alt ist.<br><br>Welche Fragen kann man mit dem Foto beantworten?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Welche Kleidung hat man damals getragen, wenn man in die Kirche gegangen ist?', true, [
        { name: 'bb_mittererlach.jpg', description: 'Foto einer Bauernfamilie' },
    ]), 'unmöglich', 'möglich')));
    tour.addPart(new TourPart('', 'Das ist ein Foto aus Bramberg, das etwa 100 Jahre alt ist.<br><br>Welche Fragen kann man mit dem Foto beantworten?', '', false, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Wie haben sich Urlauber in Barmberg gekleidet?', true, [
        { name: 'bb_mittererlach.jpg', description: 'Foto einer Bauernfamilie' },
    ]), 'unmöglich', 'möglich')));
    tour.addPart(new TourPart('', 'Die lange Geschichte der Menschen wird in <b>Abschnitte</b> eingeteilt.<br><br>Hilf mit, die Abschnitte in die richtige Reihenfolge zu bringen.', '', true, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis('', .5 * (1.3 / 4), undefined, undefined, false, false, true, 'Steinzeit', '', '', PUZZLE_PIECE_ELEMENTS[0]),
        new ChallengeItemPlaceOnAxis('Jahr 0', (1.5 * (1.3 / 4)) - .1, undefined, undefined, true, false, true, 'Altertum', '', '', PUZZLE_PIECE_ELEMENTS[1]),
        new ChallengeItemPlaceOnAxis('', (2.5 * (1.3 / 4)) - .2, undefined, undefined, false, false, true, 'Mittelalter', '', '', PUZZLE_PIECE_ELEMENTS[2]),
        new ChallengeItemPlaceOnAxis('heute', (3.5 * (1.3 / 4)) - .3, undefined, undefined, true, false, true, 'Neuzeit', '', '', PUZZLE_PIECE_ELEMENTS[3]),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false, false),
    ], 1 / 20, true, true, true, 0, 0, true), false, true, 'xx_geschichte_der_menschen.m4a'));
    tour.addPart(new TourPart('', 'Ordne die Quellen ihren richtigen Abschnitten zu.<br><br>Aus welchem Zeitabschnitt sind die Quellen aus dem Museum?', '', false, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis('', .5 * (1.3 / 4), undefined, undefined, false, false, true, 'Steinzeit', 'bb_steinzeitmesser.png', 'Steinzeitmesser, Pinzgau, ca. 7000 Jahre alt', PUZZLE_PIECE_ELEMENTS[0]),
        new ChallengeItemPlaceOnAxis('Jahr 0', (1.5 * (1.3 / 4)) - .1, undefined, undefined, true, false, true, 'Altertum', 'bb_roemische-muenze.png', 'Römische Münze, Bramberg, 81-91 n. Chr., ca. 2000 Jahre alt', PUZZLE_PIECE_ELEMENTS[1]),
        new ChallengeItemPlaceOnAxis('', (2.5 * (1.3 / 4)) - .2, undefined, undefined, false, false, false, 'Mittelalter', '', '', PUZZLE_PIECE_ELEMENTS[2]),
        new ChallengeItemPlaceOnAxis('heute', (3.5 * (1.3 / 4)) - .3, undefined, undefined, true, false, true, 'Neuzeit', 'bb_katechismus.png', 'Lehrbuch für katholischen Glaubensunterricht (Katechismus), 1742, ca. 280 Jahre alt', PUZZLE_PIECE_ELEMENTS[3]),
        new ChallengeItemPlaceOnAxis('', (3.5 * (1.3 / 4)) - .3, undefined, undefined, false, false, true, '', 'bb_kinderwagen-alt.png', 'Kinderwagen, Österreich, in etwa 90 Jahre alt', PUZZLE_PIECE_ELEMENTS[3]),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false, false),
    ], 1 / 20, true, true, true, 0, 0, true, true, false, false, true, false)));
    tour.addPart(new TourPart('', 'Ein Blick ins Museum Bramberg ...', 'Die ältesten Ausstellungsstücke im Museum Bramberg sind die Mineralien. Sie sind viele Millionen Jahre alt.<br><br>Die ältesten Gegenstände, die von Menschen gemacht sind, sind viel, viel jünger. Sie stammen aus der Steinzeit und sind ca. 4.000 bis 5.000 Jahre alt. Viele Geräte wurden damals aus speziellen Steinen wie zum Beispiel Feuerstein oder Bergkristall hergestellt. Das waren aber ganz geniale Werkzeuge. Manche waren messerscharf!<br><br>Die meisten Dinge aus der Vergangenheit sind aber nicht so alt. Manche sind aus dem Mittelalter. Sie sind ca. 700 Jahre alt ...', true, undefined, false, false, '', { name: 'bb_einblick.mp4', portrait: false }));
    tour.addPart(new TourPart('', '', 'Welche Zeitabschnitte der Geschichte werden vom Museum Bramberg gezeigt?<br><br>Wenn du möchtest, kannst du dir hier das Video gern nochmal anschauen.<br><br>Markiere <b>alle</b> richtigen Antworten!', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Steinzeit', true),
        new ChallengeItemMultiSelectText('Altertum', true),
        new ChallengeItemMultiSelectText('Mittelalter', true),
        new ChallengeItemMultiSelectText('Neuzeit', true),
    ], [], false, true, undefined, false), false, false, '', { name: 'bb_einblick.mp4', portrait: false }));
    tour.addPart(new TourPart('', 'Warum erzählt das Museum in Bramberg über diese verschiedenen Zeiten? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Weil es dazu Dinge aus der Vergangenheit gesammelt hat.', false), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Warum erzählt das Museum Bramberg vor allem über das Leben und Arbeiten in Bramberg? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Weil es keine andere Geschichte erzählen kann.', true), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Warum erzählt das Museum in Bramberg über diese Zeiten? Wische!', '', false, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Weil es nur einen Teil aus der Vergangenheit erzählen kann.', false), 'falsch', 'richtig')));
    return markRaw(tour);
}
