import { defineComponent } from "vue";
import { ChallengeSingleSelectImage } from "@/data/challenges/challengeSingleSelectImage";
import Image from "@/components/core/Image.vue";
import Button from '@/components/core/Button.vue';
import SymbolSolution from '@/components/symbols/solution.vue';
import router from '@/router';
import modalHandler from "@/data/modalHandler";
import audioHandler from "@/data/audioHandler";
import pageHookMixin from "@/data/pageHookMixin";
export default defineComponent({
    name: 'ChallengeSingleSelectImage',
    mixins: [pageHookMixin],
    components: {
        SymbolSolution,
        Image,
        Button
    },
    props: {
        challenge: {
            type: ChallengeSingleSelectImage
        },
        forwardUrl: {
            type: String
        },
        showMotivationModal: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        selectedKey() {
            if (this.challenge) {
                return this.challenge.getSelectedKey();
            }
            return -1;
        },
        solved() {
            if (this.challenge) {
                return this.challenge.state === 'solved';
            }
            return false;
        },
        buttonColor() {
            if (this.challenge) {
                if (this.solved) {
                    return 'green';
                }
                if (this.challenge.getSelectedKey() >= 0) {
                    return 'orange';
                }
            }
            return 'gray';
        }
    },
    methods: {
        select(event, item) {
            event.preventDefault();
            event.stopImmediatePropagation();
            const newRotation = `${Math.floor(Math.random() * 40) - 20}deg`;
            const newTranslationX = `${(Math.floor(Math.random() * 20) - 10) / 10}vw`;
            const newTranslationY = `${(Math.floor(Math.random() * 20) - 10) / 10}vw`;
            event.currentTarget.style.setProperty('--selected-offset-rotation', newRotation);
            event.currentTarget.style.setProperty('--selected-offset-x', newTranslationX);
            event.currentTarget.style.setProperty('--selected-offset-y', newTranslationY);
            if (this.challenge && !this.solved) {
                if (this.selectedKey === item.key) {
                    this.challenge.deselect();
                }
                else {
                    audioHandler.play('select');
                    this.challenge.select(item.key);
                }
            }
        },
        onForwardButtonClick() {
            if (this.challenge) {
                if (this.solved) {
                    if (this.forwardUrl) {
                        if (this.showMotivationModal) {
                            modalHandler.show('motivation', {
                                forwardUrl: this.forwardUrl
                            });
                        }
                        else {
                            router.replace(this.forwardUrl);
                        }
                    }
                }
                if (this.challenge.getSelectedKey() >= 0) {
                    this.challenge.evaluate();
                }
            }
        }
    }
});
