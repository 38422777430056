import { defineComponent } from "vue";
import { MAX_BONES_PER_TOUR } from '@/lib/constants';
import router from '@/router';
import Button from '@/components/core/Button.vue';
import SymbolBone from "@/components/symbols/bone.vue";
import { tourHandler } from "@/data/tourHandler";
import pageHookMixin from "@/data/pageHookMixin";
export default defineComponent({
    name: 'TourSummary',
    mixins: [pageHookMixin],
    components: {
        SymbolBone,
        Button
    },
    setup() {
        const { tourCollectionKey, tourKey } = router.currentRoute?.value?.params || {};
        const tourCollection = tourHandler.getTourCollectionByKey(String(tourCollectionKey));
        const tour = tourCollection?.getTourByKey(String(tourKey));
        return {
            tourCollection,
            tour,
            tourKey,
            fullScore: MAX_BONES_PER_TOUR,
        };
    },
    computed: {
        normalizedScore() {
            if (this.tour) {
                return this.tour.getNormalizedScore();
            }
            return 0;
        },
        isPerfectScore() {
            if (this.tour) {
                return this.tour.isPerfectScore();
            }
            return false;
        }
    },
    methods: {
        onForwardButtonClick() {
            if (this.tourCollection) {
                router.push({
                    path: this.tourCollection.getPath(this.tour, -1, true),
                    replace: true,
                    force: true
                });
            }
        }
    }
});
