import { BaseChallenge, BaseChallengeIterable, BaseChallengeItem } from "@/data/challenges/challenge";
import PairImageText from "@/components/challenges/PairImageText.vue";
import MediaImage from "@/data/mediaImage";
import { markRaw } from "vue";
import userHandler from "@/data/userHandler";
export class ChallengePairImageText extends BaseChallenge {
    // separate item iterator for texts within same item list
    textItems;
    // recombined texts and images after shuffle
    combinedItems = [];
    // zIndexOrdered list of element ids
    zIndexOrdered = [];
    // combined items according to the users selection
    solvedCombinedItems = [];
    // user selected pairs
    selectedPairs = {};
    // .pair bound target positions needed for solve animations
    targetBoundingBoxes = [];
    constructor(items = []) {
        super('PairImageText', PairImageText);
        this.items = items;
        this.textItems = new BaseChallengeIterable(items);
    }
    init() {
        this.solvedCombinedItems = [];
        this.selectedPairs = {};
        this.state = '';
        this.targetBoundingBoxes = [];
        this.zIndexOrdered = [];
        this.shuffle();
        this.textItems.shuffle();
        this.combinedItems = markRaw([]);
        for (const i of this.itemsIndexes) {
            const item = this.getItem(i);
            const textItem = this.textItems.getItem(i);
            this.combinedItems.push({
                image: {
                    key: item?.key,
                    media: item?.media,
                    bbox: {}
                },
                text: {
                    key: textItem?.key,
                    text: textItem?.text,
                    bbox: {}
                }
            });
            this.zIndexOrdered.push(`image_${item?.key}`);
            this.zIndexOrdered.push(`text_${textItem?.key}`);
        }
        this.postInit();
    }
    getCombinedItems() {
        return this.combinedItems;
    }
    reorderZIndexList(itemKey, isText = false) {
        let sourceType = 'image';
        if (isText) {
            sourceType = 'text';
        }
        const sourceKey = `${sourceType}_${itemKey}`;
        const targetKey = this.selectedPairs[sourceKey];
        if (targetKey) {
            const targetIndex = this.zIndexOrdered.indexOf(targetKey);
            this.zIndexOrdered.splice(targetIndex, 1);
            this.zIndexOrdered.push(targetKey);
        }
        const sourceIndex = this.zIndexOrdered.indexOf(sourceKey);
        this.zIndexOrdered.splice(sourceIndex, 1);
        this.zIndexOrdered.push(sourceKey);
        return this.zIndexOrdered;
    }
    pair(keyA = -1, typeA = '', keyB = -1) {
        if (keyA > -1 && keyB > -1 && typeA && this.state !== 'solved') {
            let typeB = 'text';
            if (typeA === 'text') {
                typeB = 'image';
            }
            const kA = `${typeA}_${keyA}`;
            const kB = `${typeB}_${keyB}`;
            this.selectedPairs[kA] = kB;
            this.selectedPairs[kB] = kA;
            this.updateState();
        }
    }
    unPair(key = -1, type = '') {
        let pairedElement = '';
        if (key > -1 && type && this.state !== 'solved') {
            const k = `${type}_${key}`;
            if (this.selectedPairs[k] && this.selectedPairs[k] !== k) {
                pairedElement = this.selectedPairs[k];
                delete this.selectedPairs[this.selectedPairs[k]];
            }
            delete this.selectedPairs[k];
            this.updateState();
        }
        return pairedElement;
    }
    isPaired(key = -1, type = '') {
        return !!this.selectedPairs[`${type}_${key}`];
    }
    getPaired(key = -1, type = '') {
        return this.getItemDefinition(this.selectedPairs[`${type}_${key}`] || '');
    }
    updateState() {
        if (this.state !== 'solved') {
            let chosen = true;
            for (const item of this.items) {
                chosen = chosen && !!item?.key && !!this.selectedPairs[`image_${item.key}`];
            }
            if (chosen) {
                this.state = 'chosen';
            }
            else {
                this.state = '';
            }
        }
    }
    getItemDefinition(combinedKey = '') {
        if (combinedKey) {
            const pairedParts = combinedKey.split('_');
            if (pairedParts.length === 2) {
                return {
                    type: pairedParts[0],
                    key: Number(pairedParts[1])
                };
            }
        }
        return {
            key: -1,
            type: ''
        };
    }
    evaluate() {
        if (this.state === 'chosen') {
            const combinedItems = this.getFinalItemProperties();
            if (combinedItems.length > 0) {
                let nrOfCorrectItems = 0;
                for (const item of combinedItems) {
                    if (item.isCorrect) {
                        nrOfCorrectItems++;
                    }
                }
                userHandler.getUser().setScore(this.getTourCollectionKey(), this.getTourKey(), this.getTourPartIndex(), nrOfCorrectItems / combinedItems.length);
            }
        }
        this.state = 'solved';
    }
    getFinalItemProperties() {
        if (this.state !== 'solved' && this.solvedCombinedItems.length < 1) {
            const combinedItems = [];
            // combine items according to user selected pairs
            for (const key in this.selectedPairs) {
                const image = this.getItemDefinition(key);
                if (image.type == 'image') {
                    const newItem = {};
                    const text = this.getItemDefinition(this.selectedPairs[key]);
                    for (const item of this.combinedItems) {
                        if (item.text.key === text.key) {
                            newItem.text = item.text;
                        }
                        if (item.image.key === image.key) {
                            newItem.image = item.image;
                        }
                    }
                    if (newItem.text && newItem.image) {
                        newItem.isCorrect = (newItem.text.key === newItem.image.key);
                        combinedItems.push(newItem);
                    }
                }
            }
            // sort items top to bottom according their positions
            combinedItems.sort((a, b) => {
                let topA = a.image;
                if (a.image.bbox.y > a.text.bbox.y) {
                    topA = a.text;
                }
                let topB = b.image;
                if (b.image.bbox.y > b.text.bbox.y) {
                    topB = b.text;
                }
                if (topA.bbox.y > topB.bbox.y)
                    return 1;
                if (topA.bbox.y == topB.bbox.y)
                    return 0;
                return -1;
            });
            // calculate items target positions
            for (const targetIndex in combinedItems) {
                combinedItems[targetIndex].image.targetY = this.targetBoundingBoxes[targetIndex].y;
                combinedItems[targetIndex].text.targetY = this.targetBoundingBoxes[targetIndex].y;
                combinedItems[targetIndex].targetOrderKey = this.targetBoundingBoxes[targetIndex].key;
                combinedItems[targetIndex].image.targetX = this.targetBoundingBoxes[targetIndex].centerX;
                combinedItems[targetIndex].text.targetX = this.targetBoundingBoxes[targetIndex].centerX;
                if (combinedItems[targetIndex].image.bbox.x > combinedItems[targetIndex].text.bbox.x) {
                    // use image width for positioning within the x-axis to support asymmetric bbox-widths.
                    combinedItems[targetIndex].text.targetX -= combinedItems[targetIndex].image.bbox.width;
                    combinedItems[targetIndex].image.targetX += combinedItems[targetIndex].text.bbox.width - combinedItems[targetIndex].image.bbox.width;
                }
                else {
                    combinedItems[targetIndex].image.targetX -= combinedItems[targetIndex].image.bbox.width;
                }
            }
            this.solvedCombinedItems = combinedItems;
        }
        return this.solvedCombinedItems;
    }
    getNumberOfBoundingBoxes() {
        let nrOfBBoxes = 0;
        for (const item of this.combinedItems) {
            if (item.image.bbox.width) {
                nrOfBBoxes++;
            }
            if (item.text.bbox.width) {
                nrOfBBoxes++;
            }
        }
        return nrOfBBoxes;
    }
    registerBoundingBox(key, type, bbox) {
        if (key && type && bbox) {
            for (const i in this.combinedItems) {
                const item = this.combinedItems[i];
                if (Number(item[type].key) == key) {
                    item[type].bbox = bbox;
                    break;
                }
            }
        }
    }
    registerTargetBoundingBox(bbox) {
        if (bbox) {
            const newTarget = {
                y: bbox.y,
                centerX: bbox.x + Math.round(bbox.width / 2),
                key: this.targetBoundingBoxes.length
            };
            this.targetBoundingBoxes.push(newTarget);
            return newTarget;
        }
        return {
            y: 0,
            centerX: 0,
            key: -1
        };
    }
    sortTargetBoundingBoxes() {
        this.targetBoundingBoxes.sort((a, b) => {
            if (a.y > b.y)
                return 1;
            if (a.y === b.y)
                return 0;
            return -1;
        });
    }
    calculateBoundingBoxOverlapping(key, type) {
        if (key && type) {
            let element = {};
            for (const item of this.combinedItems) {
                if (item[type].key === key) {
                    element = item[type];
                    break;
                }
            }
            if (element) {
                const candidates = [];
                for (const baseItem of this.combinedItems) {
                    for (const baseType in baseItem) {
                        const item = baseItem[baseType];
                        if (baseType !== type) {
                            const overlap = this.getOverlap(element.bbox, item.bbox);
                            if (overlap.area > 0) {
                                candidates.push({
                                    key: item.key,
                                    associatedKey: baseItem[type].key,
                                    type: baseType,
                                    clipA: '',
                                    clipB: '',
                                    overlap,
                                    previouslyPairedElement: {
                                        key: -1,
                                        type: ''
                                    }
                                });
                            }
                        }
                    }
                }
                if (candidates.length > 0) {
                    candidates.sort((a, b) => {
                        if (a.overlap.area < b.overlap.area)
                            return 1;
                        if (a.overlap.area == b.overlap.area)
                            return 0;
                        return -1;
                    });
                    const bestCandidate = candidates[0];
                    this.unPair(key, type);
                    const previouslyPairedElement = this.unPair(bestCandidate.key, bestCandidate.type);
                    if (previouslyPairedElement) {
                        bestCandidate.previouslyPairedElement = this.getItemDefinition(previouslyPairedElement);
                    }
                    this.pair(key, type, bestCandidate.key);
                    this.calculateClipPaths(bestCandidate);
                    return bestCandidate;
                }
                else {
                    this.unPair(key, type);
                }
            }
        }
    }
    calculateClipPaths(candidate) {
        if (candidate) {
            const overlap = candidate.overlap;
            // position top-left to bottom-left clockwise 00, 01, 11, 10
            if (overlap.position === '00' || overlap.position === '11') {
                const clipA = `0% 0%, 100% 0%, 100% ${overlap.delta.y}px, ${overlap.delta.x}px 100%, 0% 100%`;
                const clipB = `0% ${overlap.height}px, ${overlap.width}px 0%, 100% 0%, 100% 100%, 0% 100%`;
                if (overlap.position === '00') {
                    candidate.clipA = clipA;
                    candidate.clipB = clipB;
                }
                else {
                    candidate.clipA = clipB;
                    candidate.clipB = clipA;
                }
            }
            else {
                const clipA = `0% ${overlap.delta.y}px, 0% 0%, 100% 0%, 100% 100%, ${overlap.width}px 100%`;
                const clipB = `0% 0%, ${overlap.delta.x}px 0%, 100% ${overlap.height}px, 100% 100%, 0% 100%`;
                if (overlap.position === '10') {
                    candidate.clipA = clipA;
                    candidate.clipB = clipB;
                }
                else {
                    candidate.clipA = clipB;
                    candidate.clipB = clipA;
                }
            }
        }
    }
    getOverlap(a, b) {
        let left = a;
        let right = b;
        let top = a;
        let bottom = b;
        let switchHorizontal = 0;
        let switchVertical = 0;
        if (left.x > right.x) {
            left = b;
            right = a;
            switchHorizontal = 1;
        }
        if (top.y > bottom.y) {
            top = b;
            bottom = a;
            switchVertical = 1;
        }
        const delta = {
            x: right.x - left.x,
            y: bottom.y - top.y
        };
        if (delta.x > 0 && delta.x < left.width && delta.y > 0 && delta.y < top.height) {
            const overlap = {
                x: left.x + delta.x,
                y: top.y + delta.y,
                width: left.width - delta.x,
                height: top.height - delta.y,
                delta,
                area: 0,
                position: `${switchHorizontal}${switchVertical}`
            };
            overlap.area = overlap.width * overlap.height;
            return overlap;
        }
        return {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            delta: {
                x: 0,
                y: 0,
            },
            area: 0,
            position: ''
        };
    }
}
export class ChallengeItemPairImageText extends BaseChallengeItem {
    media;
    text;
    constructor(text, imageName, imageDescription = '', imageMeta = {}) {
        super('challenge');
        this.media = new MediaImage(imageName, undefined, imageDescription, imageMeta);
        this.text = text;
    }
}
