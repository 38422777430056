import { defineComponent } from 'vue';
import { IonContent } from '@ionic/vue';
import router from '@/router';
import Wave from '@/components/core/Wave.vue';
import Button from '@/components/core/Button.vue';
import pageHandler from "@/data/pageHandler";
import userHandler from "@/data/userHandler";
export default defineComponent({
    name: 'Content',
    components: {
        IonContent,
        Wave,
        Button
    },
    props: {
        waveType: {
            type: Number,
            default: -1
        },
        waveColor: {
            type: String,
            default: '#2f728f'
        },
        collapsable: {
            type: Boolean,
            default: false
        },
        centeredBottom: {
            type: Boolean,
            default: false
        },
        preventForwardButton: {
            type: Boolean,
            default: false
        },
        forwardUrl: {
            type: String,
            default: ""
        },
        scrollable: {
            type: Boolean,
            default: false
        },
    },
    data() {
        let collapsableState = '';
        if (this.collapsable) {
            collapsableState = 'atBottom';
        }
        return {
            waveTypeMain: this.waveType < 0 ? 'intro' : 'wave',
            waveTypeSub: this.waveType,
            collapsableState
        };
    },
    computed: {
        containerClasses() {
            const containerClasses = ['container'];
            if (this.collapsable) {
                containerClasses.push('collapsable');
                containerClasses.push(this.collapsableState);
            }
            if (this.scrollable) {
                containerClasses.push('scrollable');
            }
            return containerClasses.join(' ');
        },
        bottomStyle() {
            const style = {};
            if (this.centeredBottom) {
                style['align-items'] = 'center';
            }
            return style;
        },
        containerStyle() {
            const style = {};
            style['--wave-color'] = this.waveColor;
            return style;
        }
    },
    methods: {
        onWaveClick() {
            if (this.waveTypeMain === 'intro') {
                if (userHandler.getUser().getName()) {
                    router.push('/main/tourCollectionSelection');
                }
                else {
                    router.push('/main/login');
                }
            }
        },
        onWaveCollapsableButtonClick() {
            if (this.collapsable) {
                if (this.collapsableState === 'atBottom') {
                    pageHandler.update(undefined, true, true);
                    this.collapsableState = 'atTop';
                }
                else {
                    pageHandler.update(undefined, true, false);
                    this.collapsableState = 'atBottom';
                }
            }
        },
        onForwardButtonClick() {
            if (this.forwardUrl) {
                router.push(this.forwardUrl);
            }
        }
    }
});
