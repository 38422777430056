import { defineComponent } from "vue";
import { tourHandler } from "@/data/tourHandler";
import LayoutsMain from "@/components/layouts/Main.vue";
import Headline from "@/components/core/Headline.vue";
import Description from "@/components/core/Description.vue";
import router from "@/router";
import Button from "@/components/core/Button.vue";
import pageHandler from "@/data/pageHandler";
import pageHookMixin from "@/data/pageHookMixin";
export default defineComponent({
    name: 'ViewTourCollectionSelection',
    mixins: [pageHookMixin],
    components: { LayoutsMain, Headline, Description, Button },
    setup() {
        return {
            tourCollections: tourHandler.getTourCollections()
        };
    },
    data() {
        this.prepare();
        return {
            logos: {}
        };
    },
    methods: {
        async prepare() {
            for (const tourCollection of this.tourCollections || []) {
                this.logos[tourCollection.key] = await tourCollection.logo.getData();
            }
        },
        startTourCollection(tourCollection) {
            router.push(tourCollection.getWelcomePath());
        }
    },
    ionViewWillEnter() {
        pageHandler.emit('beforePageEnter');
    },
    ionViewDidEnter() {
        pageHandler.emit('pageEnter');
    },
});
