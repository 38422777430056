import { markRaw } from "vue";
import { Tour, TourPart } from "@/data/tourHandlerCore";
import { PUZZLE_PIECE_ELEMENTS } from "@/lib/constants";
import { ChallengeItemMultiSelectImage, ChallengeItemMultiSelectText, ChallengeMultiSelectImageText } from "@/data/challenges/challengeMultiSelectImageText";
import { ChallengeItemPickImage, ChallengePickImage } from "@/data/challenges/challengePickImage";
import { ChallengeItemPlaceOnAxis, ChallengePlaceOnAxis } from "@/data/challenges/challengePlaceOnAxis";
import { ChallengeItemPickTextBySwipe, ChallengePickTextBySwipe } from "@/data/challenges/challengePickTextBySwipe";
import { ChallengeItemPlaceImageOnText, ChallengePlaceImageOnText } from "@/data/challenges/challengePlaceImageOnText";
import { ChallengeItemPlaceImageOnImage, ChallengePlaceImageOnImage } from "@/data/challenges/challengePlaceImageOnImage";
export default function (tourCollectionKey) {
    const tour = new Tour(tourCollectionKey, 'E', 'Jetzt geht\'s um die Wurst', true, '', true);
    tour.addPart(new TourPart('', '', 'Das Museum in Bürmoos bewahrt unterschiedliche <b>Dinge aus der Vergangenheit</b> auf. Wir nennen diese Dinge auch <b>Quellen</b>.<br><br>Heute können wir sie ansehen und mit ihnen herausfinden, wie es früher gewesen sein könnte.'));
    tour.addPart(new TourPart('', 'Ziehe das Bild der Quelle zur richtigen Beschreibung!<br><br>Tippe auf ein Bild, um es zu vergrößern. Dort findest du wichtige Hinweise.', '', false, new ChallengePlaceImageOnText([
        new ChallengeItemPlaceImageOnText('Dieser Glasgegenstand für zu Hause wurde von Arbeitern in der Freizeit hergestellt.', 'bm_glaskrug.png', 'Glaskrug, Bürmoos, um 1920'),
        new ChallengeItemPlaceImageOnText('Mit dieser Pferdekutsche fuhr der Besitzer der Fabrik zu den Arbeitsstätten.', 'bm_pferdekutsche_ignaz_glaser.jpg', 'Foto, Fabriksbesitzer Ignaz Glaser, Bürmoos, um 1900'),
        new ChallengeItemPlaceImageOnText('Gruppenfoto von Arbeitern aus der Glasfabrik vor 110 Jahren.', 'bm_glasarbeiter_gruppe.jpg', 'Foto, Glasarbeiter, Bürmoos, 1910'),
        new ChallengeItemPlaceImageOnText('Mit diesem Werkzeug wurde Glas hergestellt. Ein Arbeiter blies dazu Luft durch das Rohr und formte die Glasmasse.', 'bm_glasblaeserpfeife.png', 'Glasbläserpfeife, Blasrohr für die Glasherstellung, Bürmoos, um 1900'),
    ])));
    tour.addPart(new TourPart('', 'Was können wir aus dieser Quelle über die Vergangenheit erfahren?<br><br>Tippe auf ein Bild, um es zu vergrößern. Dort findest du wichtige Hinweise.', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Der Fabriksbesitzer Ignaz Glaser hatte vor 120 Jahren eine Kutsche mit zwei Pferden.', true),
        new ChallengeItemMultiSelectText('Der Fabriksbesitzer Ignaz Glaser hatte vor 120 Jahren schon ein Automobil.', false),
        new ChallengeItemMultiSelectText('Der Fabriksbesitzer Ignaz Glaser ist vor 120 Jahren immer zu Fuß gegangen.', false),
    ], [
        new ChallengeItemMultiSelectImage('bm_pferdekutsche_ignaz_glaser.jpg', undefined, 'Foto, Fabriksbesitzer Ignaz Glaser, Bürmoos, um 1900'),
    ], true, true, 'slim')));
    tour.addPart(new TourPart('', 'Was können wir aus dieser Quelle über die Vergangenheit erfahren?', '', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('In der Glasfabrik arbeiteten vor 110 Jahren auch Frauen als Glasarbeiter.', false),
        new ChallengeItemMultiSelectText('In der Glasfabrik arbeiteten vor 110 Jahren auch Jugendliche und Kinder.', true),
        new ChallengeItemMultiSelectText('In der Glasfabrik arbeiteten vor 110 Jahren auch Tiere.', false),
    ], [
        new ChallengeItemMultiSelectImage('bm_glasarbeiter_gruppe.jpg', undefined, 'Foto, Glasarbeiter, Bürmoos, 1910'),
    ], true, true, 'slim')));
    tour.addPart(new TourPart('', 'Was können wir aus dieser Quelle über die Vergangenheit erfahren?', '', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('In Bürmoos wurden vor 100 Jahren auch Glasgegenstände für zu Hause hergestellt.', true),
        new ChallengeItemMultiSelectText('In Bürmoos wurden vor 100 Jahren auch Glasmurmeln hergestellt.', false),
        new ChallengeItemMultiSelectText('In Bürmoos wurden vor 100 Jahren auch Brillen hergestellt.', false),
    ], [
        new ChallengeItemMultiSelectImage('bm_glaskrug.png', undefined, 'Glaskrug, Bürmoos, um 1920, hergestellt in Bürmoos.'),
    ], true, true, 'slim')));
    tour.addPart(new TourPart('', 'Passt die Geschichte zu den Quellen? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Vor 140 Jahren wurden in Bürmoos Fensterglas und später viele Ziegelsteine hergestellt. Als vor 125 Jahren eine Eisenbahnlinie nach Bürmoos kam, konnten die Produkte der Glas- und Ziegelfabrik nun schneller ausgeliefert werden.', true, [
        { name: 'bm_glaskrug.png', description: 'Glaskrug, Bürmoos, um 1920' },
        { name: 'bm_pferdekutsche_ignaz_glaser.jpg', description: 'Foto, Fabriksbesitzer Ignaz Glaser, Bürmoos, um 1900' },
        { name: 'bm_glasarbeiter_gruppe.jpg', description: 'Foto, Glasarbeiter, Bürmoos, 1910' },
        { name: 'bm_glasblaeserpfeife.png', description: 'Glasbläserpfeife, Blasrohr für die Glasherstellung, Bürmoos, um 1900' },
    ]), 'passt nicht', 'passt', 'slim'), false));
    tour.addPart(new TourPart('', 'Passt die Geschichte zu den Quellen? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('In Bürmoos gab es vor 120 Jahren ganz unterschiedliche Fabriken. Neben dem Abbau von Torferde, wurden auch Fensterglas und Ziegelsteine hergestellt. Die Arbeiter arbeiteten den ganzen Tag lang hart, um Geld für ihre Familien zu verdienen.', true, [
        { name: 'bm_glaskrug.png', description: 'Glaskrug, Bürmoos, um 1920' },
        { name: 'bm_pferdekutsche_ignaz_glaser.jpg', description: 'Foto, Fabriksbesitzer Ignaz Glaser, Bürmoos, um 1900' },
        { name: 'bm_glasarbeiter_gruppe.jpg', description: 'Foto, Glasarbeiter, Bürmoos, 1910' },
        { name: 'bm_glasblaeserpfeife.png', description: 'Glasbläserpfeife, Blasrohr für die Glasherstellung, Bürmoos, um 1900' },
    ]), 'passt nicht', 'passt', 'slim'), false));
    tour.addPart(new TourPart('', 'Passt die Geschichte zu den Quellen? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Die Glasfabrik in Bürmoos war vor 120 Jahren sehr bekannt. Viele Glasarbeiter arbeiteten in der Fabrik. Mit einem Blasrohr, der „Glasbläserpfeife“, formten die Glasbläser Fensterglas und Glasgegenstände. Der Fabriksbesitzer war damals Ignaz Glaser. Er war ein reicher Mann.', false, [
        { name: 'bm_glaskrug.png', description: 'Glaskrug, Bürmoos, um 1920' },
        { name: 'bm_pferdekutsche_ignaz_glaser.jpg', description: 'Foto, Fabriksbesitzer Ignaz Glaser, Bürmoos, um 1900' },
        { name: 'bm_glasarbeiter_gruppe.jpg', description: 'Foto, Glasarbeiter, Bürmoos, 1910' },
        { name: 'bm_glasblaeserpfeife.png', description: 'Glasbläserpfeife, Blasrohr für die Glasherstellung, Bürmoos, um 1900' },
    ]), 'passt nicht', 'passt', 'slim'), false));
    tour.addPart(new TourPart('', 'Passt die Geschichte zu den Quellen? Wische!', '', false, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Bürmoos veränderte sich in den letzten 150 Jahren stark. Zuerst war das Gebiet eine Moorlandschaft. Mit dem Torfabbau kamen viele Menschen. Ein kleiner Ort entstand. Immer mehr Fabriken, Häuser und Geschäfte folgten. Heute leben in Bürmoos ca. 5.000 Menschen.', true, [
        { name: 'bm_glaskrug.png', description: 'Glaskrug, Bürmoos, um 1920' },
        { name: 'bm_pferdekutsche_ignaz_glaser.jpg', description: 'Foto, Fabriksbesitzer Ignaz Glaser, Bürmoos, um 1900' },
        { name: 'bm_glasarbeiter_gruppe.jpg', description: 'Foto, Glasarbeiter, Bürmoos, 1910' },
        { name: 'bm_glasblaeserpfeife.png', description: 'Glasbläserpfeife, Blasrohr für die Glasherstellung, Bürmoos, um 1900' },
    ]), 'passt nicht', 'passt', 'slim')));
    tour.addPart(new TourPart('', 'In welcher Quelle kannst du die Antwort auf die Frage finden? Zieh die richtige Quelle auf die Frage.', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('bm_glasblaeserpfeife.png', 'Glasbläserpfeife, Blasrohr für die Glasherstellung, Bürmoos, um 1900', false),
        new ChallengeItemPickImage('bm_glasarbeiter_gruppe.jpg', 'Foto, Glasarbeiter, Bürmoos, 1910', false),
        new ChallengeItemPickImage('bm_pferdekutsche_ignaz_glaser.jpg', 'Foto, Fabriksbesitzer Ignaz Glaser, Bürmoos, um 1900', true),
    ], 'Wie schaute die Kutsche aus, die dem Fabriksbesitzer Ignaz Glaser vor 120 Jahren gehörte?', 'slim')));
    tour.addPart(new TourPart('', 'In welcher Quelle kannst du die Antwort auf die Frage finden? Zieh die richtige Quelle auf die Frage.', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('bm_glasblaeserpfeife.png', 'Glasbläserpfeife, Blasrohr für die Glasherstellung, Bürmoos, um 1900', false),
        new ChallengeItemPickImage('bm_glasarbeiter_gruppe.jpg', 'Foto, Glasarbeiter, Bürmoos, 1910', true),
        new ChallengeItemPickImage('bm_pferdekutsche_ignaz_glaser.jpg', 'Foto, Fabriksbesitzer Ignaz Glaser, Bürmoos, um 1900', false),
    ], 'Wie waren Glasarbeiter vor 110 Jahren gekleidet?', 'slim')));
    tour.addPart(new TourPart('', 'In welcher Quelle kannst du die Antwort auf die Frage finden? Zieh die richtige Quelle auf die Frage.', '', false, new ChallengePickImage([
        new ChallengeItemPickImage('bm_glasblaeserpfeife.png', 'Glasbläserpfeife, Blasrohr für die Glasherstellung, Bürmoos, um 1900', true),
        new ChallengeItemPickImage('bm_glasarbeiter_gruppe.jpg', 'Foto, Glasarbeiter, Bürmoos, 1910', false),
        new ChallengeItemPickImage('bm_pferdekutsche_ignaz_glaser.jpg', 'Foto, Fabriksbesitzer Ignaz Glaser, Bürmoos, um 1900', false),
    ], 'Mit welchem Werkzeug wurde vor 110 Jahren Glas hergestellt?\n', 'slim')));
    tour.addPart(new TourPart('', '', 'Eine Künstlerin hat versucht, einen römischen Soldaten möglichst richtig zu malen.', true, new ChallengePlaceImageOnImage('bm_roemer-rekonstruktion.png', 1000, 2100, [], true)));
    tour.addPart(new TourPart('', 'Welche Teile des Bildes konnte die Zeichnerin mit Quellen als Vorlage gut zeichnen?<br>Ziehe die Quellen an die richtigen Stellen!', '', false, new ChallengePlaceImageOnImage('bm_roemer-rekonstruktion.png', 1000, 2100, [
        new ChallengeItemPlaceImageOnImage({ x: 370, y: 276 }, 'grave'),
        new ChallengeItemPlaceImageOnImage({ x: 930, y: 359 }, 'spear', 'bm_roemer-rekonstruktion-speer-spitze.png', 'Römische Speerspitzen, 1.-4. Jh. n. Chr.', {
            isDark: true,
            copyright: {
                name: 'N. Gail | Landessammlungen NÖ, Archäologischer Park Carnuntum',
                license: '',
                symbol: 'c',
                url: 'https://www.online.landessammlungen-noe.at/objects/1303802/lanzenspeerspitze'
            }
        }),
        new ChallengeItemPlaceImageOnImage({ x: 412, y: 831 }, 'grave', 'bm_roemer-rekonstruktion-grabstele-titus-calidius.png', 'Römisches Grabmal, 1. Jh. n. Chr., Fundort: Österreich', {
            copyright: {
                name: 'Mathias Kabel',
                license: 'CC BY 2.5',
                symbol: 'cc',
                url: 'https://commons.wikimedia.org/wiki/File:Grabstein_Titus_Calidius_Carnuntum.jpg'
            }
        }),
        new ChallengeItemPlaceImageOnImage({ x: 244, y: 1262 }, 'sword', 'bm_roemer-rekonstruktion-klinge.png', 'Römische Klinge, 1.-2. Jh. n. Chr.', {
            isDark: true,
            copyright: {
                name: 'N. Gail | Landessammlungen NÖ, Archäologischer Park Carnuntum',
                license: '',
                symbol: 'c',
                url: 'https://www.online.landessammlungen-noe.at/objects/1303394/klinge'
            }
        }),
        new ChallengeItemPlaceImageOnImage({ x: 276, y: 1786 }, 'grave'), // shinpad
    ]), false));
    tour.addPart(new TourPart('', 'Hör dir das an ...', 'Ist das ein Märchen oder geht es um Geschichte?', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Das ist ein Märchen.', false),
        new ChallengeItemMultiSelectText('Das ist Geschichte.', true),
    ], [], true, true), false, false, 'bm_e_1_geschichte.m4a'));
    tour.addPart(new TourPart('', 'Hör dir das zweite Beispiel an ...', 'Ist das ein Märchen oder geht es um Geschichte?', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Das ist ein Märchen.', true),
        new ChallengeItemMultiSelectText('Das ist Geschichte.', false),
    ], [], true, true), false, false, 'bm_e_2_maerchen.m4a'));
    tour.addPart(new TourPart('', 'Hör dir das dritte Beispiel an ...', 'Ist das ein Märchen oder geht es um Geschichte?', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Das ist ein Märchen.', false),
        new ChallengeItemMultiSelectText('Das ist Geschichte.', true),
    ], [], true, true), false, false, 'bm_e_3_geschichte.m4a'));
    tour.addPart(new TourPart('', 'Das ist ein Foto aus Bürmoos, das über 90 Jahre alt ist. Es zeigt die Kaufleute aus dem Ort.<br><br>Welche Fragen kann man mit dem Foto beantworten?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Wie lebten die Kinder von Torfarbeitern?', true, [
        { name: 'bm_kaufhausbesitzerfamilie.jpg', description: 'Foto, Kaufhausbesitzerfamilie, Bürmoos 1930' },
    ]), 'unmöglich', 'möglich')));
    tour.addPart(new TourPart('', 'Das ist ein Foto aus Bürmoos, das über 90 Jahre alt ist. Es zeigt die Kaufleute aus dem Ort.<br><br>Welche Fragen kann man mit dem Foto beantworten?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Wie wurden in Bürmoos Ziegel hergestellt?', true, [
        { name: 'bm_kaufhausbesitzerfamilie.jpg', description: 'Foto, Kaufhausbesitzerfamilie, Bürmoos 1930' },
    ]), 'unmöglich', 'möglich')));
    tour.addPart(new TourPart('', 'Das ist ein Foto aus Bürmoos, das über 90 Jahre alt ist. Es zeigt die Kaufleute aus dem Ort.<br><br>Welche Fragen kann man mit dem Foto beantworten?', '', false, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Welche Kleidung trugen Kaufleute damals?', false, [
        { name: 'bm_kaufhausbesitzerfamilie.jpg', description: 'Foto, Kaufhausbesitzerfamilie, Bürmoos 1930' },
    ]), 'unmöglich', 'möglich')));
    tour.addPart(new TourPart('', 'Die lange Geschichte der Menschen wird in <b>Abschnitte</b> eingeteilt.<br><br>Hilf mit, die Abschnitte in die richtige Reihenfolge zu bringen.', '', true, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis('', .5 * (1.3 / 4), undefined, undefined, false, false, true, 'Steinzeit', '', '', PUZZLE_PIECE_ELEMENTS[0]),
        new ChallengeItemPlaceOnAxis('Jahr 0', (1.5 * (1.3 / 4)) - .1, undefined, undefined, true, false, true, 'Altertum', '', '', PUZZLE_PIECE_ELEMENTS[1]),
        new ChallengeItemPlaceOnAxis('', (2.5 * (1.3 / 4)) - .2, undefined, undefined, false, false, true, 'Mittelalter', '', '', PUZZLE_PIECE_ELEMENTS[2]),
        new ChallengeItemPlaceOnAxis('heute', (3.5 * (1.3 / 4)) - .3, undefined, undefined, true, false, true, 'Neuzeit', '', '', PUZZLE_PIECE_ELEMENTS[3]),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false, false),
    ], 1 / 20, true, true, true, 0, 0, true), false, true, 'xx_geschichte_der_menschen.m4a'));
    tour.addPart(new TourPart('', 'Ordne die Quellen ihren richtigen Abschnitten zu.<br><br>Aus welchem Zeitabschnitt sind die Quellen aus dem Museum?', '', false, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis('', .5 * (1.3 / 4), undefined, undefined, false, false, false, 'Steinzeit', '', '', PUZZLE_PIECE_ELEMENTS[0]),
        new ChallengeItemPlaceOnAxis('Jahr 0', (1.5 * (1.3 / 4)) - .1, undefined, undefined, true, false, true, 'Altertum', 'bm_roemischer_ziegel.png', 'Römischer Ziegel, Carnuntum, 100-400 n. Chr., ca. 1800 Jahre alt', PUZZLE_PIECE_ELEMENTS[1]),
        new ChallengeItemPlaceOnAxis('', (2.5 * (1.3 / 4)) - .2, undefined, undefined, false, false, false, 'Mittelalter', '', '', PUZZLE_PIECE_ELEMENTS[2]),
        new ChallengeItemPlaceOnAxis('heute', (3.5 * (1.3 / 4)) - .3, undefined, undefined, true, false, true, 'Neuzeit', 'bm_trinkglas.png', 'Bemalenes Glas, Deutschland, vor 1900, ca. 130 Jahre alt', PUZZLE_PIECE_ELEMENTS[3]),
        new ChallengeItemPlaceOnAxis('', (3.5 * (1.3 / 4)) - .3, undefined, undefined, false, false, true, '', 'bm_holzschuhe.png', 'Selbstgemachte Holzschuhe, Bürmoos, um 1930, ca. 100 Jahre alt', PUZZLE_PIECE_ELEMENTS[3]),
        new ChallengeItemPlaceOnAxis('', (3.5 * (1.3 / 4)) - .3, undefined, undefined, false, false, true, '', 'bm_schulweg.jpg', 'Foto, Kinder am Schulweg, Bürmoos, um 1930, ca. 100 Jahre alt', PUZZLE_PIECE_ELEMENTS[3]),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false, false),
    ], 1 / 20, true, true, true, 0, 0, true, true, false, false, true, false)));
    tour.addPart(new TourPart('', 'Ein Blick ins Torf-Glas-Ziegel-Museum in Bürmoos ...', 'Das Gebiet, wo heute Bürmoos ist, war vor 220 Jahren eine Moorlandschaft und nicht besiedelt. Das Museum in Bürmoos erzählt von der Entwicklung des Ortes, die vor 170 Jahren mit dem Abbau von Torf begann. Später kam die Herstellung von Glas und Ziegeln dazu. Die im Museum dargestellte Geschichte zeigt auch, <b>wie die Menschen</b> damals begannen, sich hier anzusiedeln und zu arbeiten.<br><br>Da dieses Gebiet damals nicht besiedelt war, findet man in unserem Museum auch <b>keine Gegenstände aus dem Mittelalter</b>. Wir zeigen im Museum zwar einige Ausstellungsstücke, die viel älter sind, doch die meisten sind aus der <b>Neuzeit</b> und zwischen 150 und 50 Jahre alt.', true, undefined, false, false, '', { name: 'bm_zeitabschnitte.mp4', portrait: false }, undefined, undefined, true));
    tour.addPart(new TourPart('', '', 'Aus welchem Zeitabschnitt besitzt das Museum die meisten Gegenstände?<br><br>Wenn du möchtest, kannst du dir hier das Video gern nochmal anschauen.', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Steinzeit', false),
        new ChallengeItemMultiSelectText('Altertum', false),
        new ChallengeItemMultiSelectText('Mittelalter', false),
        new ChallengeItemMultiSelectText('Neuzeit', true),
    ], [], true, true, undefined, false), false, false, '', { name: 'bm_zeitabschnitte.mp4', portrait: false }));
    tour.addPart(new TourPart('', 'Warum erzählt das Museum in Bürmoos über diese Zeit? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Weil es dazu Dinge aus der Vergangenheit gesammelt hat.', false), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Warum erzählt das Museum in Bürmoos über diese Zeit? Wische!', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('... weil es keine andere Geschichte erzählen kann.', true), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Warum erzählt das Museum in Bürmoos vor allem über das Leben und Arbeiten in Bürmoos? Wische!', '', false, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('... weil es nur einen Teil aus der Vergangenheit erzählen kann.', false), 'falsch', 'richtig')));
    return markRaw(tour);
}
