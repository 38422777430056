import { markRaw } from "vue";
import { Tour, TourPart } from "@/data/tourHandlerCore";
import { YEAR } from "@/lib/constants";
import { ChallengeItemMultiSelectText, ChallengeMultiSelectImageText } from "@/data/challenges/challengeMultiSelectImageText";
import { ChallengeItemPairImageText, ChallengePairImageText } from "@/data/challenges/challengePairImageText";
import { ChallengeItemTimelineText, ChallengeTimeline } from "@/data/challenges/challengeTimeline";
import { ChallengeItemPlaceOnAxis, ChallengePlaceOnAxis } from "@/data/challenges/challengePlaceOnAxis";
import { ChallengeItemPickDropZoneImage, ChallengeItemPickDropZoneText, ChallengePickDropZone } from "@/data/challenges/challengePickDropZone";
export default function (tourCollectionKey) {
    const tour = new Tour(tourCollectionKey, 'C', 'Schnüffeltour 3', false, '', true);
    tour.addPart(new TourPart('', 'Hör dir an, was Lorenz über seinen Besuch im Museum Bramberg zu berichten hat!', 'Letztes Jahr besuchte ich mit meiner Klasse das Museum in Bramberg. Anna und mir hat es dort so gut gefallen. Lustig fand ich, dass die alten Holztreppen im Museum so knarrten. Und dann die vielen bunten, glitzernden Steine aus den Bergen. Sie waren so schön. Am besten haben mir aber die Ausstellungsstücke zu den Bienen gefallen. Da konnte man einen Kasten öffnen und in einen echten Bienenstock hineinschauen. Früher gab es ja fast nur Honig zum Süßen von Speisen. Das wusste ich noch gar nicht.<br><br>Wenn man die Vergangenheit mit heute vergleicht, dann kann man gut erkennen, was sich so alles verändert hat.', true, null, false, false, 'bb_lorenz_museumsbesuch.m4a', undefined, undefined, undefined, true));
    tour.addPart(new TourPart('', 'Lorenz hat heute seinen siebten Geburtstag. Genau vor einem Jahr war er im Museum in Bramberg.<br><br>In welchem Jahr fanden die anderen Ereignisse statt? Jetzt musst du auch ein bisschen rechnen.', '', true, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 8), (3.5 + 0 * 15 / 8) / 22, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 7), (3.5 + 1 * 15 / 8) / 22, undefined, undefined, true, false, true, 'Geburt von Lorenz'),
        new ChallengeItemPlaceOnAxis(String(YEAR - 6), (3.5 + 2 * 15 / 8) / 22, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 5), (3.5 + 3 * 15 / 8) / 22, undefined, undefined, true, false, true, '2. Geburtstag von Lorenz'),
        new ChallengeItemPlaceOnAxis(String(YEAR - 4), (3.5 + 4 * 15 / 8) / 22, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 3), (3.5 + 5 * 15 / 8) / 22, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 2), (3.5 + 6 * 15 / 8) / 22, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 1), (3.5 + 7 * 15 / 8) / 22, undefined, undefined, true, false, true, 'Museums-Besuch von Lorenz'),
        new ChallengeItemPlaceOnAxis(String(YEAR), (3.5 + 8 * 15 / 8) / 22, undefined, undefined, true, true, true, '7. Geburtstag von Lorenz'),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false, false),
    ], 1 / 22, true, true, true, 0, 0, false, false, false, false, false, false)));
    tour.addPart(new TourPart('', '', 'Lorenz feiert heute seinen siebten Geburtstag.<br><br>Welches der drei Ereignisse liegt für Lorenz nicht in der Vergangenheit?', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Museumsbesuch', false),
        new ChallengeItemMultiSelectText('Geburt', false),
        new ChallengeItemMultiSelectText('7. Geburtstag', true)
    ], undefined, true, true)));
    tour.addPart(new TourPart('', 'Das Museum Bramberg erzählt auch Geschichten. Dort wird ebenso etwas über die Vergangenheit erzählt.', 'Schon vor 3.000 Jahren lebten Menschen in Bramberg. Man weiß das, weil man auf einer Baustelle im Ort Gräber von den Menschen von damals gefunden hat. Die Toten wurden verbrannt und ihre Asche oft in großen Tontöpfen bestattet.<br><br>Die Kleidung der Menschen war damals ganz anders. Es gab keine Reißverschlüsse oder Knöpfe. Die Kleider wurden mit Gürteln und Nadeln zusammengehalten. Auch Messer hat man gefunden. Sie waren noch nicht aus Eisen. Man verwendete Bronze. Man wusste damals ja noch nicht, wie man Eisen herstellt.', true, undefined, false, false, 'bb_geschichte_brambergs.m4a'));
    tour.addPart(new TourPart('', 'Vor 3.000 Jahren lebten schon Menschen in Bramberg.<br><br>Lorenz ist heute 7 Jahre alt geworden.<br><br>Wo am Zeitstrahl müssen seine sieben Lebensjahre eingeordnet werden? Ziehe!', '', true, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis('vor 3000 Jahren', 2 / 21, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('', 10.5 / 21, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('', 18 / 21, .5 / 21, 2 / 21, false, false, true, 'Lebens-abschnitt von Lorenz'),
        new ChallengeItemPlaceOnAxis('heute', 19 / 21, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false, false),
    ], 1 / 21, true, true, true)));
    tour.addPart(new TourPart('', 'Wo liegen die Zeitabschnitte? Ziehe!', '', true, new ChallengeTimeline([
        new ChallengeItemTimelineText('Vergangenheit', 'gestern'),
        new ChallengeItemTimelineText('Jetztzeit /<br>Gegenwart', 'heute'),
        new ChallengeItemTimelineText('Zukunft', 'morgen'),
    ])));
    tour.addPart(new TourPart('', '', 'Erzählt man in einem Museum über die Vergangenheit, nennt man das Geschichte.<br><br>Was braucht ein Museum dazu, um das gut zu machen?', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Wissen über die Zukunft', false),
        new ChallengeItemMultiSelectText('Dinge von früher', true),
        new ChallengeItemMultiSelectText('Fantasie', false)
    ], undefined, true, true)));
    tour.addPart(new TourPart('', '', 'Erzählt man in einem Museum über die Vergangenheit, nennt man das Geschichte.<br><br>Was braucht ein Museum dazu, um das gut zu machen?', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Dinge aus dem Supermarkt', false),
        new ChallengeItemMultiSelectText('alte Aufzeichnungen', true),
        new ChallengeItemMultiSelectText('Fotos von heute', false)
    ], undefined, true, true)));
    tour.addPart(new TourPart('', '', 'Es gibt unterschiedliche Museen. Manche Museen beschäftigen sich mit <b>Kunst</b>, andere mit <b>Technik</b> oder <b>Natur</b>, wieder andere mit <b>Geschichte</b>.<br><br>In Museen finden sich unterschiedliche Dinge.', true));
    tour.addPart(new TourPart('Finde die richtigen Paare!', 'Ziehe Bild und Erklärung zusammen.', '', false, new ChallengePairImageText([
        new ChallengeItemPairImageText('Kunst-<br>museum', 'bm_kunstmuseum_personen.jpg'),
        new ChallengeItemPairImageText('Naturkunde-<br>museum', 'bm_naturkundemuseum_muscheln.jpg'),
        new ChallengeItemPairImageText('Geschichts-<br>museum', 'bm_geschichtsmuseum_ruestungen.jpg'),
    ])));
    tour.addPart(new TourPart('', 'Das Museum Bramberg, ein besonderes Museum ...', 'Das Museum Bramberg ist ein <b>Naturkundemuseum</b> und ein <b>Geschichtsmuseum</b>.<br><br>Es zeigt also einerseits die verschiedenen <b>Mineralien und Kristalle</b> aus <b>der Umgebung</b>, andererseits wird auch das <b>Leben von früher</b> rund um Bramberg gezeigt, wie man es sich heute vorstellt.<br><br>Das Museum Bramberg beschäftigt sich also mit Natur und der Geschichte.', true, undefined, false, false, '', { name: 'bb_museum_intro.mp4', portrait: false }));
    tour.addPart(new TourPart('', '', 'Was wird also vom Museum Bramberg gezeigt?<br><br>Markiere <b>alle</b> richtigen Antworten!', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Kunst', false),
        new ChallengeItemMultiSelectText('Geschichte', true),
        new ChallengeItemMultiSelectText('Natur', true)
    ], undefined, false, true)));
    tour.addPart(new TourPart('', '', 'Die Dinge aus dem nächsten Spiel stammen aus dem Museum Bramberg.<br><br>Sie helfen uns zu erkennen, dass es früher anders war.<br><br>Manche Dinge zeigen im Vergleich zu heute, dass sie sich verändert haben. Andere Dinge gibt es gar nicht mehr.', true));
    tour.addPart(new TourPart('', 'Ist das Ding aus unserem Alltag verschwunden? Oder wird das Ding heute noch in einer veränderten Form genützt?<br><br>Tippe auf das Bild, um es zu vergrößern.<br><br>Ziehe!', '', true, new ChallengePickDropZone(new ChallengeItemPickDropZoneImage('bb_blaeuel_waschrumpel.png', 'Waschbrett zum Wäschewaschen mit Bläuel (um die Feuchtigkeit aus der gewaschenen Wäsche zu schlagen), Pinzgau, 1794, ca. 230 Jahre alt'), [
        new ChallengeItemPickDropZoneText('verschwunden', true),
        new ChallengeItemPickDropZoneText('verändert', false)
    ])));
    tour.addPart(new TourPart('', 'Ist das Ding aus unserem Alltag verschwunden? Oder wird das Ding heute noch in einer veränderten Form genützt?<br><br>Tippe auf das Bild, um es zu vergrößern.<br><br>Ziehe!', '', true, new ChallengePickDropZone(new ChallengeItemPickDropZoneImage('bb_waschschuessel.png', 'Waschgeschirr, Österreich, ca. 120 Jahre alt'), [
        new ChallengeItemPickDropZoneText('verschwunden', true),
        new ChallengeItemPickDropZoneText('verändert', false)
    ])));
    tour.addPart(new TourPart('', 'Ist das Ding aus unserem Alltag verschwunden? Oder wird das Ding heute noch in einer veränderten Form genützt?<br><br>Tippe auf das Bild, um es zu vergrößern.<br><br>Ziehe!', '', true, new ChallengePickDropZone(new ChallengeItemPickDropZoneImage('bb_naehmaschine_alt.png', 'Nähmaschine, ca. 170 Jahre alt'), [
        new ChallengeItemPickDropZoneText('verschwunden', false),
        new ChallengeItemPickDropZoneText('verändert', true)
    ])));
    tour.addPart(new TourPart('', 'Ist das Ding aus unserem Alltag verschwunden? Oder wird das Ding heute noch in einer veränderten Form genützt?<br><br>Tippe auf das Bild, um es zu vergrößern.<br><br>Ziehe!', '', true, new ChallengePickDropZone(new ChallengeItemPickDropZoneImage('bb_schneebesen_alt.png', 'Quirl, Küchengerät zum Verrühren von Flüssigkeiten wie zB. Milch, Pinzgau, ca. 120 Jahre alt'), [
        new ChallengeItemPickDropZoneText('verschwunden', false),
        new ChallengeItemPickDropZoneText('verändert', true)
    ])));
    tour.addPart(new TourPart('', 'Ist das Ding aus unserem Alltag verschwunden? Oder wird das Ding heute noch in einer veränderten Form genützt?<br><br>Tippe auf das Bild, um es zu vergrößern.<br><br>Ziehe!', '', false, new ChallengePickDropZone(new ChallengeItemPickDropZoneImage('bb_reispel.png', 'Reispel, Vorgänger des Haushaltsschwamms, Pinzgau, ca. 120 Jahre alt'), [
        new ChallengeItemPickDropZoneText('verschwunden', false),
        new ChallengeItemPickDropZoneText('verändert', true)
    ])));
    return markRaw(tour);
}
