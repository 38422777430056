import { ChallengeItemOrderTextCircle, ChallengeOrderTextCircle } from "@/data/challenges/challengeOrderTextCircle";
import { ChallengeItemMultiSelectText, ChallengeMultiSelectImageText } from "@/data/challenges/challengeMultiSelectImageText";
import { ChallengeItemPlaceOnAxis, ChallengePlaceOnAxis } from "@/data/challenges/challengePlaceOnAxis";
import { ChallengeItemPickTextBySwipe, ChallengePickTextBySwipe } from "@/data/challenges/challengePickTextBySwipe";
import { ChallengeItemPairImageText, ChallengePairImageText } from "@/data/challenges/challengePairImageText";
import { ChallengeItemPickImage, ChallengePickImage } from "@/data/challenges/challengePickImage";
import { Tour, TourPart } from "@/data/tourHandlerCore";
import { markRaw } from "vue";
export default function (tourCollectionKey) {
    const tour = new Tour(tourCollectionKey, 'A', 'Schnüffeltour 1', false, '', true);
    tour.addPart(new TourPart('Was ist Zeit?', 'Jetzt geht\'s los: Drück den oberen Knopf und hör dir den Text an. Weiter kommst du mit dem Knopf unten.', 'Wenn wir über Zeit sprechen, denken die meisten Menschen an Minuten und Stunden. Wir denken auch an die Zeit, die uns fehlt, um etwas fertig zu machen.<br><br>Sehr früh begannen die Menschen, Zeit einzuteilen. Sie beobachteten dazu die Sonne und den Mond. Tage und Monate konnten so festgelegt werden. Auch Jahreszeiten wurden eingeteilt: Frühling, Sommer, Herbst und Winter.<br><br>Aber auch ich werde immer älter. Jeden Geburtstag werde ich daran erinnert, ein Jahr älter zu sein.', false, null, false, false, 'xx_was-ist-zeit.m4a'));
    tour.addPart(new TourPart('Die Jahreszeiten', 'Ziehe die Jahreszeiten an die richtige Stelle.', '', false, new ChallengeOrderTextCircle([
        new ChallengeItemOrderTextCircle('Frühling', 'spring', 0),
        new ChallengeItemOrderTextCircle('Sommer', 'summer'),
        new ChallengeItemOrderTextCircle('Herbst', 'autumn'),
        new ChallengeItemOrderTextCircle('Winter', 'winter')
    ])));
    tour.addPart(new TourPart('', 'Wer ist am ältesten? Tippe auf die richtige Antwort.', '', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Ich', false),
        new ChallengeItemMultiSelectText('Mama', false),
        new ChallengeItemMultiSelectText('Oma', true)
    ], undefined, true, true)));
    tour.addPart(new TourPart('Mein Leben auf einer Linie', 'Zu deiner Geburt warst du null Jahre alt.<br>Wie alt warst du bei den anderen Ereignissen in deinem Leben.<br>Ziehe die Ereignisse an die richtige Stelle.', '', false, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('*', 0, undefined, undefined, false, true, true, 'Meine Geburt'),
        new ChallengeItemPlaceOnAxis('1', 1 / 8, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('2', 2 / 8, .5 / 8, undefined, true, false, true, 'zweiter Geburtstag'),
        new ChallengeItemPlaceOnAxis('3', 3 / 8, undefined, undefined, true),
        new ChallengeItemPlaceOnAxis('', 3.5 / 8, 1.5 / 8, undefined, false, false, true, 'erster Tag im Kindergarten'),
        new ChallengeItemPlaceOnAxis('4', 4 / 8, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('5', 5 / 8, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('6', 6 / 8, undefined, undefined, true, false, false),
        new ChallengeItemPlaceOnAxis('', 6.25 / 8, .75 / 8, undefined, false, false, true, 'erster Schultag'),
        new ChallengeItemPlaceOnAxis('7', 7 / 8, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false)
    ], .5 / 8, false, true)));
    tour.addPart(new TourPart('Was ist Vergangenheit?', '', 'Das, was jetzt gerade ist, nennen wir <b>Gegenwart</b>.<br><br>Das, was morgen sein wird, ist die <b>Zukunft</b>.<br><br><b>Vergangenheit</b> nennen wir alles, was bereits gewesen ist. Das ist alles, was du erlebt hast und vieles mehr.', false, null, false, false, 'xx_was-ist-vergangenheit.m4a'));
    tour.addPart(new TourPart('', 'Wie kannst du herausfinden, womit du in der Vergangenheit als Baby gespielt hast?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Ich frage einen Erwachsenen, der mich als Baby kannte.', false), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kannst du herausfinden, womit du in der Vergangenheit als Baby gespielt hast?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Ich suche im Keller nach Spielsachen von mir.', false), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kannst du herausfinden, womit du in der Vergangenheit als Baby gespielt hast?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Das lese ich in einem Sachbuch nach.', true), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kannst du herausfinden, womit du in der Vergangenheit als Baby gespielt hast?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Ich schaue im Internet nach.', true), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kannst du herausfinden, womit du in der Vergangenheit als Baby gespielt hast?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Ich frage meine Schulfreundin.', true), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kannst du herausfinden, womit du in der Vergangenheit als Baby gespielt hast?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Ich suche Fotos von mir von früher.', false), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kannst du herausfinden, womit du in der Vergangenheit als Baby gespielt hast?', '', false, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Ich suche nach Videos von mir als Baby.', false), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', '', 'Es gibt verschiedene Dinge aus der Vergangenheit.<br><br>Durch diese Dinge können wir etwas über früher herausfinden.<br><br>Die Dinge können unterschiedliche Formen haben.', true));
    tour.addPart(new TourPart('Finde die passenden Paare!', 'Ziehe Bild und Erklärung zusammen.', '', false, new ChallengePairImageText([
        new ChallengeItemPairImageText('Film von früher', 'bm_filmrolle.png', ''),
        new ChallengeItemPairImageText('Gegenstand von früher', 'bm_kinderschuhe.png', ''),
        new ChallengeItemPairImageText('Bild von früher', 'bm_kinderfoto.png', ''),
        new ChallengeItemPairImageText('Erzählungen von früher', 'bm_mann-sprechblase-baby.jpg', ''),
    ])));
    tour.addPart(new TourPart('', 'Im Museum in Werfenweng werden Dinge von früher aufbewahrt.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welches Ding ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('ww_skischuh_alt.png', 'Skischuh, Österreich, 1960', true),
        new ChallengeItemPickImage('ww_skischuh_neu.png', 'Skischuh, Österreich, 2019', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', 'Im Museum in Werfenweng werden Dinge von früher aufbewahrt.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welches Ding ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('ww_skihelm_mittelalt.png', 'Skihelm, Österreich, Jahr 1958', true),
        new ChallengeItemPickImage('ww_skihelm_neu.png', 'Skihelm, Österreich, 2020', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', 'Im Museum in Werfenweng werden Dinge von früher aufbewahrt.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welches Ding ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('ww_holzski.png', 'Skier, Salzburg, 1915', true),
        new ChallengeItemPickImage('ww_abfahrtsski.png', 'Skier, Österreich, 2020', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', 'Im Museum in Werfenweng werden Dinge von früher aufbewahrt.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welches Ding ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', false, new ChallengePickImage([
        new ChallengeItemPickImage('ww_knappenbrett.png', 'Knappenroß, Salzburger Land, ca. 1800', true),
        new ChallengeItemPickImage('ww_snowboard_neu.png', 'Snowboard, Österreich 1994', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', '', 'Mit Dingen aus der Vergangenheit kann man herausfinden, wie Menschen früher gelebt haben. Erzählt man davon, wird Geschichte erzählt.<br><br>Das Salzburger FIS Landesskimuseum erzählt über die Vergangenheit des Ortes. Dazu hat es viele Dinge gesammelt.', true));
    tour.addPart(new TourPart('', 'Hör dir an, was es aus dem Salzburger FIS Landesskimuseum zu berichten gibt!', 'Das Landesskimuseum heißt mit vollem Namen „Salzburger FIS Landesschimuseum“. Dort wird erzählt, wie der Ski aus den nördlichen Ländern Europas in unser Gebiet kam. Auch wird erzählt, wie daraus das Skifahren wurde, wie wir es heute kennen.<br><br>Dafür wurde vom Museum viel gesammelt: Alte Beschreibungen, Fotos, Skier, Skischuhe, Bindungen, Skikleidung und viele andere Wintersportgeräte wie z.B. Snowboards. Diese Dinge wurden aber auch untersucht und werden im Museum ausgestellt.', true, null, false, false, 'ww_intro-museum.mp3'));
    tour.addPart(new TourPart('', 'Hör genau hin, was im Video erzählt wird.<br><br>Damit kannst du die nächste Aufgabe lösen.', 'Im Salzburger FIS Landesskimuseum gibt es aber auch noch weitere Dinge zu entdecken.', true, null, false, false, '', { name: 'ww_aeltester_ski.mp4', portrait: false }));
    tour.addPart(new TourPart('', 'Wie kann uns dieses Ding aus dem Museum in Werfenweng helfen, etwas über die Vergangenheit herauszufinden?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Mit diesem Ding kann man herausfinden, wie Menschen früher über den Schnee fuhren.', false, [{ name: 'ww_holzski.png', description: 'Skier, Salzburg, 1915' }]), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kann uns dieses Ding aus dem Museums helfen, etwas über die Vergangenheit herauszufinden?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Mit diesem Ding kann man herausfinden, wie die Menschen früher gewohnt haben.', true, [{ name: 'ww_holzski.png', description: 'Skier, Salzburg, 1915' }]), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', 'Wie kann uns dieses Ding aus dem Museums helfen, etwas über die Vergangenheit herauszufinden?', '', true, new ChallengePickTextBySwipe(new ChallengeItemPickTextBySwipe('Mit diesem Ding kann man herausfinden, wie Menschen früher gekocht haben.', true, [{ name: 'ww_holzski.png', description: 'Skier, Salzburg, 1915' }]), 'falsch', 'richtig')));
    tour.addPart(new TourPart('', '', 'Hör dir nun eine Geschichte zur Entwicklung der Skier an.', false, null, false, false, '', { name: 'ww_carving.mp4', portrait: false }));
    return markRaw(tour);
}
