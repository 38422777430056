import { BaseChallenge, BaseChallengeItem } from "@/data/challenges/challenge";
import Timeline from "@/components/challenges/Timeline.vue";
import MediaImage from "@/data/mediaImage";
import userHandler from "@/data/userHandler";
import audioHandler from "@/data/audioHandler";
export class ChallengeTimeline extends BaseChallenge {
    itemKeyOrder = [];
    dropZones = {};
    itemType = '';
    axisTextLeft = '';
    axisTextRight = '';
    constructor(items = [], axisTextLeft = '', axisTextRight = '') {
        super('timeline', Timeline);
        this.itemType = items[0]?.itemType || 'text';
        this.items = items;
        this.itemKeyOrder = items.map(item => item.key);
        this.axisTextLeft = axisTextLeft;
        this.axisTextRight = axisTextRight;
    }
    init() {
        this.state = '';
        this.dropZones = {};
        this.shuffle();
        this.postInit();
    }
    registerDropZone(dropZoneKey, bbox) {
        let centerX = 0;
        if (bbox?.x > 0 && bbox?.width > 0) {
            centerX = Math.floor(bbox.x + (bbox.width / 2));
            if (!this.dropZones[centerX]) {
                this.dropZones[centerX] = {
                    key: dropZoneKey,
                    index: -1,
                    itemKey: -1,
                    itemIsCorrect: false,
                    itemKeys: {},
                    valid: true,
                    bbox
                };
            }
        }
        return centerX;
    }
    sortDropZones() {
        const listOfX = [];
        for (const cx in this.dropZones) {
            listOfX.push(Number(cx));
        }
        listOfX.sort((a, b) => a < b ? -1 : (a === b ? 0 : 1));
        for (const i in listOfX) {
            const x = Number(listOfX[i]);
            this.dropZones[x].index = Number(i);
        }
    }
    selectItemDropZone(itemKey, cursorX, cursorY) {
        let chosen = true;
        let playSoundEffect = false;
        for (const centerX in this.dropZones) {
            const dropZone = this.dropZones[centerX];
            if (dropZone.bbox.x <= cursorX && cursorX <= (dropZone.bbox.x + dropZone.bbox.width)
                &&
                    dropZone.bbox.y <= cursorY && cursorY <= (dropZone.bbox.y + dropZone.bbox.height)) {
                dropZone.itemKeys[itemKey] = true;
                if (dropZone.itemKey < 0) {
                    playSoundEffect = true;
                    dropZone.itemKey = itemKey;
                    dropZone.itemIsCorrect = this.itemKeyOrder[dropZone.index] === dropZone.itemKey;
                }
            }
            else {
                delete dropZone.itemKeys[itemKey];
                if (dropZone.itemKey === itemKey) {
                    const itemKeyIndexes = Object.keys(dropZone.itemKeys);
                    if (itemKeyIndexes.length > 0) {
                        dropZone.itemKey = Number(itemKeyIndexes[0]);
                        dropZone.itemIsCorrect = this.itemKeyOrder[dropZone.index] === dropZone.itemKey;
                    }
                    else {
                        dropZone.itemKey = -1;
                        dropZone.itemIsCorrect = false;
                    }
                }
            }
            dropZone.valid = Object.keys(dropZone.itemKeys).length < 2;
            if (dropZone.itemKey < 0 || !dropZone.valid) {
                chosen = false;
            }
        }
        if (playSoundEffect) {
            audioHandler.play('select');
        }
        if (this.state !== 'solved') {
            if (chosen) {
                this.state = 'chosen';
            }
            else {
                this.state = '';
            }
        }
    }
    getDropZoneStates() {
        const dropZoneStates = {};
        for (const centerX in this.dropZones) {
            const dropZone = this.dropZones[centerX];
            if (dropZone.key > -1) {
                dropZoneStates[dropZone.key] = {
                    itemKey: dropZone.itemKey,
                    itemIsCorrect: dropZone.itemIsCorrect,
                    valid: dropZone.valid
                };
            }
        }
        return dropZoneStates;
    }
    getNumberOfDropZones() {
        return Object.keys(this.dropZones).length;
    }
    evaluate() {
        if (this.state !== 'solved') {
            this.state = 'solved';
            if (this.items.length > 0) {
                let nrOfCorrectItems = 0;
                for (const centerX in this.dropZones) {
                    const dropZone = this.dropZones[centerX];
                    if (dropZone.itemIsCorrect) {
                        nrOfCorrectItems++;
                    }
                }
                this.score = nrOfCorrectItems / this.items.length;
            }
            userHandler.getUser().setScore(this.getTourCollectionKey(), this.getTourKey(), this.getTourPartIndex(), this.score);
        }
    }
}
export class ChallengeItemTimelineText extends BaseChallengeItem {
    itemType = 'text';
    text;
    dropZoneText;
    constructor(text, dropZoneText = '') {
        super('challenge');
        this.text = text;
        this.dropZoneText = dropZoneText;
    }
}
export class ChallengeItemTimelineImage extends BaseChallengeItem {
    itemType = 'image';
    media;
    dropZoneText;
    constructor(imageName, imageDescription = '', imageMeta = {}, dropZoneText = '') {
        super('challenge');
        this.dropZoneText = dropZoneText;
        this.media = new MediaImage(imageName, undefined, imageDescription, imageMeta);
    }
}
