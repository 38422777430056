import { BaseChallenge, BaseChallengeItem } from "@/data/challenges/challenge";
import OrderTextCircle from "@/components/challenges/OrderTextCircle.vue";
import userHandler from "@/data/userHandler";
/**
 * flex box order of flex-wrapped dropZones:
 *          0 1
 *          2 3
 *
 * natural circle clockwise order starting from top
 *          3 0
 *          2 1
 */
const circleItemOrderMatrix = [
    3, 0,
    2, 1
];
export class ChallengeOrderTextCircle extends BaseChallenge {
    itemKeyOrder = [];
    dropZones = {};
    constructor(items = []) {
        super('orderTextCircle', OrderTextCircle);
        this.items = items;
        this.itemKeyOrder = items.map(item => item.key);
    }
    init() {
        this.state = '';
        this.dropZones = {};
        this.shuffle();
        this.postInit();
    }
    registerDropZone(dropZoneKey, bbox) {
        let center = '';
        if (bbox?.x > 0 && bbox?.y > 0 && bbox?.width > 0 && bbox?.height > 0) {
            center = `${Math.floor(bbox.x + (bbox.width / 2))}_${Math.floor(bbox.y + (bbox.height / 2))}`;
            if (!this.dropZones[center]) {
                this.dropZones[center] = {
                    key: dropZoneKey,
                    index: -1,
                    itemKey: -1,
                    itemIsCorrect: false,
                    itemKeys: {},
                    autoPlaced: false,
                    bbox
                };
            }
        }
        return center;
    }
    sortDropZones() {
        const listOfC = [];
        for (const c in this.dropZones) {
            const parts = String(c).split('_');
            listOfC.push({
                x: Number(parts[0]),
                y: Number(parts[1])
            });
        }
        listOfC.sort((a, b) => (a.y === b.y ? (a.x < b.x ? -1 : (a.x === b.x ? 0 : 1)) : a.y < b.y ? -1 : 1));
        let i = 0;
        for (const c in listOfC) {
            const coords = listOfC[c] || {};
            const dropZone = this.dropZones[`${coords.x}_${coords.y}`];
            dropZone.index = circleItemOrderMatrix[i++];
            // set data for autoPlaced items
            for (const item of this.items) {
                if (item.autoPlaceToItemIndex === dropZone.index) {
                    dropZone.autoPlaced = true;
                    dropZone.itemKey = item.key;
                    dropZone.itemKeys[item.key] = true;
                    dropZone.itemIsCorrect = true;
                }
            }
        }
    }
    selectItemDropZone(itemKey, cursorX, cursorY) {
        let chosen = true;
        let matchingDropZone = null;
        for (const center in this.dropZones) {
            const dropZone = this.dropZones[center];
            if (dropZone.bbox.x <= cursorX && cursorX <= (dropZone.bbox.x + dropZone.bbox.width)
                &&
                    dropZone.bbox.y <= cursorY && cursorY <= (dropZone.bbox.y + dropZone.bbox.height)) {
                matchingDropZone = dropZone;
                dropZone.itemKeys[itemKey] = true;
                if (dropZone.itemKey < 0 || dropZone.itemKey === itemKey) {
                    dropZone.itemKey = itemKey;
                    dropZone.itemIsCorrect = this.itemKeyOrder[dropZone.index] === dropZone.itemKey;
                }
            }
            else {
                delete dropZone.itemKeys[itemKey];
                if (dropZone.itemKey === itemKey) {
                    const itemKeyIndexes = Object.keys(dropZone.itemKeys);
                    if (itemKeyIndexes.length > 0) {
                        dropZone.itemKey = Number(itemKeyIndexes[0]);
                        dropZone.itemIsCorrect = this.itemKeyOrder[dropZone.index] === dropZone.itemKey;
                    }
                    else {
                        dropZone.itemKey = -1;
                        dropZone.itemIsCorrect = false;
                    }
                }
            }
            if (dropZone.itemKey < 0 || Object.keys(dropZone.itemKeys).length > 1) {
                chosen = false;
            }
        }
        if (this.state !== 'solved') {
            if (chosen) {
                this.state = 'chosen';
            }
            else {
                this.state = '';
            }
        }
        return matchingDropZone;
    }
    getDropZoneStates() {
        const dropZoneStates = {};
        for (const center in this.dropZones) {
            const dropZone = this.dropZones[center];
            if (dropZone.key > -1) {
                dropZoneStates[dropZone.key] = dropZone;
            }
        }
        return dropZoneStates;
    }
    getNumberOfDropZones() {
        return Object.keys(this.dropZones).length;
    }
    isCorrect() {
        return (this.state === 'solved' && this.score === 1);
    }
    evaluate() {
        if (this.state !== 'solved') {
            this.state = 'solved';
            if (this.dropZones) {
                let nrOfCorrectItems = 0;
                for (const center in this.dropZones) {
                    const dropZone = this.dropZones[center];
                    if (dropZone.key > -1 && dropZone.itemIsCorrect) {
                        nrOfCorrectItems++;
                    }
                }
                this.score = nrOfCorrectItems / this.items.length;
            }
            userHandler.getUser().setScore(this.getTourCollectionKey(), this.getTourKey(), this.getTourPartIndex(), this.score);
        }
    }
}
export class ChallengeItemOrderTextCircle extends BaseChallengeItem {
    text = '';
    symbol = '';
    autoPlaceToItemIndex;
    constructor(text, symbol = '', autoPlaceToItemIndex = undefined) {
        super('challenge');
        this.text = text;
        this.symbol = symbol;
        this.autoPlaceToItemIndex = autoPlaceToItemIndex;
    }
}
