import { defineComponent } from "vue";
import { ChallengePickTextBySwipe } from "@/data/challenges/challengePickTextBySwipe";
import Text from '@/components/core/Text.vue';
import Image from '@/components/core/Image.vue';
import Button from '@/components/core/Button.vue';
import SymbolSolution from "@/components/symbols/solution.vue";
import router from '@/router';
import modalHandler from "@/data/modalHandler";
import pageHookMixin from "@/data/pageHookMixin";
export default defineComponent({
    name: 'ChallengePickTextBySwipe',
    mixins: [pageHookMixin],
    components: {
        SymbolSolution,
        Text,
        Image,
        Button
    },
    props: {
        challenge: {
            type: ChallengePickTextBySwipe
        },
        forwardUrl: {
            type: String
        },
        showMotivationModal: {
            type: Boolean,
            default: false
        },
        bottomReadyToMeasure: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            arcLeft: '',
            arcLeftText: '',
            arcRight: '',
            arcRightText: '',
            arcRightTextTransformOrigin: '',
            moveStartX: 0,
            positionIndicator: 0,
            camber: {
                r: 0,
                x: 0,
                y: 0,
                w: 0,
            },
            arc: {
                width: 0,
                w: 0,
                h: 0,
                alpha: 0,
                beta: 0,
                ra: 0,
                rb: 0,
                z: {
                    x: 0,
                    y: 0,
                },
                scalingFactor: 1,
                arrowLength: 45
            },
            lastAxisBBox: {},
            axisInitialized: false,
            axisPosition: {},
            initTimeout: 0
        };
    },
    mounted() {
        if (this.challenge && this.bottomReadyToMeasure) {
            this.initAxis();
        }
    },
    updated() {
        if (this.bottomReadyToMeasure && !this.axisInitialized) {
            this.initAxis();
        }
    },
    computed: {
        solved() {
            if (this.challenge) {
                return this.challenge.state === 'solved';
            }
            return false;
        },
        buttonColor() {
            if (this.challenge) {
                if (this.solved) {
                    return 'green';
                }
                if (this.challenge.state === 'chosen') {
                    return 'orange';
                }
            }
            return 'gray';
        },
    },
    methods: {
        onScreenUpdate() {
            router.go(0);
        },
        calcPosition(x, y, r, alpha, rightFactor = -1) {
            return {
                x: Math.round((x + (rightFactor * (r * Math.sin(alpha * Math.PI / 180)))) * 100) / 100,
                y: Math.round((y - (r * Math.cos(alpha * Math.PI / 180))) * 100) / 100,
            };
        },
        initAxis(delta = 0) {
            this.axisInitialized = true;
            if (delta) {
                if (this.initTimeout)
                    clearTimeout(this.initTimeout);
                this.initTimeout = setTimeout((self) => {
                    self.initAxis();
                }, delta, this);
            }
            else {
                const element = this.$el.querySelector('.activityArea');
                if (element) {
                    const bbox = element.getBoundingClientRect();
                    if (bbox.width < 1 || bbox.height < 1 || !this.lastAxisBBox.height || this.lastAxisBBox.height !== bbox.height) {
                        this.lastAxisBBox = bbox;
                        this.initAxis(25);
                    }
                    else {
                        const width = 100;
                        const height = Math.max(100, bbox.height / bbox.width * 100);
                        const delta = height * .022;
                        const radius = height - 15;
                        const beta = Math.asin(((width / 2) - delta) / radius) * 180 / Math.PI;
                        const arc = this.arc = {
                            width: bbox.width,
                            w: width,
                            h: height,
                            alpha: beta / 10,
                            beta,
                            ra: radius - delta,
                            rb: radius,
                            z: {
                                x: width / 2,
                                y: height
                            },
                            scalingFactor: Math.round(height * 100000 / bbox.height) / 100000,
                            arrowLength: radius * (beta * .89) * Math.PI / 180
                        };
                        let start = this.calcPosition(arc.z.x, arc.z.y, arc.ra, arc.beta);
                        let end = this.calcPosition(arc.z.x, arc.z.y, arc.ra, arc.alpha);
                        let startStart = this.calcPosition(arc.z.x, arc.z.y, arc.ra + 1, arc.alpha);
                        let startEnd = this.calcPosition(arc.z.x, arc.z.y, arc.ra - 1, arc.alpha);
                        let endStart = this.calcPosition(arc.z.x, arc.z.y, arc.ra + 1.5, arc.beta - .75);
                        let endEnd = this.calcPosition(arc.z.x, arc.z.y, arc.ra - 1.5, arc.beta - .75);
                        this.arcLeft = `M ${start.x} ${start.y} A ${arc.ra} ${arc.ra}, 0, 0, 1, ${end.x} ${end.y} M ${startStart.x} ${startStart.y} L ${startEnd.x} ${startEnd.y} M ${endStart.x} ${endStart.y} L ${start.x} ${start.y} L ${endEnd.x} ${endEnd.y}`;
                        start = this.calcPosition(arc.z.x, arc.z.y, arc.rb, arc.beta);
                        end = this.calcPosition(arc.z.x, arc.z.y, arc.rb, arc.alpha);
                        this.arcLeftText = `M ${start.x} ${start.y} A ${arc.ra} ${arc.ra}, 0, 0, 1, ${end.x} ${end.y}`;
                        start = this.calcPosition(arc.z.x, arc.z.y, arc.ra, arc.alpha, 1);
                        end = this.calcPosition(arc.z.x, arc.z.y, arc.ra, arc.beta, 1);
                        startStart = this.calcPosition(arc.z.x, arc.z.y, arc.ra + 1, arc.alpha, 1);
                        startEnd = this.calcPosition(arc.z.x, arc.z.y, arc.ra - 1, arc.alpha, 1);
                        endStart = this.calcPosition(arc.z.x, arc.z.y, arc.ra + 1.5, arc.beta - .75, 1);
                        endEnd = this.calcPosition(arc.z.x, arc.z.y, arc.ra - 1.5, arc.beta - .75, 1);
                        this.arcRight = `M ${start.x} ${start.y} A ${arc.ra} ${arc.ra}, 0, 0, 1, ${end.x} ${end.y} M ${startStart.x} ${startStart.y} L ${startEnd.x} ${startEnd.y} M ${endStart.x} ${endStart.y} L ${end.x} ${end.y} L ${endEnd.x} ${endEnd.y}`;
                        start = this.calcPosition(arc.z.x, arc.z.y, arc.rb, arc.alpha, 1);
                        end = this.calcPosition(arc.z.x, arc.z.y, arc.rb, arc.beta, 1);
                        this.arcRightText = `M ${start.x} ${start.y} A ${arc.ra} ${arc.ra}, 0, 0, 1, ${end.x} ${end.y}`;
                        this.arcRightTextTransformOrigin = `${start.x} ${start.y}`;
                        this.camber.r = arc.ra - 15;
                        this.camber.w = 60;
                        const gamma = (Math.asin(this.camber.w / 2 / this.camber.r) * 180 / Math.PI);
                        const p = this.calcPosition(this.camber.w / 2, this.camber.r, this.camber.r, gamma);
                        this.camber.x = p.x;
                        this.camber.y = p.y + 1;
                        this.$el.classList.add('initDone');
                    }
                }
                else {
                    this.initAxis(25);
                }
            }
        },
        draggableOnStart(itemKey, cursorX, cursorY, bbox = null, element = null) {
            if (element) {
                element.style.transition = `none`;
                element.style.animation = `none`;
            }
            this.moveStartX = cursorX;
        },
        draggableOnMove(itemKey, cursorX, cursorY, bbox = null, element = null) {
            if (element) {
                const angle = Math.max(-1, Math.min(1, (((((cursorX - this.moveStartX) / this.arc.width)) * 2) + this.positionIndicator))) * this.arc.beta;
                element.style.transform = `rotate(${angle}deg)`;
            }
        },
        draggableOnEnd(itemKey, cursorX, cursorY, bbox = null, element = null) {
            if (element) {
                element.style.transition = `transform .3s ease-in-out`;
                const angleIndicator = Math.max(-1, Math.min(1, (((((cursorX - this.moveStartX) / this.arc.width)) * 2) + this.positionIndicator)));
                if (angleIndicator > .5) {
                    this.positionIndicator = 1;
                    element.style.transform = `rotate(${this.arc.beta}deg)`;
                }
                else if (angleIndicator < -.5) {
                    this.positionIndicator = -1;
                }
                else {
                    this.positionIndicator = 0;
                    element.style.transform = `rotate(0deg)`;
                }
                if (this.challenge && this.positionIndicator !== 0) {
                    this.challenge.toggleSelection(this.positionIndicator);
                    this.challenge.evaluate();
                }
            }
        },
        onForwardButtonClick() {
            if (this.challenge) {
                if (this.solved) {
                    if (this.forwardUrl) {
                        if (this.showMotivationModal) {
                            modalHandler.show('motivation', {
                                forwardUrl: this.forwardUrl
                            });
                        }
                        else {
                            router.replace(this.forwardUrl);
                        }
                    }
                }
            }
        }
    }
});
