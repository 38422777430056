import { defineComponent } from "vue";
import { ChallengeItemPlaceOnAxis, ChallengePlaceOnAxis } from "@/data/challenges/challengePlaceOnAxis";
import Text from '@/components/core/Text.vue';
import Image from '@/components/core/Image.vue';
import Button from '@/components/core/Button.vue';
import router from '@/router';
import DraggableGesture from "@/components/core/DraggableGesture.vue";
import SymbolBoneText from "@/components/symbols/boneText.vue";
import SymbolSolution from "@/components/symbols/solution.vue";
import DropZone from "@/components/core/DropZone.vue";
import SymbolPuzzlePiece from "@/components/symbols/puzzlePiece.vue";
import modalHandler from "@/data/modalHandler";
import { onBBoxReady, round } from "@/lib/utils";
import pageHookMixin from "@/data/pageHookMixin";
import { SPEECH_BUBBLE_AREA_HEIGHT, SPEECH_BUBBLE_AREA_OFFSET_Y } from "@/lib/constants";
export default defineComponent({
    name: 'ChallengePlaceOnAxis',
    mixins: [pageHookMixin],
    components: {
        SymbolPuzzlePiece,
        DropZone,
        SymbolSolution,
        Text,
        Image,
        DraggableGesture,
        Button,
        SymbolBoneText
    },
    props: {
        challenge: {
            type: ChallengePlaceOnAxis
        },
        forwardUrl: {
            type: String
        },
        showMotivationModal: {
            type: Boolean,
            default: false
        },
        bottomReadyToMeasure: {
            type: Boolean,
            default: false
        },
    },
    setup() {
        return {
            bubbleXFactor: .2
        };
    },
    data() {
        const itemGuiStates = {};
        return {
            lastAxisBBox: {},
            axisInitialized: false,
            axisPosition: {
                x: 0,
                y: 0,
                w: 0
            },
            target: {
                minY: 0,
                y: 0,
                maxY: 0
            },
            itemGuiStates
        };
    },
    mounted() {
        if (this.challenge && this.bottomReadyToMeasure) {
            this.startInitAxis();
        }
    },
    updated() {
        if (this.bottomReadyToMeasure) {
            this.startInitAxis();
        }
    },
    computed: {
        cssClasses() {
            const cssClasses = [];
            if (this.isBoneChallenge) {
                cssClasses.push('bones');
            }
            if (this.challenge?.isPuzzle) {
                cssClasses.push('puzzle');
                if (this.challenge?.isPuzzleImageToText) {
                    cssClasses.push('puzzleImageToText');
                }
            }
            return cssClasses.join(' ');
        },
        solved() {
            if (this.challenge) {
                return this.challenge.state === 'solved';
            }
            return false;
        },
        buttonColor() {
            if (this.challenge) {
                if (this.solved) {
                    return 'green';
                }
                if (this.challenge.state === 'chosen') {
                    return 'orange';
                }
            }
            return 'gray';
        },
        isBoneChallenge() {
            return (this.challenge?.numberOfBones || 0) > 0;
        }
    },
    methods: {
        onScreenUpdate() {
            router.go(0);
        },
        getDraggableTextItemClasses(itemKey, puzzleElements) {
            let classes = [];
            if (puzzleElements) {
                classes = classes.concat(this.puzzlePieceOffsetXClasses(puzzleElements));
            }
            const itemGuiStates = this.itemGuiStates[itemKey];
            if (itemGuiStates?.flipped) {
                classes.push('flipped');
            }
            if (itemGuiStates?.centered) {
                classes.push('centered');
            }
            return classes.join(' ');
        },
        getPuzzlePieceClipPath(puzzleElements) {
            return ChallengeItemPlaceOnAxis.getPuzzlePieceClipPath(puzzleElements);
        },
        puzzlePieceOffsetXClass(puzzleElements) {
            return this.puzzlePieceOffsetXClasses(puzzleElements).join(' ');
        },
        puzzlePieceOffsetXClasses(puzzleElements) {
            const classList = [];
            const offsetX = ChallengeItemPlaceOnAxis.getPuzzlePieceOffsetXFactor(puzzleElements?.left || '', puzzleElements?.right || '');
            if (offsetX !== 0) {
                if (offsetX > 0) {
                    if (offsetX > .5) {
                        classList.push('offsetXRight');
                    }
                    else {
                        classList.push('offsetXRightSlim');
                    }
                }
                else {
                    if (offsetX < -.5) {
                        classList.push('offsetXLeft');
                    }
                    else {
                        classList.push('offsetXLeftSlim');
                    }
                }
            }
            return classList;
        },
        startInitAxis() {
            if (!this.axisInitialized) {
                this.axisInitialized = true;
                onBBoxReady(this.$el.querySelector('.axis'), 100, (axisBBox) => this.initAxis(axisBBox));
            }
        },
        initAxis(axisBBox) {
            const draggableContainers = this.$el.querySelectorAll('.source > div');
            this.axisPosition = {
                x: axisBBox.x,
                y: axisBBox.y + (axisBBox.height / 2),
                w: axisBBox.width
            };
            for (const dc in draggableContainers) {
                if (!isNaN(Number(dc))) {
                    const draggableContainer = draggableContainers[dc];
                    const itemKey = Number(draggableContainer.dataset.itemKey);
                    const draggable = draggableContainer.querySelector('.dnd');
                    const item = this.challenge?.draggableItems()?.getItemByKey(itemKey);
                    if (item && item.autoPlace) {
                        const itemPosition = this.updateDraggable(draggable, itemKey);
                        if (item.position !== undefined) {
                            const deltaX = (item.position - itemPosition.p) * this.axisPosition.w;
                            const style = draggable.style;
                            style.setProperty('transition', `transform .3s ease-in-out`);
                            style.setProperty('transform', `translateX(${Math.round(deltaX)}px)`);
                        }
                    }
                }
            }
            if (this.isBoneChallenge) {
                // measure target width
                const boneTargetContainer = this.$el.querySelector('.target');
                let boneTargetBBox = boneTargetContainer.getBoundingClientRect();
                // set target bone's w
                const boneWidth = boneTargetBBox.width / (this.challenge?.numberOfTargetBones || 1);
                this.$el.style.setProperty('--bone-width', `${boneWidth}px`);
                // measure target height (dependent on bone's width)
                boneTargetBBox = boneTargetContainer.getBoundingClientRect();
                this.target = {
                    minY: boneTargetBBox.y,
                    y: boneTargetBBox.y + (boneTargetBBox.height / 2),
                    maxY: boneTargetBBox.y + boneTargetBBox.height
                };
                // randomize source bone rotation and y
                const draggableBones = this.$el.querySelectorAll('.source .dnd');
                let randomOffsetRotation = 0;
                let randomOffsetY = 0;
                const nrOfBones = this.challenge?.numberOfBones || 0;
                const groupSize = Math.round(nrOfBones / 3);
                for (const db in draggableBones) {
                    if (!isNaN(Number(db))) {
                        const draggableBone = draggableBones[db];
                        randomOffsetRotation = Math.round(Math.random() * 40);
                        const baseFactor = 1;
                        if ((Number(db) / groupSize) < 1) {
                            randomOffsetY = Math.round(Math.random() * baseFactor * boneWidth);
                        }
                        else if ((Number(db) / groupSize) >= 2) {
                            randomOffsetY = Math.round(Math.random() * -baseFactor * boneWidth);
                        }
                        else {
                            randomOffsetY = Math.round(((Math.random() * baseFactor) - (baseFactor / 2)) * boneWidth);
                        }
                        draggableBone.style.setProperty('--outer-offset-rotation', `${randomOffsetRotation}deg`);
                        draggableBone.style.setProperty('--outer-offset-y', `${randomOffsetY}px`);
                        draggableBone.style.setProperty('--bone-transition-delay', `${round((nrOfBones - Number(db)) * .0083, 2)}s`);
                    }
                }
            }
            else if (this.challenge?.isPuzzle) {
                // measure target width
                const targetContainer = this.$el.querySelector('.target');
                let targetBBox = targetContainer.getBoundingClientRect();
                // measure target height (dependent on bone's width)
                this.target = {
                    minY: targetBBox.y,
                    y: targetBBox.y + (targetBBox.height / 2),
                    maxY: targetBBox.y + targetBBox.height
                };
            }
            this.$el.classList.add('animate');
        },
        updateDraggable(draggable, itemKey, bbox = null, updateChosenState = false, cursorX = 0, cursorY = 0) {
            if (draggable && this.challenge) {
                if (!bbox) {
                    bbox = draggable.getBoundingClientRect();
                }
                if (this.isBoneChallenge) {
                    const posX = cursorX;
                    const posY = cursorY;
                    const position = (posX - this.axisPosition.x) / this.axisPosition.w;
                    const positionIndex = Math.ceil(position * this.challenge.numberOfTargetBones);
                    const boneTargetContainer = this.$el.querySelector('.target');
                    const targetBone = boneTargetContainer.querySelector(`.bone:nth-child(${positionIndex})`);
                    if (posY > (this.target.minY - 10) && posY < (this.target.maxY + 10)
                        &&
                            positionIndex > 0 && positionIndex <= this.challenge.numberOfTargetBones
                        &&
                            targetBone
                        &&
                            (this.challenge.getItemPosition(positionIndex) < 1
                                ||
                                    this.challenge.getItemPosition(positionIndex) == itemKey)) {
                        targetBone.style.opacity = .7;
                        this.challenge.setItemPosition(itemKey, positionIndex, updateChosenState);
                        if (updateChosenState) {
                            const targetBoneBBox = targetBone.getBoundingClientRect();
                            const draggableStyle = draggable.style;
                            const randomOffsetY = Number(String(draggableStyle.getPropertyValue('--outer-offset-y') || '').replace(/px$/, ''));
                            const randomOffsetX = Number(String(draggableStyle.getPropertyValue('--outer-offset-x') || '').replace(/px$/, ''));
                            const x = randomOffsetX + (targetBoneBBox.x + (targetBoneBBox.width / 2)) - (bbox.x + (bbox.width / 2));
                            const y = randomOffsetY + (this.target.y) - (bbox.y + (bbox.height / 2) + (targetBoneBBox.height / 6));
                            draggableStyle.setProperty('transition', `transform .15s ease-in-out`);
                            draggableStyle.setProperty('--outer-offset-x', `${x}px`);
                            draggableStyle.setProperty('--outer-offset-y', `${y}px`);
                            draggableStyle.setProperty('--outer-offset-rotation', `20deg`);
                            draggableStyle.setProperty('--outer-offset-scale', `.6`);
                        }
                    }
                    else {
                        this.challenge.setItemPosition(itemKey, 0, updateChosenState);
                        draggable.style.zIndex = '420';
                    }
                }
                else if (this.challenge.isPuzzle) {
                    const nrOfDraggables = this.challenge.draggableItems().length() || 0;
                    const posX = cursorX;
                    const posY = cursorY;
                    const position = (posX - this.axisPosition.x) / this.axisPosition.w;
                    const positionIndex = Math.ceil(position * nrOfDraggables);
                    const targetContainer = this.$el.querySelector('.target');
                    const target = targetContainer.querySelector(`.dropZoneContainer:nth-child(${positionIndex})`);
                    const puzzlePosition = Math.round((((positionIndex - .5) * (1.3 / 4)) - ((positionIndex - 1) * .1)) * 10000) / 10000;
                    if (posY > (this.target.minY - 10) && posY < (this.target.maxY + 10)
                        &&
                            positionIndex > 0 && positionIndex <= nrOfDraggables
                        &&
                            target
                        &&
                            (!this.challenge.grantOneItemPerTarget
                                ||
                                    this.challenge.getItemPosition(puzzlePosition) < 1
                                ||
                                    this.challenge.getItemPosition(puzzlePosition) == itemKey)) {
                        if (updateChosenState) {
                            const itemSubPosition = this.challenge.setItemPosition(itemKey, puzzlePosition, updateChosenState);
                            const targetBBox = target.getBoundingClientRect();
                            const draggableStyle = draggable.style;
                            const randomOffsetY = Number(String(draggableStyle.getPropertyValue('--outer-offset-y') || '').replace(/px$/, ''));
                            const randomOffsetX = Number(String(draggableStyle.getPropertyValue('--outer-offset-x') || '').replace(/px$/, ''));
                            let x = randomOffsetX + (targetBBox.x + (targetBBox.width / 2)) - (bbox.x + (bbox.width / 2));
                            let y = randomOffsetY + (this.target.y) - (bbox.y + (bbox.height / 2));
                            if (this.challenge.pullInItemOnDrop) {
                                x = randomOffsetX + targetBBox.x - bbox.x + targetBBox.width * .15;
                                y = randomOffsetY + (this.target.minY) - bbox.y + targetBBox.height * .15;
                                if (itemSubPosition % 2 === 0) {
                                    x += targetBBox.width * .35;
                                    draggable.classList.remove('pulledInLeft');
                                    draggable.classList.remove('pulledInRight');
                                    draggable.classList.add('pulledInRight');
                                }
                                else {
                                    draggable.classList.remove('pulledInLeft');
                                    draggable.classList.remove('pulledInRight');
                                    draggable.classList.add('pulledInLeft');
                                }
                                if (itemSubPosition > 2) {
                                    y += targetBBox.height * .35;
                                }
                                if (target.classList.contains('offsetXLeft')) {
                                    x += targetBBox.width * .125;
                                }
                                else if (target.classList.contains('offsetXLeftSlim')) {
                                    x += targetBBox.width * .0625;
                                }
                                else if (target.classList.contains('offsetXRight')) {
                                    x -= targetBBox.width * .125;
                                }
                                else if (target.classList.contains('offsetXRightSlim')) {
                                    x -= targetBBox.width * .0625;
                                }
                                draggable.classList.add('pulledIn');
                                draggableStyle.setProperty('--outer-offset-scale', '.35');
                            }
                            draggableStyle.setProperty('transition', `transform .15s ease-in-out`);
                            draggableStyle.setProperty('--outer-offset-x', `${x}px`);
                            draggableStyle.setProperty('--outer-offset-y', `${y}px`);
                        }
                    }
                    else {
                        this.challenge.setItemPosition(itemKey, 0, updateChosenState);
                        const draggableStyle = draggable.style;
                        draggableStyle.zIndex = '420';
                        if (updateChosenState) {
                            if (this.challenge.pullInItemOnDrop) {
                                draggable.classList.remove('pulledIn');
                                draggableStyle.setProperty('transition', `transform .15s ease-in-out`);
                                draggableStyle.setProperty('--outer-offset-scale', '1');
                            }
                        }
                    }
                }
                else {
                    const posX = bbox.x + (bbox.width * this.bubbleXFactor);
                    const pos = {
                        x: posX,
                        p: (posX - this.axisPosition.x) / this.axisPosition.w,
                        deltaY: bbox.y - this.axisPosition.y,
                        flippedDeltaY: this.axisPosition.y - bbox.y - bbox.height
                    };
                    const itemGuiState = this.itemGuiStates[itemKey] || {
                        centered: false,
                        flipped: false
                    };
                    const style = draggable.style;
                    if (pos.deltaY - SPEECH_BUBBLE_AREA_HEIGHT - SPEECH_BUBBLE_AREA_OFFSET_Y > 0) {
                        draggable.classList.remove('centered');
                        style.setProperty('--speech-bubble-pointer-height', `${round(pos.deltaY + 4, 3)}px`);
                        style.setProperty('--speech-bubble-pointer-opacity', '1');
                        draggable.classList.remove('flipped');
                    }
                    else if (pos.flippedDeltaY - SPEECH_BUBBLE_AREA_HEIGHT - SPEECH_BUBBLE_AREA_OFFSET_Y > 0) {
                        draggable.classList.remove('centered');
                        style.setProperty('--speech-bubble-pointer-height', `${round(pos.flippedDeltaY + 4, 3)}px`);
                        style.setProperty('--speech-bubble-pointer-opacity', '1');
                        draggable.classList.add('flipped');
                        itemGuiState.flipped = true;
                    }
                    else {
                        itemGuiState.centerd = true;
                        draggable.classList.add('centered');
                        draggable.classList.remove('flipped');
                        style.setProperty('--speech-bubble-pointer-opacity', '0');
                    }
                    this.itemGuiStates[itemKey] = itemGuiState;
                    this.challenge.setItemPosition(itemKey, pos.p, updateChosenState);
                    return pos;
                }
            }
            return {
                p: 0
            };
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        draggableOnStart(itemKey, cursorX, cursorY, bbox = null, element = null) {
            if (this.isBoneChallenge || this.challenge?.isPuzzle) {
                const style = element.style;
                if (style) {
                    style.setProperty('transition', `none`);
                }
            }
        },
        draggableOnMove(itemKey, cursorX, cursorY, bbox = null, element = null) {
            this.updateDraggable(element, itemKey, bbox, false, cursorX, cursorY);
        },
        draggableOnEnd(itemKey, cursorX, cursorY, bbox = null, element = null) {
            this.updateDraggable(element, itemKey, bbox, true, cursorX, cursorY);
        },
        getDraggableSubItemRelatedClasses(itemKey) {
            const classes = [];
            if (itemKey > -1 && this.challenge?.isPuzzle && this.challenge?.pullInItemOnDrop) {
                const subPosition = this.challenge?.getItemsSubPosition(itemKey);
                if (subPosition > -1) {
                    classes.push('pulledIn');
                    if (subPosition % 2 == 0) {
                        classes.push('pulledInRight');
                    }
                    else {
                        classes.push('pulledInLeft');
                    }
                }
            }
            return classes;
        },
        onForwardButtonClick() {
            if (this.challenge) {
                if (this.solved) {
                    if (this.forwardUrl) {
                        if (this.showMotivationModal) {
                            modalHandler.show('motivation', {
                                forwardUrl: this.forwardUrl
                            });
                        }
                        else {
                            router.replace(this.forwardUrl);
                        }
                    }
                }
                if (this.challenge.state === 'chosen') {
                    this.challenge.evaluate();
                }
            }
        }
    }
});
