import { defineComponent } from 'vue';
import audioHandler from "@/data/audioHandler";
export default defineComponent({
    name: 'AudioHandlerContainer',
    data() {
        const audio = new Audio();
        return {
            initDone: false,
            audio,
            sounds: {}
        };
    },
    mounted() {
        this.init();
    },
    watch: {
        audioTrigger(newState, oldState) {
            if (newState != oldState) {
                this.play();
            }
        }
    },
    computed: {
        sound() {
            return audioHandler.sound;
        },
        audioTrigger() {
            return audioHandler.playCounter;
        },
    },
    methods: {
        init() {
            this.sounds = audioHandler.getSounds();
            for (const soundKey in this.sounds) {
                for (let soundIndex = 0; soundIndex < this.sounds[soundKey].size; soundIndex++) {
                    this.sounds[soundKey].states[soundIndex] = {
                        playing: false
                    };
                    const audio = new Audio(this.sounds[soundKey].sound);
                    audio.onended = () => {
                        this.sounds[soundKey].states[soundIndex].playing = false;
                    };
                    audio.preload = 'auto';
                    audio.classList.add(this.getSoundID(soundKey, Number(soundIndex)));
                    this.$el.append(audio);
                }
            }
        },
        getSoundID(key, index) {
            return `mediaPlayerAudioHandler_${key || ''}_${index || 0}`;
        },
        async play() {
            let soundIndex = -1;
            for (const nextFreeSoundIndex in this.sounds[this.sound].states) {
                if (!this.sounds[this.sound].states[nextFreeSoundIndex].playing) {
                    soundIndex = Number(nextFreeSoundIndex);
                    this.sounds[this.sound].states[nextFreeSoundIndex].playing = true;
                    break;
                }
            }
            if (soundIndex > -1) {
                const audio = this.$el.querySelector(`.${this.getSoundID(this.sound, soundIndex)}`);
                if (audio) {
                    try {
                        await audio.play();
                    }
                    catch (error) {
                        // prevent error on multiple fast replays
                        this.sounds[this.sound].states[soundIndex].playing = false;
                    }
                }
            }
        }
    }
});
