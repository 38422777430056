import { markRaw } from "vue";
import { Tour, TourPart } from "@/data/tourHandlerCore";
import { YEAR } from "@/lib/constants";
import { ChallengeItemMultiSelectText, ChallengeMultiSelectImageText } from "@/data/challenges/challengeMultiSelectImageText";
import { ChallengeItemPairImageText, ChallengePairImageText } from "@/data/challenges/challengePairImageText";
import { ChallengeItemTimelineText, ChallengeTimeline } from "@/data/challenges/challengeTimeline";
import { ChallengeItemPlaceOnAxis, ChallengePlaceOnAxis } from "@/data/challenges/challengePlaceOnAxis";
import { ChallengeItemPickDropZoneImage, ChallengeItemPickDropZoneText, ChallengePickDropZone } from "@/data/challenges/challengePickDropZone";
export default function (tourCollectionKey) {
    const tour = new Tour(tourCollectionKey, 'C', 'Schnüffeltour 3', false, '', true);
    tour.addPart(new TourPart('', 'Hör dir an, was Lorenz über seinen Besuch im Salzburger FIS Landesskimuseum zu berichten hat!', 'Letztes Jahr besuchte ich mit meiner Klasse das Landesskimuseum in Werfenweng. Da hat es mir gefallen.<br><br>Die zeigten uns dort, mit welchen Skiern man früher bei uns die Hänge hinunter gefahren ist.<br><br>Am meisten hat mich fasziniert, dass die Ski vor über 100 Jahren nur aus einem Stück Holz hergestellt wurden. Die schauen schon anders aus, als meine Ski. Aber Skifahren war bestimmt auch damals schon lustig.', true, null, false, false, 'ww_lorenz_museumsbesuch.m4a'));
    tour.addPart(new TourPart('', 'Lorenz hat heute seinen siebten Geburtstag. Genau vor einem Jahr war er im Museum in Werfenweng.<br><br>In welchem Jahr fanden die anderen Ereignisse statt? Jetzt musst du auch ein bisschen rechnen.', '', true, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 8), (3.5 + 0 * 15 / 8) / 22, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 7), (3.5 + 1 * 15 / 8) / 22, undefined, undefined, true, false, true, 'Geburt von Lorenz'),
        new ChallengeItemPlaceOnAxis(String(YEAR - 6), (3.5 + 2 * 15 / 8) / 22, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 5), (3.5 + 3 * 15 / 8) / 22, undefined, undefined, true, false, true, '2. Geburtstag von Lorenz'),
        new ChallengeItemPlaceOnAxis(String(YEAR - 4), (3.5 + 4 * 15 / 8) / 22, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 3), (3.5 + 5 * 15 / 8) / 22, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 2), (3.5 + 6 * 15 / 8) / 22, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis(String(YEAR - 1), (3.5 + 7 * 15 / 8) / 22, undefined, undefined, true, false, true, 'Museums-Besuch von Lorenz'),
        new ChallengeItemPlaceOnAxis(String(YEAR), (3.5 + 8 * 15 / 8) / 22, undefined, undefined, true, true, true, '7. Geburtstag von Lorenz'),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false, false),
    ], 1 / 22, true, true, true, 0, 0, false, false, false, false, false, false)));
    tour.addPart(new TourPart('', '', 'Lorenz feiert heute seinen siebten Geburtstag.<br><br>Welches der drei Ereignisse liegt für Lorenz nicht in der Vergangenheit?', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Museumsbesuch', false),
        new ChallengeItemMultiSelectText('Geburt', false),
        new ChallengeItemMultiSelectText('7. Geburtstag', true)
    ], undefined, true, true)));
    tour.addPart(new TourPart('', 'Das Salzburger FIS Landesskimuseum erzählt auch Geschichten. Dort wird ebenso etwas über die Vergangenheit erzählt.', 'Im Landesskimuseum wird auch die Geschichte des Skisprungs erzählt.<br><br>Man muss sich vorstellen, dass man vor über 80 Jahren ein besonderes Holz extra aus Amerika nach Europa brachte und dann daraus Sprungski baute. Vor ca. vierzig Jahren hatte sich der Ski aber schon stark weiterentwickelt. Er wurde aus verleimtem Holz und Kunststoff hergestellt. So wurde der Ski viel leichter als der Vorgänger.<br><br>Heute sind die Sprungskier nochmal leichter als damals. Die Technik wird immer besser.', true, undefined, false, false, 'ww_geschichte.m4a'));
    tour.addPart(new TourPart('', 'Vor über 80 Jahren wurden Skier aus einem Holz aus Amerika hergestellt.<br><br>Lorenz ist heute 7 Jahre alt geworden.<br><br>Wo am Zeitstrahl müssen seine sieben Lebensjahre eingeordnet werden? Ziehe!', '', true, new ChallengePlaceOnAxis([
        new ChallengeItemPlaceOnAxis('', 0, undefined, undefined, false, false),
        new ChallengeItemPlaceOnAxis('vor 80 Jahren', 2 / 21, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('', 10.5 / 21, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('', 18 / 21, .5 / 21, 2 / 21, false, false, true, 'Lebens-abschnitt von Lorenz'),
        new ChallengeItemPlaceOnAxis('heute', 19 / 21, undefined, undefined, true, false),
        new ChallengeItemPlaceOnAxis('', 1, undefined, undefined, false, false),
    ], 1 / 21, true, true, true)));
    tour.addPart(new TourPart('', 'Wo liegen die Zeitabschnitte? Ziehe!', '', true, new ChallengeTimeline([
        new ChallengeItemTimelineText('Vergangenheit', 'gestern'),
        new ChallengeItemTimelineText('Jetztzeit /<br>Gegenwart', 'heute'),
        new ChallengeItemTimelineText('Zukunft', 'morgen'),
    ])));
    tour.addPart(new TourPart('', '', 'Erzählt man in einem Museum über die Vergangenheit, nennt man das Geschichte.<br><br>Was braucht ein Museum dazu, um das gut zu machen?', true, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Wissen über die Zukunft', false),
        new ChallengeItemMultiSelectText('Dinge von früher', true),
        new ChallengeItemMultiSelectText('Fantasie', false)
    ], undefined, true, true)));
    tour.addPart(new TourPart('', '', 'Erzählt man in einem Museum über die Vergangenheit, nennt man das Geschichte.<br><br>Was braucht ein Museum dazu, um das gut zu machen?', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Dinge aus dem Supermarkt', false),
        new ChallengeItemMultiSelectText('alte Aufzeichnungen', true),
        new ChallengeItemMultiSelectText('Fotos von heute', false)
    ], undefined, true, true)));
    tour.addPart(new TourPart('', '', 'Es gibt unterschiedliche Museen. Manche Museen beschäftigen sich mit <b>Kunst</b>, andere mit <b>Technik</b> oder <b>Natur</b>, wieder andere mit <b>Geschichte</b>.<br><br>In Museen finden sich unterschiedliche Dinge.', true));
    tour.addPart(new TourPart('Finde die richtigen Paare!', 'Ziehe Bild und Erklärung zusammen.', '', false, new ChallengePairImageText([
        new ChallengeItemPairImageText('Kunst-<br>museum', 'bm_kunstmuseum_personen.jpg'),
        new ChallengeItemPairImageText('Naturkunde-<br>museum', 'bm_naturkundemuseum_muscheln.jpg'),
        new ChallengeItemPairImageText('Geschichts-<br>museum', 'bm_geschichtsmuseum_ruestungen.jpg'),
    ])));
    tour.addPart(new TourPart('', 'Das Salzburger FIS Landesskimuseum, ein besonderes Museum ...', 'Das <b>Salzburger Landesskimuseum ist ein Geschichtsmuseum</b>.<br><br>Es zeigt keine Kunst oder Dinge aus der Natur, sondern beschäftigt sich <b>mit der Entwicklung des Skifahrens</b> bei uns.<br><br>Dazu werden verschiedene Dinge gezeigt, mit denen Menschen am Schnee vorankamen. Am spannendsten sind dabei aber sicherlich die Ski selbst.', true, undefined, false, false, '', { name: 'ww_museum_intro.mp4', portrait: true }));
    tour.addPart(new TourPart('', '', 'Was wird also vom Salzburger FIS Landesskimuseum gezeigt?', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Kunst', false),
        new ChallengeItemMultiSelectText('Geschichte', true),
        new ChallengeItemMultiSelectText('Natur', false)
    ], undefined, false, true)));
    tour.addPart(new TourPart('', '', 'Die Dinge aus dem nächsten Spiel stammen aus dem Salzburger FIS Landesskimuseum.<br><br>Sie helfen uns zu erkennen, dass es früher anders war.<br><br>Manche Dinge zeigen im Vergleich zu heute, dass sie sich verändert haben. Andere Dinge gibt es gar nicht mehr.', true));
    tour.addPart(new TourPart('', 'Sind die Dinge aus der Vergangenheit Vorläufer von heutigen Snowboards? Ziehe!', '', true, new ChallengePickDropZone(new ChallengeItemPickDropZoneImage('ww_swingbo.png', 'Swingbo: Vorgänger des Snowboards mit taillierten Skiern unter einer Standplatte, Deutschland ca. 1984 (erfunden 1981)'), [
        new ChallengeItemPickDropZoneText('Kein Vorläufer', false),
        new ChallengeItemPickDropZoneText('Vorläufer', true)
    ])));
    tour.addPart(new TourPart('', 'Sind die Dinge aus der Vergangenheit Vorläufer von heutigen Snowboards? Ziehe!', '', true, new ChallengePickDropZone(new ChallengeItemPickDropZoneImage('ww_schneeschuh.png', 'Schneereifen, Österreich, Ende 19. Jh.'), [
        new ChallengeItemPickDropZoneText('Kein Vorläufer', true),
        new ChallengeItemPickDropZoneText('Vorläufer', false)
    ])));
    tour.addPart(new TourPart('', 'Sind die Dinge aus der Vergangenheit Vorläufer von heutigen Snowboards? Ziehe!', '', true, new ChallengePickDropZone(new ChallengeItemPickDropZoneImage('ww_snowboard_alt.png', 'Selbstgemachtes frühes Snowboard von Robert Jölli, Bischofshofen, 1984'), [
        new ChallengeItemPickDropZoneText('Kein Vorläufer', false),
        new ChallengeItemPickDropZoneText('Vorläufer', true)
    ])));
    tour.addPart(new TourPart('', 'Sind die Dinge aus der Vergangenheit Vorläufer von heutigen Snowboards? Ziehe!', '', true, new ChallengePickDropZone(new ChallengeItemPickDropZoneImage('ww_knappenbrett.png', 'Knappenrößl, Salzburg, ca. 1900'), [
        new ChallengeItemPickDropZoneText('Kein Vorläufer', false),
        new ChallengeItemPickDropZoneText('Vorläufer', true)
    ])));
    tour.addPart(new TourPart('', 'Sind die Dinge aus der Vergangenheit Vorläufer von heutigen Snowboards? Ziehe!', '', false, new ChallengePickDropZone(new ChallengeItemPickDropZoneImage('ww_rodel.png', 'Rodel, Österreich, 20. Jh.'), [
        new ChallengeItemPickDropZoneText('Kein Vorläufer', true),
        new ChallengeItemPickDropZoneText('Vorläufer', false)
    ])));
    return markRaw(tour);
}
