import { defineComponent } from "vue";
import pageHandler from "@/data/pageHandler";
import LayoutsMain from "@/components/layouts/Main.vue";
import Headline from "@/components/core/Headline.vue";
import Button from "@/components/core/Button.vue";
import Description from "@/components/core/Description.vue";
import ChallengeSingleSelectImage from "@/components/challenges/SingleSelectImage.vue";
import Audio from "@/components/core/Audio.vue";
import Video from "@/components/core/Video.vue";
import Image from "@/components/core/Image.vue";
import { tourHandler } from "@/data/tourHandler";
import router from "@/router";
export default defineComponent({
    name: 'ViewTourPart',
    components: { ChallengeSingleSelectImage, LayoutsMain, Headline, Description, Audio, Video, Image, Button },
    setup() {
        const route = router.currentRoute.value;
        const { tourCollectionKey, tourKey, tourPartIndex } = route.params;
        const tourCollection = tourHandler.getTourCollectionByKey(String(tourCollectionKey));
        const tour = tourCollection?.getTourByKey(String(tourKey));
        const tourPart = tour?.getPart(Number(tourPartIndex));
        return {
            tourCollectionKey,
            tourKey,
            tourPartIndex: parseInt(String(tourPartIndex)),
            numberOfParts: tour?.getNumberOfParts(),
            tourCollection,
            tour,
            tourPart,
        };
    },
    computed: {
        lastDeployAt() {
            return pageHandler.lastDeployAt;
        },
        waveColor() {
            if (this.tourPart?.challenge?.type === 'timeline' ||
                this.tourPart?.challenge?.type === 'orderTextCircle' ||
                this.tourPart?.challenge?.type === 'placeImageOnText') {
                return '#5690A9';
            }
            return '#2f728f';
        },
        bottomReadyToMeasure() {
            return pageHandler.bottomReadyToMeasure();
        },
        headlineClasses() {
            const classes = [];
            if (this.tourPart?.headline && !this.tourPart?.description) {
                classes.push('headlineOnly');
            }
            return classes.join(' ');
        }
    },
    ionViewWillEnter() {
        pageHandler.emit('beforePageEnter');
    },
    ionViewDidEnter() {
        pageHandler.emit('pageEnter');
    },
});
