import { defineComponent } from "vue";
import SymbolX from "@/components/symbols/x.vue";
import SymbolCheck from "@/components/symbols/check.vue";
import SymbolBone from "@/components/symbols/bone.vue";
import { vw } from "@/lib/utils";
import pageHookMixin from "@/data/pageHookMixin";
export default defineComponent({
    name: 'Button',
    mixins: [pageHookMixin],
    components: { SymbolBone, SymbolCheck, SymbolX },
    props: {
        symbol: {
            type: String,
            default: 'doubleArrows'
        },
        color: {
            type: String,
            default: 'gray'
        },
        size: {
            type: String,
            default: 'big'
        },
        arrowSize: {
            type: String,
            default: 'big'
        },
        arrowBold: {
            type: Boolean,
            default: false
        },
        supportAnimation: {
            type: Boolean,
            default: true
        },
        maxBones: {
            type: Number,
            default: 0
        },
        bones: {
            type: Number,
            default: 0
        },
        isPerfectScore: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        buttonClasses() {
            const classes = [];
            if (this.supportAnimation && this.color === 'green') {
                classes.push('bouncing');
            }
            return classes.join(' ');
        },
        arrowClasses() {
            const classes = ['symbol', 'arrows'];
            if (this.supportAnimation) {
                if (this.color === 'green') {
                    classes.push('moving');
                    classes.push('bouncing');
                }
                else if (this.color === 'orange') {
                    classes.push('moving');
                }
            }
            return classes.join(' ');
        },
        arrowStyle() {
            let height = '100%';
            if (this.arrowSize === 'small') {
                height = '70%';
            }
            else if (this.$slots.default) {
                height = '60%';
            }
            let marginLeft = 0;
            let marginRight = 0;
            if (this.$slots.default) {
                marginRight = 2;
                if (this.color === 'transparent') {
                    marginLeft = -.75;
                }
                else {
                    marginLeft = 2;
                }
            }
            return {
                height,
                'margin-left': vw(marginLeft),
                'margin-right': vw(marginRight)
            };
        },
        arrowStrokeWidth() {
            let strokeWidth = '10';
            if (this.arrowBold) {
                strokeWidth = '14';
            }
            return strokeWidth;
        },
        glossyColor() {
            let color = '#AAAAAA';
            if (this.color === 'green') {
                color = '#308130';
            }
            else if (this.color === 'darkgray') {
                color = '#69635D';
            }
            else if (this.color === 'orange') {
                color = '#FC9630';
            }
            else if (this.color === 'red') {
                color = '#BE3030';
            }
            else if (this.color === 'transparent') {
                color = 'transparent';
            }
            return color;
        },
        buttonStyle() {
            let color = '#999999';
            let borderColor = '#FFFFFF';
            if (this.color === 'green') {
                color = '#006600';
            }
            else if (this.color === 'darkgray') {
                color = '#444444';
            }
            else if (this.color === 'orange') {
                color = '#FF8000';
            }
            else if (this.color === 'red') {
                color = '#B20000';
            }
            else if (this.color === 'transparent') {
                color = 'transparent';
                borderColor = 'transparent';
            }
            let size = 13;
            let fontSize = '.875em';
            if (this.size === 'small') {
                size = 8;
                fontSize = '.75em';
            }
            let paddingRight = '0';
            if (this.color !== 'transparent' && this.$slots.default && this.maxBones < 1) {
                if (this.size === 'small') {
                    paddingRight = '.75em';
                }
                else {
                    paddingRight = '1em';
                }
            }
            let forwardButtonOpacity = 1;
            if (this.color === 'gray' || !this.color) {
                forwardButtonOpacity = .4;
            }
            const styles = {
                'background-color': `${color} !important`,
                'font-size': fontSize,
                'border-color': borderColor,
                'padding-right': paddingRight,
                '--forward-button-opacity': forwardButtonOpacity
            };
            if (this.$slots.default) {
                styles.height = vw(size);
            }
            else {
                styles.width = vw(size);
                styles.height = vw(size);
            }
            return styles;
        },
    },
    methods: {
        pageEnter() {
            if (this.maxBones > 0 && this.bones > 0) {
                this.$el.classList.add('animateBones');
            }
        }
    }
});
