import { defineComponent } from "vue";
import { ChallengePlaceImageOnImage } from "@/data/challenges/challengePlaceImageOnImage";
import Image from '@/components/core/Image.vue';
import Button from '@/components/core/Button.vue';
import router from '@/router';
import modalHandler from "@/data/modalHandler";
import pageHookMixin from "@/data/pageHookMixin";
import { onBBoxReady } from "@/lib/utils";
import SymbolSolution from "@/components/symbols/solution.vue";
export default defineComponent({
    name: 'ChallengePickTextBySwipe',
    mixins: [pageHookMixin],
    components: {
        SymbolSolution,
        Image,
        Button
    },
    props: {
        challenge: {
            type: ChallengePlaceImageOnImage
        },
        forwardUrl: {
            type: String
        },
        showMotivationModal: {
            type: Boolean,
            default: false
        },
        bottomReadyToMeasure: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        if (this.challenge && this.bottomReadyToMeasure) {
            this.startInit();
        }
    },
    updated() {
        if (this.bottomReadyToMeasure) {
            this.startInit();
        }
        if (!this.dropZonesRegistered) {
            const firstDropZone = this.$el.querySelector('.target .dropZone');
            if (firstDropZone) {
                onBBoxReady(firstDropZone, 75, () => this.updateDropZones());
            }
        }
    },
    data() {
        return {
            initialized: false,
            dropZonesRegistered: false,
            dropZones: this.challenge?.getDropZones() || {},
            referenceFrame: this.challenge?.getReferenceFrame() || {},
            targetImage: ''
        };
    },
    computed: {
        solved() {
            if (this.challenge) {
                return this.challenge.state === 'solved';
            }
            return false;
        },
        buttonColor() {
            if (this.challenge) {
                if (this.solved) {
                    return 'green';
                }
                if (this.challenge.state === 'chosen') {
                    return 'orange';
                }
            }
            return 'gray';
        },
    },
    methods: {
        onScreenUpdate() {
            router.go(0);
        },
        async startInit() {
            if (!this.initialized) {
                this.initialized = true;
                if (this.challenge?.targetImage) {
                    this.targetImage = await this.challenge?.targetImage.getImage();
                }
                onBBoxReady(this.$el.querySelector('.target'), 75, (bbox) => this.init(bbox));
            }
        },
        init(targetBBox) {
            this.referenceFrame = this.challenge?.initializeReferenceFrame(targetBBox);
            this.dropZones = this.challenge?.getDropZones() || [];
        },
        updateDropZones() {
            if (this.challenge) {
                this.dropZonesRegistered = true;
                const dropZones = this.$el.querySelectorAll('.target .dropZone');
                for (const dropZone of dropZones) {
                    if (dropZone) {
                        const dropZoneKey = Number(dropZone.dataset.dropZoneKey);
                        if (dropZoneKey) {
                            this.challenge?.registerDropZone(dropZoneKey, dropZone.getBoundingClientRect());
                        }
                    }
                }
            }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        draggableOnStart(itemKey, cursorX, cursorY, bbox = null, element = null) {
            if (element) {
                element.style.setProperty('transition', `none`);
            }
        },
        draggableOnMove(itemKey, cursorX, cursorY) {
            this.challenge?.selectItemDropZone(itemKey, cursorX, cursorY);
            this.dropZones = this.challenge?.getDropZones() || [];
        },
        draggableOnEnd(itemKey, cursorX, cursorY, bbox = null, element = null) {
            this.dropZones = this.challenge?.getDropZones() || [];
            if (element && bbox?.x) {
                for (const dropZoneKey in this.dropZones) {
                    const dropZoneState = this.dropZones[dropZoneKey];
                    if (dropZoneState.itemKey === itemKey) {
                        if (dropZoneState.valid) {
                            const offsetX = Number(String(element.style.getPropertyValue('--outer-offset-x') || '').replace(/px$/, ''));
                            const offsetY = Number(String(element.style.getPropertyValue('--outer-offset-y') || '').replace(/px$/, ''));
                            const x = Math.round(offsetX + dropZoneState.bbox.x - bbox.x);
                            const y = Math.round(offsetY + dropZoneState.bbox.y + (dropZoneState.bbox.height / 2) - (bbox.y + (bbox.height / 2)));
                            element.style.setProperty('transition', `transform .2s ease-in-out`);
                            element.style.setProperty('--outer-offset-x', `${x}px`);
                            element.style.setProperty('--outer-offset-y', `${y}px`);
                        }
                        break;
                    }
                }
            }
        },
        onForwardButtonClick() {
            if (this.challenge?.state === 'solved' && this.forwardUrl) {
                if (this.showMotivationModal) {
                    modalHandler.show('motivation', {
                        forwardUrl: this.forwardUrl
                    });
                }
                else {
                    router.replace(this.forwardUrl);
                }
            }
            if (this.challenge?.state === 'chosen') {
                this.dropZones = this.challenge?.getDropZones() || [];
                this.challenge.evaluate();
            }
        }
    }
});
