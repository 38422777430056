import ModalSpeechBubble from "@/components/modals/SpeechBubble.vue";
import Headline from '@/components/core/Headline.vue';
import Description from '@/components/core/Description.vue';
import Button from '@/components/core/Button.vue';
import modalHandler from '@/data/modalHandler';
import router from '@/router';
import userHandler from "@/data/userHandler";
import { defineComponent } from "vue";
export default defineComponent({
    name: 'ModalUserAlreadyExists',
    components: {
        ModalSpeechBubble,
        Headline,
        Description,
        Button
    },
    methods: {
        close(useIt = false) {
            const meta = modalHandler.getMeta();
            if (useIt && meta.preExistingUserIndex > -1) {
                userHandler.setUser(meta.preExistingUserIndex, true);
                router.push('/main/tourCollectionSelection');
            }
            modalHandler.hide();
        }
    }
});
