import { defineComponent } from "vue";
import pageHandler from "@/data/pageHandler";
import LayoutsMain from "@/components/layouts/Main.vue";
import Description from "@/components/core/Description.vue";
import router from "@/router";
import { tourHandler } from "@/data/tourHandler";
import userHandler from "@/data/userHandler";
import Button from "@/components/core/Button.vue";
import Media from "@/data/media";
export default defineComponent({
    name: 'ViewTourPartSummary',
    components: { LayoutsMain, Description, Button },
    data() {
        const route = router.currentRoute.value;
        const { tourCollectionKey, tourKey } = route.params;
        const tourCollection = tourHandler.getTourCollectionByKey(String(tourCollectionKey));
        const tour = tourCollection?.getTourByKey(String(tourKey));
        const username = userHandler.getUser().getName();
        this.prepare();
        return {
            tourCollectionKey,
            tourKey,
            tourCollection,
            tour,
            entries: {},
            username
        };
    },
    computed: {
        tourSummary() {
            return {
                headline: this.headline(this.username),
                description: this.description(this.username)
            };
        }
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        headline: (username = '', nrOfAchievedBones = 0, perfectScore = false) => {
            if (perfectScore)
                return 'Beeindruckend!';
            if (nrOfAchievedBones > 2)
                return 'Super!';
            return 'Geschafft!';
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        description: (username, nrOfAchievedBones = 0, perfectScore = false) => {
            return `${username}, willst du noch mehr erfahren?`;
        },
        async prepare() {
            this.entries = {
                'BM': {
                    logo: await new Media('image', 'logo_buermoos.jpg').getData(),
                    museum: await new Media('image', 'bm_invitation_mascot.jpg').getData(),
                    link: {
                        url: 'http://www.tgz-museum.at',
                        name: 'www.tgz-museum.at'
                    }
                },
                'BB': {
                    logo: await new Media('image', 'logo_bramberg.svg').getData(),
                    museum: await new Media('image', 'bb_museum_eingang.jpg').getData(),
                    link: {
                        url: 'https://www.museumbramberg.at/',
                        name: 'www.museumbramberg.at'
                    }
                },
                'WW': {
                    logo: await new Media('image', 'logo_werfenweng.jpg').getData(),
                    museum: await new Media('image', 'ww_museum_eingang.jpg').getData(),
                    link: {
                        url: 'https://www.skimuseum.at/',
                        name: 'www.skimuseum.at'
                    }
                },
            };
        },
        navTo(event) {
            let target = event.target;
            if (target) {
                while (!target.classList.contains('entry') && !!target.parentElement) {
                    target = target.parentElement;
                }
                for (const web of target.getElementsByClassName('web')) {
                    if (web) {
                        web.click();
                        break;
                    }
                }
            }
        },
        onForwardButtonClick() {
            if (this.tourCollection) {
                router.push({
                    path: this.tourCollection.getPath(),
                    replace: true,
                    force: true
                });
            }
        }
    },
    ionViewWillEnter() {
        pageHandler.emit('beforePageEnter');
    },
    ionViewDidEnter() {
        pageHandler.emit('pageEnter');
    },
});
