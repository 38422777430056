import { BaseChallenge, BaseChallengeItem } from "@/data/challenges/challenge";
import PickImage from "@/components/challenges/PickImage.vue";
import MediaImage from "@/data/mediaImage";
import userHandler from "@/data/userHandler";
export class ChallengePickImage extends BaseChallenge {
    dropZone;
    dropZoneText = '';
    dropZoneStyle = '';
    constructor(items = [], dropZoneText = '', dropZoneStyle = '') {
        super('pickImage', PickImage);
        this.items = items;
        this.dropZoneText = dropZoneText;
        this.dropZoneStyle = dropZoneStyle;
    }
    init() {
        this.state = '';
        this.dropZone = null;
        this.shuffle();
        this.postInit();
    }
    registerDropZone(bbox) {
        if (bbox?.x > 0 && bbox?.width > 0) {
            if (!this.dropZone) {
                this.dropZone = {
                    index: -1,
                    itemKey: -1,
                    itemIsCorrect: false,
                    itemKeys: {},
                    bbox
                };
            }
        }
    }
    selectItemDropZone(itemKey, cursorX, cursorY) {
        const dropZone = this.dropZone;
        const item = this.getItemByKey(itemKey);
        if (dropZone.bbox.x <= cursorX && cursorX <= (dropZone.bbox.x + dropZone.bbox.width)
            &&
                dropZone.bbox.y <= cursorY && cursorY <= (dropZone.bbox.y + dropZone.bbox.height)) {
            dropZone.itemKeys[itemKey] = true;
            if (dropZone.itemKey < 0) {
                dropZone.itemKey = itemKey;
                dropZone.itemIsCorrect = item?.isCorrect || false;
            }
        }
        else {
            delete dropZone.itemKeys[itemKey];
            if (dropZone.itemKey === itemKey) {
                const itemKeyIndexes = Object.keys(dropZone.itemKeys);
                if (itemKeyIndexes.length > 0) {
                    const nextItemKey = Number(itemKeyIndexes[0]);
                    const nextItem = this.getItemByKey(nextItemKey);
                    dropZone.itemIsCorrect = nextItem?.isCorrect;
                    dropZone.itemKey = nextItemKey;
                }
                else {
                    dropZone.itemIsCorrect = false;
                    dropZone.itemKey = -1;
                }
            }
        }
        if (this.state !== 'solved') {
            if (dropZone.itemKey > -1) {
                if (Object.keys(dropZone.itemKeys).length > 1) {
                    this.state = 'invalid';
                }
                else {
                    this.state = 'chosen';
                }
            }
            else {
                this.state = '';
            }
        }
    }
    getDropZoneState() {
        return {
            itemKey: this.dropZone?.itemKey || -1,
            itemIsCorrect: this.dropZone?.itemIsCorrect || false
        };
    }
    getNumberOfDropZones() {
        return this.dropZone ? 1 : 0;
    }
    getNumberOfItems() {
        return this.items.length;
    }
    isCorrect() {
        return this.state === 'solved' && this.dropZone?.itemIsCorrect;
    }
    evaluate() {
        if (this.state !== 'solved' && this.state !== 'invalid') {
            this.state = 'solved';
            if (this.dropZone?.itemIsCorrect) {
                userHandler.getUser().setScore(this.getTourCollectionKey(), this.getTourKey(), this.getTourPartIndex(), 1);
            }
            else {
                userHandler.getUser().setScore(this.getTourCollectionKey(), this.getTourKey(), this.getTourPartIndex(), 0);
            }
        }
    }
}
export class ChallengeItemPickImage extends BaseChallengeItem {
    media;
    isCorrect = false;
    constructor(imageName, imageDescription = '', isCorrect = false) {
        super('challenge');
        this.media = new MediaImage(imageName, undefined, imageDescription);
        this.isCorrect = isCorrect;
    }
}
