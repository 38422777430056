import { defineComponent } from "vue";
import userHandler from "@/data/userHandler";
import router from "@/router";
import Button from "@/components/core/Button.vue";
import pageHookMixin from "@/data/pageHookMixin";
export default defineComponent({
    name: 'LoginList',
    mixins: [pageHookMixin],
    components: {
        Button
    },
    data() {
        return {
            users: userHandler.getUserList()
        };
    },
    methods: {
        setUser(userIndex) {
            userHandler.setUser(userIndex);
            router.push('/main/tourCollectionSelection');
        }
    }
});
