import { reactive } from 'vue';
export class RouterHandler {
    isNextRouteGranted = false;
    grantNextRoute() {
        this.isNextRouteGranted = true;
    }
    nextRouteGranted() {
        const nextRouteGranted = this.isNextRouteGranted;
        this.isNextRouteGranted = false;
        return nextRouteGranted;
    }
}
const routerHandler = reactive(new RouterHandler());
export default routerHandler;
