import ImageZoom from '@/components/core/ImageZoom.vue';
import modalHandler from '@/data/modalHandler';
export default {
    name: 'EarlyLeaveTour',
    components: {
        ImageZoom
    },
    computed: {
        mediaImage() {
            return modalHandler.getMeta().mediaImage;
        }
    },
    methods: {
        close() {
            modalHandler.hide();
        }
    }
};
