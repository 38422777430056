import ModalSpeechBubble from "@/components/modals/SpeechBubble.vue";
import Headline from '@/components/core/Headline.vue';
import Description from '@/components/core/Description.vue';
import Button from '@/components/core/Button.vue';
import modalHandler from '@/data/modalHandler';
import router, { isInTour } from '@/router';
import userHandler from "@/data/userHandler";
import routerHandler from "@/data/routerHandler";
import { defineComponent } from "vue";
export default defineComponent({
    name: 'ModalRemoveUser',
    components: {
        ModalSpeechBubble,
        Headline,
        Description,
        Button
    },
    methods: {
        async close(doIt = false) {
            const userIndex = userHandler.getUser().getIndex();
            if (doIt && userIndex > -1 && await userHandler.removeUser(userIndex)) {
                routerHandler.grantNextRoute();
                router.push({
                    path: '/main/login',
                    replace: isInTour()
                });
            }
            modalHandler.hide();
        }
    }
});
