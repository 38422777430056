import { markRaw } from "vue";
import { Tour, TourPart } from "@/data/tourHandlerCore";
import { ChallengeItemMultiSelectImage, ChallengeItemMultiSelectText, ChallengeMultiSelectImageText } from "@/data/challenges/challengeMultiSelectImageText";
import { ChallengeItemPairImageText, ChallengePairImageText } from "@/data/challenges/challengePairImageText";
import { ChallengeItemPickImage, ChallengePickImage } from "@/data/challenges/challengePickImage";
import { ChallengeItemSingleSelectImage, ChallengeSingleSelectImage } from "@/data/challenges/challengeSingleSelectImage";
import { ChallengeItemTimelineImage, ChallengeTimeline } from "@/data/challenges/challengeTimeline";
export default function (tourCollectionKey) {
    const tour = new Tour(tourCollectionKey, 'B', 'Schnüffeltour 2', false, '', true);
    tour.addPart(new TourPart('Was ist ein Museum?', 'Jetzt geht\'s los: Drück den oberen Knopf und hör dir den Text an. Weiter kommst du mit dem Knopf unten.', 'In einem Museum werden verschiedene Dinge gesammelt und aufbewahrt. Ein Museum befindet sich zumeist in einem Haus. In diesem Haus gibt es Ausstellungen. Besucherinnen und Besucher können sich dort die gesammelten Dinge ansehen.<br><br>Geschichtsmuseen versuchen zu zeigen, was in der Vergangenheit passiert ist und wie Menschen früher gelebt haben. Die Leute vom Museum erforschen auch die gesammelten Gegenstände. Sie untersuchen, was man damit über die Vergangenheit herausfinden kann.', true, null, false, false, 'xx_was-ist-ein-museum.m4a'));
    tour.addPart(new TourPart('', 'Das Sammeln von besonderen Gegenständen ist die Aufgabe von Museen.<br><br>Geschichtsmuseen bewahren für uns Dinge aus der Vergangenheit in einem Lager auf. Dieses Lager nennt man Sammlung.<br><br>Welches Bild zeigt die <b>Sammlung</b> des Salzburger FIS Landesskimuseums?', '', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(false, 'ww_museum_eingang.jpg'),
        new ChallengeItemSingleSelectImage(false, 'ww_kassa.jpg'),
        new ChallengeItemSingleSelectImage(true, 'ww_sammlung.jpg'),
        new ChallengeItemSingleSelectImage(true, 'ww_ausstellung.jpg'),
    ])));
    tour.addPart(new TourPart('', 'In Ausstellungen können sich Besucherinnen und Besucher die gesammelten Gegenstände ansehen.<br><br>Welches Bild zeigt die <b>Ausstellung</b> des Salzburger FIS Landesskimuseums?', '', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(false, 'ww_museum_eingang.jpg'),
        new ChallengeItemSingleSelectImage(false, 'ww_kassa.jpg'),
        new ChallengeItemSingleSelectImage(true, 'ww_sammlung.jpg'),
        new ChallengeItemSingleSelectImage(true, 'ww_ausstellung.jpg'),
    ])));
    tour.addPart(new TourPart('', 'Ein Museum befindet sich in den meisten Fällen in einem Haus. Dieses Haus ist zu bestimmten Öffnungszeiten für alle interessierten Menschen zugänglich.<br><br>Welches Bild zeigt das <b>Haus</b> des Salzburger FIS Landesskimuseums?', '', false, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(true, 'ww_museum_eingang.jpg'),
        new ChallengeItemSingleSelectImage(false, 'ww_kassa.jpg'),
        new ChallengeItemSingleSelectImage(false, 'ww_sammlung.jpg'),
        new ChallengeItemSingleSelectImage(false, 'ww_ausstellung.jpg'),
    ])));
    tour.addPart(new TourPart('', 'Museen bewahren Dinge von früher für heute auf.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welcher Gegenstand ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('ww_skihelm_alt.png', 'Skihelm', true),
        new ChallengeItemPickImage('ww_skihelm_neu.png', 'Skihelm', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', 'Museen bewahren Dinge von früher für heute auf.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welcher Gegenstand ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('ww_skibob_alt.png', 'Skibob, Österreich, ca. 1950', true),
        new ChallengeItemPickImage('ww_skibob_neu.png', 'Skibike, Österreich, 2020', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', 'Museen bewahren Dinge von früher für heute auf.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welcher Gegenstand ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('ww_skibrille_alt.png', 'Skibrille', true),
        new ChallengeItemPickImage('ww_skibrille_neu.png', 'Skibrille', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', 'Museen bewahren Dinge von früher für heute auf.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welcher Gegenstand ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', true, new ChallengePickImage([
        new ChallengeItemPickImage('ww_startnummer_alt.png', 'Startnummer für Skirennen', true),
        new ChallengeItemPickImage('ww_startnummer_neu.png', 'Startnummer für Skirennen', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', 'Museen bewahren Dinge von früher für heute auf.<br><br>Tippe auf ein Bild, um es zu vergrößern.<br><br>Welcher Gegenstand ist von früher? Ziehe das Bild auf das Lösungsfeld!', '', false, new ChallengePickImage([
        new ChallengeItemPickImage('ww_skibindung_alt.png', 'Skibindung', true),
        new ChallengeItemPickImage('ww_skibindung_neu.png', 'Skibindung', false),
    ], 'Das ist von früher')));
    tour.addPart(new TourPart('', '', 'Manche Dinge von früher sind bis heute aufgehoben worden. Man kann diese Dinge untersuchen, um etwas über früher herauszufinden.<br><br>Dann bezeichnet man sie als <b>Quellen</b>. Mit Quellen kann man etwas über die Vergangenheit herausfinden.<br><br>Man unterscheidet verschiedene Arten von Quellen.', true));
    tour.addPart(new TourPart('Finde die richtigen Paare!', 'Ziehe Bild und Erklärung zusammen.', '', true, new ChallengePairImageText([
        new ChallengeItemPairImageText('Bildliche Quelle', 'bm_photo_album.jpg'),
        new ChallengeItemPairImageText('Gegenständliche Quelle', 'bb_holzschuessel.png'),
        new ChallengeItemPairImageText('Schriftliche Quelle', 'bm_tagebuch_der_anne_frank.jpg', 'Tagebuch der Anne Frank, geschrieben vor 80 Jahren (1942-1944)', {
            copyright: {
                name: 'Phantast987, via Wikimedia Commons',
                license: 'CC BY-SA 4.0',
                symbol: 'cc',
                url: 'https://commons.wikimedia.org/wiki/File:Das_Tagebuch_der_Anne_Frank.jpg'
            }
        }),
        new ChallengeItemPairImageText('Mündliche Quelle', 'bm_zeitzeuge.jpg'),
    ])));
    tour.addPart(new TourPart('', 'Quellen können schriftlich sein.', 'Welche Quelle passt nicht dazu?', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(false, 'bm_inkunabel_memmingen.jpg', ''),
        new ChallengeItemSingleSelectImage(false, 'bm_postcard.jpg', ''),
        new ChallengeItemSingleSelectImage(true, 'bm_lascaux.jpg', 'Malerei aus einer Höhle in Frankreich', {
            copyright: {
                name: 'Lascaux, Public domain, via Wikimedia Commons',
                license: '',
                symbol: 'cc',
                url: 'https://commons.wikimedia.org/wiki/File:Lascaux_painting.jpg'
            }
        }),
    ])));
    tour.addPart(new TourPart('', 'Quellen können bildlich sein.', 'Welche Quelle passt nicht dazu?', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(true, 'bm_photo_apparat.jpg', ''),
        new ChallengeItemSingleSelectImage(false, 'bm_photo_collage.jpg', ''),
        new ChallengeItemSingleSelectImage(false, 'bm_brueghel-tower-of-babel.jpg', 'Gemälde von Pieter Brueghel dem Älteren, gemalt vor etwa 450 Jahren (1563)', {
            copyright: {
                name: 'Pieter Brueghel the Elder, Public domain, via Wikimedia Commons',
                license: '',
                symbol: 'cc',
                url: 'https://commons.wikimedia.org/wiki/File:Brueghel-tower-of-babel.jpg'
            }
        }),
    ])));
    tour.addPart(new TourPart('', 'Quellen können gegenständlich sein.', 'Welche Quelle passt nicht dazu?', true, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(false, 'bm_naehmaschine_alt.jpg', 'Nähmaschine'),
        new ChallengeItemSingleSelectImage(false, 'bm_vase.jpg', 'Vase'),
        new ChallengeItemSingleSelectImage(true, 'bm_zeitzeuge_2.jpg', 'Zeitzeugin'),
    ])));
    tour.addPart(new TourPart('', 'Quellen können mündlich sein.', 'Welche Quelle passt nicht dazu?', false, new ChallengeSingleSelectImage([
        new ChallengeItemSingleSelectImage(true, 'bm_burg.jpg', 'Burg'),
        new ChallengeItemSingleSelectImage(false, 'bm_kind.jpg', 'Zeitzeuge'),
        new ChallengeItemSingleSelectImage(false, 'bm_zeitzeuge_3.jpg', 'Zeitzeuge'),
    ])));
    tour.addPart(new TourPart('', 'Forschung und Dokumentation als Aufgaben des Museums', 'Eine wichtige Aufgabe des Museums ist die Forschung.<br><br>Die Vergangenheit wird erforscht, um etwas über das Früher und über das Heute zu lernen – oder einfach nur, um zu staunen und sich zu unterhalten.<br><br>Deshalb werden Quellen, also Dinge aus der Vergangenheit, untersucht. Man fragt sich, was man mit ihnen über die Vergangenheit herausfinden kann.<br><br>Die Ergebnisse der Forschung werden in der Ausstellung den Besucherinnen und Besuchern gezeigt.', true, undefined, false, false, 'xx_forschung_dokumentation.m4a'));
    tour.addPart(new TourPart('', 'Wie und warum wird inventarisiert und geforscht im Museum?', 'Unsere Ausstellungsobjekte werden vermessen, fotografiert und es wird genau notiert, wo sie herkommen und wofür sie verwendet wurden. Außerdem ist bei den Skiern die Herstellungsweise und das Gewicht wichtig und welche Besonderheiten sie aufweisen.<br><br>Hier haben wir einen Sprungski aus dem Jahr 1940. Er ist aus schwerem Vollholz und lang geformt. Der 80 Jahre alte Sprungski wurde aus wertvollem Hickory Holz gebaut ...', true, undefined, false, false, undefined, { name: 'ww_inventarisierung.mp4', portrait: true }));
    tour.addPart(new TourPart('', 'Was kann man mit diesen drei Quellen erforschen? Markiere <b>alle</b> richtigen Antworten!', '', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Ob Ski auf der ganzen Welt gleich ausgesehen haben.', false),
        new ChallengeItemMultiSelectText('Ob sich Ski verändert haben.', true),
        new ChallengeItemMultiSelectText('Ob wir Ski heute noch so verwenden.', true),
        new ChallengeItemMultiSelectText('Wie Menschen früher zusammengelebt haben.', false)
    ], [
        new ChallengeItemMultiSelectImage('ww_sprungski_alt.png', undefined, 'Sprungski, Österreich, 1940, 80 Jahre alt'),
        new ChallengeItemMultiSelectImage('ww_sprungski_mittelalt.png', undefined, 'Sprungski, Österreich, 1977, 40 Jahre alt'),
        new ChallengeItemMultiSelectImage('ww_sprungski_neu.png', undefined, 'Sprungski, Österreich, 2010, 11 Jahre alt')
    ])));
    tour.addPart(new TourPart('', 'Mit diesen Quellen kann man etwas über die Entwicklung von Sprungskiern erfahren. Bringe die drei Objekte in die richtige Reihenfolge.', '', false, new ChallengeTimeline([
        new ChallengeItemTimelineImage('ww_sprungski_alt.png', 'Sprungski, Österreich, 1940, 80 Jahre alt'),
        new ChallengeItemTimelineImage('ww_sprungski_mittelalt.png', 'Sprungski, Österreich, 1977, 40 Jahre alt'),
        new ChallengeItemTimelineImage('ww_sprungski_neu.png', 'Sprungski, Österreich, 2010, 11 Jahre alt', {}, 'heute')
    ], 'alt', 'neu')));
    tour.addPart(new TourPart('', '', 'Anhand der drei Sprungski lässt sich die Entwicklung des Skispringens über die letzten 80 Jahre hinweg verfolgen. Die aus Holz gefertigten Sprungski aus den 1940er Jahren, also vor 80 Jahren, waren zwar schwer, aber aufgrund der besonderen Eigenschaften des Holzes waren sie auch sehr schnell in der Anfahrt. Mit ihnen konnte man bereits 100 Meter weit springen. Im Laufe der Zeit wurde zusätzlich zum Holz auch Kunststoff für die Herstellung der Ski genutzt. Vor 50 Jahren bekamen Sprungski einen Kern aus verleimtem Holz, eine Kunststoffoberfläche und eine Lauffläche mit Rillen ...', true, undefined, false, false, 'ww_schisprung.mp3'));
    tour.addPart(new TourPart('', 'Welche Frage wird in der Geschichte beantwortet?<br><br>Wenn du möchtest, kannst du dir hier die Erzählung gern noch einmal anhören.', '', false, new ChallengeMultiSelectImageText([
        new ChallengeItemMultiSelectText('Hat es zur Zeit der Kelten auch schon Ski gegeben?', false),
        new ChallengeItemMultiSelectText('Wie veränderten sich die Sprungski im Lauf der Zeit?', true),
        new ChallengeItemMultiSelectText('Wird es Ski auch in der Zukunft geben?', false)
    ], [], true), false, true, 'ww_schisprung.mp3'));
    return markRaw(tour);
}
