import { defineComponent } from "vue";
import Description from "@/components/core/Description.vue";
export default defineComponent({
    name: 'PrivacyPolicy',
    components: { Description },
    props: {
        isApp: {
            type: Boolean,
            default: false
        }
    }
});
