import { defineComponent } from 'vue';
import panzoom from 'panzoom';
import { onBBoxReady } from '@/lib/utils';
import MediaImage from '@/data/mediaImage';
import ImageCopyright from "@/components/core/ImageCopyright.vue";
import pageHookMixin from "@/data/pageHookMixin";
import { IonSpinner } from "@ionic/vue";
let touched = false;
let preventClickInFuture = false;
let preventClick = false;
export default defineComponent({
    name: 'Image',
    components: { ImageCopyright, IonSpinner },
    mixins: [pageHookMixin],
    props: {
        mediaImage: {
            type: MediaImage
        }
    },
    unmounted() {
        touched = false;
        preventClick = false;
        preventClickInFuture = false;
        this.panzoom = null;
        this.image = '';
    },
    data() {
        let spinnerColor = 'light';
        if (this.mediaImage?.meta.isDark) {
            spinnerColor = 'dark';
        }
        this.prepare();
        return {
            image: '',
            highlightBBox: {
                x: 0,
                y: 0,
                w: 0,
                h: 0
            },
            panzoom: null,
            spinnerColor,
        };
    },
    mounted() {
        touched = false;
        preventClick = false;
        preventClickInFuture = false;
        this.panzoom = panzoom(this.$refs.img, {
            initialZoom: 1,
            minZoom: 1,
            maxZoom: 4,
            bounds: true,
            smoothScroll: false,
        });
        this.panzoom.on('pan', () => {
            if (!preventClickInFuture) {
                preventClickInFuture = true;
                setTimeout(() => {
                    preventClick = true;
                }, 50);
            }
        });
        this.panzoom.on('panstart', () => {
            if (!preventClickInFuture) {
                preventClickInFuture = true;
                setTimeout(() => {
                    preventClick = true;
                }, 50);
            }
        });
        this.panzoom.on('zoom', () => {
            preventClick = true;
        });
        this.panzoom.on('zoomend', () => {
            preventClick = true;
        });
    },
    computed: {
        classes() {
            const classes = ['imageZoom', 'container'];
            if (this.mediaImage?.meta.isDark) {
                classes.push('light');
            }
            else {
                classes.push('dark');
            }
            return classes.join(' ');
        }
    },
    methods: {
        async prepare() {
            if (this.mediaImage) {
                this.image = await this.mediaImage.getImage();
                const bbox = this.mediaImage.getHighlightBBox();
                if (bbox.w > 0) {
                    this.highlightBBox = {
                        x: Math.round(bbox.x * 100),
                        y: Math.round(bbox.y * 100),
                        w: Math.round(bbox.w * 100),
                        h: Math.round(bbox.h * 100),
                    };
                    onBBoxReady(this.$refs.containment, 75, (containerBBox) => this.zoomIn(containerBBox, bbox));
                }
                else {
                    this.$el.classList.add('animate');
                }
            }
        },
        zoomIn(containerBBox, highlightBBox) {
            const screenBBox = this.$el.getBoundingClientRect();
            if (this.panzoom !== null) {
                const mainContainerBBox = this.$refs.img.getBoundingClientRect();
                const targetX = (highlightBBox.x + (highlightBBox.w / 2)) * containerBBox.width;
                const targetY = ((highlightBBox.y + (highlightBBox.h / 2)) * containerBBox.height) - ((screenBBox.height - mainContainerBBox.height) / 2);
                // center highlighted box while hidden
                this.panzoom.moveTo((containerBBox.width / 2) - targetX, (containerBBox.height / 2) - targetY);
                // zoom in on center and animate show
                this.panzoom.smoothZoomAbs((screenBBox.width / 2), (screenBBox.height / 2), 2);
                this.$el.classList.add('animate');
            }
        },
        click(event) {
            if (!preventClick) {
                this.close(event);
            }
            touched = false;
            preventClick = false;
            preventClickInFuture = false;
        },
        close(event) {
            const copyrightLink = event?.target?.closest('a.link');
            if (!copyrightLink) {
                event.preventDefault();
                event.stopImmediatePropagation();
                if (this.panzoom) {
                    this.panzoom.dispose();
                }
                this.$emit('ImageZoomClose', event);
            }
        },
        touchStart() {
            touched = true;
        },
        touchEnd(event) {
            if (touched) {
                if (!preventClick) {
                    this.close(event);
                }
                touched = false;
                preventClick = false;
                preventClickInFuture = false;
            }
        },
    }
});
